import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'INRFormat'
})
export class InrFormatPipe implements PipeTransform {
  transform(value: number | string): string {
    let parsedNumber: number;
    if (value == null) {
      return '0';
    }
    if (typeof value === 'string') {
      parsedNumber = parseFloat(value.replace(/,/g, ''));
    } else {
      parsedNumber = value;
    }

    // Handle large numbers based on their scale
    if (Math.abs(parsedNumber) >= 10000000) {
      return this.formatValue(parsedNumber, 10000000, 'CR');
    } else if (Math.abs(parsedNumber) >= 100000) {
      return this.formatValue(parsedNumber, 100000, 'L');
    } else if (Math.abs(parsedNumber) >= 1000) {
      return this.formatValue(parsedNumber, 1000, 'K');
    } else {
      return this.roundToTwoDecimals(parsedNumber).toString();
    }
  }

  private formatValue(value: number, divisor: number, suffix: string): string {
    const result = value / divisor;
    const roundedResult = this.roundToTwoDecimals(result);

    // Return value with suffix, handling negative numbers correctly
    return `${roundedResult}${suffix}`;
  }

  private roundToTwoDecimals(value: number): string {
    // Check if value is a whole number
    if (value % 1 === 0) {
      return value.toFixed(0);
    }
    return value.toFixed(2);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productdetailMissing',
  pure: false
})
export class ProductdetailMissingPipe implements PipeTransform {
  transform(orderDetail: any, Products: any): boolean {
    const productDetails = Products[orderDetail?.record?.esin || orderDetail?.esin];
    let isAnyDetailMissing = orderDetail?.record?.isProductInfoMissing || orderDetail?.isProductInfoMissing;
    if (isAnyDetailMissing == undefined) {
      return false;
    }
    if (productDetails != undefined) {
      isAnyDetailMissing =
        productDetails['hsnCode'] == undefined ||
        productDetails['hsnCode'] == null ||
        productDetails['hsnCode'] == '' ||
        productDetails['taxCode'] == undefined ||
        productDetails['taxCode'] == null ||
        productDetails['taxCode'] == '' ||
        productDetails['mrp'] == undefined ||
        productDetails['mrp'] == null ||
        productDetails['mrp'] == '' ||
        productDetails['weight'] == undefined ||
        productDetails['weight'] == null ||
        productDetails['weight'] == '' ||
        productDetails['dimensionLength'] == undefined ||
        productDetails['dimensionLength'] == null ||
        productDetails['dimensionLength'] == '' ||
        productDetails['dimensionHeight'] == undefined ||
        productDetails['dimensionHeight'] == null ||
        productDetails['dimensionHeight'] == '' ||
        productDetails['dimensionWidth'] == undefined ||
        productDetails['dimensionWidth'] == null ||
        productDetails['dimensionWidth'] == '';
    }
    return isAnyDetailMissing;
  }
}

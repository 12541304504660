import * as segment from '../actions/segment.actions';
import { SegmentState } from '../state/segment.state';

export const initialState: SegmentState = {
  segments: { loading: false, loaded: false, segment: null },
  returnCount: { loading: false, loaded: false, count: null },
  allOrdersCount: { loading: false, loaded: false, count: null },
  filters: { loading: false, loaded: false, filters: null },
  editSegment: {
    isEditSegmentLoading: false,
    isEditSegmentLoaded: false,
    modifiedSegmendData: null
  },
  deleteSegment: {
    isDeleteSegmentLoding: false,
    isDeleteSegmentLoaded: false,
    deletedSegment: null
  },
  saveSegment: {
    isSaveSegmentLoading: false,
    isSaveSegmentLoaded: false,
    savedSegmentData: null
  },
  noScanReturnsFilterData: {
    loading: false,
    loaded: false,
    filters: null
  }
};

export function SegmentReducer(state = initialState, action: segment.SegmentUnion): SegmentState {
  switch (action.type) {
    case segment.SegmentActionTypes.GET_NEW_SEGMENTS_DATA: {
      return {
        ...state,
        segments: {
          loading: true,
          loaded: false,
          segment: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_NEW_SEGMENTS_DATA_SUCCESS: {
      return {
        ...state,
        segments: {
          loading: false,
          loaded: true,
          segment: action.payload
        }
      };
    }
    case segment.SegmentActionTypes.GET_NEW_SEGMENTS_DATA_FAILURE: {
      return {
        ...state,
        segments: {
          loading: true, // Requirement was to show loading state if API fails
          loaded: true,
          segment: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA: {
      return {
        ...state,
        allOrdersCount: {
          loading: true,
          loaded: false,
          count: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_SUCCESS: {
      let updatedCount;
      if (action.payload.operation === 'add') {
        // Add to existing count
        updatedCount = { ...(action.payload.countData || {}) };
        for (const key in action.payload['data']) {
          if (action.payload['data'].hasOwnProperty(key)) {
            updatedCount[key] = action.payload['data'][key];
          }
        }
      } else {
        // Replace existing count
        updatedCount = action.payload['data'];
      }
      return {
        ...state,
        allOrdersCount: {
          ...state.allOrdersCount,
          loading: false,
          loaded: true,
          count: action.payload.operation === 'add' ? { ...state.allOrdersCount.count, ...updatedCount } : updatedCount
        }
      };
    }
    case segment.SegmentActionTypes.GET_ALL_ORDERS_COUNT_DATA_FAILURE: {
      return {
        ...state,
        allOrdersCount: {
          loading: true, // Requirement was to show loading state if API fails
          loaded: true,
          count: action.payload
        }
      };
    }
    case segment.SegmentActionTypes.SAVE_SEGMENT:
      return {
        ...state,
        saveSegment: {
          isSaveSegmentLoading: true,
          isSaveSegmentLoaded: false,
          savedSegmentData: null
        }
      };
    case segment.SegmentActionTypes.SAVE_SEGMENT_SUCCESS:
      return {
        ...state,
        saveSegment: {
          isSaveSegmentLoading: false,
          isSaveSegmentLoaded: true,
          savedSegmentData: action.payload
        }
      };
    case segment.SegmentActionTypes.SAVE_SEGMENT_FAILURE:
      return {
        ...state,
        saveSegment: {
          isSaveSegmentLoading: false,
          isSaveSegmentLoaded: true,
          savedSegmentData: action.payload
        }
      };

    case segment.SegmentActionTypes.EDIT_SEGMENT:
      return {
        ...state,
        editSegment: {
          isEditSegmentLoading: true,
          isEditSegmentLoaded: false,
          modifiedSegmendData: null
        }
      };
    case segment.SegmentActionTypes.EDIT_SEGMENT_SUCCESS:
      return {
        ...state,
        editSegment: {
          isEditSegmentLoading: false,
          isEditSegmentLoaded: true,
          modifiedSegmendData: action.payload
        }
      };
    case segment.SegmentActionTypes.EDIT_SEGMENT_FAILURE:
      return {
        ...state,
        editSegment: {
          isEditSegmentLoading: false,
          isEditSegmentLoaded: true,
          modifiedSegmendData: action.payload
        }
      };

    case segment.SegmentActionTypes.DELETE_SEGMENT:
      return {
        ...state,
        deleteSegment: {
          isDeleteSegmentLoding: true,
          isDeleteSegmentLoaded: false,
          deletedSegment: null
        }
      };
    case segment.SegmentActionTypes.DELETE_SEGMENT_SUCCESS:
      return {
        ...state,
        deleteSegment: {
          isDeleteSegmentLoding: false,
          isDeleteSegmentLoaded: true,
          deletedSegment: action.payload
        }
      };
    case segment.SegmentActionTypes.DELETE_SEGMENT_FAILURE:
      return {
        ...state,
        deleteSegment: {
          isDeleteSegmentLoding: false,
          isDeleteSegmentLoaded: true,
          deletedSegment: action.payload
        }
      };

    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS: {
      return {
        ...state,
        noScanReturnsFilterData: {
          loading: true,
          loaded: false,
          filters: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_SUCCESS: {
      return {
        ...state,
        noScanReturnsFilterData: {
          loading: false,
          loaded: true,
          filters: action.payload
        }
      };
    }
    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_FILTERS_FAILURE: {
      return {
        ...state,
        noScanReturnsFilterData: {
          loading: true,
          loaded: false,
          filters: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA: {
      return {
        ...state,
        returnCount: {
          loading: true,
          loaded: false,
          count: null
        }
      };
    }
    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_SUCCESS: {
      let updatedCount;
      if (action.payload.operation === 'add') {
        // Add to existing count
        updatedCount = { ...(action.payload.countData || {}) };
        for (const key in action.payload['data']) {
          if (action.payload['data'].hasOwnProperty(key)) {
            updatedCount[key] = action.payload['data'][key];
          }
        }
      } else {
        // Replace existing count
        updatedCount = action.payload['data'];
      }
      return {
        ...state,
        returnCount: {
          ...state.allOrdersCount,
          loading: false,
          loaded: true,
          count: updatedCount
        }
      };
    }
    case segment.SegmentActionTypes.GET_NO_SCAN_RETURN_COUNT_DATA_FAILURE: {
      return {
        ...state,
        returnCount: {
          loading: true, // Requirement was to show loading state if API fails
          loaded: true,
          count: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getSegmentsData = (state: SegmentState) => state.segments;
export const getAllOrdersCountData = (state: SegmentState) => state.allOrdersCount;
export const getSavedSegmentDetails = (state: SegmentState) => state.saveSegment;
export const getEditSegmentDetails = (state: SegmentState) => state.editSegment;
export const getDeleteSegmentDetails = (state: SegmentState) => state.deleteSegment;
export const getNoScanReturnFiltersData = (state: SegmentState) => state.noScanReturnsFilterData;
export const getNoScanReturnSegmentCountData = (state: SegmentState) => state.returnCount;

<div
  class="all-select select_export export-consignment"
  [ngClass]="
    ['draft_return_report', 'incorrect_return_item_report'].includes(templateSelected.jobType) ? 'draft-select' : ''
  "
  *ngIf="
    templateSelected &&
    templateSelected.jobType != 'product' &&
    templateSelected.jobType != 'sale_order_report' &&
    templateSelected.jobType != 'sale_order_report_v2'
  "
>
  <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero">
    <div class="col-4">
      <ul class="select-list">
        <h4>
          <mat-checkbox
            (change)="toggleParent($event, item)"
            (click)="$event.stopPropagation()"
            [checked]="selectAllOption[item.key]"
            [indeterminate]="someComplete(item)"
          >
            {{ item?.key }}
            <div class="mat-tip" *ngIf="templateList?.parentCheckboxInfo">
              <ng-container *ngIf="(templateList?.parentCheckboxInfo)[item?.key]">
                <img
                  tooltip="{{ (templateList?.parentCheckboxInfo)[item?.key] }}"
                  placement="right"
                  tooltipWidth="200"
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
                  class="export-info-icon"
                />
              </ng-container>
            </div>
          </mat-checkbox>
        </h4>
        <li *ngFor="let f of item.value">
          <!-- <ng-container *ngIf="f == 'Inventory Age'">
            <ng-container *ngIf="accountSlugCheck()">
              <mat-checkbox
                [checked]="f?.checked"
                (change)="toggleSelection($event, f, item)"
                (click)="$event.stopPropagation()"
              >
                {{ f?.key }}
                <div class="mat-tip" *ngIf="f?.info">
                  <img
                    tooltip="{{ f?.info }}"
                    placement="right"
                    tooltipWidth="200"
                    src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
                    class="export-info-icon"
                  />
                </div>
              </mat-checkbox>
            </ng-container>
          </ng-container> -->

          <ng-container
            *ngIf="accountSlug != 'eshop' && f?.key != 'Brand' && f?.key != 'Workspace' && f?.key != 'Selling Party'"
          >
            <mat-checkbox
              [checked]="f?.checked"
              (change)="toggleSelection($event, f, item)"
              (click)="$event.stopPropagation()"
            >
              {{ f?.key }}
              <div class="mat-tip" *ngIf="f?.info">
                <img
                  tooltip="{{ f?.info }}"
                  placement="right"
                  tooltipWidth="200"
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
                  class="export-info-icon"
                />
              </div>
            </mat-checkbox>
          </ng-container>
          <ng-container *ngIf="accountSlug == 'eshop'">
            <mat-checkbox
              [checked]="f?.checked"
              (change)="toggleSelection($event, f, item)"
              (click)="$event.stopPropagation()"
            >
              {{ f?.key }}
              <div class="mat-tip" *ngIf="f?.info">
                <img
                  tooltip="{{ f?.info }}"
                  placement="right"
                  tooltipWidth="200"
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/export-info-ic.svg"
                  class="export-info-icon"
                />
              </div>
            </mat-checkbox>
          </ng-container>
        </li>
      </ul>
    </div>
  </ng-container>
</div>

<!-- Sales Export with Cancellation key-->
<div
  class="all-select select_export"
  *ngIf="
    templateSelected &&
    (templateSelected.jobType == 'sale_order_report' || templateSelected.jobType == 'sale_order_report_v2') &&
    templateSelected.columnStatus &&
    templateSelected.columnStatus['Cancellation']
  "
>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i <= 2">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 2 && i <= 5">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 5 && i <= 8">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 8">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Sales Export without Cancellation key-->
<div
  class="all-select select_export"
  *ngIf="
    templateSelected &&
    (templateSelected.jobType == 'sale_order_report' || templateSelected.jobType == 'sale_order_report_v2') &&
    templateSelected.columnStatus &&
    !templateSelected.columnStatus['Cancellation']
  "
>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i <= 1">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 1 && i <= 3">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 3 && i <= 5">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 5">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Product Export -->
<div class="all-select select_export" *ngIf="templateSelected && templateSelected.jobType == 'product'">
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i <= 1 && !arrayToinclude.includes(item.key)">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container *ngIf="i > 1 && i <= 3 && !arrayToinclude.includes(item.key)">
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container
        *ngIf="i > 3 && i < objectKeys(templateSelected.columnStatus).length - 1 && !arrayToinclude.includes(item.key)"
      >
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
  <div class="col-4">
    <ng-container *ngFor="let item of templateSelected.columnStatus | keyvalue: returnZero; index as i">
      <ng-container
        *ngIf="i == objectKeys(templateSelected.columnStatus).length - 1 && !arrayToinclude.includes(item.key)"
      >
        <app-export-product-columns
          [item]="item"
          [templateList]="templateList"
          [templateSelected]="templateSelected"
          [selectAllOption]="selectAllOption"
          (toggleParent)="toggleParentEvent($event)"
          (toggleSelection)="toggleSelectionEvent($event)"
        ></app-export-product-columns>
      </ng-container>
    </ng-container>
  </div>
</div>

import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ALERT_MESSAGES } from '../constants/constants';
import { AlertService, AuthService, SharedService } from '../shared/services';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, private alertService: AlertService, private sharedService: SharedService) {}
  tokenRefreshed: boolean = false;
  renewTokenTime = 3 * 60 * 60 * 1000; //  3 hours (10800000)
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getAuthToken()) {
      var decoded: any = jwt_decode(this.auth.getAuthToken());
      let currentTime = Date.now();
      if (decoded.exp * 1000 - currentTime < this.renewTokenTime && !this.tokenRefreshed) {
        const isEshopUser = localStorage.getItem('email')
          ? localStorage.getItem('email').includes('@eshopbox.com')
          : false;
        if (isEshopUser) {
          try {
            this.auth.checkSessionEshopUser();
          } catch (error) {
            return;
          }
        } else this.auth.checkSession();
        this.tokenRefreshed = true;
        setTimeout(() => {
          this.tokenRefreshed = false;
        }, 10000);
      }
    }
    let accountSlug: any = this.auth.getAccountSlug() || 'auth';
    if (
      request.url.includes('buildingblock') &&
      (!request.urlWithParams || !request.urlWithParams.includes('fromWorkspace'))
    )
      accountSlug = this.auth.getFlexSlug();
    let apiUrl =
      request.url.indexOf('user-account-mapping') > -1 && environment.production
        ? `https://${accountSlug}${environment.BASE_DOMAIN}${environment.API_DOMAIN}`
        : !environment.production
        ? 'https://' + accountSlug + environment.API_DOMAIN
        : 'https://' + window.location.hostname + environment.API_DOMAIN;
    if (request.url.includes('upload-template')) {
      if (environment.BASE_DOMAIN === '.myeshopbox.com') {
        apiUrl = 'https://client-portal-upload-jobs.myeshopbox.com/';
      } else {
        apiUrl = 'https://client-portal-jobs-dev.auperator.co/';
      }
    }
    if (
      request.url == 'b2bOrders/api/esb/v1/confirm/b2bOrder' ||
      request.url == 'b2bOrders/api/esb/v1/validateCsvFile/b2bOrder'
    ) {
      // link-> https://productstagingcompute.auperator.co/b2bOrders/api/esb/v1/confirm/b2bOrder
      apiUrl = 'https://productstagingcompute.auperator.co/';
    }
    if (
      window.location.pathname.includes('building-blocks') &&
      (request.url == 'platform/api/v1/export-job' ||
        request.url == 'platform/api/v1/import-job' ||
        request.url == 'b2b-orders/api/v1/picklists/preview')
    ) {
      accountSlug = this.auth.getFlexSlug();
      apiUrl = `https://${accountSlug}${environment.BASE_DOMAIN}${environment.API_DOMAIN}`;
    }
    if (request.url == '/woocommerce/api/v1/taxClasses' || request.url == '/woocommerce/api/v1/attributes') {
      apiUrl = 'https://' + accountSlug + environment.BASE_DOMAIN;
    }
    if (!navigator.onLine) {
      this.alertService.showError(ALERT_MESSAGES.NO_INTERNET_CONNECTION);
    } else {
      // todo hack to be removed
      var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
      var dupRequest;
      if (
        // apidetector !== 'searchOrderReturnIndexData' &&
        apidetector !== 'fees' &&
        apidetector !== 'fetchFees' &&
        apidetector !== 'saveTransactionRule' &&
        apidetector !== 'updateTransactionRule' &&
        apidetector !== 'fetchChannelsForTransaction' &&
        apidetector !== 'fetchTransactionRules' &&
        apidetector !== 'getFeesList' &&
        apidetector !== 'feeRule' &&
        apidetector !== 'feeRule' &&
        apidetector !== 'addFeeRule' &&
        apidetector !== 'getFeesDetails' &&
        apidetector !== 'getBrand' &&
        apidetector !== 'getVersions' &&
        apidetector !== 'getCategory' &&
        apidetector !== 'reviseFeeRule' &&
        apidetector !== 'import-job' &&
        apidetector !== 'getFeesDetailByDocumentId' &&
        apidetector !== 'cloneFeeRule' &&
        apidetector !== 'export-job' &&
        // apidetector !== 'setup' &&
        request.url.indexOf('payments/api/v1/save') === -1 &&
        request.url.indexOf('payments/api/v1/standardPayout') === -1 &&
        request.url.indexOf('appinstall/consent') === -1 &&
        request.url.indexOf('payout') === -1 &&
        request.url.indexOf('fetchPaymentReportStatus') === -1 &&
        request.url.indexOf('receivables') === -1 &&
        request.url.indexOf('payables') === -1 &&
        request.url.indexOf('expenseInvoiceApi') === -1 &&
        request.url.indexOf('statement') === -1 &&
        request.url.indexOf('getSettlementDetails') === -1 &&
        request.url.indexOf('/appinstall/consent') === -1 &&
        request.url.indexOf('payments/api/v1/dashboardRevenue') === -1 &&
        request.url.indexOf('consignment/grnItems') === -1 &&
        request.url.indexOf('nykaa/product/sync/cron') === -1 &&
        request.url.indexOf('incident') === -1 &&
        request.url.indexOf('incidents') === -1 &&
        request.url.indexOf('incident/affectedArea/import') === -1 &&
        request.url.indexOf('billing') === -1 &&
        request.url.indexOf('/b2b-orders/') === -1 &&
        request.url.indexOf('b2b-orders/api/v1/orderItemDetails') === -1
      ) {
        // const apiUrl = 'https://' + accountSlug + '.' + environment.API_URL;
        if (request.url.includes(accountSlug)) {
          dupRequest = request.clone({
            url: request.url,
            setHeaders: {
              Authorization: `Bearer ${this.auth.getAuthToken()}`
            }
          });
        } else {
          dupRequest = request.clone({
            url: apiUrl + request.url,
            setHeaders: {
              Authorization: `Bearer ${this.auth.getAuthToken()}`
            }
          });
        }
      } else {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }

      if (
        apidetector === 'products' ||
        request.url.includes('v1/products') ||
        request.url.includes('activity') ||
        apidetector === 'property' ||
        request.url.includes('property') ||
        request.url.includes('platform') ||
        request.url.includes('key') ||
        request.url.includes('reportDetailsOnParams') ||
        request.url.includes('templates') ||
        request.url.includes('listings') ||
        request.url.includes('productListing') ||
        request.url.includes('mergeProducts') ||
        request.url.includes('v2/access_token') ||
        request.url.includes('buildingblock') ||
        request.url.includes('fields') ||
        request.url.includes('connection/start') ||
        request.url.includes('appinstall/connection') ||
        request.url.includes('connection') ||
        request.url.includes('config') ||
        request.url.includes('v1/connection/channel') ||
        request.url.includes('setup-steps') ||
        request.url.includes('setup') ||
        request.url.includes('getColumns') ||
        request.url.includes('segment') ||
        request.url.includes('salesChannelInventory') ||
        request.url.includes('locationWiseInventoryDistribution') ||
        request.url.includes('snapshotInventoryStatus') ||
        request.url.includes('channel-inventory') ||
        request.url.includes('customer-portal') ||
        request.url.includes('checkOldReportDetailsOnParams') ||
        request.url.includes('checkOldReportDetailsOnParamsOld') ||
        request.url.includes('salesChannelUnavailableInventory') ||
        request.url.includes('warehouses/inventoryAge') ||
        request.url.includes('esins/inventoryAge') ||
        request.url.includes('getSalesChannelInventoryfromCache') ||
        request.url.includes('appinstall/syncjob') ||
        request.url.includes('payments/cod/api/v1/orders') ||
        request.url.includes('payments/cod/api/v1/payment') ||
        request.url.includes('payments/cod/api/v1/finance/payment') ||
        request.url.includes('payments/cod/api/v1/order/summary') ||
        request.url.includes('payments/cod/api/v1/payment/summary') ||
        request.url.includes('appinstall/syncjob') ||
        request.url.includes('payments/cod/api/v1/bank/details') ||
        request.url.includes('payments/cod/api/v1/bank') ||
        request.url.includes('payments/cod/api/v1/business') ||
        request.url.includes('cod/api/v1/bank/verification') ||
        request.url.includes('payments/cod/api/v1/order/summary') ||
        request.url.includes('platform/api/v1/export-job') ||
        request.url.includes('payments/cod/api/v1/account-slug') ||
        request.url.includes('payments/cod/api/v1/payment/adjustment-settings') ||
        request.url.includes('payments/cod/api/v1/payment/adjustment') ||
        request.url.includes('payments/cod/api/v1/payment/complete') ||
        request.url.includes('payments/cod/api/v1/payment/initiate') ||
        request.url.includes('payments/cod/api/v1/payment/fail') ||
        request.url.includes('payments/cod/api/v1/payment/completed') ||
        request.url.includes('payments/cod/api/v1/bank/details') ||
        request.url.includes('payments/cod/api/v1/bank') ||
        request.url.includes('payments/cod/api/v1/business') ||
        request.url.includes('payments/cod/api/v1/payment/adjustment/settings') ||
        request.url.includes('cod/api/v1/bank/verification') ||
        request.url.includes('return/api/v2/return-shipment') ||
        request.url.includes('claims-service/api/v1') ||
        request.url.includes('/api/v1/appinstall') ||
        request.url.includes('return/api/v1/captureProofs') ||
        request.url.includes('payments/cod/api/v1/mp/order-settlement') ||
        request.url.includes('payments/cod/api/v1/mp/transaction-details') ||
        request.url.includes('payments/cod/api/v1/mp/previous-payments') ||
        request.url.includes('payments/cod/api/v1/mp/order-summary') ||
        request.url.includes('payments/cod/api/v1/mp/payment-detail') ||
        request.url.includes('payments/cod/api/v1/mp/payment-summary') ||
        request.url.includes('payments/cod/api/v1/mp/nod-settlement') ||
        request.url.includes('payments/cod/api/v1/mp/nod-settlement/resolve') ||
        request.url.includes('payments/cod/api/v1/mp/recent-payment') ||
        request.url.includes('payments/cod/api/v1/mp/account-statement') ||
        request.url.includes('payments/cod/api/v1/mp/order-attention') ||
        request.url.includes('payments/cod/api/v1/portal') ||
        request.url.includes('claims-service/api/v1') ||
        request.url.includes('/api/v1/appinstall') ||
        request.url.includes('return/api/v2/return-shipment') ||
        request.url.includes('payments/cod/api/v1/mp/order/status-count') ||
        request.url.includes('return/api/v1/captureProofs') ||
        request.url.includes('v1/orderItem/pick') ||
        request.url.includes('return/api/v1/captureProofs') ||
        request.url.includes('payments/cod/api/v1/mp/order/status-count') ||
        request.url.includes('payments/cod/api/v1/mp/order/settlement-summary') ||
        request.url.includes('b2b-orders/api/v1/picklists/preview') ||
        request.url.includes('b2b-orders/api/v1/createB2BOrder') ||
        request.url.includes('payments/cod/api/v1/mp/order/settlement-summary')
        // request.url.includes('searchOrderReturnIndexData')
      ) {
        apiUrl = apiUrl.split('api')[0];
        dupRequest = request.clone({
          url: apiUrl + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
        // if (request.url.includes('claims-service/api/v1')) {
        //   console.log('dupRequest', dupRequest);
        // }
      }
      // Temporary changes Order revamp new implementation
      // if (request.url.includes('order-return-dot-eshopbox-portal-dev')) {
      //   dupRequest = request.clone({
      //     url: request.url,
      //     headers: request.headers
      //       .set('Authorization', `Bearer ${this.auth.getAuthToken()}`)
      //       .set('ProxyHost', `${this.auth.getAccountSlug()}`)
      //   });
      // }
      // Temporary Change For New Order Return New Page
      // if (request.url.includes('orderreturn-dot-eshopbox-client-portal-prod')) {
      //   dupRequest = request.clone({
      //     url: request.url,
      //     headers: request.headers
      //       .set('Authorization', `Bearer ${this.auth.getAuthToken()}`)
      //       .set('proxyHost', `${this.auth.getAccountSlug()}`)
      //   });
      // }

      // Temp Change for Building blocks
      if (request.url.includes('buildingblock') && environment.production) {
        dupRequest = request.clone({
          url: `https://${accountSlug}${environment.BASE_DOMAIN}/` + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }

      if (request.url.includes('token')) {
        apiUrl = apiUrl.split('api')[0];
        dupRequest = request.clone({
          url: apiUrl + request.url,
          headers: request.headers
            .set('Authorization', `Bearer ${this.auth.getAuthToken()}`)
            .set('ProxyHost', `${this.auth.getAccountSlug()}`)
        });
      }

      if (request.url.includes('scopes')) {
        // apiUrl = apiUrl.includes('api') ? apiUrl : `${apiUrl}api/`;
        apiUrl = `https://partners${environment.BASE_DOMAIN}/api/`;
        dupRequest = request.clone({
          url: apiUrl + request.url
        });
      }

      // if (request.url.includes('v1/account/profile')) {
      //   console.log('ACCOUNT INTERCEPT', accountSlug);
      //   // apiUrl = apiUrl.split('api')[0];
      //   dupRequest = request.clone({
      //     url: `https://${accountSlug}${environment.API_DOMAIN}` + request.url,
      //     setHeaders: {
      //       Authorization: `Bearer ${this.auth.getAuthToken()}`
      //     }
      //   });
      // }
      if (request.url.includes('v1/app/')) {
        let domain = environment.BASE_DOMAIN == '.myeshopbox.com' ? '.myeshopbox.com' : '.auperator.co';
        apiUrl = 'https://partners' + domain;

        dupRequest = request.clone({
          url: `${apiUrl}` + request.url,
          setHeaders: {
            'Content-Type': 'application/json',
            partnerCode: localStorage.getItem('accountPartnerCode'),
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      if (request.url.includes('customapp')) {
        dupRequest = request.clone({
          url: `${apiUrl}` + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      if (request.url.includes('esb/v1/ftpTestConnection')) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      if (request.url.includes(environment.algolia.product_index)) {
        apiUrl = apiUrl.split('//')[0];
        dupRequest = request.clone({
          url: `${apiUrl}//${environment.algolia.id}-dsn.algolia.net/1/indexes/` + request.url,
          setHeaders: {
            'Content-Type': 'application/json',
            'X-Algolia-Application-Id': environment.algolia.id,
            'X-Algolia-API-Key': environment.algolia.search_key
          }
        });
      }
      if (request.url.includes('master/entries')) {
        apiUrl = 'https://';
        dupRequest = request.clone({
          url: `${apiUrl}` + request.url
        });
      }
      if (request.url.includes(environment.algolia.order_return_index)) {
        apiUrl = apiUrl.split('//')[0];
        dupRequest = request.clone({
          url: `${apiUrl}//${environment.algolia.id}-dsn.algolia.net/1/indexes/` + request.url,
          setHeaders: {
            'Content-Type': 'application/json',
            'X-Algolia-Application-Id': environment.algolia.id,
            'X-Algolia-API-Key': environment.algolia.order_return_search_key
          }
        });
      }
      if (request.url.includes(environment.algolia.channel_index)) {
        apiUrl = apiUrl.split('//')[0];
        dupRequest = request.clone({
          url: `${apiUrl}//${environment.algolia.id}-dsn.algolia.net/1/indexes/` + request.url,
          setHeaders: {
            'Content-Type': 'application/json'
            // 'X-Algolia-Application-Id': 'W7AL34LYP4',
            // 'X-Algolia-API-Key': '8da3746839fa84d56959e0330c48cc19'
          }
        });
      }
      // For Customer Portal tracking page domain
      // if (request.url.includes('/api/v1/test')) {
      // Restrict localhost in api url for building block
      if (
        request.url.includes('/api/v1/test') &&
        !request.url.includes('buildingblock') &&
        !request.url.includes('buildingBlock')
      ) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            ProxyHost: `${this.auth.getAccountSlug()}`
          }
        });
      }
      // packaging post api for the create form
      if (
        request.url.includes('product-engine/api/v1/package') ||
        request.url.includes('product-engine/api/v1/user/package')
      ) {
        // apiUrl = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      if (request.url.includes('product-engine/api/v1/admin/package')) {
        apiUrl = `https://eshop${environment.BASE_DOMAIN}/`;
        dupRequest = request.clone({
          url: `${apiUrl}` + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }

      /*====== bb call for rule combination for shipping modes ======*/
      if (request.url.includes('/api/v1/ruleCombination')) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            ProxyHost: `${this.auth.getFlexSlug()}`
          }
        });
      }

      /*====== bb call order API to print invoice and Shipping Label ======*/
      if (request.url.includes('/api/auperator/orders/labels')) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            ProxyHost: `${this.auth.getFlexSlug()}`
          }
        });
      }
      /*====== bb call order API to reprint invoice ======*/
      if (request.url.includes('/api/v1/regenerate-invoice')) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      // weight waiver
      if (request.url.includes('product-engine/api/v1/category')) {
        apiUrl = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
        dupRequest = request.clone({
          url: `${apiUrl}` + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }
      // if (request.url.includes('/api/unicommerce/courierDetails?orderItemIds=')) {
      //   dupRequest = request.clone({
      //     url: request.url,
      //     setHeaders: {
      //       Authorization: `Bearer ${this.auth.getAuthToken()}`,
      //       ProxyHost: `${this.auth.getAccountSlug()}`
      //     }
      //   });
      // }
      /*====== bb call order API to print invoice and Shipping Label ends======*/

      /*====== bb call for invoice URL ======*/
      if (request.url.includes('/v1/recall-consignment')) {
        dupRequest = request.clone({
          url: request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }

      // order cancellation starts
      if (request.url.includes('order-item/cancel')) {
        // console.log('environment', environment.API_URL);
        let orderCancelAccountSlug = this.auth.getAccountSlug();
        let orderCancelApi = '';
        let headers;
        if (environment.API_URL.includes('myeshopbox')) {
          orderCancelApi = `https://${orderCancelAccountSlug}.eshopbox.com/api/v1`;
          // orderCancelApi = `https://order-return-dot-eshopbox-client-portal-prod.appspot.com/_ah/api/esb/v1`;
          headers = {
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            ProxyHost: `${this.auth.getAccountSlug()}`
          };
        } else {
          // orderCancelApi = `https://order-return-dot-eshopbox-portal-dev.el.r.appspot.com/_ah/api/esb/v1`;
          orderCancelApi = `https://${orderCancelAccountSlug}.auperator.co/api/v1`;
          headers = {
            Authorization: `Bearer ${this.auth.getAuthToken()}`,
            ProxyHost: `${this.auth.getAccountSlug()}`
          };
        }
        dupRequest = request.clone({
          url: orderCancelApi + request.url,
          setHeaders: headers
        });
      }

      if (request.url.includes('zoho')) {
        if (request.url.includes('portalId')) {
          dupRequest = request.clone({
            url: request.url
          });
        } else {
          dupRequest = request.clone({
            url: request.url,
            setHeaders: {
              Authorization: `Bearer ${this.auth.getAuthToken()}`
            }
          });
        }
      }
      // product admin verification

      if (request.url.includes('product-engine/api/v1/admin/weightverification')) {
        apiUrl = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
        dupRequest = request.clone({
          url: `${apiUrl}` + request.url,
          setHeaders: {
            Authorization: `Bearer ${this.auth.getAuthToken()}`
          }
        });
      }

      // order cancellation ends
      this.sharedService.showHideLoader(true);
      return next.handle(dupRequest);
    }
  }
}
export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};

<!-- <div [ngClass]="showimportmenu ? 'visible' : 'hidden'" class="searchoverlay" (click)="closeimportmenu()"></div> -->
<!-- [ngClass]="showimportmenu ? 'visible' : 'hidden'" -->
<div class="importsubmenu visible" (mouseleave)="filesMenuClicked = false">
  <!-- <a class="close" (click)="closeimportmenu()">
    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg" />
  </a> -->
  <div class="tab">
    <li>
      <a (click)="activeTab('import')" [class.active]="tab == 'import'"> Imports </a>
      <a (click)="activeTab('export')" [class.active]="tab == 'export'"> Exports </a>
      <a (click)="activeTab('schedule')" [class.active]="tab == 'schedule'" *ngIf="!flagForBB"> Scheduled reports </a>
    </li>
  </div>
  <ng-container *ngIf="tab == 'export'">
    <ul class="importlist less-h-50">
      <div class="web-loader h-100" *ngIf="isLoadingExport">
        <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
      </div>

      <!-- <span class="when-created"> Today </span> -->
      <ng-container *ngIf="!isLoadingExport">
        <li *ngFor="let i of exportJobs">
          <span
            class="download-item"
            [ngClass]="
              (i && i.status == 1 && i?.isScheduledReport == 1 ? 'schedule' : '') +
              (i && i.status == 1 && i?.isScheduledReport != 1 ? 'success' : '') +
              (i && i.status == 0 ? 'process' : '') +
              (i && i.status == 2 ? 'faild' : '')
            "
          >
            <span class="heading file_heading">
              <span class="product_name">{{ displayJobType(i) }}</span>
              <span class="created-by" #text>
                by
                <span
                  [matTooltipDisabled]="!isOverflowing(text)"
                  [matTooltip]="fullName"
                  matTooltipPosition="right"
                  matTooltipClass="right-arrow"
                  class="product_name"
                >
                  {{ fullName }}
                </span>
              </span>
            </span>
            <span class="item-status">
              <span *ngIf="i.status == '1' && +i.recordCount > 0" class="count completed_span">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/green-check.svg" />
                {{ i.recordCount }} Items completed</span
              >
              <!-- <span *ngIf="i.status != '1' && i.recordCount>0" class="count failed_span">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.svg" /> {{ i.recordCount }} Items failed</span
              > -->
              <span *ngIf="i.status == '2'" class="count failed_span">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.svg" />Failed
                to export. Please try again.</span
              >
              <span *ngIf="i.status == '1' && +i.recordCount == 0">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
                0 items available in this export</span
              >
            </span>
            <span class="created-time">
              <b class="start-time">
                {{ formatDate(i?.updatedAt) }}
              </b>
            </span>
            <!-- <span class="download" *ngIf="i && i.status == 1">
              <a *ngIf="i.filePath" href="{{ createFilePath(i.filePath) }}" download>
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-ic1.png" />
              </a>
            </span> -->
            <span class="download download-exports" *ngIf="i && i.status == 1 && +i.recordCount > 0">
              <a
                *ngIf="i.filePath && i.jobType != 'capture_proof_report'"
                href="{{ createFilePath(i.filePath) }}"
                download
              >
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/file-download.svg" />
                <span class="download-text">
                  Download
                </span>
              </a>
              <a
                *ngIf="i.filePath && i.jobType == 'capture_proof_report'"
                href="{{ createFilePath(i.filePath) }}"
                download
                [ngClass]="{ 'disable-download': downloadDisableFun(i?.updatedAt) }"
                [tooltip]="'You cannot download this file after 7 days of your import.'"
                reusableTooltip
              >
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/file-download.svg" />
                <span class="download-text">
                  Download
                </span>
              </a>
            </span>
          </span>
        </li>
      </ng-container>
      <div class="empty_div" *ngIf="paginationObjExport?.total === 0 && !isLoadingExport">
        <div class="empty_inner_div">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_mag_glass.svg" />
          <span>No export jobs</span>
        </div>
      </div>
    </ul>
    <div class="when-available" *ngIf="paginationObjExport?.total != 0">
      <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" /> Reports will be
      available here for 72 hours.
    </div>
    <div class="empty_div" style="display: none;">
      <div class="empty_inner_div">
        <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_mag_glass.svg" />
        <span>No export jobs</span>
      </div>
    </div>
    <mat-paginator
      [length]="paginationObjExport['total']"
      [pageSize]="paginationObjExport['perPage']"
      [pageIndex]="paginationObjExport['page'] - 1"
      (page)="paginateExport($event)"
    ></mat-paginator>
  </ng-container>
  <ng-container *ngIf="tab == 'import'">
    <ul class="importlist">
      <div class="web-loader h-100" *ngIf="isLoading">
        <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
      </div>
      <!-- <span class="when-created"> Today </span> -->
      <ng-container *ngIf="!isLoading">
        <li *ngFor="let i of importJobsList">
          <span
            class="download-item"
            [ngClass]="
              (i && i.status == 1 ? 'success' : '') +
              (i && i.status == 0 ? 'process' : '') +
              (i && i.status == 2 ? 'faild' : '')
            "
          >
            <span class="heading file_heading">
              <span class="product_name">{{ displayJobType(i) }}</span>
              <span class="created-by" #text>
                by
                <span
                  [matTooltipDisabled]="!isOverflowing(text)"
                  [matTooltip]="fullName"
                  matTooltipPosition="right"
                  matTooltipClass="right-arrow"
                  class="product_name"
                >
                  {{ fullName }}
                </span>
              </span>
            </span>
            <span class="item-status">
              <span class="count completed_span" *ngIf="i.successCount > 0">
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/green-check.svg" />
                <ng-container *ngIf="i.jobType == 'order'">
                  {{ i.successCount }} Order{{ i.successCount > 1 ? 's' : '' }} completed
                </ng-container>
                <ng-container *ngIf="i.jobType != 'order'">
                  {{ i.successCount || 'null' }}
                  {{ i.jobType == 'return' ? 'returns' : 'Items' }} completed</ng-container
                >
              </span>
              <span class="count failed_span" *ngIf="i.failureCount > 0">
                <img
                  class="fail_icon"
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.svg"
                />
                <ng-container *ngIf="i.jobType == 'order'">
                  {{ i.failureCount || 'null' }} Order{{ i.failureCount > 1 ? 's' : '' }} failed
                </ng-container>
                <ng-container *ngIf="i.jobType != 'order'">
                  {{ i.failureCount || 'null' }} {{ i.jobType == 'return' ? 'returns' : 'Items' }} failed
                </ng-container>
              </span>
              <ng-container *ngIf="i.jobType != 'return'; else jobTypeReturn">
                <span *ngIf="i.status == '2'" class="count failed_span">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.svg" />
                  {{
                    i.fileReadFailureReason == 'Incorrect csv format' ||
                    ((i.jobType == 'flex_inventory_update' ||
                      i.jobType == 'mark_product_unavailable' ||
                      i.jobType == 'mark_product_available') &&
                      i.fileReadFailureReason)
                      ? i.fileReadFailureReason
                      : 'Failed to import. Please try again.'
                  }}
                </span>
              </ng-container>
              <ng-template #jobTypeReturn>
                <span *ngIf="i.status == '2'" class="count failed_span">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/error-icon.svg" />
                  {{
                    i.fileReadFailureReason == 'Incorrect csv format' || i.fileReadFailureReason == 'No records found'
                      ? 'Failed: Incorrect CSV format'
                      : 'Failed: Something went wrong. Try again'
                  }}
                </span>
              </ng-template>
              <span class="download download-imports" *ngIf="i && i.status == 1">
                <a *ngIf="i.additionalDetails" href="{{ createFilePath(i.additionalDetails) }}" download>
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/file-download.svg" />
                  <span class="download-text">
                    Download
                  </span>
                </a>
              </span>
              <!-- <span class="download" *ngIf="i && i.status == 1">
                <a *ngIf="i.filePath" href="{{ createFilePath(i.filePath) }}" download>
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-ic1.png" />
                </a>
              </span> -->
            </span>
            <span class="created-time">
              <b class="start-time">
                {{ formatDate(i?.updatedAt) }}
              </b>
            </span>
            <!-- <span class="download">
              <a *ngIf="i.additionalDetails" [href]="i.additionalDetails" download>
                <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/download-ic1.png" />
              </a>
            </span> -->
          </span>
        </li>
      </ng-container>
      <div class="empty_div" *ngIf="paginationObjImport?.total === 0 && !isLoading">
        <div class="empty_inner_div">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_mag_glass.svg" />
          <span>No import jobs</span>
        </div>
      </div>
    </ul>

    <mat-paginator
      [length]="paginationObjImport['total']"
      [pageSize]="paginationObjImport['perPage']"
      [pageIndex]="paginationObjImport['page'] - 1"
      (page)="paginateImport($event)"
    ></mat-paginator>
  </ng-container>
  <ng-container *ngIf="tab == 'schedule'">
    <ul class="schedule-item">
      <div class="web-loader h-100" *ngIf="isLoadingSchedule">
        <mat-spinner [diameter]="38" [strokeWidth]="3.5" class="centre-align"></mat-spinner>
      </div>
      <ng-container *ngIf="!isLoadingSchedule">
        <li *ngFor="let i of scheduleJobs">
          <span class="download-item success">
            <span class="heading file_heading">
              <span
                matTooltip="{{
                  'Created on ' +
                    (i.createdAt | date: 'd LLL yy') +
                    (i.createdAt != i.updatedAt ? ' Updated on ' + (i.updatedAt | date: 'd LLL yy') : '')
                }}"
                matTooltipPosition="right"
                matTooltipClass="right-arrow top-min"
                class="product_name"
              >
                {{ i?.report }}
              </span>
            </span>
            <span class="item-status">
              {{ displayJobType(i) }}
              <b>
                {{ i?.interval?.every === 'day' ? 'Daily' : i?.interval?.every === 'month' ? 'Monthly' : 'Weekly' }},
                <ng-container *ngIf="i?.interval?.every != 'day'">
                  Day {{ i?.interval?.day | titlecase }},
                </ng-container>
                {{ i?.interval?.time.toLowerCase() }}
              </b>
            </span>
            <span class="tree-dots">
              <div class="edit-delete-dropdown">
                <span class="dot-icon">
                  <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/more-horizontal.svg" />
                </span>
                <div class="dropdown-item">
                  <button (click)="editSchedule(i)">
                    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/edit-icon.svg" /> Edit
                  </button>
                  <button (click)="deleteSchedule(i.id)">
                    <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/delete.svg" /> Delete
                  </button>
                </div>
              </div>
            </span>
          </span>
        </li>
        <!-- No reports view -->
        <div class="empty_div" *ngIf="paginationObjSchedule?.total === 0">
          <div class="empty_inner_div">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_mag_glass.svg" />
            <span>No reports scheduled</span>
          </div>
        </div>
        <!-- <div class="when-available" *ngIf="paginationObjSchedule?.total != 0">
          <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" /> Reports will be available here for 72 hours.
        </div> -->
      </ng-container>
    </ul>
    <mat-paginator
      [length]="paginationObjSchedule['total']"
      [pageSize]="paginationObjSchedule['perPage']"
      [pageIndex]="paginationObjSchedule['page'] - 1"
      (page)="paginateSchedule($event)"
    ></mat-paginator>
  </ng-container>
</div>

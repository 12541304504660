import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from 'src/app/shared/services';
import { ZohoService } from 'src/app/tickets/services/zoho-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plan-modify-dialog',
  templateUrl: './plan-modify-dialog.component.html',
  styleUrls: ['./plan-modify-dialog.component.scss']
})
export class PlanModifyDialogComponent implements OnInit {
  updatePlanForm: FormGroup;
  zohoDepartmentID = environment.zohoDepartmentID;
  public isProceedLoader: boolean = false;
  constructor(
    private fb: FormBuilder,
    private cookieService: CookieService,
    private router: Router,
    private alertService: AlertService,
    private zohoService: ZohoService,
    public dialogRef: MatDialogRef<PlanModifyDialogComponent>
  ) {}

  ngOnInit(): void {
    this.updatePlanForm = this.fb.group({
      planRequirementsControl: ['', [Validators.required]]
    });
  }

  createTicketForPlanModification() {
    this.isProceedLoader = true;
    let zohoAccount = JSON.parse(this.cookieService.get('zohoAccount'));
    let contactId = JSON.parse(this.cookieService.get('zohoContact'));
    const subdomain = window.location.hostname.split('.')[0];
    if (environment.production && subdomain !== zohoAccount.accountName) {
      this.alertService.showError(
        'Your active workspace has changed. Please try submitting the ticket from your active workspace.',
        3000,
        false
      );
      setTimeout(() => {
        this.router.navigate(['/auth/signin-complete']);
      }, 3000);
      return;
    } else {
      const ticketPayload = {
        subject: 'I want to upgrade my subscription',
        cf: {
          cf_topic: 'Billing and Subscriptions'
        },
        contactId: contactId,
        departmentId: this.zohoDepartmentID,
        description: this.updatePlanForm.value.planRequirementsControl
      };
      this.zohoService.createTicket(ticketPayload).subscribe(
        (data: any) => {
          if (data.errorCode) {
            this.isProceedLoader = false;
            this.dialogRef.close({ ticketCreated: false });
            this.alertService.showError(data.message);
          } else {
            this.isProceedLoader = false;
            this.dialogRef.close({ ticketCreated: true });
          }
        },
        error => {}
      );
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PlanModifyDialogComponent } from '../plan-modify-dialog/plan-modify-dialog.component';

@Component({
  selector: 'app-plan-notification',
  templateUrl: './plan-notification.component.html',
  styleUrls: ['./plan-notification.component.scss']
})
export class PlanNotificationComponent implements OnInit {
  @Input() content: string;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    let dialogRef = this.dialog.open(PlanModifyDialogComponent, {
      panelClass: 'disable-fulfilment-dialog',
      width: '570px'
    });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AuthActionTypes } from 'src/app/auth/actions/auth.actions';
import { getCurrentUser } from 'src/app/auth/reducers/auth.selectors';
import { ROUTES } from 'src/app/constants/routes';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit, OnDestroy {
  userInitials = '';
  userSubscription$: Subscription;
  ROUTES = ROUTES;
  showCloseIcon;
  currenturl: string;
  notOtpOrSignupPage: boolean;
  isHubspotPage: boolean = false;
  isOtpPopupOpen: boolean;
  skipUrls = [
    '/auth/signin-complete',
    '/auth/otp-validation',
    'invite',
    '/auth/signup',
    '/auth/signup-form-step',
    '/auth/create-account'
  ];
  constructor(private store: Store<AppState>, public authService: AuthService, private router: Router) {}

  ngOnInit() {
    // this.activatedRoute.snapshot.data.subscribe(response => {
    //   console.log('sucess icon', response );
    // })
    this.authService.currentMessage.subscribe(message => {
      this.showCloseIcon = message;
      console.log('closeIcon', this.showCloseIcon);
    });
    // if (window.location.hostname !== environment.DOMAIN && environment.production) {
    //   window.location.href = 'https://' + environment.DOMAIN + ROUTES.SIGNUP;
    // }
    this.userSubscription$ = this.store.select(getCurrentUser).subscribe(result => {
      if (result && result['firstName']) {
        this.userInitials = result['firstName'].charAt(0);
      }
      // Get lastname if not null
      if (result && result['lastName']) {
        this.userInitials += result['lastName'].charAt(0);
      }
    });
    this.router.events
      .pipe(
        startWith(new NavigationEnd(0, '/', '/')),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        if (event && event.url !== '/') {
          this.currenturl = event.url;
        } else {
          this.currenturl = this.router.url;
        }
        this.notOtpOrSignupPage =
          !this.currenturl.includes('/auth/new-signup') && !this.currenturl.includes('otp-validation');
        this.isHubspotPage = this.currenturl.includes('/auth/hubspot-user-form');
      });
    this.authService.getOtpPopupOpen().subscribe(isOpen => {
      if (isOpen) {
        this.isOtpPopupOpen = true;
      } else {
        this.isOtpPopupOpen = false;
      }
    });
  }

  onLogout() {
    this.store.dispatch({ type: AuthActionTypes.LOGOUT });
  }

  ngOnDestroy() {
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
  }
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as ld from 'lodash';
import { Subscription } from 'rxjs';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { ADMIN_EMAIL_ACCESS, ASSETS, ROUTESDATA, WAIVER_EMAIL_ACCESS } from 'src/app/constants/routes';
import { AuthService } from 'src/app/shared/services';
import { BuildingblockService } from 'src/app/shared/services/buildingblock.service';
@Component({
  selector: 'app-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss']
})
export class SettingsSidebarComponent implements OnInit, AfterViewInit {
  hasLocation: boolean;
  hasLocationAccess: boolean;
  SETTINGROUTES = [];
  searchsetting: string;
  accountSlug: any;
  locationDataSubscription$: Subscription;
  currentRoute = '';
  isAdminPackaging: boolean;
  iswaiverEmail: boolean;
  settingSidebarMappings: any;
  settingsOptionsToMap = {
    Fees: 'fees',
    'E-invoicing': 'e_invoicing',
    'Custom fields': 'custom_fields',
    'Payment terms': 'payment_terms'
  };
  settingsMappings$: Subscription;

  constructor(
    public authService: AuthService,
    private buildingblockService: BuildingblockService,
    private _route: ActivatedRoute,
    private router: Router,
    private store: Store<{ auth }>
  ) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe(queryParams => {
      window.scrollTo(0, 0);
      // this.scrollToCurentMenu();
    });
    const accountSlug = this.authService.getAccountSlug();
    this.accountSlug = accountSlug;
    this.getLocationAccess();
    this.hasAccessAdminPackaging();
    this.hasWaiverEmailAddress();
    this.store.pipe(select(fromSelector.getAccountList)).subscribe(async result => {
      this.settingsMappings$ = this.authService.getPlanMappingsData('settings').subscribe(data => {
        this.settingSidebarMappings = data;
        this.getSettingRoutes();
      });
    });
  }

  hasAccessAdminPackaging() {
    let email = localStorage.getItem('email');
    this.isAdminPackaging = ADMIN_EMAIL_ACCESS.includes(email) ? true : false;
    return this.isAdminPackaging;
  }

  hasWaiverEmailAddress() {
    let email = localStorage.getItem('email');
    this.iswaiverEmail = WAIVER_EMAIL_ACCESS.includes(email) ? true : false;
    return this.iswaiverEmail;
  }

  getLocationAccess() {
    this.locationDataSubscription$ = this.buildingblockService.locationData$.subscribe(data => {
      this.hasLocation = data.hasLocation;
      this.hasLocationAccess = data.hasLocationAccess;
      this.getSettingRoutes();
    });
  }

  getSettingRoutes() {
    this.SETTINGROUTES = ld.cloneDeep(ROUTESDATA);
    let routingIn = this.SETTINGROUTES['setting'].findIndex(item => item.URL == '/settings/weight-waiver');
    if (routingIn !== -1) {
      if (this.accountSlug == 'eshop' && this.iswaiverEmail) {
        this.SETTINGROUTES['setting'][routingIn]['isVisible'] = true;
        this.SETTINGROUTES['setting'][routingIn]['hasAccess'] = true;
      } else {
        this.SETTINGROUTES['setting'][routingIn]['isVisible'] = false;
      }
    }
    // mapping changes to show/hide settings sidebar options as per plan
    this.SETTINGROUTES['setting'].forEach(element => {
      if (Object.keys(this.settingsOptionsToMap).includes(element.name)) {
        const mappingKey = this.settingsOptionsToMap[element.name];
        if (this.settingSidebarMappings?.[mappingKey] === 'hide') {
          element.isVisible = false;
        }
      }
      // hide assets option if all the sub navigations are hidden
      if (
        element.name === 'Assets' &&
        this.settingSidebarMappings?.assets?.totes === 'hide' &&
        this.settingSidebarMappings?.assets?.pigeon_hole === 'hide' &&
        this.settingSidebarMappings?.assets?.staging_area === 'hide' &&
        this.settingSidebarMappings?.assets?.shelfs === 'hide'
      ) {
        element.isVisible = false;
      }
    });

    this.SETTINGROUTES['setting'] = this.SETTINGROUTES['setting']
      .filter(route => route.parent != 'Assets')
      .filter(route => route.isVisible === true);
    const insertIndex = this.SETTINGROUTES['setting'].findIndex(
      (route, index) =>
        index < this.SETTINGROUTES['setting'].length - 1 &&
        route.subParent === 'USER & LOCATIONS' &&
        this.SETTINGROUTES['setting'][index + 1].subParent !== 'USER & LOCATIONS'
    );
    if (insertIndex !== -1) {
      this.SETTINGROUTES['setting'].splice(insertIndex + 1, 0, ASSETS);
    }
    const incidentIndex = this.SETTINGROUTES['setting'].findIndex(item => item.URL == '/tool/incidents');
    if (incidentIndex !== -1) {
      this.SETTINGROUTES['setting'][incidentIndex]['name'] =
        this.accountSlug != 'eshop' ? 'Incidents' : 'Incident management';
    }
    // const routingIndex = this.SETTINGROUTES['setting'].findIndex(item => item.URL == '/settings/order-routings');
    // if (routingIndex !== -1) {
    //   this.SETTINGROUTES['setting'][routingIndex]['isVisible'] = true;
    //     // this.accountSlug != 'eshop' ? 'Incidents' : 'Incident management';
    // }
    let routingPackaging = this.SETTINGROUTES['setting'].findIndex(
      item => item.URL == '/settings/packaging-material-list'
    );
    if (routingPackaging !== -1) {
      this.SETTINGROUTES['setting'][routingPackaging]['isVisible'] = true;
    }
    let routingPackagingAdmin = this.SETTINGROUTES['setting'].findIndex(
      item => item.URL == '/settings/packaging-list-admin'
    );
    if (this.accountSlug == 'eshop' && this.hasAccessAdminPackaging()) {
      this.SETTINGROUTES['setting'][routingPackagingAdmin]['isVisible'] = true;
    } else {
      this.SETTINGROUTES['setting'][routingPackagingAdmin]['isVisible'] = false;
    }
  }

  async selectedFC(bbHandleBar, name) {
    this.currentRoute = name;
    this.buildingblockService.setCallSelectedFC(bbHandleBar);
  }

  onClickTab() {
    this.currentRoute = '';
  }

  removeSearch() {
    this.searchsetting = '';
  }

  scrollToCurentMenu(): void {
    if (!this.SETTINGROUTES['setting'] || this.SETTINGROUTES['setting'].length === 0) return;
    const pathName = window.location.pathname;
    const currentMenu = this.SETTINGROUTES['setting'].find(item => item.URL == pathName);
    const menuId = pathName != '/building-blocks/view-record-list' ? currentMenu?.name : 'Assets';
    const element: any = document.getElementById(menuId);
    if (pathName.includes('building-blocks')) {
      this.currentRoute =
        pathName == '/building-blocks/self-fulfilment-settings' ? 'Self fulfilment settings' : 'Assets';
    } else this.currentRoute = '';
    setTimeout(() => {
      if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 600);
  }

  ngAfterViewInit() {
    this.scrollToCurentMenu();
  }

  ngOnDestroy() {
    if (this.locationDataSubscription$) this.locationDataSubscription$.unsubscribe();
    this.settingsMappings$?.unsubscribe();
  }
}

<!-- For order export -->
<form [formGroup]="dateRangeForm">
  <p *ngIf="['all_returns_virtual_kit', 'all_draft_return'].includes(jobType)" class="label-des new-label">
    Select atleast one of the date range
  </p>
  <div
    class="form-field date-rng"
    *ngIf="
      ['sale_order_report', 'shipment_report', 'sale_order_report_v2', 'payments', 'all_returns_virtual_kit'].includes(
        jobType
      )
    "
  >
    <p *ngIf="!['all_returns_virtual_kit'].includes(jobType)" class="label-des">Select atleast one of the date range</p>
    <mat-form-field
      *ngIf="!['all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('placedOnChannelCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('placedOnChannelCtrl').value
            ? dateRangeForm.get('placedOnChannelCtrl').value
            : 'Order placed on channel'
        }}
      </mat-label>
      <mat-select
        #placedOnChannelId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="placedOnChannelCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('placedOnChannelCtrl').value">
          {{ dateRangeForm.get('placedOnChannelCtrl').value ? dateRangeForm.get('placedOnChannelCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderDetails.orderDate', d, 'Order placed on channel: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="Custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'placedOnChannel'"
              (getCustomDates)="getCustomDates('orderDetails.orderDate', $event, 'Order placed on channel: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="!['all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('createdInEshopboxCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>{{
        dateRangeForm.get('createdInEshopboxCtrl').value
          ? dateRangeForm.get('createdInEshopboxCtrl').value
          : 'Order created in Eshopbox'
      }}</mat-label>
      <mat-select
        #createdInEshopboxId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="createdInEshopboxCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('createdInEshopboxCtrl').value">
          {{ dateRangeForm.get('createdInEshopboxCtrl').value ? dateRangeForm.get('createdInEshopboxCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderReceivedOn', d, 'Order created in Eshopbox: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'createdInEshopbox'"
              (getCustomDates)="getCustomDates('orderReceivedOn', $event, 'Order created in Eshopbox: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('invoicedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('invoicedOnCtrl').value ? dateRangeForm.get('invoicedOnCtrl').value : 'Order Invoiced on'
        }}
      </mat-label>
      <mat-select
        #invoicedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="invoicedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('invoicedOnCtrl').value">
          {{ dateRangeForm.get('invoicedOnCtrl').value ? dateRangeForm.get('invoicedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('shipmentDetails.invoiceDate', d, 'Order Invoiced on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'invoicedOn'"
              (getCustomDates)="getCustomDates('shipmentDetails.invoiceDate', $event, 'Order Invoiced on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field
      *ngIf="!['all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('readyToShipOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('readyToShipOnCtrl').value
            ? dateRangeForm.get('readyToShipOnCtrl').value
            : 'Order ready to ship (RTS) on'
        }}
      </mat-label>
      <mat-select
        #readyToShipOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="readyToShipOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('readyToShipOnCtrl').value">
          {{ dateRangeForm.get('readyToShipOnCtrl').value ? dateRangeForm.get('readyToShipOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderReadyToShipTimestamp', d, 'Order ready to ship (RTS) on : ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'readyToShipOn'"
              (getCustomDates)="getCustomDates('orderReadyToShipTimestamp', $event, 'Order ready to ship (RTS) on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('fullFilledOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('fullFilledOnCtrl').value
            ? dateRangeForm.get('fullFilledOnCtrl').value
            : 'Order fulfilled on'
        }}
      </mat-label>
      <mat-select
        #fullFilledOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="fullFilledOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('fullFilledOnCtrl').value">
          {{ dateRangeForm.get('fullFilledOnCtrl').value ? dateRangeForm.get('fullFilledOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderHandoverDoneTimestamp', d, 'Order fulfilled on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'fullFilledOn'"
              (getCustomDates)="getCustomDates('orderHandoverDoneTimestamp', $event, 'Order fulfilled on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <!-- <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('deliveredOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('deliveredOnCtrl').value ? dateRangeForm.get('deliveredOnCtrl').value : 'Order delivered on'
        }}
      </mat-label>
      <mat-select
        #deliveredOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="deliveredOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('deliveredOnCtrl').value">
          {{ dateRangeForm.get('deliveredOnCtrl').value ? dateRangeForm.get('deliveredOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderDeliveredTimestamp', d, 'Order delivered on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="deliveredOn"
              (getCustomDates)="getCustomDates('orderDeliveredTimestamp', $event, 'Order delivered on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field
      *ngIf="!['all_returns_virtual_kit', 'all_draft_return'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('cancelledOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('cancelledOnCtrl').value ? dateRangeForm.get('cancelledOnCtrl').value : 'Order cancelled on'
        }}
      </mat-label>
      <mat-select
        #cancelledOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="cancelledOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('cancelledOnCtrl').value">
          {{ dateRangeForm.get('cancelledOnCtrl').value ? dateRangeForm.get('cancelledOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderCancelledTimestamp', d, 'Order cancelled on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="cancelledOn"
              (getCustomDates)="getCustomDates('orderCancelledTimestamp', $event, 'Order cancelled on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnCreatedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnCreatedOnCtrl').value
            ? dateRangeForm.get('returnCreatedOnCtrl').value
            : 'Return created on'
        }}
      </mat-label>
      <mat-select
        #returnCreatedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnCreatedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnCreatedOnCtrl').value">
          {{ dateRangeForm.get('returnCreatedOnCtrl').value ? dateRangeForm.get('returnCreatedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('returns.returnDetails.createdOnTimestamp', d, 'Return created on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'returnCreatedOn'"
              (getCustomDates)="
                getCustomDates('returns.returnDetails.createdOnTimestamp', $event, 'Return created on: ')
              "
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnReceivedOnCtrl').value ? 'add-bg-color' : ''"
      *ngIf="!['all_returns_virtual_kit'].includes(jobType)"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnReceivedOnCtrl').value
            ? dateRangeForm.get('returnReceivedOnCtrl').value
            : 'Return received on'
        }}
      </mat-label>
      <mat-select
        #returnReceivedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnReceivedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnReceivedOnCtrl').value">
          {{ dateRangeForm.get('returnReceivedOnCtrl').value ? dateRangeForm.get('returnReceivedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('returns.returnDetails.receivedOnTimestamp', d, 'Return received on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnReceivedOn"
              (getCustomDates)="
                getCustomDates('returns.returnDetails.receivedOnTimestamp', $event, 'Return received on: ')
              "
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnDeliveredOnCtrl').value ? 'add-bg-color' : ''"
      *ngIf="['all_returns_virtual_kit'].includes(jobType)"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnDeliveredOnCtrl').value
            ? dateRangeForm.get('returnDeliveredOnCtrl').value
            : 'Return delivered on'
        }}
      </mat-label>
      <mat-select
        #returnDeliveredOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnDeliveredOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnDeliveredOnCtrl').value">
          {{ dateRangeForm.get('returnDeliveredOnCtrl').value ? dateRangeForm.get('returnDeliveredOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('returns.returnDetails.deliveredOnTimestamp', d, 'Return delivered on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnDeliveredOn"
              (getCustomDates)="
                getCustomDates('returns.returnDetails.deliveredOnTimestamp', $event, 'Return delivered on: ')
              "
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnCompletedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnCompletedOnCtrl').value
            ? dateRangeForm.get('returnCompletedOnCtrl').value
            : 'Return completed on'
        }}
      </mat-label>
      <mat-select
        #returnCompletedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnCompletedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnCompletedOnCtrl').value">
          {{ dateRangeForm.get('returnCompletedOnCtrl').value ? dateRangeForm.get('returnCompletedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('returns.returnDetails.completedOnTimestamp', d, 'Return completed on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnCompletedOn"
              (getCustomDates)="
                getCustomDates('returns.returnDetails.completedOnTimestamp', $event, 'Return completed on: ')
              "
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnPickedOnCtrl').value ? 'add-bg-color' : ''"
      *ngIf="['all_returns_virtual_kit'].includes(jobType)"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnPickedOnCtrl').value
            ? dateRangeForm.get('returnPickedOnCtrl').value
            : 'Return picked on'
        }}
      </mat-label>
      <mat-select
        #returnPickedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnPickedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnPickedOnCtrl').value">
          {{ dateRangeForm.get('returnPickedOnCtrl').value ? dateRangeForm.get('returnPickedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('returns.returnDetails.pickedOnTimestamp', d, 'Return picked on: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnPickedOn"
              (getCustomDates)="getCustomDates('returns.returnDetails.pickedOnTimestamp', $event, 'Return picked on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-form-field
      *ngIf="['all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
      [ngClass]="dateRangeForm.get('returnTypeCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-select
        placeholder="Return Type"
        [multiple]="true"
        disableOptionCentering
        panelClass="custom-select-ui location-mrg filter-option order locationFilter"
        floatlLabel="never"
        [disabled]="query ? true : false"
        formControlName="returnTypeCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnTypeCtrl').value">
          Return type: {{ displayReturnType(1, dateRangeForm.get('returnTypeCtrl').value) }}
          <ng-container *ngIf="dateRangeForm.value.returnTypeCtrl && dateRangeForm.value.returnTypeCtrl.length > 1">
            + {{ dateRangeForm.value.returnTypeCtrl.length - 1 }} more
          </ng-container>
        </mat-select-trigger>
        <mat-option #allRetrnTypeSelected (click)="toggleAllReturnType()" [value]="0">
          All
        </mat-option>
        <mat-option *ngFor="let type of returnType" [value]="type" #matOption>
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-export-sales-channel-filter
    *ngIf="['incorrect_return_item_report'].includes(jobType)"
    (getSalesChannelFilterData)="getSalesChannelFilterData($event)"
    class="export-sales-channel-filter"
  >
  </app-export-sales-channel-filter>

  <!-- Filters for incorrect return -->
  <div class="form-field date-rng" *ngIf="['incorrect_return_item_report'].includes(jobType)">
    <p class="label-des">Select atleast one of the date range</p>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('placedOnChannelCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('placedOnChannelCtrl').value
            ? dateRangeForm.get('placedOnChannelCtrl').value
            : 'Order placed on channel'
        }}
      </mat-label>
      <mat-select
        #placedOnChannelId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="placedOnChannelCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('placedOnChannelCtrl').value">
          {{ dateRangeForm.get('placedOnChannelCtrl').value ? dateRangeForm.get('placedOnChannelCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('orderDate', d, 'Order placed on channel: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="Custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'placedOnChannel'"
              (getCustomDates)="getCustomDates('orderDate', $event, 'Order placed on channel: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('createdInEshopboxCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>{{
        dateRangeForm.get('createdInEshopboxCtrl').value
          ? dateRangeForm.get('createdInEshopboxCtrl').value
          : 'Order created in Eshopbox'
      }}</mat-label>
      <mat-select
        #createdInEshopboxId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="createdInEshopboxCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('createdInEshopboxCtrl').value">
          {{ dateRangeForm.get('createdInEshopboxCtrl').value ? dateRangeForm.get('createdInEshopboxCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option
            value="{{ d.key }}"
            (click)="getDate('orderReceivedOn', d, 'Order created in Eshopbox: ' + d.key)"
          >
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'createdInEshopbox'"
              (getCustomDates)="getCustomDates('orderReceivedOn', $event, 'Order created in Eshopbox: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnReceivedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnReceivedOnCtrl').value
            ? dateRangeForm.get('returnReceivedOnCtrl').value
            : 'Return received on'
        }}
      </mat-label>
      <mat-select
        #returnReceivedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnReceivedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnReceivedOnCtrl').value">
          {{ dateRangeForm.get('returnReceivedOnCtrl').value ? dateRangeForm.get('returnReceivedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('returnReceivedOn', d, 'Return received on: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnReceivedOn"
              (getCustomDates)="getCustomDates('returnReceivedOn', $event, 'Return received on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnCompletedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnCompletedOnCtrl').value
            ? dateRangeForm.get('returnCompletedOnCtrl').value
            : 'Return completed on'
        }}
      </mat-label>
      <mat-select
        #returnCompletedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnCompletedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnCompletedOnCtrl').value">
          {{ dateRangeForm.get('returnCompletedOnCtrl').value ? dateRangeForm.get('returnCompletedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('returnCompletedOn', d, 'Return completed on: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnCompletedOn"
              (getCustomDates)="getCustomDates('returnCompletedOn', $event, 'Return completed on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Filters for draft return -->
  <div
    class="form-field date-rng"
    *ngIf="['draft_return_report', 'all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
  >
    <mat-form-field
      *ngIf="!['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
      [ngClass]="dateRangeForm.get('qcStatusCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-select
        placeholder="QC Status"
        [multiple]="true"
        disableOptionCentering
        floatlLabel="never"
        [disabled]="query ? true : false"
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="qcStatusCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('qcStatusCtrl').value">
          QC Status:<ng-container *ngIf="dateRangeForm.get('qcStatusCtrl').value.length == 1">
            {{ dateRangeForm.get('qcStatusCtrl').value[0] == 'received' ? 'QC Pending' : 'QC Completed' }}
          </ng-container>
          <ng-container *ngIf="dateRangeForm.get('qcStatusCtrl').value.length == 2">
            {{ dateRangeForm.get('qcStatusCtrl').value[0] == 'received' ? 'QC Pending' : 'QC Completed' }},
            {{ dateRangeForm.get('qcStatusCtrl').value[1] == 'received' ? 'QC Pending' : 'QC Completed' }}
          </ng-container>
        </mat-select-trigger>
        <mat-option value="received">
          QC Pending
          <div class="filter-dropdown">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
            <div class="dropdown ">
              <p>
                Return not created in Eshopbox. However, it has been received at the fulfilment centre and is currently
                pending for quality check.
              </p>
            </div>
          </div>
        </mat-option>
        <mat-option value="complete">
          QC Completed
          <div class="filter-dropdown">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/info-circle.svg" />
            <div class="dropdown ">
              <p>
                Return not created in Eshopbox. However, it has been received at the fulfilment centre and the quality
                check is completed.
              </p>
            </div>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      *ngIf="!['all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnReceivedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnReceivedOnCtrl').value
            ? dateRangeForm.get('returnReceivedOnCtrl').value
            : 'Return received on'
        }}
      </mat-label>
      <mat-select
        #returnReceivedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnReceivedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnReceivedOnCtrl').value">
          {{ dateRangeForm.get('returnReceivedOnCtrl').value ? dateRangeForm.get('returnReceivedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('returnReceivedOn', d, 'Return received on: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnReceivedOn"
              (getCustomDates)="getCustomDates('returnReceivedOn', $event, 'Return received on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngIf="!['all_returns_virtual_kit', 'all_draft_return'].includes(jobType)"
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('returnCompletedOnCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('returnCompletedOnCtrl').value
            ? dateRangeForm.get('returnCompletedOnCtrl').value
            : 'Return completed on'
        }}
      </mat-label>
      <mat-select
        [matTooltip]="
          dateRangeForm.get('qcStatusCtrl').value == 'received'
            ? 'For QC status pending, return completed on cannot be selected.'
            : ''
        "
        [disabled]="dateRangeForm.get('qcStatusCtrl').value == 'received'"
        #returnCompletedOnId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="returnCompletedOnCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnCompletedOnCtrl').value">
          {{ dateRangeForm.get('returnCompletedOnCtrl').value ? dateRangeForm.get('returnCompletedOnCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.dateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('returnCompletedOn', d, 'Return completed on: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="returnCompletedOn"
              (getCustomDates)="getCustomDates('returnCompletedOn', $event, 'Return completed on: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-export-portal-filter
      *ngIf="!['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
      (getPortalData)="getPortalFilterData($event)"
      class="export-sales-channel-filter"
    >
    </app-export-portal-filter>

    <mat-form-field
      *ngIf="!['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
      [ngClass]="dateRangeForm.get('locationFilterFormData').value ? 'add-bg-color' : ''"
    >
      <mat-select
        placeholder="Location"
        [multiple]="true"
        disableOptionCentering
        panelClass="custom-select-ui location-mrg filter-option order locationFilter"
        floatlLabel="never"
        [disabled]="query ? true : false"
        formControlName="locationFilterFormData"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('locationFilterFormData').value">
          Location: {{ displayLocation(1, dateRangeForm.get('locationFilterFormData').value) }}
          <ng-container
            *ngIf="dateRangeForm.value.locationFilterFormData && dateRangeForm.value.locationFilterFormData.length > 1"
          >
            + {{ dateRangeForm.value.locationFilterFormData.length - 1 }} more
          </ng-container>
        </mat-select-trigger>
        <input
          matInput
          type="text"
          name="search"
          placeholder="Search"
          #input
          class="search-input"
          onfocus="this.placeholder=''"
          onblur="this.placeholder='Search'"
        />
        <mat-option #allSelected (click)="toggleAllLoaction()" [value]="0">
          All
        </mat-option>
        <mat-option
          *ngFor="let fullfillmentCenter of filteredEnrolledFCData | searchDropDownFilter: input.value:'facilityLabel'"
          [value]="fullfillmentCenter.warehouseId"
          #matOption
        >
          {{ fullfillmentCenter.facilityLabel }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="accountslug == 'eshop' && !['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)">
      <mat-form-field
        class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
        [ngClass]="dateRangeForm.get('sellingParty').value ? 'add-bg-color' : ''"
      >
        <mat-select
          placeholder="Selling party"
          [multiple]="false"
          disableOptionCentering
          panelClass="custom-select-ui location-mrg filter-option order locationFilter"
          floatlLabel="never"
          formControlName="sellingParty"
        >
          <input
            matInput
            type="text"
            placeholder="Search"
            #workspaceinput
            class="search-input"
            [(ngModel)]="searchWorkspace"
            [ngModelOptions]="{ standalone: true }"
            (keydown)="$event.keyCode == 32 ? $event.stopPropagation() : ''"
            name="searchWorkspace"
          />

          <mat-option
            *ngFor="let workspace of accountLists | searchDropDownFilter: workspaceinput.value:'accountName'"
            [value]="workspace.accountSlug"
            #matOption
          >
            {{ workspace.accountName }} - {{ workspace.accountSlug }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field
      *ngIf="['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
      class="select-team-member empty-label-show dropdown-override qcStatusCtrl"
      [ngClass]="dateRangeForm.get('returnStatusCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-select
        placeholder="Status"
        [multiple]="true"
        disableOptionCentering
        panelClass="custom-select-ui location-mrg filter-option order locationFilter"
        floatlLabel="never"
        [disabled]="query ? true : false"
        formControlName="returnStatusCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('returnStatusCtrl').value">
          Status: {{ displayReturnStatus(1, dateRangeForm.get('returnStatusCtrl').value) }}
          <ng-container *ngIf="dateRangeForm.value.returnStatusCtrl && dateRangeForm.value.returnStatusCtrl.length > 1">
            + {{ dateRangeForm.value.returnStatusCtrl.length - 1 }} more
          </ng-container>
        </mat-select-trigger>
        <mat-option #allStatusSelected (click)="toggleAllStatus()" [value]="0">
          All
        </mat-option>
        <mat-option (click)="getStatus(status)" *ngFor="let status of returnStatus" [value]="status" #matOption>
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-export-sales-channel-filter
    *ngIf="['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
    (getSalesChannelFilterData)="getSalesChannelFilterData($event)"
    class="export-sales-channel-filter"
  >
  </app-export-sales-channel-filter>

  <!-- Filters for Payment Reconciliation Report -->
  <div class="form-field date-rng" *ngIf="['bq_sale_order_payments_report'].includes(jobType)">
    <p class="label-des">Specify date range</p>
    <mat-form-field
      class="select-team-member empty-label-show"
      [ngClass]="dateRangeForm.get('paymentOrderDateCtrl').value ? 'add-bg-color' : ''"
    >
      <mat-label>
        {{
          dateRangeForm.get('paymentOrderDateCtrl').value
            ? dateRangeForm.get('paymentOrderDateCtrl').value
            : 'Order date'
        }}
      </mat-label>
      <mat-select
        #placedOnChannelId
        disableOptionCentering
        panelClass="custom-select-ui order-exp order-export-select"
        formControlName="paymentOrderDateCtrl"
      >
        <mat-select-trigger *ngIf="dateRangeForm.get('paymentOrderDateCtrl').value">
          {{ dateRangeForm.get('paymentOrderDateCtrl').value ? dateRangeForm.get('paymentOrderDateCtrl').value : '' }}
        </mat-select-trigger>
        <ng-container *ngFor="let d of interval.paymentRecReportDateRange">
          <mat-option value="{{ d.key }}" (click)="getDate('paymentOrderDate', d, 'Order date: ' + d.key)">
            {{ d.key }}
            <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
          </mat-option>
        </ng-container>
        <mat-option value="Custom" class="custom" disabled>
          Custom
          <div class="custom-date-rang">
            <app-custom-date-range-filter
              [selectedFilter]="'paymentOrderDate'"
              (getCustomDates)="getCustomDates('paymentOrderDate', $event, 'Order date: ')"
            ></app-custom-date-range-filter>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-export-sales-channel-filter
      (getSalesChannelFilterData)="getSalesChannelFilterData($event)"
      class="export-sales-channel-filter"
    >
    </app-export-sales-channel-filter>
  </div>

  <!-- filters for updated date -->

  <mat-form-field
    *ngIf="!['all_draft_return', 'all_returns_virtual_kit'].includes(jobType)"
    class="select-team-member empty-label-show"
    [ngClass]="dateRangeForm.get('updatedOrderDateCtrl').value ? 'add-bg-color' : ''"
  >
    <mat-label>
      {{
        dateRangeForm.get('updatedOrderDateCtrl').value
          ? dateRangeForm.get('updatedOrderDateCtrl').value
          : 'Order last updated on'
      }}
    </mat-label>
    <mat-select
      #updatedOrderDate
      disableOptionCentering
      panelClass="custom-select-ui order-exp order-export-select"
      formControlName="updatedOrderDateCtrl"
    >
      <mat-select-trigger *ngIf="dateRangeForm.get('updatedOrderDateCtrl').value">
        {{ dateRangeForm.get('updatedOrderDateCtrl').value ? dateRangeForm.get('updatedOrderDateCtrl').value : '' }}
      </mat-select-trigger>
      <ng-container *ngFor="let d of interval.updatedatDateRange">
        <mat-option value="{{ d.key }}" (click)="getDate('updatedOrderDateOn', d, 'Order last updated on: ' + d.key)">
          {{ d.key }}
          <app-schedule-info-icon [intervalKey]="d.key"></app-schedule-info-icon>
        </mat-option>
      </ng-container>
      <!-- <mat-option value="custom" class="custom" disabled>
        Custom
        <div class="custom-date-rang">
          <app-custom-date-range-filter [selectedFilter]="updatedOrderDate"
            (getCustomDates)="getCustomDates('updatedOrderDateOn', $event, 'Order last updated on: ')"></app-custom-date-range-filter>
        </div>
      </mat-option> -->
    </mat-select>
  </mat-form-field>

  <div class="btn-wrp mg-12 text-left">
    <button [disabled]="buttondisabledFlag" class="btn primary-btn no-icon" (click)="emitEnt()">
      Continue
    </button>
  </div>
</form>

import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { GetCurrentLoggedInUserData, GetPlanMappings } from '../auth/actions/auth.actions';
import { getCurrentLoggedInUserData } from '../auth/reducers/auth.selectors';
import { MappingPermissions } from '../filter-widget/models/mapping-permissions.model';
import { AuthService } from '../shared/services';
import { ToastNotificationsService } from '../shared/services/toast-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceDataResolver implements Resolve<any> {
  isPaymentCompleted: boolean;
  constructor(
    private store: Store<any>,
    private authService: AuthService,
    private router: Router,
    private toastService: ToastNotificationsService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['paymentCompleted']) {
        this.isPaymentCompleted = true;
        this.authService.setIsPaymentCompleted(true);
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store.pipe(
      select(getCurrentLoggedInUserData),
      take(1),
      switchMap(loggedInUserData => {
        if (loggedInUserData?.currentUser) {
          return of(loggedInUserData.currentUser);
        } else if (
          this.authService.getAuthToken() &&
          !loggedInUserData.currentUser &&
          !loggedInUserData.loading &&
          !loggedInUserData.loaded
        ) {
          this.store.dispatch(new GetCurrentLoggedInUserData());
          if (this.isPaymentCompleted) {
            setTimeout(() => {
              this.store.dispatch(new GetPlanMappings());
            }, 3000);
          } else {
            this.store.dispatch(new GetPlanMappings());
          }
          this.store
            .select(fromSelector.getPlanMappings)
            .pipe(
              filter((result: MappingPermissions) => !!result),
              take(1)
            )
            .subscribe((result: MappingPermissions) => {
              if (result) {
                this.authService.setIsPaymentCompleted(false);
                if (
                  typeof result?.subscription_status === 'string' &&
                  !['live', 'trail', 'dunning', 'unpaid'].includes(result?.subscription_status?.toLowerCase())
                ) {
                  // redicrect to plans
                  const qParams = { subscriptionStatus: result.subscription_status };
                  this.router.navigate(['/auth/plans'], { queryParams: qParams });
                }
              }
            });
          return this.store.pipe(
            select(getCurrentLoggedInUserData),
            filter(userData => !!userData?.currentUser),
            take(1),
            switchMap(userData => of(userData.currentUser))
          );
        }
      })
    );
  }
}

export const ERROR_MESSAGES = {
  pdfs: 'Accepted file type is .pdf only',
  txt: 'Accepted file type is .txt only',
  xls: 'Accepted file types are .xls and .xlsx only',
  csvsXls: 'Accepted file types are .csv, .xls  and .xlsx only',
  csvs: 'Accepted file type is .csv only',
  imagesDocsPdfs: 'Accepted file types are .png, .jpeg, .jpg, .doc, .docx and .pdf only',
  images: 'Accepted file types are .png, .jpeg, .jpg only',
  imagesPdfs: 'Accepted file types are .png, .jpeg, .jpg and .pdf only',
  imagesCsvsXls: 'Accepted file types are .png, .jpeg, .jpg, .csv, .xls and .xlsx only',
  textCsvsXls: 'Accepted file types are .txt, .csv, .xls and .xlsx only',
  video: 'Accepted file types are .mp4, .mov, .wmv and .avi'
};

// please keep error messages in last index only.
export const SUPPORTED_FILE_TYPES = {
  pdfs: ['application/pdf', ERROR_MESSAGES.pdfs],
  txt: ['application/text', 'text/plain', ERROR_MESSAGES.txt],
  xls: [
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ERROR_MESSAGES.xls
  ],
  csvsXls: [
    'text/csv',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ERROR_MESSAGES.csvsXls
  ],
  csvs: ['text/csv', ERROR_MESSAGES.csvs],
  imagesDocsPdfs: [
    'image/png',
    'image/jpg',
    'image/jpeg',
    '.doc',
    '.docx',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    ERROR_MESSAGES.imagesDocsPdfs
  ],
  images: ['image/jpg', 'image/png', 'image/jpeg', 'image/gif', ERROR_MESSAGES.images],
  videos: ['video/mp4', 'video/quicktime', 'video/x-ms-wmv', 'video/avi', ERROR_MESSAGES.video],
  imagesPdfs: ['image/jpg', 'image/png', 'image/jpeg', 'application/pdf', ERROR_MESSAGES.imagesPdfs],
  imagesCsvsXls: [
    'application/pdf',
    'image/jpg',
    'image/png',
    'image/jpeg',
    'text/csv',
    '.xlsx',
    '.xls',
    'text/csv',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ERROR_MESSAGES.imagesCsvsXls
  ],
  textCsvsXls: [
    'text/csv',
    'text/plain',
    'application/text',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ERROR_MESSAGES.textCsvsXls
  ]
};

export const BUCKET_NAMES = {
  settingsFees: 'settings_fees',
  settingsBrand: 'settings_brand',
  settingsPackaging: 'settings_packaging',
  fcLoction: 'fc_location',
  styleCustomerPortal: 'customerPortal_stylePortal',
  consignmentImport: 'consignment_import',
  consignmentTrack: 'consignment_track',
  productImport: 'product_import',
  productCreate: 'product_create',
  orderImport: 'order_import',
  returnImport: 'return_import',
  paymentsImport: 'payments_import',
  b2bOrderImport: 'b2bOrder_import',
  packagingImport: 'packaging_import',
  b2bOrderTrack: 'b2bOrder_track',
  orderLabel: 'order_label',
  paymentsAdd: 'payments_add',
  inventoryImport: 'inventory_import',
  captureProof: 'capture_proof',
  bulkClaimsStatusUpdate: 'bulk_claims_status_update',
  bulkClaimsAddProofs: 'bulk_claims_add_proofs',
  productVerification: 'product_verification',
  weightDiscripancy: 'weight_discripancy',
  incident: 'incident'
};

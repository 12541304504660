import { Injectable } from '@angular/core';
@Injectable()
export class Configuration {
  public CONFIG_URL = {
    SIGNUP: 'v2/user/otp/signup',
    CREATE_ACCOUNT: 'v1/account',
    GET_COUNTRY_LIST: 'v1/country?page=1&perPage=200',
    GET_STATE_LIST: 'v1/state?page=1&perPage=200',
    GET_CITY_LIST: 'v1/city?page=1&perPage=200',
    SEARCH_PINCODE_DETAILS: 'v1/pincode/',
    GET_VERTICALS_LIST: 'v1/vertical?page=1&perPage=50',
    ACCOUNT_LIST: 'v1/account?page=1&perPage=3500',
    GET_PROFILE: 'v1/user/profile',
    HIT_USER_LOGIN: 'v1/user/login',
    GET_ACCOUNT_DETAILS: 'v1/account/profile',
    UPDATE_ACCOUNT_DETAILS: 'v1/account',
    SAVE_WAREHOUSE_DETAILS: 'v1/party',
    GET_WAREHOUSE_BY_ID: 'v1/party/',
    GET_WAREHOUSE_DETAILS: 'v1/party?page=1&perPage=1500',
    GET_BRAND_DETAILS: 'v1/brand?page=1&perPage=1000',
    GET_BRAND: 'v1/brand/',
    SAVE_BRAND_DETAILS: 'v1/brand',
    GET_TEAM_MEMBER_DETAILS: 'v1/user?page=1&perPage=2500',
    SAVE_TEAM_MEMBER: 'v1/user-account-mapping',
    GET_TEAM_MEMBER_BY_ID: 'v1/user-account-mapping/',
    GET_USER_GROUP_LIST: 'v1/user-group?page=1&perPage=100',
    CREATE_USER_GROUP: 'v1/user-group',
    GET_FULFILLMENT_CENTERS: 'v2/warehouse?page=1&perPage=500',
    GET_EXTERNAL_WMS_ACCOUNTS: 'v1/external-wms-account?page=1&perPage=10',
    CREATE_SUBSCRIPTION: 'v2/subscription',
    CREATE_EXTERNAL_WMS: 'v1/external-wms-account',
    GET_FC_BY_ID: 'v2/warehouse/',
    EDIT_SUBSCRIPTION: 'v2/subscription/',
    EDIT_CHANNEL_SUBSCRIPTION: 'v1/channel/',
    GET_CHANNELS_ACCOUNT_LIST: 'v1/channel-account?portalId=',
    GET_SALES_CHANNELS: 'v1/channel?page=1&perPage=1000',
    GET_UNIQUE_CHANNELS_FOR_ADD_TRANSACTION_RULES: 'fetchChannelsForTransaction?page=1&perPage=100',
    CREATE_CHANNEL_ACCOUNT: 'v1/channel-account',
    GET_PORTAL_LIST: 'v1/portal?page=1&perPage=50',
    GET_INTEGRATION_MODEL: 'v1/integration-model?portalId=',
    CREATE_SALES_CHANNEL: 'v1/channel',
    GET_CHANNEL_BY_ID: 'v1/channel/',
    UPDATE_INVITE: 'v1/user-account-mapping/',
    EMAIL_EXISTS: 'v1/check-email?email=',
    FETCH_TRANSACTIONRULES: 'v1/fetchTransactionRules',
    INVOICE_LIST: '/receivables',
    EXPENSE_LISTING: 'v1/user-account-mapping/',
    EXPENSE_DETAILS: 'v1/user-account-mapping/',
    SAVE_PAYMENT_BOOKING_DETAILS: 'payments/api/v1/save',
    GET_PAYMENT_REPORT_STATUS: 'payments/api/v1/fetchPaymentReportStatus',
    EXPENSE_LISTIING_URL: '/payables',
    CHECK_PAYOUT_STATUS_URL: 'payments/api/v1',
    GET_TEMPLATE_LIST_URL: 'payments/api/v1/templatesForPortal',
    GET_COD_ORDER_LIST: 'payments/cod/api/v1/orders?page=1&perPage=10',
    GET_PINCODE_CITY_STATE_CODE: 'v1/pincodecitystatecode',
    CHECK_SERVICEABILITY_PINCODE: 'v1/checkpincodeserviceability',
    GET_BANK_DETAILS_LIST_URL: 'payments/cod/api/v1/bank/details',
    GET_BANK_DETAILS_URL: 'payments/cod/api/v1/bank',
    GET_CLIENT_BANK_DETAILS_URL: 'payments/cod/api/v1/bank/verification',
    GET_BUSINESS_DETAILS_URL: 'payments/cod/api/v1/business',
    GET_COD_PAYMENT_TERM_DETAILS_URL: 'payments/cod/api/v1/payment/adjustment/settings',
    SUBSCRIPTIONS_BASE_URL: 'billing/api/v1',
    HUBSPOT_BASE_URL: 'api/v1'
  };
}

export const VALIDATION_MESSAGES = {
  firstName: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter valid first name' },
    { type: 'maxlength', message: 'Only 30 characters are allowed' }
  ],
  lastName: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter valid last name' },
    { type: 'maxlength', message: 'Only 30 characters are allowed' }
  ],
  email: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid email address' }
  ],
  phone: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid mobile number.' }
  ],
  companyName: [
    { type: 'required', message: 'Required' },
    { type: 'maxlength', message: 'Only 40 characters are allowed' },
    { type: 'companyNameInvalid', message: 'Enter a valid company name' }
  ],
  productCategory: [{ type: 'required', message: 'Required' }],
  shipOrderQty: [{ type: 'required', message: 'Required' }],
  eshopboxFunctions: [{ type: 'required', message: 'Required' }],
  intrestedPlan: [{ type: 'required', message: 'Required' }],

  address: [
    { type: 'required', message: 'Required' },
    { type: 'minlength', message: 'Enter a valid address' },
    { type: 'whitespace', message: 'Enter a valid address' }
  ],
  pincode: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid pincode' }
  ],
  gstin: [{ type: 'required', message: 'Required' }],
  gstNumber: [
    { type: 'required', message: 'Required' },
    { type: 'pattern', message: 'Enter a valid GST number.' }
  ],
  referralCode: [{ message: 'Entered referral code is invalid. Please try again' }]
};

export const PATTERNS = {
  SUB_DOMAIN: /(?:[\s.])([a-z0-9][a-z0-9-]+[a-z0-9]\.auperator\.co)/,
  SUB_DOMAIN_STRING: /^[a-z0-9_-]+$/,
  // tslint:disable-next-line:max-line-length
  EMAIL: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(com|org|net|edu|gov|mil|info|biz|io|co|me|us|uk|ca|de|fr|au|in|jp)))$/,
  EMAIL_EXCLUDE_ESHOPBOX: /^(?!.*@eshopbox\.com)(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /^[6-9]{1}[0-9]{9}$/,
  PAN_CARD: /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}?$/,
  SLUG: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/,
  PINCODE: /^[1-9][0-9]{5}$/,
  NUMBER: /^[0-9]*$/,
  STRING: /^[A-Za-z]/,
  URL: /\./,
  IFSC: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
  GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  DISALLOW_SPECIAL_CHARACTERS: /^(?! )([a-zA-Z0-9-.()&' ]{1,})$/,
  NAME: /^(?!.* {2})([A-Za-z'-] ?){1,}$/
};

export const SHIP_ORDER_QUANTITY_OPTIONS = [
  { label: '1000 to 3000 items', value: '1000-3000', id: 'label1' },
  { label: '3001 to 10000 items', value: '3001-10000', id: 'label2' },
  { label: '10001 to 25000 items', value: '10001-25000', id: 'label3' },
  { label: 'more than 25000 items', value: '25000+', id: 'label4' },
  { label: 'I am just getting started', value: 'Just getting started', id: 'label5' }
];

export const PRODUCT_CATEGORY_OPTIONS = [
  {
    id: 'img1',
    label: 'Apparel & Accessories',
    value: 'Apparel and accessories'
  },
  {
    id: 'img2',
    label: 'Health & Personal Care',
    value: 'Health and wellness'
  },
  {
    id: 'img3',
    label: 'Beauty & Cosmetics',
    value: 'Beauty and cosmetics'
  },
  {
    id: 'img4',
    label: 'Consumer Electronics',
    value: 'Electronic'
  },
  {
    id: 'img5',
    label: 'Home Furnishings',
    value: 'Home essentials'
  },
  {
    id: 'img6',
    label: 'Others',
    value: 'Any others'
  }
];

export const CAROUSEL_IMG_LIST = [
  'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/default-carousel.png?updatedAt=1714391016390',
  'https://assets-global.website-files.com/5ef15d131a03d2a52d8075ad/61e688cd4d9e2e9ecc02d5cd_Img%203-p-800.jpeg',
  'https://assets-global.website-files.com/5ef15d131a03d2a52d8075ad/61e56acd35ed35ead79a7367_Group%209531-2-p-800.jpeg'
];

export const BREADCRUMBS = [
  { label: 'Pick a plan', url: '/auth/plans' },
  { label: 'Make payment', url: '/auth/billing-details' }
];

export const ESB_SIGNUP_URLS = {
  signup: '/auth/new-signup',
  plans: '/auth/plans',
  billingDetails: '/auth/billing-details'
};

export const WORDS_TO_REMOVE_FROM_COMPANY_NAME = [
  'pvt',
  'pvt.',
  'limited',
  'private',
  'ltd',
  'ltd.',
  'llp',
  'liability',
  'partnership',
  'company',
  'firm',
  'opc',
  'one person company'
];

export const ICONS_LIST = {
  couponIcon:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/iconamoon_discount-light.svg?updatedAt=1720529285632',
  truckIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/truck.svg?updatedAt=1720784227047',
  shippingIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/shipping_icon.svg?updatedAt=1720784289380',
  fulfillmentIcon: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/fulfillment.svg?updatedAt=1720784327961',
  multiChannelSync:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/multi_channel_sync.svg?updatedAt=1720784378744',
  wms: 'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/wms.svg?updatedAt=1720784431014',
  paymentReconciliation:
    'https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/payment_reconcilation.svg?updatedAt=1720784486875',
  externalIcon: 'https://ik.imagekit.io/2gwij97w0o/external_grey.svg?updatedAt=1709633345428',
  alertIcon: 'https://ik.imagekit.io/2gwij97w0o/alert-triangle%20(1).svg?updatedAt=1715669467105'
};

export const REDIRECTION_LINKS = {
  termsAndConditions: 'https://help.eshopbox.com/en/collections/2441904-eshopbox-terms-and-policies',
  serviceAgreementTerms: 'https://help.eshopbox.com/en/articles/4321435-eshopbox-terms-of-service-agreement',
  privacyPolicy: 'https://help.eshopbox.com/en/articles/4288688-eshopbox-privacy-policy',
  viewFullPricing: 'https://eshopbox-web.webflow.io/eshopbox-pricing-details',
  viewAllFeatures: 'https://eshopbox-web.webflow.io/eshopbox-pricing'
};

export const ESHOPBOX_FUNCTIONALISED = [
  { id: 1, label: 'Warehousing & Fulfillment', value: 'Warehousing & Fulfillment' },
  {
    id: 2,
    label: 'All-in-1 software to manage ecommerce operations',
    value: 'All-in-1 software to manage ecommerce operations'
  },
  { id: 3, label: 'Shipping & Delivery', value: 'Shipping & Delivery' },
  { id: 4, label: 'Eshopbox Plus', value: 'Eshopbox Plus' },
  { id: 5, label: 'Some or all of the above', value: 'Some or all of the above' },
  { id: 6, label: 'Help me decide', value: 'Help me decide' }
];

export const PLANS_AT_ESHOPBOX = [
  { id: 1, label: 'Essential', value: 'Essential plan' },
  { id: 2, label: 'Premium', value: 'Premium plan' },
  { id: 3, label: 'Enterprise', value: 'Enterprise plan' },
  { id: 4, label: 'Help me decide', value: 'Help me decide' }
];

export const ERROR_MESSAGES = {
  gstMismatchError: 'Entered State and GST Number registered state does not seems to match',
  computeFailedError: 'Unable to fetch amount, please try later'
};

/**
 * Sort by type list
 */
export const SORTBYTYPELIST: any = [
  { label: 'Newest to oldest', value: 'newest_to_oldest' },
  { label: 'Oldest to newest', value: 'oldest_to_newest' },
  { label: 'Pending to complete', value: 'pending_to_complete' },
  { label: 'Complete to pending', value: 'complete_to_pending' },
  { label: 'Qty high to low', value: 'qty_high_to_low' },
  { label: 'Qty low to high', value: 'qty_low_to_high' }
];

/**
 * Document type list
 */
export const DOCUMENTTYPELIST: any = [
  { label: 'Invoice', value: 'inv' },
  { label: 'Stock Transfer/ Challan', value: 'stc' }
];

/**
 * Pagination options
 */
export const PAGINATIONOPTIONLIST: number[] = [10, 25, 50, 100];

/**
 * Consignment default parameter list
 */
export const CONSIGNMENT_DEFAULT_PARAM_LIST: any = {
  page: 1,
  perPage: 50,
  // sort_by: 'updated_at',
  // sort_order: 'desc',
  type: 'inward'
};

/**
 * Recall status list
 *
 * @author Chandrika Aggarwal <chandrika@eshopbox.com>
 */
export const RECALL_STATUS_LIST: any = [
  // { label: 'Pending', value: 'PENDING' },
  { label: 'Created', value: 'CREATED' },
  { label: 'Failed', value: 'FAILED' },
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Packed', value: 'PACKED' },
  { label: 'Dispatched', value: 'DISPATCHED' },
  { label: 'Canceled', value: 'CANCELLED' }
];

export const REMOVAL_STATUS_LIST: any = [
  { label: 'Processing', value: 'PROCESSING' },
  { label: 'Packed', value: 'PACKED' },
  { label: 'Dispatched', value: 'DISPATCHED' }
];

/**
 * Sort by type list - Recall consignment
 */
export const RECALL_SORT_BY_TYPE_LIST: any = [
  { label: 'Newest to Oldest', value: 'dateDescending' },
  { label: 'Oldest to Newest', value: 'dateAscending' },
  { label: 'Created to Dispatched', value: 'statusAscending' },
  { label: 'Dispatched to Created', value: 'statusDescending' },
  { label: 'Qty high to low', value: 'qtyDescending' },
  { label: 'Qty low to high', value: 'qtyAscending' }
];

/**
 * Default query parameter list for Recall consignment listing page
 */
export const DEFAULT_RECALL_LIST_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending'
};
export const DEFAULT_RECALL_LIST_NON_SELLABLE_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending',
  inventoryType: 'nonSellable'
};

export const DEFAULT_REMOVALS_LIST_PARAMETERS: any = {
  page: 1,
  perPage: 50,
  type: 'recall',
  sortBy: 'dateDescending',
  inventoryType: 'nonsellable'
};

/**
 * Master recall inventory type list
 */
export const RECALL_INVENTORY_TYPE_LIST: any = [
  { label: 'Sellable', value: 'GOOD' },
  { label: 'Non sellable inwards', value: 'Non sellable inwards' },
  { label: 'Overage', value: 'Overage inwards' },
  { label: 'Non sellable returns', value: 'Non sellable returns' },
  // { label: 'Other brand returns', value: 'Incorrect returns (other brand)' },
  { label: 'Non sellable transfer items', value: 'non-sellable transfer items' },
  { label: 'Non sellable batch items', value: 'Non sellable batch items' }
];

/**
 * Custom date filter
 *
 * 1. 30 days selection
 * 2. 90 days selection
 */
export const CURRENT_DATE = new Date(new Date().setHours(0, 0, 0, 0));
export const DATE_PRE_30_DAYS = new Date(new Date().setDate(CURRENT_DATE.getDate() - 30));
export const DATE_PRE_90_DAYS = new Date(new Date().setDate(CURRENT_DATE.getDate() - 90));

export const CUSTOM_FILTER_30_DAYS_SELECTION = (d: Date): boolean => {
  const day = new Date(d);
  return day <= CURRENT_DATE && day > DATE_PRE_30_DAYS;
};
export const CUSTOM_FILTER_90_DAYS_SELECTION = (d: Date): boolean => {
  const day = new Date(d);
  return day <= CURRENT_DATE && day > DATE_PRE_90_DAYS;
};

// RECALL CONSIGNMENT CONSTANTS STARTS

export const BATCHING_METHODS = [
  {
    id: 1,
    methodName: 'Assign specific batches',
    description:
      'When creating an order with this option, you can provide specific batch details for each product, such as Manufacturing Date, Expiry Date, or Batch Code.'
  },
  {
    id: 2,
    methodName: 'Assign batches as per max stop ship date',
    description: 'When creating an order with this option, you can provide max stop ship date.'
  },
  {
    id: 3,
    methodName: 'Auto-assign batches as per FEFO',
    description:
      'If you use this option, items will be picked from inventory based on their nearest expiry date, prioritizing products with the earliest expiration.'
  }
];

export const BATCH_INFO = {
  'Assign specific batches': [
    {
      field: 'Product ID',
      description: 'Provide relevant product ID such as SKU, UPC, EAN, GTIN, or any other product aliases.'
    },
    { field: 'Quantity', description: 'Provide the quantity of the product.' },
    {
      field: 'Manufacturing date',
      description:
        'Provide the manufacturing date if the batch tracking attribute for a particular product is set as Manufacturing date. The date format should be in mmm-yy (e.g. Oct-22) or dd-mmm-yy (e.g. 05-Oct-22).'
    },
    {
      field: 'Expiry date',
      description:
        'Provide the expiry date if the batch tracking attribute for a particular product is set as Expiry date. The date format should be in mmm-yy (e.g. Oct-22) or dd-mmm-yy (e.g. 05-Oct-22).'
    },
    {
      field: 'Batch code',
      description:
        'Provide the batch code if the batch tracking attribute for a particular product is set as Batch code.'
    }
  ],
  'Assign batches as per max stop ship date': [
    {
      field: 'Product ID',
      description: 'Provide relevant product ID such as SKU, UPC, EAN, GTIN, or any other product aliases.'
    },
    { field: 'Quantity', description: 'Provide the quantity of the product.' },
    {
      field: 'Max stop ship date',
      description:
        "Max stop-ship date is the deadline after which a product can't be sold or used to fulfill orders. Provide a max stop-ship date for a product in the order. Only products with a stop-ship date before the given date will be used to allocate inventory to this order. Enter the date in the format dd-mmm-yy (e.g. 10-Jun-24)"
    }
  ],
  'Auto-assign batches as per FEFO': [
    {
      field: 'Product ID',
      description: 'Provide relevant product ID such as SKU, UPC, EAN, GTIN, or any other product aliases.'
    },
    { field: 'Quantity', description: 'Provide the quantity of the product.' }
  ]
};

export const DOWNLOAD_TEMPLATE_INFO = [
  {
    field: 'SKU',
    description: 'It is a unique name for your product against which you want to recall your inventory.'
  },
  {
    field: 'ESIN',
    description: 'Eshopbox generated product identification number against which you want to recall the inventory.'
  },
  { field: 'Quantity', description: 'The quantity that you want to recall.' }
];

// RECALL CONSIGNMENT CONSTANTS ENDS

// B2B Template Url Starts //

export const RECALL_BATCHING_URLS = {
  default: '../../../../assets/files/Recall_Template.csv',
  assignSpecificBatches:
    'https://storage.googleapis.com/eshopbox-portal-dev.appspot.com/recall-consignment/RecallItems/Import%20-%20Specific%20Batch%20-%20B2B%200rder%20-%20Sheet.csv',
  assignBatchesMaxStopShipDate:
    'https://storage.googleapis.com/eshopbox-portal-dev.appspot.com/recall-consignment/RecallItems/Import%20-%20Stop%20Ship%20Date%20-%20B2B%20order%20-%20Sheet1.csv',
  autoAssignBatchesFEFO:
    'https://storage.googleapis.com/eshopbox-portal-dev.appspot.com/recall-consignment/RecallItems/Import%20-%20Auto%20Assign_without%20batch%20-%20B2B%20orders%20-%20Sheet1.csv',
  nonBatches:
    'https://storage.googleapis.com/eshopbox-portal-dev.appspot.com/recall-consignment/RecallItems/Import%20B2B%20orders%20-%20Template.csv'
};

// B2B Template Url Ends //

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomToastComponent } from '../component/custom-toast/custom-toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationsService {
  constructor(private snackbar: MatSnackBar) {}

  showSuccessToast(message, msgDuration: number = 2000) {
    const successSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-success-notification'],
      duration: msgDuration
    });
    successSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(successSnackbarRef);
    });
  }

  showErrorToast(message, msgDuration: number = 2000) {
    const errorSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-error-notification'],
      duration: msgDuration
    });

    errorSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(errorSnackbarRef);
    });
  }

  onSnackBarAction(snackbarRef: MatSnackBarRef<any>) {
    snackbarRef.dismiss();
  }

  showFailureToast(message, msgDuration: number = 2000) {
    const failureSnackbarRef = this.snackbar.open(message, 'X', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['new-failed-notification'],
      duration: msgDuration
    });
    failureSnackbarRef.onAction().subscribe(() => {
      this.onSnackBarAction(failureSnackbarRef);
    });
  }
  // method to show the import progress toast notification
  showImportInProgressToast(
    message: string,
    horizontalPosition: 'start' | 'center' | 'end' | 'left' | 'right' = 'center'
  ) {
    const config: MatSnackBarConfig = {
      duration: 5000,
      verticalPosition: 'top',
      horizontalPosition: horizontalPosition,
      panelClass: ['custom-toast-container']
    };
    this.snackbar.openFromComponent(CustomToastComponent, config);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PlanService } from '../../services/plan.service';

@Component({
  selector: 'app-ticket-notification',
  templateUrl: './ticket-notification.component.html',
  styleUrls: ['./ticket-notification.component.scss']
})
export class TicketNotificationComponent implements OnInit {
  @Input() header: string = '';
  @Input() para: string = '';
  @Input() showBtn: string = '';
  @Input() viewTicketRout: string = '';
  @Output() closeNotification: any = new EventEmitter();
  @Input() iconSrc: string = '';
  constructor(private planService: PlanService, private router: Router) {}

  ngOnInit(): void {
    if (this.iconSrc == '') {
      this.iconSrc = 'https://ik.imagekit.io/2gwij97w0o/success-light.svg?updatedAt=1720603813623';
    }
  }

  closeTicketSnackbar() {
    this.closeNotification.emit(true);
    this.planService.hideBanner();
  }

  goToTickets() {
    this.planService.hideBanner();
    this.router.navigate([`tickets/view-all-tickets`], {
      queryParams: {
        status: '|is|New'
      }
    });
  }
}

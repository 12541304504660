<div class="full-page-loader" *ngIf="loader">
  <div class="center-loader">
    <mat-spinner [diameter]="50" [strokeWidth]="2.5"></mat-spinner>
    <div class="loader-text">
      Loading...
    </div>
  </div>
</div>
<div class="update-missing-container" *ngIf="!loader">
  <div class="product-popup-header">
    Update missing information
    <div class="close-missingpopup">
      <img
        (click)="close.emit(true)"
        src="https://ik.imagekit.io/2gwij97w0o/close-action-page.svg?updatedAt=1686129563724"
      />
    </div>
  </div>
  <div class="product-form-container">
    <form
      (click)="$event.stopPropagation()"
      (keydown.tab)="$event.stopPropagation()"
      [formGroup]="updateMissingProductForm"
    >
      <div class="product-details">
        <div class="innr-form-container" *ngIf="fieldVisible.hsnCode">
          <label name="hsn"
            >HSN code<span
              class="red-label"
              *ngIf="workflowSettings['packingInfo'][this.packingMethod]['productInformationMandatory']"
              >*</span
            ></label
          >
          <input
            type="text"
            formControlName="hsnCode"
            placeholder="Enter HSN code"
            [ngClass]="{ error: updateMissingProductForm.get('hsnCode').invalid }"
          />
        </div>
        <div class="innr-form-container" *ngIf="fieldVisible.taxCode">
          <label name="hsn"
            >Tax Code<span
              class="red-label"
              *ngIf="workflowSettings['packingInfo'][this.packingMethod]['productInformationMandatory']"
              >*</span
            ></label
          >
          <mat-form-field
            class="select-team-member con-create select-missing-product"
            [ngClass]="{ error: updateMissingProductForm.get('taxCode').invalid }"
          >
            <mat-select
              placeholder="Select tax code"
              formControlName="taxCode"
              panelClass="custom-select-ui con-create select-panel-missing"
            >
              <ng-container *ngFor="let t of taxCode">
                <mat-option [value]="t.internalName" class="option-style">
                  <span>{{ t.internalName }}: if tax applicable is {{ t.gst }} </span>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="innr-form-container" *ngIf="fieldVisible.mrp">
          <label name="hsn">MRP</label>
          <input
            type="text"
            formControlName="mrp"
            placeholder="₹  Enter amount"
            [ngClass]="{ error: updateMissingProductForm.get('mrp').invalid }"
          />
        </div>
        <div class="innr-form-container" *ngIf="this.fieldVisible.WDL">
          <label name="hsn">Dead weight <span class="label-light">(g)</span></label>
          <input
            type="text"
            formControlName="weight"
            placeholder="Enter weight"
            [ngClass]="{ error: updateMissingProductForm.get('weight').invalid }"
            (input)="calculatedChargeableWeight()"
          />
        </div>
        <div class="innr-form-container" *ngIf="this.fieldVisible.WDL">
          <label name="hsn">Dimension <span class="label-light">(L x B x H)</span></label>
          <div class="dimension-otr-container">
            <div
              class="container-dimensions"
              [ngClass]="{
                error:
                  updateMissingProductForm.get('dimensionLength').invalid ||
                  updateMissingProductForm.get('dimensionWidth').invalid ||
                  updateMissingProductForm.get('dimensionHeight').invalid
              }"
            >
              <div class="container-dimensions-col big-col">
                <input
                  formControlName="dimensionLength"
                  placeholder="L"
                  class="big-single"
                  (input)="calculatedChargeableWeight()"
                />
              </div>
              <div class="container-dimensions-col big-col">
                <input
                  formControlName="dimensionWidth"
                  placeholder="B"
                  class="big-single"
                  (input)="calculatedChargeableWeight()"
                />
              </div>
              <div class="container-dimensions-col big-col">
                <input
                  formControlName="dimensionHeight"
                  placeholder="H"
                  class="big-single"
                  (input)="calculatedChargeableWeight()"
                />
              </div>
            </div>
            <div class="input-unit">cm</div>
          </div>
        </div>
        <div class="innr-form-container" *ngIf="this.fieldVisible.WDL">
          <label name="hsn">Chargeable weight (kg)</label>
          <input type="text" formControlName="chargeableWeight" />
        </div>
        <div class="note_para">
          <span class="lft_heading">Note:</span> This information will be saved with the product & applied to all future
          orders.
        </div>
        <div class="btn_cta_product">
          <button class="btn icon-hide" (click)="close.emit(true)">Cancel</button>
          <button
            class="btn primary-btn icon-hide"
            type="submit"
            [disabled]="!formChanged() || !updateMissingProductForm.valid || loading"
            (click)="onSubmit()"
            [matTooltip]="updateMissingProductForm.valid ? '' : 'To continue, provide HSN code and Tax code'"
            matTooltipPosition="below"
            matTooltipClass="theme-tooltip below"
          >
            Update
            <mat-spinner
              style="margin-left: 5px;"
              *ngIf="loading"
              [diameter]="20"
              [strokeWidth]="3.0"
              class="centre-align"
            ></mat-spinner>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

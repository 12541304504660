<span class="upgrade-team-container">
  <div mat-dialog-title class="header">
    <p align="start">
      <img src="https://ik.imagekit.io/2gwij97w0o/Upgrade-Filled.svg?updatedAt=1724841951801" alt="" />
      {{ dialogContent.dialog_title }}
    </p>
    <img mat-dialog-close src="https://ik.imagekit.io/2gwij97w0o/cross-cancel-multiply.png?updatedAt=1723534497701" />
  </div>
  <form #userForm="ngForm">
    <div class="dialog-content" align="start">
      <div class="note">
        {{ dialogContent.note }}
      </div>
      <div class="members">
        <div class="add-members">
          <h2 class="heading">
            {{ dialogContent.box_header }}
          </h2>

          <img src="https://ik.imagekit.io/2gwij97w0o/info-small.svg?updatedAt=1716880624229" />

          <div class="muted-text" *ngIf="!priceForOneLoding">
            ₹ {{ priceForOne ? priceForOne : '0' }} / user / month
          </div>
          <div class="skeleton-line" *ngIf="priceForOneLoding"></div>
        </div>

        <div class="btn-input-field">
          <button (click)="decreaseCount()">
            <img
              src="https://ik.imagekit.io/2gwij97w0o/new_sign_up_subscription/subtract.svg?updatedAt=1714887793917"
              alt=""
            />
          </button>
          <input type="number" [(ngModel)]="addCount" name="addCount" (focusout)="onInputFocusOut($event)" />
          <button (click)="increaseCount()">
            <img
              src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/add-action.svg?updatedAt=1625848779478"
            />
          </button>
        </div>
      </div>

      <div class="info">
        <img src="https://ik.imagekit.io/2gwij97w0o/info-small.svg?updatedAt=1716880624229" class="info-image" />
        <p class="info-content">
          {{ dialogContent.info }}
        </p>
      </div>
    </div>
    <div class="footer">
      <div>
        <div class="charges">
          Immediate charges
          <img
            class="charges-info"
            src="https://ik.imagekit.io/2gwij97w0o/info-small.svg?updatedAt=1716880624229"
            alt=""
          />
        </div>
        <span class="total-charges">
          <div *ngIf="!priceIsLoading">
            <span class="total-charges-count">
              ₹{{ addOncharge.totalCharges ? addOncharge.totalCharges : ('0' | number) }}
            </span>
          </div>
          <div class="skeleton-line" *ngIf="priceIsLoading"></div>

          <span class="charges-break-up" *ngIf="!priceIsLoading">
            <div class="breakup-heading">
              Breakup
            </div>
            <span class="charges-details">
              <div class="">
                {{ dialogContent.box_header }}
              </div>
              <div>
                {{ addOncharge.addonPrice ? addOncharge.addonPrice : ('0' | number) }}
              </div>
            </span>
            <!-- <span class="charges-details">
              <div class="">
                Additional customer portal
              </div>
              <div>
                ₹2000/-
              </div>
            </span> -->
            <div class="dashed_separator"></div>

            <span class="charges-details">
              <div>
                Taxes
              </div>
              <div>
                {{ addOncharge.totalTax ? addOncharge.totalTax : ('0' | number) }}
              </div>
            </span>

            <!-- <span class="charges-details">
              <div>
                Credit applied
              </div>
              <div>
                ₹700/-
              </div>
            </span> -->
            <div class="solid-separator"></div>

            <span class="total">
              <div>
                <span class="font">Total immediate charges</span>
                <span class="muted-text font"> On:{{ currentDate | date: 'dd/MM/yyyy' }} </span>
              </div>
              <div class="total-digit font">
                {{ addOncharge.totalCharges ? addOncharge.totalCharges : ('0' | number) }}
              </div>
            </span>
            <!-- 
            <div class="info-msg">
              <img src="https://ik.imagekit.io/2gwij97w0o/info-small.svg?updatedAt=1716880624229" alt="" />
              <div>
                Visit My subscription to view detailed recurring charges for all your addons as per your billing cycle.
              </div>
            </div> -->
          </span>
        </span>
      </div>

      <div align="end" class="actions">
        <button mat-button mat-dialog-close class="cancel"><span class="font">Cancel</span></button>
        <button
          mat-button
          [disabled]="priceIsLoading || addCount == 0"
          class="submit"
          (click)="processPayment()"
          [ngClass]="{
            'disable-pay-now': priceIsLoading || addCount == 0
          }"
        >
          <span class="loader_inside_button" *ngIf="isProceedLoader"></span>
          <span class="font" [class.disabletext]="isProceedLoader"> Pay now</span>
        </button>
      </div>
    </div>
  </form>
</span>

import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as fromAuth from './auth.reducer';

const getAuthState = (state: AppState) => state.auth;

export const getUserType = createSelector(getAuthState, fromAuth.getUserType);

export const getCurrentUser = createSelector(getAuthState, fromAuth.getUserInfo);

export const getCurrentAccount = createSelector(getAuthState, fromAuth.getUserAccountInfo);

export const getSignUpLoading = createSelector(getAuthState, fromAuth.getSignUpLoading);

export const getProfileLoading = createSelector(getAuthState, fromAuth.getProfileLoading);

export const getAccountLoading = createSelector(getAuthState, fromAuth.getAccountLoading);

export const getJobList = createSelector(getAuthState, fromAuth.getAlljobs);

export const getJobLoading = createSelector(getAuthState, fromAuth.getjobloading);

export const getJobLoaded = createSelector(getAuthState, fromAuth.getjobloaded);

// OTP
export const otp = createSelector(getAuthState, fromAuth.otp);

export const otpValidating = createSelector(getAuthState, fromAuth.otpValidating);

export const otpValidated = createSelector(getAuthState, fromAuth.otpValidated);

// CREAT WOKR

export const createWorkspace = createSelector(getAuthState, fromAuth.createWorkspace);
export const workspaceCreated = createSelector(getAuthState, fromAuth.workspaceCreated);
export const workspaceCreating = createSelector(getAuthState, fromAuth.workspaceCreating);

export const createAccountSlug = createSelector(getAuthState, fromAuth.createAccountSlug);
export const accountSlugCreated = createSelector(getAuthState, fromAuth.accountSlugCreated);
export const accountSlugCreating = createSelector(getAuthState, fromAuth.accountSlugCreating);
export const getActivityToken = createSelector(getAuthState, fromAuth.getActivityToken);

export const getSalesChannelData = createSelector(getAuthState, fromAuth.getSalesChannelData);
export const getFulfillmentCenterData = createSelector(getAuthState, fromAuth.getFulfillmentCenterData);
export const getSegmentsData = createSelector(getAuthState, fromAuth.getSegmentsData);

export const getScheduleJobs = createSelector(getAuthState, fromAuth.getScheduleJobs);
export const editSchedule = createSelector(getAuthState, fromAuth.editSchedule);
export const deleteSchedule = createSelector(getAuthState, fromAuth.deleteSchedule);

export const subscriptionPlanDetails = createSelector(getAuthState, fromAuth.subscriptionPlanDetails);
export const getPlans = createSelector(getAuthState, fromAuth.plansData);
export const getCustomer = createSelector(getAuthState, fromAuth.getCustomerData);
export const updateCustomer = createSelector(getAuthState, fromAuth.updateCustomerData);
export const getSubscription = createSelector(getAuthState, fromAuth.subsriptionData);
export const makePaymentData = createSelector(getAuthState, fromAuth.makePaymentData);
export const authorizePayment = createSelector(getAuthState, fromAuth.authorizePayment);
export const getOrdersFiltersData = createSelector(getAuthState, fromAuth.getOrdersFiltersData);
export const getUpdatedUserProfileDetails = createSelector(getAuthState, fromAuth.getUpdatedUserProfileDetails);
export const getOrdersFiltersDataV2 = createSelector(getAuthState, fromAuth.getOrdersFiltersDataV2);

export const getCurrentLoggedInUserData = createSelector(getAuthState, fromAuth.GetCurrentLoggedInUserData);

export const getAccountList = createSelector(getAuthState, fromAuth.GetAccountList);
export const getPlanMappings = createSelector(getAuthState, fromAuth.planMappingsData);
export const getIsPlanMappingsLoading = createSelector(getAuthState, fromAuth.getIsPlanMappingsLoading);
export const getIsPlanMappingsLoaded = createSelector(getAuthState, fromAuth.getIsPlanMappingsLoaded);

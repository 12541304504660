import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appLocationSearch'
})
export class AppLocationSearchPipe implements PipeTransform {
  transform(items: any[], searchText?: string, pageIndex?: number, size?: number): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      if (size === undefined) {
        return items;
      } else {
        return items.filter((data, index) => index >= pageIndex * size && index < pageIndex * size + size);
      }
    }
    searchText = searchText.toLocaleLowerCase();
    if (size === undefined) {
      return items.filter(it => {
        return (
          it.facilityLabel?.toLocaleLowerCase().includes(searchText) ||
          it.facilityName?.toLocaleLowerCase().includes(searchText)
        );
      });
    } else {
      return items
        .filter(it => {
          return (
            it.facilityLabel?.toLocaleLowerCase().includes(searchText) ||
            it.facilityName?.toLocaleLowerCase().includes(searchText)
          );
        })
        .filter((data, index) => index >= pageIndex * size && index < pageIndex * size + size);
    }
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormData } from 'src/app/records/containers/form-runner/form-base';
import * as moment from 'moment';
import { isNullOrUndefined } from 'src/app/records/utils/common-util';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { NgSwitch } from '@angular/common';
import { PDFDocument, rgb } from 'pdf-lib';
import { select, Store } from '@ngrx/store';
import { GetWorkspaceSettingsData } from 'src/app/settings/store/actions/settings.actions';
import { getWorkspaceSettings } from 'src/app/settings/store/selectors/settings.selector';
import { RecordsService } from 'src/app/shared/services/records.service';
import { ErrorHandlingService } from './error-handling.service';

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
  handleBarDetils: any = '';
  FieldDetails: any[] = [];
  buildingBlockDetails: any;
  buildingBlockActions: any;
  allBuildingBlockRecords: any[] = [];
  currentFieldHandleBar: string = '';
  allFieldsData: FormData[] = [];
  allActionsFields: any[] = [];
  allFiles: any[] = [];
  allKeywords: any[] = [];
  currentAction: any;
  currentField: any;
  currentStepperIndex: number = 0;
  savedActionsRecordsResponses: any[] = [];
  selectedRecordFromRecordList: any;
  printButtonClicked: boolean = false;
  showExitAlert: boolean = false;
  per_page: number = 10;
  currentFieldData: any = {};
  childDraftData: any;
  // isDuplicateAction: boolean = false;
  printShipmentsArray = [];
  //used for get detais if device is mobile or not (less then 900px)
  innerWidth: number = 0;
  mobileWidth: number = 500;
  packagingScreenLoader = false;
  bulkLabelNotifyFlag: boolean = false;
  public tableRefresh = new Subject();
  scanPackSidelineButton = false;
  scanPackPackageLoader = false;
  invoiceResponse = {};
  public getUpdatedRecords = new Subject();
  public getUpdatedFilesFromRight = new Subject();
  public getUpdatedFilesFromLeft = new Subject();
  public getUpdatedDataFromLeft = new Subject();
  public hideShowMobileView = new Subject();
  public getUpdateWhenHiddenField = new Subject();
  public getActionUpdatesFromLeft = new Subject();
  public getReferenceFromPopupToLeft = new Subject();
  public getReferenceFromPopupToRight = new Subject();
  public hidePopup = new Subject();
  public updateReferenceDetailsToLeftParent = new Subject();
  public updateReferenceDetailsToRightParent = new Subject();
  public updateKeywordsToRightSide = new Subject();
  public updateMobileBottomPanel = new Subject();
  public getUpdateInvoiceStatus = new Subject();
  public formRunnerRecord: any;
  public previousActionDetails: any;
  public isManuallyInitiated: boolean;
  public isShowReturnLoader: boolean = false;
  public allProductsBatchingDetails: any = [];
  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private recordsService: RecordsService,
    private errorService: ErrorHandlingService,
    private store: Store<{ auth }>
  ) {
    this.innerWidth = window.innerWidth;
    this.isManuallyInitiated = false;
  }

  setUpdatePrintInvoiceStatus(data: any) {
    this.getUpdateInvoiceStatus.next(data);
  }

  setUpdatedRecordsToLeftSide(data: any) {
    this.getUpdatedRecords.next(data);
  }
  setUpdatedFilesToLeftSide(data: any) {
    this.getUpdatedFilesFromRight.next(data);
  }
  setUpdatedFilesToRightSide(data: any) {
    this.getUpdatedFilesFromLeft.next(data);
  }
  setUpdatedDataToRightSide(data: any) {
    this.getUpdatedDataFromLeft.next(data);
  }
  tableDataRefresh(data: boolean) {
    this.tableRefresh.next(data);
  }

  changeMobileView(data: any) {
    this.hideShowMobileView.next(data);
  }

  updateWhenHiddenField(data: any) {
    this.getUpdateWhenHiddenField.next(data);
  }

  updateActionsDetailsToRight(data: any) {
    this.getActionUpdatesFromLeft.next(data);
  }

  updateReferenceToLeft(data: any) {
    this.getReferenceFromPopupToLeft.next(data);
  }

  updateReferenceToRight(data: any) {
    this.getReferenceFromPopupToRight.next(data);
  }

  closePopup(data: any) {
    this.hidePopup.next(data);
  }

  // updateReferenceDetailsToLeft(data: any) {
  //   this.updateReferenceDetailsToLeftParent.next(data);
  // }

  getUniqueResultFilter(currentfield, filterObj) {
    let uniqueFields = '|IS|' + currentfield.validations?.uniqueResultInfo?.uniqueFields.join('|');
    let filterString =
      `isGetUniqueResult=|IS|${currentfield.validations.isGetUniqueResult}` + `&uniqueFields=${uniqueFields}`;
    return filterString;
  }

  getPerPageCount() {
    const currentBB = this._route.snapshot.queryParams.handleBar;
    let paginationCount = 10;
    let initializePageSize = false;
    if (this.innerWidth > this.mobileWidth) {
      if (localStorage.getItem('paginationSizeOption')) {
        let paginationSizeOptions = JSON.parse(localStorage.getItem('paginationSizeOption'));
        let currentPaginationSizeObj = paginationSizeOptions.find(opt => opt.handlebar == currentBB);
        if (currentPaginationSizeObj) {
          paginationCount = currentPaginationSizeObj.pageSize;
        } else {
          initializePageSize = true;
        }
      } else {
        initializePageSize = true;
      }
    } else {
      initializePageSize = true;
    }
    if (initializePageSize) {
      if ((currentBB === 'shipment' || currentBB === 'picklist') && this.innerWidth > this.mobileWidth) {
        paginationCount = 25;
      } else {
        paginationCount = this.innerWidth < this.mobileWidth ? 5 : 10;
      }
    }
    return paginationCount;
  }

  async updateReferenceDetailsToLeft(data: any) {
    let self = this;
    new Promise<void>(async (resolve, reject) => {
      await this.updateReferenceDetailsToLeftParent.next(data);
      function checkFlag() {
        if (self.updateReferenceDetailsToLeftParent.closed) {
          console.log('met');
          resolve();
        } else {
          setTimeout(checkFlag, 100);
        }
      }
      checkFlag();
    });
  }

  bulkLabelNotifyHide() {
    setTimeout(() => {
      this.bulkLabelNotifyFlag = false;
      this.printShipmentsArray = [];
    }, 10000);
  }

  updateReferenceDetailsToRight(data: any) {
    this.updateReferenceDetailsToRightParent.next(data);
  }

  updateKeywordsToRight(data: any) {
    this.updateKeywordsToRightSide.next(data);
  }

  updateMobileBottomPanelSelectedData(data: any) {
    this.updateMobileBottomPanel.next(data);
  }

  enableSubmitButton(handleBar) {
    let button = document.getElementById(handleBar + '_button');
    if (button) button.removeAttribute('disabled');
    let button_mob = document.getElementById(handleBar + '_button_mob');
    if (button_mob) button_mob.removeAttribute('disabled');
  }

  disableSubmitButton(handleBar) {
    let button = document.getElementById(handleBar + '_button');
    if (button) button.setAttribute('disabled', 'true');
    let button_mob = document.getElementById(handleBar + '_button_mob');
    if (button_mob) button_mob.setAttribute('disabled', 'true');
  }

  getFilterString(currentfield, allFieldsData, parentAllFieldsData?): any {
    let allRecordsValue = JSON.parse(JSON.stringify(allFieldsData));
    let filterObj = new FilterData();
    let putawayValueCheck: boolean = false;
    let filter = currentfield.validations.filter;
    if (filter != undefined) {
      filterObj.staticFilter = filter.staticFilterString ? filter.staticFilterString : '';
      let dynamicFilter = '';
      if (filter.dynamicFilter != undefined) {
        let filterOperator = filter.dynamicFilter.filterOperator ? filter.dynamicFilter.filterOperator : 'AND';

        //Putaway special handling in api call filters. BB-1714
        if (
          currentfield.buildingBlock == 'putawayCompletedItems' &&
          currentfield.action == 'addItems' &&
          (currentfield.handleBar == 'items' || currentfield.handleBar == 'products')
        ) {
          let putawayTypeCheck = this.currentFieldData.putawayType[0].value;
          if (
            putawayTypeCheck &&
            (putawayTypeCheck == 'Return sellable items' ||
              putawayTypeCheck == 'Return non sellable items' ||
              putawayTypeCheck == 'Non sellable return items' ||
              putawayTypeCheck == 'Return incorrect items' ||
              putawayTypeCheck == 'Return incorrect items (same brand)' ||
              putawayTypeCheck == 'Return incorrect items (other brand)')
          )
            putawayValueCheck = true;
        }
        for (let key in filter.dynamicFilter) {
          dynamicFilter = '';
          let value = filter.dynamicFilter[key];
          let filterString: string[] = [];

          //is
          if (key == 'is') {
            for (let childKey in value) {
              let childvalue = value[childKey];
              if (childvalue instanceof Array) {
                // array
                childvalue.forEach(x => {
                  if (x.includes('.') && !x.includes('$')) {
                    let childFieldDetails = this.getReferenceChildFieldDetails(x, currentfield);
                    let refValue = [];
                    if (childFieldDetails)
                      refValue = this.getCurrentReferenceValue(x, childFieldDetails, allFieldsData);
                    if (refValue.length > 0) {
                      // nested reference
                      if (childFieldDetails.fieldType == 'ATTACHMENT') {
                        // if (refValue instanceof Array) {
                        refValue.forEach(element => {
                          let fileName = element.split('/').pop();
                          if (fileName && fileName.includes('.')) filterString.push(childKey + '=|IS|' + fileName);
                        });
                        // }
                      } else if (childFieldDetails.fieldType == 'DATE_PICKER') {
                        refValue.forEach(element => {
                          if (element && element.includes('T'))
                            filterString.push(childKey + '=|IS|' + element.split('T')[0]);
                        });
                      } else if (
                        childFieldDetails.fieldType == 'SINGLE_SELECT' &&
                        childFieldDetails.validations.isImageIncluded
                      ) {
                        refValue.forEach(element => {
                          if (element && element.id) filterString.push(childKey + '=|IS|' + element.id);
                        });
                      } else if (
                        childFieldDetails.fieldType == 'MULTI_SELECT' &&
                        childFieldDetails.validations.isImageIncluded
                      ) {
                        refValue.forEach(element => {
                          if (element && element.id) filterString.push(childKey + '=|IS|' + element.id);
                        });
                      } else {
                        refValue.forEach(element => {
                          if (!isNullOrUndefined(element)) filterString.push(childKey + '=|IS|' + element);
                        });
                      }
                    } else {
                      let keys = x.split('.');
                      let rec = allRecordsValue.find(c => c.handleBar == keys[0]);
                      if (rec && rec.value) {
                        let key = childKey.split('.');
                        filterString.push(key[0] + '=|IS|' + '');
                      }
                    }
                  } else if (x.includes('$')) {
                    if (x.includes('.')) {
                      let parentFieldDetails = parentAllFieldsData.filter(
                        ele => ele['handleBar'] === x.split('$')[1].split('.')[0]
                      )[0];
                      if (childKey == 'shippingProvider') {
                        //for handling of special character '&' in the value of the key 'shippingProvider'
                        let myVal = parentFieldDetails['value'][0][x.split('$')[1].split('.')[1]];
                        filterString.push(childKey + '=|IS|' + encodeURIComponent(myVal));
                      } else {
                        filterString.push(
                          childKey + '=|IS|' + parentFieldDetails['value'][0][x.split('$')[1].split('.')[1]]
                        );
                      }
                    } else {
                      let parentFieldDetails = parentAllFieldsData.filter(
                        ele => ele['handleBar'] === x.split('$')[1]
                      )[0];
                      if (parentFieldDetails) {
                        if (parentFieldDetails.fieldType == 'SINGLE_SELECT') {
                          if (parentFieldDetails.value instanceof Array) {
                            let string = '';
                            parentFieldDetails.value.forEach(element => {
                              string += element + '|';
                            });
                            filterString.push(childKey + '=|IS|' + string.substring(0, string.length - 1));
                          } else filterString.push(childKey + '=|IS|' + parentFieldDetails.value);
                        } else filterString.push(childKey + '=|IS|' + parentFieldDetails['value']);
                      }
                    }
                    // console.log(x);
                    // let parentFieldDetails = parentAllFieldsData.filter(ele => ele['handleBar'] === x.split('$')[1])[0];
                    // filterString.push(childKey + '=|IS|' + parentFieldDetails['value']);
                  } else {
                    // regular property
                    let findValue = allRecordsValue.filter(c => c.handleBar == x);
                    if (findValue.length > 0) {
                      if (findValue[0].fieldType == 'ATTACHMENT') {
                        // check array
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element.name);
                          });
                        }
                        // check object
                        else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value.name);
                        }
                      }

                      if (findValue[0].fieldType == 'DATE_PICKER') {
                        // check array
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            if (element && this.isValidDate(element)) {
                              var d = new Date(element),
                                month = '' + (d.getMonth() + 1),
                                day = '' + d.getDate(),
                                year = d.getFullYear();

                              if (month.length < 2) month = '0' + month;
                              if (day.length < 2) day = '0' + day;

                              filterString.push(childKey + '=|IS|' + [year, month, day].join('-'));
                            }
                          });
                        }
                        // check object
                        else {
                          if (findValue[0].value && this.isValidDate(findValue[0].value)) {
                            var d = new Date(findValue[0].value),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                            if (month.length < 2) month = '0' + month;
                            if (day.length < 2) day = '0' + day;

                            filterString.push(childKey + '=|IS|' + [year, month, day].join('-'));
                          }
                        }
                      }

                      if (findValue[0].fieldType == 'BOOLEAN') {
                        filterString.push(childKey + '=|IS|' + findValue[0].value);
                      }

                      if (findValue[0].fieldType == 'NUMBER') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'LONG_TEXT') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'SHORT_TEXT') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'SINGLE_SELECT') {
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'MULTI_SELECT') {
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS|' + findValue[0].value);
                        }
                      }
                    }
                  }
                });
              }
            }

            dynamicFilter =
              !filterOperator || filterOperator == 'AND' ? filterString.join('&') : filterString.join('|');
          }

          //is_not
          if (key == 'is_not') {
            for (let childKey in value) {
              let childvalue = value[childKey];
              if (childvalue instanceof Array) {
                // array
                childvalue.forEach(x => {
                  if (x.includes('.')) {
                    // nested reference
                    let childFieldDetails = this.getReferenceChildFieldDetails(x, currentfield);
                    let refValue = [];
                    if (childFieldDetails)
                      refValue = this.getCurrentReferenceValue(x, childFieldDetails, allFieldsData);
                    if (refValue.length > 0) {
                      // nested reference
                      if (childFieldDetails.fieldType == 'ATTACHMENT') {
                        // if (refValue instanceof Array) {
                        refValue.forEach(element => {
                          let fileName = element.split('/').pop();
                          if (fileName && fileName.includes('.')) filterString.push(childKey + '=|IS_NOT|' + fileName);
                        });
                        // }
                      } else if (childFieldDetails.fieldType == 'DATE_PICKER') {
                        refValue.forEach(element => {
                          if (element && element.includes('T'))
                            filterString.push(childKey + '=|IS_NOT|' + element.split('T')[0]);
                        });
                      } else if (
                        childFieldDetails.fieldType == 'SINGLE_SELECT' &&
                        childFieldDetails.validations.isImageIncluded
                      ) {
                        refValue.forEach(element => {
                          if (element && element.id) filterString.push(childKey + '=|IS_NOT|' + element.id);
                        });
                      } else if (
                        childFieldDetails.fieldType == 'MULTI_SELECT' &&
                        childFieldDetails.validations.isImageIncluded
                      ) {
                        refValue.forEach(element => {
                          if (element && element.id) filterString.push(childKey + '=|IS_NOT|' + element.id);
                        });
                      } else {
                        refValue.forEach(element => {
                          if (!isNullOrUndefined(element)) filterString.push(childKey + '=|IS_NOT|' + element);
                        });
                      }
                    }
                  } else {
                    // regular property
                    let findValue = allRecordsValue.filter(c => c.handleBar == x);
                    if (findValue.length > 0) {
                      if (findValue[0].fieldType == 'ATTACHMENT') {
                        // check array
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element.name);
                          });
                        }
                        // check object
                        else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value.name);
                        }
                      }

                      if (findValue[0].fieldType == 'DATE_PICKER') {
                        // check array
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            if (element && this.isValidDate(element)) {
                              var d = new Date(element),
                                month = '' + (d.getMonth() + 1),
                                day = '' + d.getDate(),
                                year = d.getFullYear();

                              if (month.length < 2) month = '0' + month;
                              if (day.length < 2) day = '0' + day;

                              filterString.push(childKey + '=|IS_NOT|' + [year, month, day].join('-'));
                            }
                          });
                        }
                        // check object
                        else {
                          if (findValue[0].value && this.isValidDate(findValue[0].value)) {
                            var d = new Date(findValue[0].value),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                            if (month.length < 2) month = '0' + month;
                            if (day.length < 2) day = '0' + day;

                            filterString.push(childKey + '=|IS_NOT|' + [year, month, day].join('-'));
                          }
                        }
                      }

                      if (findValue[0].fieldType == 'BOOLEAN') {
                        filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                      }

                      if (findValue[0].fieldType == 'NUMBER') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'LONG_TEXT') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'SHORT_TEXT') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'SINGLE_SELECT') {
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                        }
                      }

                      if (findValue[0].fieldType == 'MULTI_SELECT') {
                        if (findValue[0].value instanceof Array) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|IS_NOT|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|IS_NOT|' + findValue[0].value);
                        }
                      }
                    }
                  }
                });
              }
            }
            dynamicFilter = filterOperator == 'AND' ? filterString.join('&') : filterString.join('|');
          }

          //is_greater_than
          if (key == 'is_greater_than') {
            for (let childKey in value) {
              let childvalue = value[childKey];
              if (childvalue instanceof Array) {
                // array
                childvalue.forEach(x => {
                  if (x.includes('.')) {
                    // nested reference
                    let childFieldDetails = this.getReferenceChildFieldDetails(x, currentfield);
                    let refValue = [];
                    if (childFieldDetails)
                      refValue = this.getCurrentReferenceValue(x, childFieldDetails, allFieldsData);
                    if (refValue.length > 0) {
                      // nested reference
                      if (childFieldDetails.fieldType == 'DATE_PICKER') {
                        refValue.forEach(element => {
                          if (element && element.includes('T'))
                            filterString.push(childKey + '=' + element.split('T')[0] + '|TO|');
                        });
                      } else if (childFieldDetails.fieldType == 'NUMBER') {
                        refValue.forEach(element => {
                          if (!isNullOrUndefined(element)) filterString.push(childKey + '=' + element + '|TO|');
                        });
                      }
                    }
                  } else {
                    // regular property
                    let findValue = allFieldsData.filter(c => c.handleBar == x);
                    if (findValue.length > 0) {
                      if (findValue[0].fieldType == 'NUMBER') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=' + element + '|TO|');
                          });
                        } else {
                          filterString.push(childKey + '=' + findValue[0].value + '|TO|');
                        }
                      } else if (findValue[0].fieldType == 'DATE_PICKER') {
                        // check array
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            if (element && this.isValidDate(element)) {
                              var d = new Date(element),
                                month = '' + (d.getMonth() + 1),
                                day = '' + d.getDate(),
                                year = d.getFullYear();

                              if (month.length < 2) month = '0' + month;
                              if (day.length < 2) day = '0' + day;
                              filterString.push(childKey + '=' + [year, month, day].join('-') + '|TO|');
                            }
                          });
                        }
                        // check object
                        else {
                          if (findValue[0].value && this.isValidDate(findValue[0].value)) {
                            var d = new Date(findValue[0].value),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                            if (month.length < 2) month = '0' + month;
                            if (day.length < 2) day = '0' + day;

                            filterString.push(childKey + '=' + [year, month, day].join('-') + '|TO|');
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
            dynamicFilter = filterOperator == 'AND' ? filterString.join('&') : filterString.join('|');
          }

          //is_less_than
          if (key == 'is_less_than') {
            for (let childKey in value) {
              let childvalue = value[childKey];
              if (childvalue instanceof Array) {
                // array
                childvalue.forEach(x => {
                  if (x.includes('.')) {
                    // nested reference
                    let childFieldDetails = this.getReferenceChildFieldDetails(x, currentfield);
                    let refValue = [];
                    if (childFieldDetails)
                      refValue = this.getCurrentReferenceValue(x, childFieldDetails, allFieldsData);
                    if (refValue.length > 0) {
                      // nested reference
                      if (childFieldDetails.fieldType == 'DATE_PICKER') {
                        refValue.forEach(element => {
                          if (element && element.includes('T'))
                            filterString.push(childKey + '=|TO|' + element.split('T')[0]);
                        });
                      } else if (childFieldDetails.fieldType == 'NUMBER') {
                        refValue.forEach(element => {
                          if (!isNullOrUndefined(element)) filterString.push(childKey + '=|TO|' + element);
                        });
                      }
                    }
                  } else {
                    // regular property
                    let findValue = allFieldsData.filter(c => c.handleBar == x);
                    if (findValue.length > 0) {
                      if (findValue[0].fieldType == 'NUMBER') {
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            filterString.push(childKey + '=|TO|' + element);
                          });
                        } else {
                          filterString.push(childKey + '=|TO|' + findValue[0].value);
                        }
                      } else if (findValue[0].fieldType == 'DATE_PICKER') {
                        // check array
                        if (findValue[0].hasMultipleValues == true) {
                          findValue[0].value.forEach(element => {
                            if (element && this.isValidDate(element)) {
                              var d = new Date(element),
                                month = '' + (d.getMonth() + 1),
                                day = '' + d.getDate(),
                                year = d.getFullYear();

                              if (month.length < 2) month = '0' + month;
                              if (day.length < 2) day = '0' + day;
                              filterString.push(childKey + '=|TO|' + [year, month, day].join('-'));
                            }
                          });
                        }
                        // check object
                        else {
                          if (findValue[0].value && this.isValidDate(findValue[0].value)) {
                            var d = new Date(findValue[0].value),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                            if (month.length < 2) month = '0' + month;
                            if (day.length < 2) day = '0' + day;

                            filterString.push(childKey + '=|TO|' + [year, month, day].join('-'));
                          }
                        }
                      }
                    }
                  }
                });
              }
            }

            dynamicFilter = filterOperator == 'AND' ? filterString.join('&') : filterString.join('|');
          }

          //Is_between
          if (key == 'is_between') {
            let innerString = '';
            for (let childKey in value) {
              let childvalue = value[childKey];
              if (childvalue instanceof Array) {
                innerString = '';
                // array
                childvalue.forEach((x, childIndex = 0) => {
                  if (x.includes('.')) {
                    // nested reference
                    let childFieldDetails = this.getReferenceChildFieldDetails(x, currentfield);
                    let refValue = [];
                    if (childFieldDetails)
                      refValue = this.getCurrentReferenceValue(x, childFieldDetails, allFieldsData);
                    if (refValue.length > 0) {
                      // nested reference
                      if (childFieldDetails.fieldType == 'DATE_PICKER') {
                        // refValue.forEach(element => {
                        if (refValue && refValue[0] && refValue[0].includes('T'))
                          innerString += refValue[0].split('T')[0] + (childIndex == 0 ? '|TO|' : '');
                        // });
                      } else if (childFieldDetails.fieldType == 'NUMBER') {
                        // refValue.forEach(element => {
                        if (refValue && !isNullOrUndefined(refValue[0]))
                          innerString += refValue[0] + (childIndex == 0 ? '|TO|' : '');
                        // });
                      }
                    }
                  } else {
                    // regular property
                    let findValue = allFieldsData.filter(c => c.handleBar == x);
                    if (findValue.length > 0) {
                      if (findValue[0].fieldType == 'NUMBER') {
                        if (!findValue[0].hasMultipleValues) {
                          innerString += findValue[0].value + (childIndex == 0 ? '|TO|' : '');
                        }
                      } else if (findValue[0].fieldType == 'DATE_PICKER') {
                        // check array
                        //not usefull for multiple values
                        if (!findValue[0].hasMultipleValues) {
                          if (findValue[0].value && this.isValidDate(findValue[0].value)) {
                            var d = new Date(findValue[0].value),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                            if (month.length < 2) month = '0' + month;
                            if (day.length < 2) day = '0' + day;

                            innerString += [year, month, day].join('-') + (childIndex == 0 ? '|TO|' : '');
                          }
                        }
                      }
                    }
                  }
                });
                filterString.push(childKey + '=' + innerString);
              }
            }

            dynamicFilter = filterOperator == 'AND' ? filterString.join('&') : filterString.join('|');
          }
          //Putaway special handling in api call filters. BB-1714
          if (putawayValueCheck && dynamicFilter.includes('putawayId=|IS')) {
            dynamicFilter = dynamicFilter.split('&')[1];
            if (dynamicFilter) filterObj.dynamicFilter = filterObj.dynamicFilter + dynamicFilter;
            else {
              dynamicFilter = '';
              filterObj.dynamicFilter = filterObj.dynamicFilter + dynamicFilter;
            }
          } else filterObj.dynamicFilter = filterObj.dynamicFilter + dynamicFilter;
        }
      }

      if (currentfield.validations.filter.sortBy != undefined && currentfield.validations.filter.sortBy != null) {
        filterObj.sortBy = 'sortBy=' + currentfield.validations.filter.sortBy;
        if (
          currentfield.validations.filter.sortOrder != undefined &&
          currentfield.validations.filter.sortOrder != null
        ) {
          filterObj.sortOrder = 'sortOrder=' + currentfield.validations.filter.sortOrder;
        } else {
          filterObj.sortBy = '';
          filterObj.sortOrder = '';
        }
      }
    }

    return filterObj;
  }

  getCurrentReferenceValue(key, childFieldDetails, allFieldsData) {
    if (childFieldDetails == null) return [];
    let allRecordsValue = JSON.parse(JSON.stringify(allFieldsData));
    let columnkeys = key.split('.');
    let obj: any = [];
    let finalValue = [];
    let childDataObj;
    let childRecordValue;

    columnkeys.forEach((o, i = 0) => {
      if (i == 0) {
        let rec = allRecordsValue.find(c => c.handleBar == o);
        obj = rec && rec.value ? rec.value : [];
      } else if (obj && obj.length > 0) {
        childRecordValue = obj;
        if (columnkeys.length == i + 1) {
          let childValueArray = [];
          childRecordValue.forEach(element => {
            childValueArray = childValueArray.concat(element[o]);
          });
          childDataObj = childValueArray;
          finalValue = childDataObj;
        } else {
          let childArray = [];
          childRecordValue.forEach(element => {
            if (element[o]) childArray = childArray.concat(element[o]);
          });
          childDataObj = childArray;
          childRecordValue = childArray.length > 0 ? childArray.map(m => m.record) : null;
          obj = childRecordValue;
        }
      }
    });
    if (finalValue.length > 0) {
      // if(finalValue[0])
      finalValue = [].concat.apply([], finalValue);
      return finalValue;
    } else return [];
  }

  getReferenceChildFieldDetails(key, currentField) {
    var bbDetails = JSON.parse(localStorage.getItem('BuildingBlockDetails'));
    if (key.includes('.') && bbDetails.length > 0) {
      var column = key.split('.');
      var refBB = currentField.validations.referenceSlug;
      var columnDetails;
      for (let j = 1; j < column.length; j++) {
        var selectedBBdetails = bbDetails.filter(bb => bb.handleBar === refBB)[0];
        var fields = [];
        selectedBBdetails['actions'].forEach(action => {
          fields.push(...action['fields']);
        });
        var selectedBBFields = fields.filter(f => {
          return f.handleBar === column[j];
        })[0];
        if (selectedBBFields) {
          columnDetails = selectedBBFields;
          if (selectedBBFields.fieldType === 'REFERENCE') {
            refBB = selectedBBFields['validations']['referenceSlug'];
          }
        }
      }
      return columnDetails;
    }
    return null;
  }

  isValidDate(str) {
    var d = moment(str, 'D/M/YYYY').local();
    if (d == null || !d.isValid()) return false;

    return (
      str.indexOf(d.format('D/M/YYYY')) >= 0 ||
      str.indexOf(d.format('DD/MM/YYYY')) >= 0 ||
      str.indexOf(d.format('D/M/YYY')) >= 0 ||
      str.indexOf(d.format('DD/MM/YYY')) >= 0
    );
  }

  printLableStreamForPack(labelStream) {
    let byteChar = atob(labelStream);
    let byteArray = new Array(byteChar.length);
    for (let i = 0; i < byteChar.length; i++) {
      byteArray[i] = byteChar.charCodeAt(i);
    }
    let blob = new Blob([new Uint8Array(byteArray)], { type: 'application/pdf' });
    let printPage = window.open(window.URL.createObjectURL(blob));
    printPage.print();
    // setTimeout(() => {
    //   printPage.close();
    // }, 10000);
  }

  recallData: any;
  async getQtyofOccurrencesForESINWithBatch() {
    let groupedData = JSON.parse(sessionStorage.getItem('SelectedRecord'));
    let esinBatchMap: Map<
      string,
      { esin: string; batchDetails: string; sku: string; scannableIdentifier: string; count: number }
    > = new Map();
    let batchDetailsIds: string[] = [];

    for (let packedItem of groupedData['packedItems']) {
      let record = packedItem['record'] || packedItem;

      let esin = record['esin'] || '-';
      let batchDetails = record['batchDetails'] || null;
      let sku = record['sku'] || '-';
      let scannableIdentifier = record['scannableIdentifier'] || '-';

      let key = esin + '-' + batchDetails;

      if (esinBatchMap.has(key)) {
        let existingItem = esinBatchMap.get(key);
        existingItem.count++;
      } else {
        let newItem = { esin, batchDetails, sku, scannableIdentifier, count: 1 };
        esinBatchMap.set(key, newItem);
        batchDetailsIds.push(batchDetails);
      }
    }

    if (groupedData['inventoryType'].id == 14) {
      if (batchDetailsIds.length > 0 && !batchDetailsIds.includes(null)) {
        const response = await this.recordsService.callApiForBatchDetails({
          handleBar: 'batchDetails',
          batchDetailsIds: batchDetailsIds
        });
        for (const batchDetailsInfo of response['data']) {
          const key = batchDetailsInfo.record.esin + '-' + batchDetailsInfo.id;
          const esinBatchItem = esinBatchMap.get(key);
          if (esinBatchItem) {
            esinBatchItem['batchDetailsInfo'] = batchDetailsInfo['record'];
            esinBatchItem['batchDetailsInfo']['id'] = batchDetailsInfo['id'];
          }
        }
      } else {
        this.errorService.errorNotification('Batch Detail is not available for some items.');
      }
    }
    this.recallData = Array.from(esinBatchMap.values());
    return this.recallData;
  }
  // way of printing multiple label urls into single pdf
  async fetchPDFContent(url: string) {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
  }

  async mergePDFs(pdfs: Uint8Array[]) {
    const pdfDoc = await PDFDocument.create();

    for (const pdf of pdfs) {
      const externalPdf = await PDFDocument.load(pdf);
      const copiedPages = await pdfDoc.copyPages(externalPdf, externalPdf.getPageIndices());
      copiedPages.forEach(page => pdfDoc.addPage(page));
    }
    return await pdfDoc.save();
  }

  printPDF(pdfData: Uint8Array) {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url, '_blank');
    printWindow?.print();
  }

  navigateToRecordList(handleBar, selectSegment?, filterOperator?) {
    const queryParams = {
      handleBar,
      location: this.auth.getFlexSlug()
    };

    if (selectSegment) {
      queryParams['filter'] = selectSegment;
    }

    if (filterOperator) {
      queryParams['filterOperator'] = filterOperator;
    }

    this.router.navigate(['/building-blocks/view-record-list'], { queryParams });
  }

  goToListingPage(handleBar) {
    const recordListURL = localStorage.getItem('recordListURL');
    const filterParam = recordListURL ? recordListURL.split('filter=')[1] : '';
    this.navigateToRecordList(handleBar, filterParam ? decodeURIComponent(filterParam) : '');
    localStorage.removeItem('recordListURL');
  }

  async getSettings(): Promise<any> {
    return new Promise(resolve => {
      this.store.pipe(select(getWorkspaceSettings)).subscribe(response => {
        if (response?.getWorkspaceSettings && response.getWorkspaceSettingsLoaded) {
          const workflowSettings = response.getWorkspaceSettings;
          resolve(workflowSettings);
        } else {
          if (
            !response?.getWorkspaceSettings &&
            !response.getWorkspaceSettingsLoading &&
            !response.getWorkspaceSettingsLoaded
          ) {
            this.store.dispatch(new GetWorkspaceSettingsData());
          }
        }
      });
    });
  }

  openFormRunner(handleBar, actionName, recordId?, id?) {
    const queryParams = {
      handleBar: handleBar,
      actionName: actionName,
      location: this.auth.getFlexSlug()
    };
    if (recordId) {
      queryParams['id'] = recordId;
    }
    if (id) {
      queryParams['draftId'] = id;
    }

    this.router.navigate(['/add-action/form-runner'], { queryParams });
  }

  removeDatePrefix(qparamaFilterValue) {
    if (!qparamaFilterValue) return qparamaFilterValue;
    if (
      qparamaFilterValue.includes('today-') ||
      qparamaFilterValue.includes('yesterday-') ||
      qparamaFilterValue.includes('last7days-') ||
      qparamaFilterValue.includes('last30days-') ||
      qparamaFilterValue.includes('last90days-') ||
      qparamaFilterValue.includes('thisweek-') ||
      qparamaFilterValue.includes('thismonth-') ||
      qparamaFilterValue.includes('lastmonth-')
    ) {
      const valuesToRemove = [
        'today-',
        'yesterday-',
        'last7days-',
        'last30days-',
        'last90days-',
        'thisweek-',
        'thismonth-',
        'lastmonth-'
      ];
      const pattern = new RegExp(valuesToRemove.map(value => '\\b' + value).join('|'), 'g');
      return qparamaFilterValue.replace(pattern, '');
    } else if (
      qparamaFilterValue.includes('today') ||
      qparamaFilterValue.includes('yesterday') ||
      qparamaFilterValue.includes('last7days') ||
      qparamaFilterValue.includes('last30days') ||
      qparamaFilterValue.includes('last90days') ||
      qparamaFilterValue.includes('thisweek') ||
      qparamaFilterValue.includes('thismonth') ||
      qparamaFilterValue.includes('lastmonth')
    ) {
      return this.replaceDateStringwithDate(qparamaFilterValue);
    } else return qparamaFilterValue;
  }

  replaceDateStringwithDate(inputString, withKey?) {
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    const replacedString = inputString
      .split('&')
      .map(pair => {
        const [key, value] = pair.split('=');
        if (value) {
          switch (value) {
            case 'today':
              return `${key}=${withKey ? value + '-' : ''}${currentDate}|TO|${currentDate}`;
            case 'yesterday':
              let yesterday: any = new Date(
                moment()
                  .subtract(1, 'days')
                  .startOf('day')
                  .valueOf()
              );
              yesterday = moment(yesterday).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${yesterday}|TO|${yesterday}`;
            case 'last7days':
              let last7daysFromDate: any = new Date(
                moment()
                  .subtract(6, 'days')
                  .startOf('day')
                  .valueOf()
              );
              last7daysFromDate = moment(last7daysFromDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${last7daysFromDate}|TO|${currentDate}`;
            case 'last30days':
              let last30daysFromDate: any = new Date(
                moment()
                  .subtract(29, 'days')
                  .startOf('day')
                  .valueOf()
              );
              last30daysFromDate = moment(last30daysFromDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${last30daysFromDate}|TO|${currentDate}`;
            case 'last90days':
              let last90daysFromDate: any = new Date(
                moment()
                  .subtract(89, 'days')
                  .startOf('day')
                  .valueOf()
              );
              last90daysFromDate = moment(last90daysFromDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${last90daysFromDate}|TO|${currentDate}`;
            case 'thisweek':
              let thisWeekFromDate: any = new Date(
                moment()
                  .startOf('week')
                  .valueOf()
              );
              thisWeekFromDate = moment(thisWeekFromDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${thisWeekFromDate}|TO|${currentDate}`;
            case 'thismonth':
              let thisMonthFromDate: any = new Date(
                moment()
                  .startOf('month')
                  .valueOf()
              );
              thisMonthFromDate = moment(thisMonthFromDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${thisMonthFromDate}|TO|${currentDate}`;
            case 'lastmonth':
              let lastMonthFromDate: any = new Date(
                moment()
                  .subtract(1, 'months')
                  .startOf('month')
                  .valueOf()
              );
              let lastMonthToDate: any = new Date(
                moment()
                  .subtract(1, 'months')
                  .endOf('month')
                  .valueOf()
              );
              lastMonthFromDate = moment(lastMonthFromDate).format('YYYY-MM-DD');
              lastMonthToDate = moment(lastMonthToDate).format('YYYY-MM-DD');
              return `${key}=${withKey ? value + '-' : ''}${lastMonthFromDate}|TO|${lastMonthToDate}`;
            default:
              return pair;
          }
        }
        return pair;
      })
      .join('&');
    return replacedString;
  }
}

export class DataStoreServicePopUp {
  handleBarDetils: any = '';
  FieldDetails: any[] = [];
  buildingBlockDetails: any;
  buildingBlockActions: any;
  allBuildingBlockRecords: any[] = [];
  currentFieldHandleBar: string = '';
  allFieldsData: FormData[] = [];
  allActionsFields: any[] = [];
  allFiles: any[] = [];
  allKeywords: any[] = [];
  currentAction: any;
  currentField: any;
  currentStepperIndex: number = 0;
  savedActionsRecordsResponses: any[] = [];
  printButtonClicked: boolean = false;
  per_page: number = 10;
  currentFieldData: any = {};

  public getUpdatedRecords = new Subject();
  public getUpdatedFilesFromRight = new Subject();
  public getUpdatedFilesFromLeft = new Subject();
  public getUpdatedDataFromLeft = new Subject();
  public hideShowMobileView = new Subject();
  public hideShowPopUpActionView = new Subject();
  public getUpdateWhenHiddenField = new Subject();
  public getActionUpdatesFromLeft = new Subject();
  public getReferenceFromPopupToLeft = new Subject();
  public getReferenceFromPopupToRight = new Subject();
  public hidePopup = new Subject();
  public updateReferenceDataToPopup = new Subject();
  public updateReferenceDetailsToLeftParent = new Subject();
  public updateReferenceDetailsToRightParent = new Subject();
  public updateKeywordsToRightSide = new Subject();
  public checkKeywordsToLestSide = new Subject();
  public clearHistoryToRightSide = new Subject();

  constructor() {}

  setUpdatedRecordsToLeftSide(data: any) {
    this.getUpdatedRecords.next(data);
  }

  setUpdatedFilesToLeftSide(data: any) {
    this.getUpdatedFilesFromRight.next(data);
  }

  setUpdatedFilesToRightSide(data: any) {
    this.getUpdatedFilesFromLeft.next(data);
  }

  setUpdatedDataToRightSide(data: any) {
    this.getUpdatedDataFromLeft.next(data);
  }

  changeMobileView(data: any) {
    this.hideShowMobileView.next(data);
  }

  changePopUpActionView(data: any) {
    this.hideShowPopUpActionView.next(data);
  }

  updateWhenHiddenField(data: any) {
    this.getUpdateWhenHiddenField.next(data);
  }

  updateActionsDetailsToRight(data: any) {
    this.getActionUpdatesFromLeft.next(data);
  }

  updateReferenceToLeft(data: any) {
    this.getReferenceFromPopupToLeft.next(data);
  }

  updateReferenceToRight(data: any) {
    this.getReferenceFromPopupToRight.next(data);
  }

  closePopup(data: any) {
    this.hidePopup.next(data);
  }

  updatePopupTableList(data: any) {
    this.updateReferenceDataToPopup.next(data);
  }

  updateReferenceDetailsToLeft(data: any) {
    this.updateReferenceDetailsToLeftParent.next(data);
  }

  updateReferenceDetailsToRight(data: any) {
    this.updateReferenceDetailsToRightParent.next(data);
  }

  updateKeywordsToRight(data: any) {
    this.updateKeywordsToRightSide.next(data);
  }

  // checkKeywordsLestSide(data: any) {
  //   this.checkKeywordsToLestSide.next(data);
  // }

  // getcheckKeywordsLestSide(): Observable<any> {
  //   return this.checkKeywordsToLestSide.asObservable();
  // }

  setclearHistoryToRightSide() {
    this.clearHistoryToRightSide.next('');
  }

  // getclearHistoryToRightSide(): Observable<any> {
  //   return this.clearHistoryToRightSide.asObservable();
  // }
}

export class FilterData {
  staticFilter: string = '';
  dynamicFilter: string = '';
  sortOrder: string = '';
  sortBy: string = '';
}

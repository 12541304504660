import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { select, Store } from '@ngrx/store';
import { Intercom } from 'ng-intercom';
import * as fromActivitySelector from '../app/activity/activity-main/store/selectors/activity-main.selector';
import { environment } from '../environments/environment';
import * as fromSelector from './apps-automation/store/selectors/install-app-list.selector';
import * as fromOrderSelector from './orderV2/store/selectors/orders.selectors';
import { RouterService } from './shared/services';
import { AuthService } from './shared/services/auth.service';
import { LocalStorageChangeDetectionService } from './shared/services/detect-local-storage-change.service';
// import smartlookClient from 'smartlook-client';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { OrdersState } from './orderV2/store/state/orders.state';
import { BANNER_MESSAGES } from './plan/components/add-team-member/add-more.modal';
import { PlanService } from './plan/services/plan.service';
import { CanonicalService } from './shared/services/canonical.service';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'MyEshopbox';
  showPopUp: boolean = false;
  loading = false;
  refDetailsData: { accountSlug: string; connectionId: number; connectionState: any; appClientId: any };
  isIntercomLoaded: boolean;
  showRefreshApplicationPop = environment.showRefreshApplicationPop;
  showTicketBanner: boolean = false;
  BANNER_MESSAGES = BANNER_MESSAGES;
  public showWsLoading: boolean = false;
  qParamsSubs: any;

  constructor(
    private routerService: RouterService,
    private swUpdate: SwUpdate,
    private authSrv: AuthService,
    private intercom: Intercom,
    private lsd: LocalStorageChangeDetectionService,
    private store: Store<any>,
    private orderStore: Store<{ ordersState: OrdersState }>,
    private canonicalService: CanonicalService,
    private planService: PlanService,
    private route: ActivatedRoute
  ) {
    this.planService.showBanner$.subscribe(show => {
      this.showTicketBanner = show;
      if (this.showTicketBanner) {
        setTimeout(() => {
          this.showTicketBanner = false;
        }, 5000);
      }
    });
    this.qParamsSubs = this.route.queryParams.subscribe(params => {
      if (params['paymentCompleted']) {
        this.showWsLoading = true;
        this.authSrv.setIsPaymentCompleted(true);
        this.authSrv.isPaymentCompleted.subscribe(data => {
          if (data) {
            this.showWsLoading = true;
          } else {
            this.showWsLoading = false;
          }
        });
      }
    });
  }
  ngOnInit() {
    this.store.pipe<any>(select(fromActivitySelector.getActivityLoading)).subscribe((res: any) => {
      this.loading = res;
    });
    this.store.pipe<any>(select(fromSelector.getProgressSpinner)).subscribe((res: any) => {
      this.loading = res;
    });
    this.orderStore.pipe(select(fromOrderSelector.getResolverLoader)).subscribe((res: any) => {
      this.loading = res;
    });
    this.routerService.ActivateRouteService();
    // Implemented for Shopify Signup / Login page redirection from Shopify install (from store)
    const refDetails = window.location.search.split('=')[1];
    if (window.location.href.includes('refDetails') && refDetails != undefined) {
      let decodeUri = decodeURIComponent(refDetails);
      let decodeUrl = atob(decodeUri);
      this.refDetailsData = {
        accountSlug: '',
        connectionId: parseInt(this.authSrv.getDecodedValue(decodeUrl, 'connectionId')),
        connectionState: this.authSrv.getDecodedValue(decodeUrl, 'connectionState'),
        appClientId: this.authSrv.getDecodedValue(decodeUrl, 'appClientId')
      };
      localStorage.setItem('refDetails', JSON.stringify(this.refDetailsData));
    }
    if (this.swUpdate.isEnabled && this.showRefreshApplicationPop) {
      this.swUpdate.available.subscribe(() => {
        if (localStorage.getItem('email')) {
          this.showPopUp = true;
          // if (confirm('New version available. Load new version?')) {
          //   window.location.reload();
          // }
        }
      });
    }
    // setInterval(() => {
    //   this.authSrv.checkSession();
    // }, 3600000);

    this.lsd.watchStorage().subscribe(res => {
      if (res) {
        if (
          window.location.hostname.includes(this.authSrv.getAccountSlug()) &&
          !this.isIntercomLoaded &&
          localStorage.getItem('fullName') &&
          localStorage.getItem('workspace')
        ) {
          if (window.location.hostname != 'localhost' && !this.authSrv.isMobileDevice()) {
            this.isIntercomLoaded = true;
            this.intercom.boot({
              app_id: environment.intercom.app_id,
              name: localStorage.getItem('fullName'),
              email: localStorage.getItem('email'),
              Workspace: localStorage.getItem('workspace'),
              phoneNo: localStorage.getItem('phoneNo'),
              widget: {
                activator: '#intercom'
              },
              company: {
                id: localStorage.getItem('workspace'),
                name: localStorage.getItem('workspace'),
                'Company tag': localStorage.getItem('email').includes('@eshopbox.com') ? 'Eshopbox' : 'Clients',
                slug: this.authSrv.getAccountSlug()
              },
              user_hash: localStorage.getItem('hmac'),
              hide_default_launcher: true
            });
          }
          // if (environment.COOKIE_DOMAIN === '.myeshopbox.com') {
          //   smartlookClient.init('c9c2e10b5a9d00b7c07eebd07f394d21ab16f42c');
          //   smartlookClient.identify(localStorage.getItem('email'), {
          //     name: localStorage.getItem('fullName'),
          //     email: localStorage.getItem('email'),
          //     workspaceName: localStorage.getItem('workspace')
          //   });
          // }
        }
      }
    });

    this.canonicalService.setCanonicalURL();
  }

  refresh() {
    window.location.reload();
  }

  remindLater() {
    this.showPopUp = false;
  }

  ngOnDestroy() {
    this.routerService.DeactivateRouteService();
    this.qParamsSubs?.unsubscribe();
  }
}

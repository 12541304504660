import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { DataStoreService } from '../../services/data-store.service';
import { RecordsService } from '../../services/records.service';
import * as ld from 'lodash';
import { AlertService } from '../../services';
@Component({
  selector: 'app-update-missing-product-popup',
  templateUrl: './update-missing-product-popup.component.html',
  styleUrls: ['./update-missing-product-popup.component.scss']
})
export class UpdateMissingProductPopupComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dataStoreService: DataStoreService,
    private recordService: RecordsService,
    private alertService: AlertService
  ) {}
  @Input() menuTrigger: MatMenuTrigger;
  @Input() item: any = {};
  @Input() productData: any = [];
  @Output() close = new EventEmitter<boolean>();
  updateMissingProductForm: FormGroup;
  workflowSettings;
  loader: boolean = false;
  loading: boolean = false;
  initialFormValues: any;
  taxCode: any = [
    { internalName: 'ESBGST1', gst: '5% OR 12%' },
    { internalName: 'ESBGST2', gst: '12% OR 18%' },
    { internalName: 'ESBGST3', gst: '0%' },
    { internalName: 'ESBGST4', gst: '0%' },
    { internalName: 'ESBGST5', gst: '18%' },
    { internalName: 'ESBGST7', gst: '5%' },
    { internalName: 'ESBGST9', gst: '12%' },
    { internalName: 'ESBGST10', gst: '3%' },
    { internalName: 'ESBGST12', gst: '28%' }
  ];
  packingMethod: string = '';
  fieldVisible = {
    hsnCode: false,
    taxCode: false,
    mrp: false,
    WDL: false
  };

  async ngOnInit() {
    let data = this.productData[this.item.esin] || {};
    this.loader = true;
    this.workflowSettings = await this.dataStoreService.getSettings();
    this.loader = false;
    this.packingMethod = this.workflowSettings['packingInfo']['packingMethod'];
    this.updateMissingProductForm = this.fb.group({
      hsnCode: [
        {
          value: this.item.hsnCode || data.hsnCode || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.hsnCode ||
            data.hsnCode
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      taxCode: [
        {
          value: this.item.taxCode || data.taxCode || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.taxCode ||
            data.taxCode
        }
      ],
      mrp: [
        {
          value: this.item.mrp || data.mrp || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.mrp ||
            data.mrp
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      weight: [
        {
          value: this.item.weight || data.weight || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.weight ||
            data.weight
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      dimensionLength: [
        {
          value: this.item.dimensionLength || data.dimensionLength || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.dimensionLength ||
            data.dimensionLength
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      dimensionWidth: [
        {
          value: this.item.dimensionWidth || data.dimensionWidth || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.dimensionWidth ||
            data.dimensionWidth
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      dimensionHeight: [
        {
          value: this.item.dimensionHeight || data.dimensionHeight || '',
          disabled:
            !this.workflowSettings['packingInfo'][this.packingMethod]['productInformationEditable'] ||
            this.item.dimensionHeight ||
            data.dimensionHeight
        },
        Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)
      ],
      chargeableWeight: [
        {
          value: '',
          disabled: true
        }
      ]
    });
    this.calculatedChargeableWeight();
    this.initialFormValues = ld.cloneDeep(this.updateMissingProductForm.getRawValue());
    this.checkAndUpdateFieldVisibility();
    if (this.workflowSettings['packingInfo'][this.packingMethod]['productInformationMandatory']) {
      this.updateMissingProductForm
        .get('hsnCode')
        .setValidators([Validators.required, Validators.pattern(/^(?=.*[1-9])\d*(?:\.\d+)?$/)]);
      this.updateMissingProductForm.get('taxCode').setValidators([Validators.required]);
      this.updateMissingProductForm.get('hsnCode').updateValueAndValidity();
      this.updateMissingProductForm.get('taxCode').updateValueAndValidity();
    }
  }
  isWDLFieldsPresent() {
    const weight = this.updateMissingProductForm.get('weight').value;
    const dimensionLength = this.updateMissingProductForm.get('dimensionLength').value;
    const dimensionWidth = this.updateMissingProductForm.get('dimensionWidth').value;
    const dimensionHeight = this.updateMissingProductForm.get('dimensionHeight').value;
    return weight && dimensionLength && dimensionWidth && dimensionHeight;
  }
  checkAndUpdateFieldVisibility() {
    // Disable and hide fields with existing values only during form initialization
    const fieldVisibility = {
      hsnCode: this.updateMissingProductForm.get('hsnCode').value,
      taxCode: this.updateMissingProductForm.get('taxCode').value,
      mrp: this.updateMissingProductForm.get('mrp').value,
      weight: this.updateMissingProductForm.get('weight').value,
      dimensionLength: this.updateMissingProductForm.get('dimensionLength').value,
      dimensionWidth: this.updateMissingProductForm.get('dimensionWidth').value,
      dimensionHeight: this.updateMissingProductForm.get('dimensionHeight').value
    };

    // Iterate over the form controls and decide visibility based on the patched values
    Object.keys(fieldVisibility).forEach(field => {
      if (fieldVisibility[field]) {
        // Disable the field and hide it if it has a value
        this.updateMissingProductForm.get(field).disable();
        this.fieldVisible[field] = false; // Hide field
      } else {
        this.fieldVisible[field] = true; // Show field
      }
    });

    // Special handling for dimension and weight fields (WDL fields)
    if (this.isWDLFieldsPresent()) {
      this.updateMissingProductForm.get('weight').disable();
      this.updateMissingProductForm.get('dimensionLength').disable();
      this.updateMissingProductForm.get('dimensionWidth').disable();
      this.updateMissingProductForm.get('dimensionHeight').disable();
      this.fieldVisible.WDL = false; // Hide all WDL fields
    } else {
      this.fieldVisible.WDL = true; // Show all WDL fields
    }
  }
  formChanged() {
    if (ld.isEqual(this.initialFormValues, this.updateMissingProductForm.getRawValue())) {
      return false;
    } else {
      return true;
    }
  }
  calculatedVolumetricWeight() {
    const length = this.updateMissingProductForm.get('dimensionLength').value;
    const breadth = this.updateMissingProductForm.get('dimensionWidth').value;
    const height = this.updateMissingProductForm.get('dimensionHeight').value;
    return (length * breadth * height) / 5000;
  }
  calculatedChargeableWeight() {
    const weight = this.updateMissingProductForm.get('weight').value;
    const volumetricWeight = this.calculatedVolumetricWeight();
    const dw = parseFloat(weight) / 1000;
    const vw = volumetricWeight;
    let chargeableWeight;
    if (dw > vw) {
      chargeableWeight = dw;
    } else {
      chargeableWeight = vw;
    }
    this.updateMissingProductForm.get('chargeableWeight').setValue(chargeableWeight.toFixed(2));
  }

  onSubmit() {
    if (this.updateMissingProductForm.valid && this.formChanged()) {
      this.loading = true;
      const formValues = this.updateMissingProductForm.value;
      let prodItem = this.productData[this.item.esin];
      let filteredPayload = {};
      Object.keys(formValues).forEach(key => {
        const value = formValues[key];
        if (value !== null && value !== undefined && value !== '') {
          filteredPayload[key] = value;
          prodItem[key] = value;
        }
      });
      let payload = {
        [this.item.sku]: filteredPayload
      };
      this.recordService.updateProductDetails(this.item.workspace, payload).subscribe(
        (res: any) => {
          if (res['partial'].length > 0 || res['complete'].length > 0) {
            payload['sku'] = this.item.sku;
            this.productData[this.item.esin] = JSON.parse(JSON.stringify(prodItem));
            this.productData[this.item.esin]['chargeable_weight'] = JSON.parse(
              JSON.stringify(this.updateMissingProductForm.get('chargeableWeight').value)
            );
            this.close.emit(true);
          }
          if (res['failed'].length > 0) {
            this.alertService.showError('Selected product details are not updated.', 10000, true);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.alertService.showError(error, 10000, true);
          this.close.emit(true);
        }
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  constructor(private router: Router) {}
  currenturl;
  isHubspotPage: boolean;
  private unsubscribe$ = new Subject<void>();
  ngOnInit() {
    this.router.events
      .pipe(
        startWith(new NavigationEnd(0, '/', '/')),
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        if (event && event.url !== '/') {
          this.currenturl = event.url;
        } else {
          this.currenturl = this.router.url;
        }
        this.isHubspotPage = this.currenturl.includes('/auth/hubspot-user-form');
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

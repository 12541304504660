import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-export-product-columns',
  templateUrl: './export-product-columns.component.html',
  styleUrls: ['../order-schedule/order-schedule.component.scss']
})
export class ExportProductColumnsComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() templateList: any;
  @Input() templateSelected: any;
  @Input() selectAllOption: any;
  @Output() toggleParent = new EventEmitter();
  @Output() toggleSelection = new EventEmitter();
  accountSlug: string;
  viewCustomerPermission: boolean;
  mappingObj = {
    'requires serial number (eg. IMEI) scan': 'show',
    'Sold as virtual kit': 'show',
    'enable batch tracking': 'show',
    'batch tracking attribute': 'show'
  };
  exportProdDetailsMappings$: Subscription;
  allOrdExportsMappings$: Subscription;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.accountSlug = this.authService.getAccountSlug();
    if (this.accountSlug == 'tcns' || this.accountSlug == 'tcnspc') {
      if (this.item.key == 'Order') {
        this.item.value.filter(el => {
          if (el.key.includes('Invoice Url')) {
            el['accountSlug'] = true;
          } else {
            el['accountSlug'] = false;
          }
        });
      }
      if (this.item.key == 'Customer') {
        this.item['accountSlug'] = true;
      } else {
        this.item['accountSlug'] = false;
      }
    } else {
      this.item['accountSlug'] = false;
    }
    if (this.item.key == 'Custom') {
      this.item.value = this.filterArchived(this.item.value);
    }
    if (this.item.key == 'Product Details') {
      this.exportProdDetailsMappings$ = this.authService
        .getPlanMappingsData('inventory.all_products.export.product_details')
        .subscribe(data => {
          this.mappingObj['requires serial number (eg. IMEI) scan'] = data.requires_serial_number;
          this.mappingObj['enable batch tracking'] = data.enable_batch_tracking;
          this.mappingObj['batch tracking attribute'] = data.batch_tracking_attribute;
        });
    }

    if (this.item.key == 'Order') {
      this.allOrdExportsMappings$ = this.authService.getPlanMappingsData('orders.all_orders.export').subscribe(data => {
        this.mappingObj['Sold as virtual kit'] = data.sold_as_virtual_kit;
      });
    }
  }

  filterArchived(data) {
    let d = JSON.parse(localStorage.getItem('customField'));
    let c = [];
    data.forEach(e => {
      // console.log(this.templateList?.internalNameToLabel[e.key],e);
      if (d.filter(ele => ele.label == this.templateList?.internalNameToLabel[e.key]).length == 0) {
        e.checked = false;
        // c.push(e);
      }
    });
    return data;
  }

  /** @description Select All Checkbox min Selection */
  someComplete(item): boolean {
    return item.value.filter(t => t.checked).length > 0 && !this.selectAllOption[item.key];
  }

  /** @description Toggle Parent Event */
  toggleParentEvent($event, item) {
    this.toggleParent.emit({ $event, item });
  }

  /** @description Toggle Selection Event */
  toggleSelectionEvent($event, f, item) {
    this.toggleSelection.emit({ $event, f, item });
  }

  ngOnDestroy(): void {
    this.allOrdExportsMappings$?.unsubscribe();
    this.exportProdDetailsMappings$?.unsubscribe();
  }
}

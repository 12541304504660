import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentCountService {
  COUNT_ENDPOINT = 'api/v1/orders/count';

  constructor(private auth: AuthService, private http: HttpClient) {}

  // All orders segment count
  getAllOrderSegmentCount(payload) {
    const accountSlug = this.auth.getAccountSlug();
    const base = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
    const URL = `${base}${this.COUNT_ENDPOINT}`;
    return this.http.post(URL, payload);
  }

  getWidgetSegmentCount(payload) {
    const accountSlug = this.auth.getAccountSlug();
    const base = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
    const URL = `${base}${this.COUNT_ENDPOINT}`;
    return this.http.post(URL, payload);
  }
}

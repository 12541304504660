import { environment } from 'src/environments/environment';
export var appIdConfig;
export var partnerCodeConfig;
// prod config
if (environment.BASE_DOMAIN == '.myeshopbox.com') {
  partnerCodeConfig = 431895;
  appIdConfig = {
    FLIPKART_APP_ID: 18,
    SHOPIFY_APP_ID: [
      21,
      35,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      51,
      52,
      53,
      55,
      56,
      58,
      59,
      61,
      63,
      64,
      66,
      67,
      68,
      69,
      70,
      72,
      73,
      75,
      76,
      77,
      78,
      89,
      90,
      104,
      105,
      107,
      108,
      109,
      110,
      111,
      122,
      125,
      127,
      135,
      137,
      138,
      139,
      141,
      143,
      148,
      149,
      150,
      153,
      156,
      157,
      158,
      162,
      163,
      166,
      167,
      172,
      174,
      178,
      179,
      180,
      194,
      199,
      200,
      201,
      204,
      218,
      219,
      220,
      221,
      241,
      243,
      244,
      251,
      253,
      255,
      283,
      284,
      297,
      302,
      343,
      344,
      347,
      348,
      349,
      351,
      352,
      354,
      359,
      367,
      368,
      369,
      370,
      372,
      373,
      382,
      398,
      402,
      404,
      406,
      412,
      416,
      420,
      422,
      427,
      429,
      437,
      445,
      447,
      448,
      458,
      459,
      461,
      465,
      467,
      470,
      471,
      486,
      491,
      500,
      501,
      508,
      514,
      517,
      522,
      523,
      524,
      525,
      526,
      533,
      543,
      547,
      549,
      554,
      555,
      558,
      559,
      565,
      566,
      567,
      568,
      569,
      575,
      587,
      591,
      593,
      617,
      618,
      619,
      620,
      622,
      623,
      627,
      628,
      634,
      636,
      653,
      659,
      660,
      663,
      668,
      674,
      676,
      684,
      685,
      688,
      691,
      692,
      693,
      699,
      700,
      705,
      706,
      716,
      717,
      720,
      734,
      737,
      739,
      740,
      746,
      747,
      751,
      753,
      754,
      757,
      758,
      761,
      767,
      770,
      788,
      794,
      795,
      796,
      804
    ],
    AJIO_APP_ID: 32,
    AMAZON_APP_ID: 29,
    TATA_CLIQ_UC_APP_ID: 33,
    TATA_CLIQ_APP_ID: 131,
    MYNTRA_APP_ID: 14,
    CLICK_POST_APP_ID: 36,
    CLOUD_TAIL_APP_ID: 34,
    UNICOMMERCE_APP_ID: 62,
    GLANCE_APP_ID: 49,
    NYKAA_APP_ID: 92,
    VINCULUM_APP_ID: 95,
    EXPORT_FTP_APP_ID: 91,
    FYND_APP_ID: 190,
    SERVITIUM_APP_ID: 242,
    WHATSAPP_APP_ID: 435,
    NEW_NYKAA_APP_ID: 434,
    CLEAR_TAG_APP_ID: 449,
    AJIO_NEW_APP_ID: 191,
    WOO_COMMERCE_APP_ID: 793
  };
}
// staging config
else {
  partnerCodeConfig = 708602;
  appIdConfig = {
    FLIPKART_APP_ID: 96,
    SHOPIFY_APP_ID: [98, 124, 119, 543, 533],
    AJIO_APP_ID: 114,
    AMAZON_APP_ID: 112,
    TATA_CLIQ_UC_APP_ID: 115,
    TATA_CLIQ_APP_ID: 484,
    MYNTRA_APP_ID: 93,
    CLICK_POST_APP_ID: 117,
    CLOUD_TAIL_APP_ID: 116,
    UNICOMMERCE_APP_ID: 125,
    GLANCE_APP_ID: 127,
    NYKAA_APP_ID: 123,
    VINCULUM_APP_ID: 466,
    EXPORT_FTP_APP_ID: 467,
    FYND_APP_ID: 485,
    SERVITIUM_APP_ID: 513,
    AJIO_NEW_APP_ID: 502,
    CLEAR_TAG_APP_ID: 610,
    WHATSAPP_APP_ID: 604,
    NEW_NYKAA_APP_ID: 564,
    WOO_COMMERCE_APP_ID: 658,
    AMAZON_SELLER_PARTNER_APP_ID: 1856
  };
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ROUTESDATA } from 'src/app/constants/routes';
import { AuthService } from 'src/app/shared/services';
import { GetInstallAppList } from '../../../app/apps-automation/store/actions/install-app-list.action';
import * as fromSelector from '../../../app/apps-automation/store/selectors/install-app-list.selector';
import { InstallAppListState } from '../../../app/apps-automation/store/state/install-app-list.state';
import { getInstalledAppsListLoaded } from '../../apps-automation/store/reducers/install-app-list.reducer';
import { ReconnectNotificationService } from '../../notification-setting/serviceV2/reconnect-notification.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss']
})
export class HomeLayoutComponent implements OnInit {
  showSidebar: boolean;
  showTopNav: boolean;
  settingSidebar: boolean = false;
  routesData: any = ROUTESDATA;
  routePath: string;
  queryParams: Params;
  hasLocationAccess: any;
  itemToOpen: any;
  hasLocation: any;
  isSingle: boolean;
  failedCount = 0;
  appInstallationId: any;
  connectionId: any;
  isDisconnected: boolean = false;
  accountSlug: string;
  appName: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private store: Store<{ installedAppList: InstallAppListState }>,
    private notificationService: ReconnectNotificationService
  ) {}

  ngOnInit() {
    this.getFeildConnectionData();
    this.shouldShowNotification();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (this.matchRoutePaths(event.url)) {
        this.settingSidebar = true;
      } else {
        this.settingSidebar = false;
      }
    });

    this.route.paramMap.subscribe(params => {
      this.routePath = params.get('path');
    });

    this.route.queryParams.subscribe((queryParams: Params) => {
      this.queryParams = queryParams;
    });
    this.settingSidebar = this.matchRoutePaths(window.location.pathname);
    if (!this.authService.getAccountSlug()) {
      this.showTopNav = false;
      this.showSidebar = false;
    } else if (this.authService.isMobileDevice()) {
      this.showSidebar = true;
      this.showTopNav = false;
    } else if (!this.authService.isMobileDevice()) {
      this.showSidebar = false;
      this.showTopNav = true;
    }
  }
  shouldShowNotification(): boolean {
    const currentUrl = this.router.url;
    return (
      this.notificationService.shouldShowNotification() &&
      (currentUrl.includes('/dashboard/overview') ||
        currentUrl.includes('/installed-app-list/view?filter=allApps') ||
        currentUrl.includes('/installed-app-list/view'))
    );
  }
  async getFeildConnectionData(): Promise<any> {
    return new Promise<void>(resolve => {
      this.store.pipe(select(fromSelector.getInstalledAppsList)).subscribe(res => {
        if (res && res.data) {
          this.failedCount = 0;
          res.data.forEach(app => {
            if (app.appInstallationId) {
              if (app.appInstallationId && Array.isArray(app.connections)) {
                app.connections.forEach(connection => {
                  if (connection.isActive === 'inactive') {
                    this.appInstallationId = app.appInstallationId;
                    this.failedCount++;
                    this.connectionId = connection.connectionId;
                    this.appName = connection.connectionName;
                    if (this.failedCount >= 2) {
                      return;
                    }
                  }
                });
              }
            }
          });

          if (this.failedCount === 1) {
            this.isSingle = true;
            this.isDisconnected = true;
          } else if (this.failedCount > 1) {
            this.isSingle = false;
            this.isDisconnected = true;
          }
          resolve();
        } else {
          // if (!res.error && (!res || !res.getInstalledAppsListLoading)) {
          //   this.store.dispatch(new GetInstallAppList());
          // }
          if (!getInstalledAppsListLoaded && !res) {
            this.store.dispatch(new GetInstallAppList());
          }
        }
      });
    });
  }

  closeDisconnectNoti() {
    this.isDisconnected = false;
    this.notificationService.dismissNotification();
  }
  setRoutesData(routesData) {
    this.routesData = routesData.routesData;
    this.itemToOpen = routesData.itemToOpen;
  }

  learnMoreReconnect() {
    let url = 'https://help.eshopbox.com/en/articles/9413646-reconnecting-apps-in-eshopbox';
    window.open(url, '_blank');
  }

  matchRoutePaths(route) {
    if (route) {
      let pathMatched = false;
      if (route.includes('?')) {
        route = route.split('?')[0];
      }
      this.routesData.setting.map(item => {
        if (
          item.fullPath == route ||
          (item.scope != '' && route.includes(item.scope)) ||
          (route.includes('/view-record-list') &&
            this.queryParams['handleBar'] &&
            this.queryParams['handleBar'] == item.URL)
        ) {
          pathMatched = true;
        }
      });
      return pathMatched;
    } else {
      return false;
    }
  }
}

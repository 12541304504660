<span class="get-feature-upgrade-plan-container">
  <div mat-dialog-title class="header">
    <img class="update" src="https://ik.imagekit.io/2gwij97w0o/Upgrade-Filled.svg?updatedAt=1724841951801" alt="" />
    <p class="get-feature-text">Get feature</p>
    <img
      align="end"
      mat-dialog-close
      class="close"
      src="https://ik.imagekit.io/2gwij97w0o/cross-cancel-multiply.png?updatedAt=1723534497701"
    />
  </div>
  <form [formGroup]="updatePlanForm">
    <div class="dialog-content" align="start">
      <div>
        <p class="disclaimer">
          This feature is not available in your current plan. To access it, you will need to upgrade to a higher plan.
        </p>
      </div>
      <div>
        <label for="requirements" class="disclaimer"
          >Tell us your requirements and we will recommend the best plan for you.</label
        >
        <textarea
          class="mod-plan-textarea"
          name="requirements"
          formControlName="planRequirementsControl"
          floatPlaceholder="never"
        ></textarea>
      </div>
    </div>
    <div align="end" class="actions">
      <button mat-button mat-dialog-close class="cancel"><span class="font">Cancel</span></button>
      <button
        mat-button
        [class.primary-btn-loader]="isProceedLoader"
        class="submit"
        [disabled]="updatePlanForm.invalid"
        (click)="createTicketForPlanModification()"
      >
        <span class="font">Submit</span>
      </button>
    </div>
  </form>
</span>

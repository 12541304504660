<div class="feature-container">
  <ng-container *ngIf="showArrowBtn">
    <div class="imgText" (click)="getFeature()" [tooltip]="tooltip" [options]="TOOLTIP_CONFIG" reusableTooltip>
      <span>
        <img class="arwImg" [src]="buttonImg" alt="" />
      </span>

      <span class="btnName">{{ buttonLabel }}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="showArrow">
    <img
      class="onlyArw"
      [src]="buttonImg"
      alt=""
      id="get-feature"
      (click)="getFeature()"
      [tooltip]="tooltip"
      [options]="TOOLTIP_CONFIG_FEATURE"
      reusableTooltip
    />
  </ng-container>

  <ng-container *ngIf="showWhiteBtn">
    <button class="whiteBtn" (click)="getFeature()" [tooltip]="tooltip" [options]="TOOLTIP_CONFIG" reusableTooltip>
      {{ buttonLabel }}
    </button>
  </ng-container>

  <ng-container *ngIf="showRedBtn">
    <div class="redBtn" (click)="getFeature()" [tooltip]="tooltip" [options]="TOOLTIP_CONFIG" reusableTooltip>
      {{ buttonLabel }}
    </div>
  </ng-container>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from '../shared/services';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NoScanReturnService {
  accountslug: any;
  returnAPIURL: '.auperator.co/return/api/v2';
  locationSlug: any;
  workspaceAllowListForDraftReturn = ['godavari_products'];
  constructor(private http: HttpClient, private authService: AuthService) {
    this.accountslug = this.authService.getAccountSlug();
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getNoScanReturnFiltersData() {
    const apiurl = 'v2/returns/filtersNew';
    return this.http.get(apiurl);
  }

  updateCreateTheReturnRecord(payload: any, method) {
    if (method == 'POST') {
      let url = `return/api/v2/return-shipment/draft`;
      return this.http.post(url, payload);
    } else {
      let url = `return/api/v2/return-shipment/${payload.returnTrackingId}`;
      return this.http.put(url, payload);
    }
  }

  getAllReturnSegmentCount(payload) {
    const URL = 'return/api/v2/return-shipment/count';
    return this.http.post(URL, payload);
  }

  globleSearchApi(requestParams) {
    let apiurl = 'return/api/v2/return-segment';
    return this.http
      .get(apiurl, { params: requestParams })
      .pipe(
        catchError(error => {
          console.error('There was an error!', error);
          return throwError(error);
        }),
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getProductDetails(requestParams) {
    const url = `buildingblock/api/v1/product/record?productID=|IS|${requestParams.sku}&shipmentWorkspace=|IS|${requestParams.shipmentWorkspace}&sortBy=sku&sortOrder=ASC`;
    return this.http
      .get(url)
      .pipe(
        catchError(error => {
          console.error('There was an error!', error);
          return throwError(error);
        }),
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }

  getShelfList(requestParams) {
    const url = `buildingblock/api/v1/shelves/record?shelf=|IS|${requestParams.shelf}&shelfType=|IS|${requestParams.shelfType}`;
    return this.http
      .get(url)
      .pipe(
        catchError(error => {
          console.error('There was an error!', error);
          return throwError(error);
        }),
        map(this.extractData),
        tap(data => {})
      )
      .toPromise();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-with-chips',
  templateUrl: './input-with-chips.component.html',
  styleUrls: ['./input-with-chips.component.scss']
})
export class InputWithChipsComponent implements OnInit {
  @Input() giftWrapSku: string;
  @Input() getPaymentOption: string;
  chips: string[] = [];
  inputText: string = '';
  @Input() appName: any;

  ngOnInit() {
    if (this.giftWrapSku) {
      const splitInput = this.giftWrapSku.trim().split(',');
      this.chips = [...this.chips, ...splitInput];
    }
    if (this.appName == 'WooCommerce') {
      // this.inputText = 'COD, Cash on Delivery, Pay on Delivery';
      if (!this.getPaymentOption || this.getPaymentOption.trim() === '') {
        this.inputText = 'COD, Cash on Delivery, Pay on Delivery';
      } else {
        this.inputText = this.getPaymentOption;
      }
      this.chips = this.inputText.split(',').map(chip => chip.trim());
      this.inputText = '';
    }
    this.addChip();
  }

  @Output() chipsChanged = new EventEmitter<string[]>();

  constructor() {}
  addChip() {
    if (this.inputText.includes(',') && this.inputText.length > 1 && !this.inputText.startsWith(',')) {
      const splitInput = this.inputText.trim().split(',');
      this.chips = [...this.chips, ...splitInput];
      this.inputText = '';
      this.emitChips();
    } else if (this.inputText.trim() !== '') {
      this.chips.push(this.inputText.trim());
      this.inputText = '';
      this.emitChips();
    }
  }

  removeChip(chip: string) {
    this.chips = this.chips.filter(c => c !== chip);
    this.emitChips();
  }

  emitChips() {
    this.chipsChanged.emit(this.chips);
  }
  ngOnChanges() {}
}

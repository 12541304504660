<div class="date-range-row" #hideDiv>
  <h2>Custom date range</h2>
  <div class="start-date">
    <div class="input-field-group">
      <input
        placeholder="Start Date"
        type="text"
        [value]="selectedStartDate | date: 'dd/MM/yyyy'"
        [disabled]="true"
        required
      />
    </div>
    <mat-calendar
      [maxDate]="maxDate"
      [selected]="selectedStartDate"
      (selectedChange)="onSelectFromDate($event)"
    ></mat-calendar>
  </div>
  <div class="end-date">
    <div class="input-field-group">
      <input
        placeholder="End Date"
        type="text"
        [value]="selectedEndDate | date: 'dd/MM/yyyy'"
        [disabled]="true"
        required
      />
    </div>
    <mat-calendar [maxDate]="maxDate" [selected]="selectedEndDate" (selectedChange)="onSelectToDate($event)">
    </mat-calendar>
  </div>
  <mat-error class="error-message" *ngIf="notValid">
    You can select a date range of up to 180 days. To export data for more than 180 days, create multiple exports with
    different date ranges.
  </mat-error>
  <mat-error class="error-message" *ngIf="notValidStartDate">
    Please select valid date
  </mat-error>
  <div class="action-buttons">
    <a class="btn no-icon" (click)="hideDivOnButtonClick()"> Cancel </a>
    <a class="btn primary-btn no-icon" [class.disabled]="notValid || notValidStartDate" (click)="onSubmitDates()">
      Done
    </a>
  </div>
</div>

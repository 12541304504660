import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ERROR_MESSAGES } from 'src/app/constants/error_code_messages';
import { ROUTES } from 'src/app/constants/routes';
import { OrdersServiceV2 } from 'src/app/orderV2/serviceV2/orders.service';
import { AlertService, UserService } from 'src/app/shared/services';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ConsignmentService } from 'src/app/shared/services/consignment.service';
import { environment } from 'src/environments/environment';
import {
  AuthActionTypes,
  AuthorizePayment,
  CreateAccountSlug,
  CreateAccountSlugFailure,
  CreateWorksapce,
  DeleteScheduled,
  EditScheduled,
  GetAccountInfoFailureAction,
  GetAccountList,
  GetActivityToken,
  GetAllEximJobs,
  GetAllEximJobsFailure,
  GetCurrentLoggedInUserData,
  GetCustomer,
  GetOrdersFiltersData,
  GetOrdersFiltersV2Data,
  GetPlanDetails,
  GetPlanMappings,
  GetPlans,
  GetScheduleJobs,
  GetSegmentsData,
  GetSubscription,
  GetUserProfileAction,
  GetUserProfileFailureAction,
  Logout,
  MakePayment,
  SignUpAccountAction,
  SignUpAccountFailureAction,
  SignUpFailureAction,
  SignUpUserAction,
  SignUpUserSuccessAction,
  UpdateCustomer,
  UpdateUserProfileDetails
} from '../actions/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthEffects {
  accountSubscription$: Subscription;
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private consignmentService: ConsignmentService,
    private alertService: AlertService,
    private store: Store<{ auth }>,
    private ordersServiceV2: OrdersServiceV2
  ) {}

  @Effect()
  SignUp: Observable<any> = this.actions$.pipe(
    ofType<SignUpUserAction>(AuthActionTypes.SIGNUP_USER),
    mergeMap(action => {
      return this.authService.signUp(action.payload).pipe(
        map((user: any) => {
          if (user) {
            // console.log("getting user",user);
            localStorage.setItem('userDetails', JSON.stringify(user));
            localStorage.setItem('email', user.email.toLowerCase());
            localStorage.setItem('fullName', `${user.firstName} ${user.lastName}`);
            this.router.navigateByUrl('/auth/otp-validation');
            // this.router.navigateByUrl('/auth/new-otp-validation');
            return { type: AuthActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS, payload: user };
          }
        }),
        catchError(error => {
          if (error.error) {
            const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
            if (!!ERROR_MESSAGES.SIGNUP[errorCode]) {
              this.alertService.showError(ERROR_MESSAGES.SIGNUP[errorCode].replace('<email>', action.payload.email));
            }
          }
          return of(new SignUpFailureAction({ error: error }));
        })
      );
    })
  );

  @Effect()
  createWorkspace$: Observable<any> = this.actions$.pipe(
    ofType<CreateWorksapce>(AuthActionTypes.CREATE_WORKSPACE),
    switchMap(action => {
      return this.authService.createAccount(action.payload).pipe(
        map(data => {
          return { type: AuthActionTypes.CREATE_WORKSPACE_SUCCESS, payload: data };
        }),
        catchError(error => {
          return of({ type: AuthActionTypes.CREATE_WORKSPACE_FAILURE, payload: error });
        })
      );
    })
  );

  @Effect()
  createAccountSlug$: Observable<any> = this.actions$.pipe(
    ofType<CreateAccountSlug>(AuthActionTypes.CREATE_ACCOUNT_SLUG),
    switchMap(action => {
      return this.authService.createAccountSlug(action.payload).pipe(
        map(data => {
          return { type: AuthActionTypes.CREATE_ACCOUNT_SLUG_SUCCESS, payload: data };
        }),
        catchError(error => {
          return of(new CreateAccountSlugFailure({ error: error }));
        })
      );
    })
  );

  @Effect()
  createAccount$: Observable<any> = this.actions$.pipe(
    ofType<SignUpAccountAction>(AuthActionTypes.SIGNUP_ACCOUNT),
    switchMap(action => {
      return this.authService.createAccount(action.payload).pipe(
        map((user: any) => {
          // this.authService.checkSession();
          // this.authService.updated_access_token.subscribe(res => {
          //   if (res.accessToken) {
          //     this.actRoute.queryParams.subscribe(qp => {
          //       if (window.location.href.includes('create-account')) {
          //         if (qp['fromLogin']) {
          //           console.log('INSIDE IF');
          //           this.switchToWorkspace(user['accountSlug']);
          //         } else {
          //           this.router.navigate([ROUTES.ON_SIGNUP_SUCCESS], {
          //             queryParams: { type: 'thank-you' }
          //           });
          //         }
          //       }
          //     });
          //   }
          // });
          return { type: AuthActionTypes.SIGNUP_USER_SUCCESS, payload: action.payload };
        }),
        catchError(error => {
          console.log('ERROR', error);
          if (error.error) {
            const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
            if (!!ERROR_MESSAGES.CREATE_ACCOUNT[errorCode]) {
              this.alertService.showError(ERROR_MESSAGES.CREATE_ACCOUNT[errorCode]);
            }
          }
          this.authService.removeAuthToken();
          return of(new SignUpAccountFailureAction(error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  signupRedirect$ = this.actions$.pipe(
    ofType<SignUpUserSuccessAction>(AuthActionTypes.SIGNUP_USER_SUCCESS),
    tap(() => {
      // this.router.navigate([ROUTES.ON_SIGNUP_SUCCESS], {
      //   queryParams: { type: 'thank-you' }
      // });
    })
  );

  @Effect()
  userProfile$: Observable<any> = this.actions$.pipe(
    ofType<GetUserProfileAction>(AuthActionTypes.GET_USER_PROFILE),
    switchMap(action => {
      return this.userService.getUserProfile().pipe(
        map(user => {
          return { type: AuthActionTypes.GET_USER_PROFILE_SUCCESS, payload: user };
        }),
        catchError(error => {
          if (error.error) {
            const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
            if (!!ERROR_MESSAGES.GET_PROFILE[errorCode]) {
              this.alertService.showError(ERROR_MESSAGES.GET_PROFILE[errorCode]);
            }
          }
          return of(new GetUserProfileFailureAction());
        })
      );
    })
  );

  @Effect()
  getUserAccount$: Observable<any> = this.actions$.pipe(
    ofType<GetUserProfileAction>(AuthActionTypes.GET_USER_ACCOUNT_DETAILS),
    switchMap(action => {
      return this.userService.getAccountDetails().pipe(
        map(user => {
          return { type: AuthActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS, payload: user };
        }),
        catchError(error => {
          if (error.error) {
            const errorCode = error.error.error ? (error.error.error.message || '').split(':')[0] : '';
            if (!!ERROR_MESSAGES.GET_ACCOUNT[errorCode]) {
              this.alertService.showError(ERROR_MESSAGES.GET_ACCOUNT[errorCode]);
            }
          }
          return of(new GetAccountInfoFailureAction());
        })
      );
    })
  );

  @Effect()
  getAllEximJobs$: Observable<any> = this.actions$.pipe(
    ofType<GetAllEximJobs>(AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS),
    switchMap(action => {
      return this.consignmentService.getAllExportJobs(action.payload).pipe(
        map(user => {
          return { type: AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS_SUCCESS, payload: user };
        }),
        catchError(error => {
          return of(new GetAllEximJobsFailure());
        })
      );
    })
  );

  @Effect()
  getActivityToken$: Observable<any> = this.actions$.pipe(
    ofType<GetActivityToken>(AuthActionTypes.GET_ACTIVITY_TOKEN),
    mergeMap(action => {
      return this.authService.getActivityToken().pipe(
        map(data => ({
          type: AuthActionTypes.GET_ACTIVITY_TOKEN_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_ACTIVITY_TOKEN_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getFulfillmentCenterData$: Observable<any> = this.actions$.pipe(
    ofType<GetActivityToken>(AuthActionTypes.GET_FULFILLMENT_CENTER_DATA),
    mergeMap(action => {
      return this.authService.getFulfillmentCentreData().pipe(
        map(data => ({
          type: AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getSalesChannelData$: Observable<any> = this.actions$.pipe(
    ofType<GetActivityToken>(AuthActionTypes.GET_SALES_CHANNEL_DATA),
    mergeMap(action => {
      return this.authService.getSalesChannelData().pipe(
        map(data => ({
          type: AuthActionTypes.GET_SALES_CHANNEL_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_SALES_CHANNEL_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getSegmentsData$: Observable<any> = this.actions$.pipe(
    ofType<GetSegmentsData>(AuthActionTypes.GET_SEGMENTS_DATA),
    concatMap(action => {
      return this.authService.getSegmentsData(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.GET_SEGMENTS_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_SEGMENTS_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.LOGOUT),
    tap(() => {
      this.authService.logout();
    })
  );

  @Effect()
  otpValidation: Observable<any> = this.actions$.pipe(
    ofType<SignUpUserAction>(AuthActionTypes.OTP_VALIDATION),
    switchMap(action => {
      return this.authService.validateOtp(action.payload).pipe(
        map(data => {
          return { type: AuthActionTypes.OTP_VALIDATION_SUCCESS, payload: data };
        }),
        catchError(error => {
          return of({ type: AuthActionTypes.OTP_VALIDATION_FAILURE, payload: error });
        })
      );
    })
  );

  @Effect()
  getScheduleJobs$: Observable<any> = this.actions$.pipe(
    ofType<GetScheduleJobs>(AuthActionTypes.GET_SCHEDULE_JOB),
    mergeMap(action => {
      return this.consignmentService.getScheduleJobs(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.GET_SCHEDULE_JOB_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_SCHEDULE_JOB_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  editSchedule$: Observable<any> = this.actions$.pipe(
    ofType<EditScheduled>(AuthActionTypes.EDIT_SCHEDULE),
    mergeMap(action => {
      return this.consignmentService.editSchedule(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.EDIT_SCHEDULE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.EDIT_SCHEDULE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  deleteSchedule$: Observable<any> = this.actions$.pipe(
    ofType<DeleteScheduled>(AuthActionTypes.DELETE_SCHEDULE),
    mergeMap(action => {
      return this.consignmentService.deleteSchedule(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.DELETE_SCHEDULE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.DELETE_SCHEDULE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getSubscriptionPlan$: Observable<any> = this.actions$.pipe(
    ofType<GetPlanDetails>(AuthActionTypes.GET_PLAN_DETAILS),
    mergeMap(action => {
      return this.authService.getSubscriptionPlanDetails(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.GET_PLAN_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_PLAN_DETAILS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getPlans$: Observable<any> = this.actions$.pipe(
    ofType<GetPlans>(AuthActionTypes.GET_PLANS),
    mergeMap(action => {
      return this.authService.getPlans().pipe(
        map(data => ({
          type: AuthActionTypes.GET_PLANS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_PLANS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getSubscription$: Observable<any> = this.actions$.pipe(
    ofType<GetSubscription>(AuthActionTypes.GET_SUBSCRIPTION),
    mergeMap(action => {
      return this.authService.getExistingSubscriptions().pipe(
        map(data => ({
          type: AuthActionTypes.GET_SUBSCRIPTION_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_SUBSCRIPTION_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getCustomer$: Observable<any> = this.actions$.pipe(
    ofType<GetCustomer>(AuthActionTypes.GET_CUSTOMER),
    mergeMap(action => {
      return this.authService.getCustomer().pipe(
        map(data => ({
          type: AuthActionTypes.GET_CUSTOMER_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_CUSTOMER_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  updateCustomer$: Observable<any> = this.actions$.pipe(
    ofType<UpdateCustomer>(AuthActionTypes.UPDATE_CUSTOMER),
    mergeMap(action => {
      return this.authService.updateCustomer(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.UPDATE_CUSTOMER_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.UPDATE_CUSTOMER_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  makePayment$: Observable<any> = this.actions$.pipe(
    ofType<MakePayment>(AuthActionTypes.MAKE_PAYMENT),
    mergeMap(action => {
      return this.authService.makePayment(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.MAKE_PAYMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.MAKE_PAYMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  authorizePayment$: Observable<any> = this.actions$.pipe(
    ofType<AuthorizePayment>(AuthActionTypes.AUTHORIZE_PAYMENT),
    mergeMap(action => {
      return this.authService.authorizePayment(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.AUTHORIZE_PAYMENT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.AUTHORIZE_PAYMENT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  switchToWorkspace(accountSlug) {
    this.authService.setWorkspace(accountSlug);
    if (!this.authService.getFlexSlug()) {
      const allACList: any = this.authService.getAccountLists();
      const accountlist = allACList.filter(x => x.parentClientWorkspaceSlug == accountSlug);
      if (accountlist.length > 0) this.authService.setFlexSlug(accountlist[0].accountSlug, false);
    }
    this.authService.getWorkspaceList().subscribe((ws: any) => {
      if (ws.data !== undefined) {
        const workspace = ws.data.filter(x => x.accountSlug == accountSlug)[0];
        this.onWorkspaceSelection(workspace);
      }
    });
  }

  onWorkspaceSelection(workspace) {
    this.authService.setWorkspace(workspace.accountSlug);
    if (!this.authService.getFlexSlug()) {
      const allACList: any = this.authService.getAccountLists();
      const accountlist = allACList.filter(x => x.parentClientWorkspaceSlug == workspace.accountSlug);
      if (accountlist.length > 0) this.authService.setFlexSlug(accountlist[0].accountSlug, false);
    }
    this.redirectUser(workspace);
  }

  redirectUser(workspace) {
    if (environment.production) {
      window.location.href = 'https://' + workspace.accountSlug + environment.BASE_DOMAIN + ROUTES.DASHBOARD;
    } else {
      this.getUserProfile();
      this.getUserAccountDetails();
    }
  }
  getUserProfile() {
    this.store.dispatch({ type: AuthActionTypes.GET_USER_PROFILE });
  }

  getUserAccountDetails() {
    /* this.accountSubscription$ = this.store.pipe(select(getCurrentAccount)).subscribe(result => {
      console.log('result', result);
      if (
        !result ||
        (null !== result &&
          (result.accountSlug + '').toLowerCase() != (this.authService.getAccountSlug() + '').toLowerCase())
      ) {
        console.log('INSIDE iF');
        this.store.dispatch({ type: AuthActionTypes.GET_USER_ACCOUNT_DETAILS });
        return;
      }
      this.router.navigate([ROUTES.DASHBOARD_URL]);
      // switch (result.status) {
      //   case '0':
      //     this.router.navigate([ROUTES.ON_SIGNUP_SUCCESS], {
      //       queryParams: { type: ROUTES.UNDER_REVIEW }
      //     });
      //     this.authService.deleteItemFromCookie(this.authService.REDIRECT_URL_KEY);
      //     break;

      //   case '1':
      //     const returnUrl = this.authService.getReturnUrl();
      //     if (returnUrl && result['stepsCompleted'] > 4) {
      //       const urlWithoutHttp = (returnUrl || '').split('//')[1];
      //       const slugFromReturnUrl = urlWithoutHttp.split('.')[0];
      //       const navigateToUrl = urlWithoutHttp.substring(urlWithoutHttp.indexOf('/'));
      //       if ((slugFromReturnUrl || '').toLowerCase() === (this.authService.getAccountSlug() || '').toLowerCase()) {
      //         this.authService.deleteItemFromCookie(this.authService.REDIRECT_URL_KEY);
      //         window.location.href = returnUrl;
      //       }
      //     }

      //     if (result['userAccountMappingRole'] === 'admin' || result['userAccountMappingRole'] === 'user') {
      //       this.navigateTo(5);
      //     } else {
      //       this.navigateTo(result['stepsCompleted']);
      //     }
      //     break;

      //   default:
      //     this.authService.deleteItemFromCookie(this.authService.REDIRECT_URL_KEY);
      //     this.alertService.showError(ALERT_MESSAGES.INACTIVE_USER);
      //     break;
      // }
      if (this.accountSubscription$) {
        this.accountSubscription$.unsubscribe();
      }
    }); */
  }
  navigateTo(step) {
    console.log('STEP', step);
    switch (step) {
      case 1:
        this.router.navigate([ROUTES.REGISTRATION_COMPANY]);
        break;

      case 2:
        this.router.navigate([ROUTES.REGISTRATION_WAREHOUSE]);
        break;

      case 3:
        this.router.navigate([ROUTES.REGISTRATION_BRAND]);
        break;

      case 4:
        this.router.navigate([ROUTES.REGISTRATION_TEAM]);
        break;

      case 5:
        this.router.navigate([ROUTES.DASHBOARD_URL]);
        break;

      default:
        this.router.navigate([ROUTES.ON_SIGNUP_SUCCESS], { queryParams: { type: 'welcome' } });
        break;
    }
  }

  @Effect()
  getFiltersData$: Observable<Action> = this.actions$.pipe(
    ofType<GetOrdersFiltersData>(AuthActionTypes.GET_ORDERS_FILTERS),
    mergeMap(action => {
      return this.ordersServiceV2.getFiltersData().pipe(
        map(data => ({
          type: AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_ORDERS_FILTERS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getPlanMappings$: Observable<any> = this.actions$.pipe(
    ofType<GetPlanMappings>(AuthActionTypes.GET_PLAN_MAPPINGS),
    mergeMap(action => {
      return this.authService.getPlanMappings().pipe(
        map(data => ({
          type: AuthActionTypes.GET_PLAN_MAPPINGS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_PLAN_MAPPINGS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  updateUserProfileDetails$: Observable<Action> = this.actions$.pipe(
    ofType<UpdateUserProfileDetails>(AuthActionTypes.UPDATE_USER_PROFILE_DETAILS),
    mergeMap(action => {
      return this.authService.updateProfileDetails(action.payload).pipe(
        map(data => ({
          type: AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getFiltersDataV2$: Observable<Action> = this.actions$.pipe(
    ofType<GetOrdersFiltersV2Data>(AuthActionTypes.GET_ORDERS_FILTERS_V2),
    mergeMap(action => {
      return this.ordersServiceV2.getFiltersData().pipe(
        map(data => ({
          type: AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS_V2,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_ORDERS_FILTERS_FAILURE_V2,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getCurrentLoggedInUserData$ = this.actions$.pipe(
    ofType<GetCurrentLoggedInUserData>(AuthActionTypes.GET_LOGGEDIN_USER_DATA),
    mergeMap(action => {
      return this.userService.getLoggedInUserDetails().pipe(
        map(data => ({
          type: AuthActionTypes.GET_LOGGEDIN_USER_DATA_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_LOGGEDIN_USER_DATA_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getAccountList$ = this.actions$.pipe(
    ofType<GetAccountList>(AuthActionTypes.GET_ACCOUNT_LIST),
    mergeMap(action => {
      return this.authService.getWorkspaceList().pipe(
        map(data => ({
          type: AuthActionTypes.GET_ACCOUNT_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: AuthActionTypes.GET_ACCOUNT_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
}

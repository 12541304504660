import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TooltipOptions } from 'src/app/reusable-tooltip/tooltip-options.service';
import { TOOLTIP_CONFIG } from 'src/app/shared/models/segment-with-count.model';
import { PlanService } from '../../services/plan.service';
import { PlanModifyDialogComponent } from '../plan-modify-dialog/plan-modify-dialog.component';
@Component({
  selector: 'app-get-feature',
  templateUrl: './get-feature.component.html',
  styleUrls: ['./get-feature.component.scss']
})
export class GetFeatureComponent implements OnInit {
  dialogRef: MatDialogRef<PlanModifyDialogComponent>;
  buttonImg: string = 'https://ik.imagekit.io/2gwij97w0o/Upgrade-Filled.svg?updatedAt=1724841951801';
  buttonLabel: string = 'Get feature';
  @Input() showRedBtn: string;
  @Input() showWhiteBtn: string;
  @Input() showArrow: string;
  @Input() showArrowBtn: string;
  TOOLTIP_CONFIG = TOOLTIP_CONFIG;
  TOOLTIP_CONFIG_FEATURE: TooltipOptions = {
    showDelay: 200,
    hideDelay: 500,
    contentType: 'html',
    hideDelayTouchscreen: 500
  };
  tooltip: string;
  constructor(private dialog: MatDialog, private planService: PlanService) {}

  ngOnInit(): void {
    this.tooltip = this.showArrow
      ? ``
      : `<div class="get-feature-tooltip">
  <p>
   This feature is not available in your current plan. Upgrade your plan to get this feature.
  </p> 
</div>`;
  }

  getFeature() {
    this.dialogRef = this.dialog.open(PlanModifyDialogComponent, {
      panelClass: 'disable-fulfilment-dialog',
      width: '600px'
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result?.ticketCreated) {
        this.planService.showBanner();
      }
    });
  }
}

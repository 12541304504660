import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'indianCurrencyFormat'
})
export class IndianCurrencyFormatPipe implements PipeTransform {
  transform(value: number | string, digitsInfo: string = '1.2-2'): string {
    if (value == null) return '';
    const numberValue = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(numberValue)) return '';
    const [minFractionDigits, maxFractionDigits] = digitsInfo.split('-').map(Number);
    const [integerPart, decimalPart] = numberValue.toString().split('.');
    let formattedAmount = this.convertToIndianCurrency(integerPart);
    if (decimalPart) {
      if (maxFractionDigits > 0) {
        const displayDecimals = (decimalPart || '').slice(0, maxFractionDigits).padEnd(maxFractionDigits, '0');

        if (parseFloat(displayDecimals) > 0 || (displayDecimals.length > 0 && minFractionDigits > 0)) {
          formattedAmount += '.' + displayDecimals;
        }
      }
    }
    return formattedAmount >= 0 ? '₹' + formattedAmount : '-₹' + formattedAmount.slice(1);
  }

  convertToIndianCurrency(amount) {
    const formattedAmount = amount.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    return formattedAmount;
  }
}

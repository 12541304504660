import { Action } from '@ngrx/store';
import { currentUserDetailsModel } from 'src/app/settings/components/teams/models/account-mapping.model';

export enum AuthActionTypes {
  SIGNIN = '[SignIn] SignIn',
  SIGNIN_FAILURE = '[SignIn] SignIn Failure',
  SIGNIN_SUCCESS = '[SignIn] SignIn Success',
  SIGNUP_USER = '[SignUp] SignUp User',
  SIGNUP_USER_SUCCESS = '[SignUp] SignUp User Success',
  SIGNUP_FAILURE = '[SignUp] SignUp Failure',
  GET_USER_PROFILE = '[Login Complete Page] Get User Details',
  GET_USER_PROFILE_SUCCESS = '[Fetch user success] Get User Details Success',
  GET_USER_PROFILE_FAILURE = '[Fetch user failure] Get User Details Failure',
  SIGNUP_ACCOUNT = '[SignUp] SignUp Account',
  SIGNUP_ACCOUNT_SUCCESS = '[SignUp] SignUp Account Success',
  SIGNUP_ACCOUNT_FAILURE = '[SignUp] SignUp Account Failure',
  GET_USER_ACCOUNT_DETAILS = '[Auth] Get Account Details',
  GET_USER_ACCOUNT_DETAILS_SUCCESS = '[Auth] Get Account Details Success',
  GET_USER_ACCOUNT_DETAILS_FAILURE = '[Auth] Get Account Details Failure',
  UPDATE_USER_ACCOUNT_DETAILS = '[Accounts Page] Update User Account Details',
  CHECK_ALREADY_EXISTS = '[SignUp] Check Already Exists',
  ALREADY_EXISTS = '[SignUp] Already Exists',
  MOVE_ALREADY_EXISTS = '[SignUp] Move Already Exists',
  LOGOUT = '[Auth] Confirm Logout',
  GET_ALL_EXPORT_IMPORT_JOBS = '[Auth] Get All Jobs',
  GET_ALL_EXPORT_IMPORT_JOBS_SUCCESS = '[Auth] Get All Jobs Success',
  GET_ALL_EXPORT_IMPORT_JOBS_FAILURE = '[Auth] Get All Jobs Failure',

  // VALIDATE OTP

  OTP_VALIDATION = '[Auth] OTP Validation',
  OTP_VALIDATION_SUCCESS = '[Auth] OTP Validation Success',
  OTP_VALIDATION_FAILURE = '[Auth] OTP Validation Failure',

  // CREATE WORKSPACE
  CREATE_WORKSPACE = '[Auth] Create Workspace',
  CREATE_WORKSPACE_SUCCESS = '[Auth] Create Workspace Success',
  CREATE_WORKSPACE_FAILURE = '[Auth] Create Workspace Failure',

  // PLAN MAPPINGS
  GET_PLAN_MAPPINGS = '[Auth] Get Plan Mappings',
  GET_PLAN_MAPPINGS_SUCCESS = '[Auth] Get Plan Mappings Success',
  GET_PLAN_MAPPINGS_FAILURE = '[Auth] Get Plan Mappings Failure',

  // CREATE ACCOUNT SLUG
  CREATE_ACCOUNT_SLUG = '[Auth] Create ACCOUNT_SLUG',
  CREATE_ACCOUNT_SLUG_SUCCESS = '[Auth] Create ACCOUNT_SLUG Success',
  CREATE_ACCOUNT_SLUG_FAILURE = '[Auth] Create ACCOUNT_SLUG Failure',
  GET_ACTIVITY_TOKEN = '[Auth] Get Activity Token',
  GET_ACTIVITY_TOKEN_SUCCESS = '[Auth] Get Activity Token Success',
  GET_ACTIVITY_TOKEN_FAILURE = '[Auth] Get Activity Token Failure',

  GET_SALES_CHANNEL_DATA = '[Auth] Get Sales Channel Data',
  GET_SALES_CHANNEL_DATA_SUCCESS = '[Auth] Get Sales Channel Data Success',
  GET_SALES_CHANNEL_DATA_FAILURE = '[Auth] Get Sales Channel Data Failure',

  GET_FULFILLMENT_CENTER_DATA = '[Auth] Get Fulfillment Center Data',
  GET_FULFILLMENT_CENTER_DATA_SUCCESS = '[Auth] Get Fulfillment Center Data Success',
  GET_FULFILLMENT_CENTER_DATA_FAILURE = '[Auth] Get Fulfillment Center Data Failure',

  GET_SEGMENTS_DATA = '[Auth] Get Segments Data',
  GET_SEGMENTS_DATA_SUCCESS = '[Auth] Get Segments Data Success',
  GET_SEGMENTS_DATA_FAILURE = '[Auth] Get Segments Data Failure',

  GET_SCHEDULE_JOB = '[Auth] Get Schedule Job',
  GET_SCHEDULE_JOB_SUCCESS = '[Auth] Get Schedule Job Success',
  GET_SCHEDULE_JOB_FAILURE = '[Auth] Get Schedule Job Failure',

  EDIT_SCHEDULE = '[Auth] Edit Schedule',
  EDIT_SCHEDULE_SUCCESS = '[Auth] Edit Schedule Success',
  EDIT_SCHEDULE_FAILURE = '[Auth] Edit Schedule Failure',

  DELETE_SCHEDULE = '[Auth] Delete Schedule',
  DELETE_SCHEDULE_SUCCESS = '[Auth] Delete Schedule Success',
  DELETE_SCHEDULE_FAILURE = '[Auth] Delete Schedule Failure',

  /* Subscription Changes */
  GET_PLAN_DETAILS = '[Auth] Get Plan Details',
  GET_PLAN_DETAILS_SUCCESS = '[Auth] Get Plan Details Success',
  GET_PLAN_DETAILS_FAILURE = '[Auth] Get Plan Details Failure',

  GET_PLANS = '[Auth] Get Plans',
  GET_PLANS_SUCCESS = '[Auth] Get Plans Success',
  GET_PLANS_FAILURE = '[Auth] Get Plans Failure',

  GET_CUSTOMER = '[Auth] Get Customer',
  GET_CUSTOMER_SUCCESS = '[Auth] Get Customer Success',
  GET_CUSTOMER_FAILURE = '[Auth] Get Customer Failure',

  UPDATE_CUSTOMER = '[Auth] Update Customer',
  UPDATE_CUSTOMER_SUCCESS = '[Auth] Update Customer Success',
  UPDATE_CUSTOMER_FAILURE = '[Auth] Update Customer Failure',

  GET_SUBSCRIPTION = '[Auth] Get Subscription',
  GET_SUBSCRIPTION_SUCCESS = '[Auth] Get Subscription Success',
  GET_SUBSCRIPTION_FAILURE = '[Auth] Get Subscription Failure',

  MAKE_PAYMENT = '[Auth] Make Payment',
  MAKE_PAYMENT_SUCCESS = '[Auth] Make Payment Success',
  MAKE_PAYMENT_FAILURE = '[Auth] Make Payment Failure',

  AUTHORIZE_PAYMENT = '[Auth] Authorize Payment',
  AUTHORIZE_PAYMENT_SUCCESS = '[Auth] Authorize Payment Success',
  AUTHORIZE_PAYMENT_FAILURE = '[Auth] Authorize Payment Failure',

  /* Subscription Changes Ends */
  GET_ORDERS_FILTERS = '[AUTH] Get Orders Filters Data',
  GET_ORDERS_FILTERS_SUCCESS = '[AUTH] Get Orders Filters Data Success',
  GET_ORDERS_FILTERS_FAILURE = '[AUTH] Get Orders Filters Data Failure',

  UPDATE_USER_PROFILE_DETAILS = '[AUTH] Update User Profile Details',
  UPDATE_USER_PROFILE_DETAILS_SUCCESS = '[AUTH] Update User Profile Details Success',
  UPDATE_USER_PROFILE_DETAILS_FAILURE = '[AUTH] Update User Profile Details Failure',

  GET_ORDERS_FILTERS_V2 = '[AUTH] Get Orders V2 Filters Data',
  GET_ORDERS_FILTERS_SUCCESS_V2 = '[AUTH] Get Orders V2 Filters Data Success',
  GET_ORDERS_FILTERS_FAILURE_V2 = '[AUTH] Get Orders V2 Filters Data Failure',

  // GET LOGGEDIN USER DATA
  GET_LOGGEDIN_USER_DATA = '[API] Get LoggedIn User Data',
  GET_LOGGEDIN_USER_DATA_SUCCESS = '[API] Get LoggedIn User Data Success',
  GET_LOGGEDIN_USER_DATA_FAILURE = '[API] Get LoggedIn User Data Failure',

  // GET LIST OF ACCOUNTS USER HAS ACCESS TO
  GET_ACCOUNT_LIST = '[API] Get Current User Data',
  GET_ACCOUNT_LIST_SUCCESS = '[API] Get Account List Success',
  GET_ACCOUNT_LIST_FAILURE = '[API] Get Account List Failure'
}

export class SignInAction implements Action {
  readonly type = AuthActionTypes.SIGNIN;
  constructor(public payload: any) {}
}

export class SignInSuccessAction implements Action {
  readonly type = AuthActionTypes.SIGNIN_SUCCESS;
  constructor(public payload: any) {}
}

export class SignInFailureAction implements Action {
  readonly type = AuthActionTypes.SIGNIN_FAILURE;

  constructor(public payload: any) {}
}

export class SignUpUserAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_USER;

  constructor(public payload: any) {}
}

export class SignUpUserSuccessAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class GetUserProfileAction implements Action {
  readonly type = AuthActionTypes.GET_USER_PROFILE;
}

export class GetUserProfileSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_USER_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetUserProfileFailureAction implements Action {
  readonly type = AuthActionTypes.GET_USER_PROFILE_FAILURE;
}

export class SignUpAccountAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_ACCOUNT;

  constructor(public payload: any) {}
}

export class SignUpAccountSuccessAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_ACCOUNT_SUCCESS;

  constructor(public payload: any) {}
}

export class SignUpAccountFailureAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_ACCOUNT_FAILURE;
  constructor(public payload: any) {}
}

export class GetAccountInfoAction implements Action {
  readonly type = AuthActionTypes.GET_USER_ACCOUNT_DETAILS;
}

export class GetAccountInfoSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAccountInfoFailureAction implements Action {
  readonly type = AuthActionTypes.GET_USER_ACCOUNT_DETAILS_FAILURE;
}

export class UpdateAccountInfoAction implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_ACCOUNT_DETAILS;
  constructor(public payload: any) {}
}

export class SignUpFailureAction implements Action {
  readonly type = AuthActionTypes.SIGNUP_FAILURE;

  constructor(public payload: any) {}
}

export class SignUpCheckAlreadyExistsAction implements Action {
  readonly type = AuthActionTypes.CHECK_ALREADY_EXISTS;

  constructor(public payload: any) {}
}

export class SignUpAlreadyExistsAction implements Action {
  readonly type = AuthActionTypes.ALREADY_EXISTS;

  constructor(public payload: any) {}
}

export class MoveSignUpAlreadyExistsAction implements Action {
  readonly type = AuthActionTypes.MOVE_ALREADY_EXISTS;

  constructor(public payload: any) {}
}

export class Logout implements Action {
  readonly type = AuthActionTypes.LOGOUT;
}

export class GetAllEximJobs implements Action {
  readonly type = AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS;
  constructor(public payload: any) {}
}

export class GetAllEximJobsSuccess implements Action {
  readonly type = AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAllEximJobsFailure implements Action {
  readonly type = AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS_FAILURE;
}

// OTP
export class OtpValidation implements Action {
  readonly type = AuthActionTypes.OTP_VALIDATION;
  constructor(public payload: any) {}
}

export class OtpValidationSuccess implements Action {
  readonly type = AuthActionTypes.OTP_VALIDATION_SUCCESS;
  constructor(public payload: any) {}
}

export class OtpValidationFailure implements Action {
  readonly type = AuthActionTypes.OTP_VALIDATION_FAILURE;
  constructor(public payload: any) {}
}

// CREATE WORKSPACE

export class CreateWorksapce implements Action {
  readonly type = AuthActionTypes.CREATE_WORKSPACE;
  constructor(public payload: any) {}
}

export class CreateWorksapceSuccess implements Action {
  readonly type = AuthActionTypes.CREATE_WORKSPACE_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateWorksapceFailure implements Action {
  readonly type = AuthActionTypes.CREATE_WORKSPACE_FAILURE;
  constructor(public payload: any) {}
}
// CREATE ACCOUNT SLUG

export class CreateAccountSlug implements Action {
  readonly type = AuthActionTypes.CREATE_ACCOUNT_SLUG;
  constructor(public payload: any) {}
}

export class CreateAccountSlugSuccess implements Action {
  readonly type = AuthActionTypes.CREATE_ACCOUNT_SLUG_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateAccountSlugFailure implements Action {
  readonly type = AuthActionTypes.CREATE_ACCOUNT_SLUG_FAILURE;
  constructor(public payload: any) {}
}

export class GetActivityToken implements Action {
  readonly type = AuthActionTypes.GET_ACTIVITY_TOKEN;
  constructor() {}
}
export class GetActivityTokenSuccess implements Action {
  readonly type = AuthActionTypes.GET_ACTIVITY_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}
export class GetActivityTokenFailure implements Action {
  readonly type = AuthActionTypes.GET_ACTIVITY_TOKEN_FAILURE;
  constructor(public payload: any) {}
}
// Sales Channel Actions
export class GetSalesChannelData implements Action {
  readonly type = AuthActionTypes.GET_SALES_CHANNEL_DATA;
  constructor() {}
}
export class GetSalesChannelDataSuccess implements Action {
  readonly type = AuthActionTypes.GET_SALES_CHANNEL_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetSalesChannelDataFailure implements Action {
  readonly type = AuthActionTypes.GET_SALES_CHANNEL_DATA_FAILURE;
  constructor() {}
}
// Sales Fulfillment Center Data
export class GetFulfillmentCenterData implements Action {
  readonly type = AuthActionTypes.GET_FULFILLMENT_CENTER_DATA;
}
export class GetFulfillmentCenterDataSuccess implements Action {
  readonly type = AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetFulfillmentCenterDataFailure implements Action {
  readonly type = AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_FAILURE;
  constructor(public payload: any) {}
}
// Get Segments Data
export class GetSegmentsData implements Action {
  readonly type = AuthActionTypes.GET_SEGMENTS_DATA;
  constructor(public payload: any) {}
}
export class GetSegmentsDataSuccess implements Action {
  readonly type = AuthActionTypes.GET_SEGMENTS_DATA_SUCCESS;
  constructor(public payload: any) {}
}
export class GetSegmentsDataFailure implements Action {
  readonly type = AuthActionTypes.GET_SEGMENTS_DATA_FAILURE;
  constructor() {}
}

export class GetScheduleJobs implements Action {
  readonly type = AuthActionTypes.GET_SCHEDULE_JOB;
  constructor(public payload: any) {}
}

export class GetScheduleJobsSuccess implements Action {
  readonly type = AuthActionTypes.GET_SCHEDULE_JOB_SUCCESS;
  constructor(public payload: any) {}
}

export class GetScheduleJobsFailure implements Action {
  readonly type = AuthActionTypes.GET_SCHEDULE_JOB_FAILURE;
}

export class EditScheduled implements Action {
  readonly type = AuthActionTypes.EDIT_SCHEDULE;
  constructor(public payload: any) {}
}

export class EditScheduledSuccess implements Action {
  readonly type = AuthActionTypes.EDIT_SCHEDULE_SUCCESS;
  constructor(public payload: any) {}
}

export class EditScheduledFailure implements Action {
  readonly type = AuthActionTypes.EDIT_SCHEDULE_FAILURE;
}

export class DeleteScheduled implements Action {
  readonly type = AuthActionTypes.DELETE_SCHEDULE;
  constructor(public payload: any) {}
}

export class DeleteScheduledSuccess implements Action {
  readonly type = AuthActionTypes.DELETE_SCHEDULE_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteScheduledFailure implements Action {
  readonly type = AuthActionTypes.DELETE_SCHEDULE_FAILURE;
}

/* Subscription Changes */
export class GetPlanDetails implements Action {
  readonly type = AuthActionTypes.GET_PLAN_DETAILS;
  constructor(public payload: any) {}
}
export class GetPlanDetailsSuccess implements Action {
  readonly type = AuthActionTypes.GET_PLAN_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPlanDetailsFailure implements Action {
  readonly type = AuthActionTypes.GET_PLAN_DETAILS_FAILURE;
  constructor(public payload: any) {}
}

export class GetPlans implements Action {
  readonly type = AuthActionTypes.GET_PLANS;
}
export class GetPlansSuccess implements Action {
  readonly type = AuthActionTypes.GET_PLANS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPlansFailure implements Action {
  readonly type = AuthActionTypes.GET_PLANS_FAILURE;
  constructor(public payload: any) {}
}

export class GetSubscription implements Action {
  readonly type = AuthActionTypes.GET_SUBSCRIPTION;
}
export class GetSubscriptionSuccess implements Action {
  readonly type = AuthActionTypes.GET_SUBSCRIPTION_SUCCESS;
  constructor(public payload: any) {}
}
export class GetSubscriptionFailure implements Action {
  readonly type = AuthActionTypes.GET_SUBSCRIPTION_FAILURE;
  constructor(public payload: any) {}
}

export class GetCustomer implements Action {
  readonly type = AuthActionTypes.GET_CUSTOMER;
}
export class GetCustomerSuccess implements Action {
  readonly type = AuthActionTypes.GET_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}
export class GetCustomerFailure implements Action {
  readonly type = AuthActionTypes.GET_CUSTOMER_FAILURE;
  constructor(public payload: any) {}
}

export class UpdateCustomer implements Action {
  readonly type = AuthActionTypes.UPDATE_CUSTOMER;
  constructor(public payload: any) {}
}
export class UpdateCustomerSuccess implements Action {
  readonly type = AuthActionTypes.UPDATE_CUSTOMER_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateCustomerFailure implements Action {
  readonly type = AuthActionTypes.UPDATE_CUSTOMER_FAILURE;
  constructor(public payload: any) {}
}

export class MakePayment implements Action {
  readonly type = AuthActionTypes.MAKE_PAYMENT;
  constructor(public payload: any) {}
}
export class MakePaymentSuccess implements Action {
  readonly type = AuthActionTypes.MAKE_PAYMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class MakePaymentFailure implements Action {
  readonly type = AuthActionTypes.MAKE_PAYMENT_FAILURE;
  constructor(public payload: any) {}
}
export class AuthorizePayment implements Action {
  readonly type = AuthActionTypes.AUTHORIZE_PAYMENT;
  constructor(public payload: any) {}
}
export class AuthorizePaymentSuccess implements Action {
  readonly type = AuthActionTypes.AUTHORIZE_PAYMENT_SUCCESS;
  constructor(public payload: any) {}
}
export class AuthorizePaymentFailure implements Action {
  readonly type = AuthActionTypes.AUTHORIZE_PAYMENT_FAILURE;
  constructor(public payload: any) {}
}
/* Subscription Changes Ends*/
export class GetOrdersFiltersData implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS;
  constructor() {}
}
export class GetOrdersFiltersDataSuccess implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetOrdersFiltersDataFailure implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS_FAILURE;
  constructor(public payload: any) {}
}
export class UpdateUserProfileDetails implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_PROFILE_DETAILS;
  constructor(public payload: any) {}
}
export class UpdateUserProfileDetailsSuccess implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_SUCCESS;
  constructor(public payload: any) {}
}
export class UpdateUserProfileDetailsFailure implements Action {
  readonly type = AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_FAILURE;
  constructor(public payload: any) {}
}
export class GetOrdersFiltersV2Data implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS_V2;
  constructor() {}
}
export class GetOrdersFiltersV2DataSuccess implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS_V2;
  constructor(public payload: any) {}
}
export class GetOrdersFiltersV2DataFailure implements Action {
  readonly type = AuthActionTypes.GET_ORDERS_FILTERS_FAILURE_V2;
  constructor(public payload: any) {}
}

export class GetCurrentLoggedInUserData implements Action {
  readonly type = AuthActionTypes.GET_LOGGEDIN_USER_DATA;
}

export class GetCurrentUserDataSuccess implements Action {
  readonly type = AuthActionTypes.GET_LOGGEDIN_USER_DATA_SUCCESS;

  constructor(public payload: currentUserDetailsModel) {}
}

export class GetCurrentUserDataFailure implements Action {
  readonly type = AuthActionTypes.GET_LOGGEDIN_USER_DATA_FAILURE;
  constructor(public payload: string) {}
}

export class GetAccountList implements Action {
  readonly type = AuthActionTypes.GET_ACCOUNT_LIST;
}

export class GetAccountListSuccess implements Action {
  readonly type = AuthActionTypes.GET_ACCOUNT_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class GetAccountListFailure implements Action {
  readonly type = AuthActionTypes.GET_ACCOUNT_LIST_FAILURE;
  constructor(public payload: string) {}
}

export class GetPlanMappings implements Action {
  readonly type = AuthActionTypes.GET_PLAN_MAPPINGS;
}
export class GetPlanMappingsSuccess implements Action {
  readonly type = AuthActionTypes.GET_PLAN_MAPPINGS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPlanMappingsFailure implements Action {
  readonly type = AuthActionTypes.GET_PLAN_MAPPINGS_FAILURE;
  constructor(public payload: any) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type AuthUnion =
  | SignInAction
  | SignInSuccessAction
  | SignInFailureAction
  | SignUpUserAction
  | SignUpUserSuccessAction
  | GetUserProfileAction
  | GetUserProfileSuccessAction
  | GetUserProfileFailureAction
  | SignUpAccountAction
  | SignUpAccountSuccessAction
  | SignUpAccountFailureAction
  | SignUpFailureAction
  | GetAccountInfoAction
  | GetAccountInfoSuccessAction
  | GetAccountInfoFailureAction
  | UpdateAccountInfoAction
  | SignUpCheckAlreadyExistsAction
  | SignUpAlreadyExistsAction
  | MoveSignUpAlreadyExistsAction
  | Logout
  | GetAllEximJobs
  | GetAllEximJobsSuccess
  | GetAllEximJobsFailure
  | OtpValidation
  | OtpValidationFailure
  | OtpValidationSuccess
  | CreateWorksapce
  | CreateWorksapceFailure
  | CreateWorksapceSuccess
  | CreateAccountSlug
  | CreateAccountSlugFailure
  | CreateAccountSlugSuccess
  | GetActivityToken
  | GetActivityTokenSuccess
  | GetActivityTokenFailure
  | GetSalesChannelData
  | GetSalesChannelDataSuccess
  | GetSalesChannelDataFailure
  | GetFulfillmentCenterData
  | GetFulfillmentCenterDataSuccess
  | GetFulfillmentCenterDataFailure
  | GetSegmentsData
  | GetSegmentsDataSuccess
  | GetSegmentsDataFailure
  | GetScheduleJobs
  | GetScheduleJobsSuccess
  | GetScheduleJobsFailure
  | EditScheduled
  | EditScheduledSuccess
  | EditScheduledFailure
  | DeleteScheduled
  | DeleteScheduledSuccess
  | DeleteScheduledFailure
  | GetPlanDetails
  | GetPlanDetailsSuccess
  | GetPlanDetailsFailure
  | MakePayment
  | MakePaymentSuccess
  | MakePaymentFailure
  | AuthorizePayment
  | AuthorizePaymentSuccess
  | AuthorizePaymentFailure
  | GetOrdersFiltersData
  | GetOrdersFiltersDataFailure
  | GetOrdersFiltersDataSuccess
  | UpdateUserProfileDetails
  | UpdateUserProfileDetailsSuccess
  | UpdateUserProfileDetailsFailure
  | GetOrdersFiltersV2Data
  | GetOrdersFiltersV2DataSuccess
  | GetOrdersFiltersV2DataFailure
  | GetCurrentLoggedInUserData
  | GetCurrentUserDataSuccess
  | GetCurrentUserDataFailure
  | GetAccountList
  | GetAccountListSuccess
  | GetAccountListFailure
  | GetPlans
  | GetPlansSuccess
  | GetPlansFailure
  | UpdateCustomer
  | UpdateCustomerSuccess
  | UpdateCustomerFailure
  | GetCustomer
  | GetCustomerSuccess
  | GetCustomerFailure
  | GetSubscription
  | GetSubscriptionSuccess
  | GetSubscriptionFailure
  | GetAccountListFailure
  | GetPlanMappings
  | GetPlanMappingsSuccess
  | GetPlanMappingsFailure;

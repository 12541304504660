import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ChannelsService } from '../../../shared/services/channels.service';
import { OrdersServiceV2 } from '../../serviceV2/orders.service';
import { OrdersExportService } from '../../serviceV2/orders-export.service';
import {
  GetOrdersList,
  GetPortalsList,
  OrdersActionTypes,
  GetTemplate,
  SaveTemplateV2,
  EditTemplateV2,
  DeleteTemplateV2,
  ExportTemplate,
  GetMemberListV2,
  ScheduleReport,
  SheetStatusCheck,
  CreateBulkReturn,
  CreateImport,
  CreateOrderCancellation
} from '../actions/orders.action';

@Injectable()
export class OrdersEffectsV2 {
  constructor(
    private actions$: Actions,
    private ordersServiceV2: OrdersServiceV2,
    private ordersExportSrvV2: OrdersExportService,
    private channelService: ChannelsService
  ) {}

  @Effect()
  getOrders$: Observable<Action> = this.actions$.pipe(
    ofType<GetOrdersList>(OrdersActionTypes.GET_ORDERS_LIST),
    switchMap(action => {
      return this.ordersServiceV2.getOrdersData(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.GET_ORDERS_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.GET_ORDERS_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  getPortalsData$: Observable<Action> = this.actions$.pipe(
    ofType<GetPortalsList>(OrdersActionTypes.GET_PORTALS_LIST),
    switchMap(action => {
      return this.channelService.getPortalList().pipe(
        map(data => ({
          type: OrdersActionTypes.GET_PORTALS_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.GET_PORTALS_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  templateData$: Observable<Action> = this.actions$.pipe(
    ofType<GetTemplate>(OrdersActionTypes.GET_TEMPLATE),
    mergeMap(action => {
      return this.ordersExportSrvV2.getTemplates(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.GET_TEMPLATE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.GET_TEMPLATE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  saveTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<SaveTemplateV2>(OrdersActionTypes.SAVE_TEMPLATE),
    mergeMap(action => {
      return this.ordersExportSrvV2.createTemplate(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.SAVE_TEMPLATE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.SAVE_TEMPLATE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  editTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<EditTemplateV2>(OrdersActionTypes.EDIT_TEMPLATE),
    mergeMap(action => {
      return this.ordersExportSrvV2.editTemplate(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.EDIT_TEMPLATE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.EDIT_TEMPLATE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  deleteTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<DeleteTemplateV2>(OrdersActionTypes.DELETE_TEMPLATE),
    mergeMap(action => {
      return this.ordersExportSrvV2.deleteTemplate(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.DELETE_TEMPLATE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.DELETE_TEMPLATE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  exportTemplate$: Observable<Action> = this.actions$.pipe(
    ofType<ExportTemplate>(OrdersActionTypes.EXPORT_TEMPLATE),
    mergeMap(action => {
      return this.ordersExportSrvV2.createExportJob(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.EXPORT_TEMPLATE_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.EXPORT_TEMPLATE_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  scheduleReportV2$: Observable<Action> = this.actions$.pipe(
    ofType<ScheduleReport>(OrdersActionTypes.SCHEDULE_REPORT),
    mergeMap(action => {
      return this.ordersExportSrvV2.createScheduleJob(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.SCHEDULE_REPORT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.SCHEDULE_REPORT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  memberList$: Observable<Action> = this.actions$.pipe(
    ofType<GetMemberListV2>(OrdersActionTypes.GET_MEMBER_LIST),
    mergeMap(action => {
      return this.ordersExportSrvV2.getTeamMembers().pipe(
        map(data => ({
          type: OrdersActionTypes.GET_MEMBER_LIST_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.GET_MEMBER_LIST_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  createImport$: Observable<Action> = this.actions$.pipe(
    ofType<CreateImport>(OrdersActionTypes.CREATE_IMPORT),
    mergeMap(action => {
      return this.ordersServiceV2.createImport(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.CREATE_IMPORT_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.CREATE_IMPORT_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  /*Temporary Change*/
  @Effect()
  bulkUploadStatus$: Observable<Action> = this.actions$.pipe(
    ofType<SheetStatusCheck>(OrdersActionTypes.DOCUMENT_UPLOAD_STATUS),
    mergeMap(action => {
      return this.ordersServiceV2.getBulkUploadDocumentStatus(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.DOCUMENT_UPLOAD_STATUS_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  @Effect()
  createBulkReturn$: Observable<Action> = this.actions$.pipe(
    ofType<CreateBulkReturn>(OrdersActionTypes.CREATE_BULK_RETURN),
    mergeMap(action => {
      return this.ordersServiceV2.createBulkReturn(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.CREATE_BULK_RETURN_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.CREATE_BULK_RETURN_FAILURE,
            payload: error
          })
        )
      );
    })
  );

  //Order cancellation starts
  @Effect()
  getOrderCancellation$: Observable<Action> = this.actions$.pipe(
    ofType<CreateOrderCancellation>(OrdersActionTypes.CREATE_ORDER_CANCELLATION),
    mergeMap(action => {
      return this.ordersServiceV2.sendOrderCancellationData(action.payload).pipe(
        map(data => ({
          type: OrdersActionTypes.CREATE_ORDER_CANCELLATION_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.CREATE_ORDER_CANCELLATION_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );

  @Effect()
  customerPortalPoeSettings$: Observable<Action> = this.actions$.pipe(
    ofType<CreateOrderCancellation>(OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS),
    mergeMap(action => {
      return this.ordersServiceV2.getPoeData().pipe(
        map(data => ({
          type: OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_SUCCESS,
          payload: data
        })),
        catchError(error =>
          of({
            type: OrdersActionTypes.CUSTOMER_PORTAL_POE_SETTINGS_FAILURE,
            payload: error['error']
          })
        )
      );
    })
  );
  // Order cancellation ends
}

export const location: fields = {
  dialog_title: 'Upgrade to add more inventory management locations',
  note:
    "You've reached the maximum number of locations with inventory management allowed for your current plan. To add more locations, please opt for the additional locations addon, which will incur additional charges.",
  box_header: 'Additional locations',
  info:
    'For the ongoing billing cycle, the prorated charges for the additional locations must be paid upfront. In subsequent billing cycles, these charges will be included in your recurring subscription invoice.',
  addonName: 'Monthly Additional Locations',
  redirectEndPoint: '/fc/home?selectedTab=location'
};

export const team: fields = {
  dialog_title: 'Upgrade to add more Team members ',
  note:
    "You've reached the maximum number of team members allowed for your current plan. To add more members, please opt for the additional team members addon, which will incur additional charges.",
  box_header: 'Additional team members',
  info:
    'For the ongoing billing cycle, the prorated charges for the additional team members must be paid upfront. In subsequent billing cycles, these charges will be included in your recurring subscription invoice.',
  addonName: 'Additional Team member',
  redirectEndPoint: '/settings/team'
};

export const BANNER_MESSAGES = {
  ticketNotificationMsg:
    'We have received your subscription modification request. A team member will reach out soon to provide updates on the next steps. To check the status of your request, please visit the Tickets section in your workspace.',
  ticketNotificationHeader: 'Subscription modification request received',
  contactSalesNotificationHeader: 'Request submitted successfully!',
  contactSalesNotificationMsg:
    'An Eshopbox sales representative will contact you shortly to assist with the next steps for your business'
};

export interface fields {
  dialog_title: string;
  info: string;
  box_header: string;
  note: string;
  addonName: string;
  redirectEndPoint: string;
}

export interface FeeDetails {
  tax: number;
  tdsDeducted: number | null;
  totalFee: number;
  discount: number;
  couponName: string | null;
  plans: {
    discountPercentage: number;
    addOns: {
      addOnTaxPercentage: number;
      addOnPrice: number;
      addOnName: string;
    }[];
    planName: string;
    discountType: string;
    planPrice: number;
  };
}
export interface HostedPageResponse {
  code: number;
  message: string;
  hostedpage: {
    hostedpage_id: string;
    decrypted_hosted_page_id: string;
    status: string;
    url: string;
    action: string;
    expiring_time: string;
    created_time: string;
  };
}

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs-compat';
import { environment } from 'src/environments/environment';
import { GetNewSegmentsData } from '../../component/segment-with-count/store/actions/segment.actions';
import { getSegmentsData } from '../../component/segment-with-count/store/selectors/segment.selectors';
import { Segment } from '../../models/segment-with-count.model';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class NewSegmentService {
  private newSegmentV2APIURL = 'platform/api/v2';
  private http: HttpClient;

  private segmentDataSource = new BehaviorSubject<Segment>(null);
  currentSegmentData = this.segmentDataSource.asObservable();

  private newSegmentEvent = new BehaviorSubject<any>(null);
  getSegmentEvent = this.newSegmentEvent.asObservable();

  // Sync count from listing total key value if mismatch
  private listingDataSource = new BehaviorSubject<string>('');
  listingCurrentData = this.listingDataSource.asObservable();

  private getActiveSegmentDetails = new BehaviorSubject<any>([]);
  activeSegmentDetails = this.getActiveSegmentDetails.asObservable();

  constructor(
    private httpClient: HttpClient,
    private auth: AuthService,
    private handler: HttpBackend,
    private api: ApiService,
    private store: Store<{ segmentState }>
  ) {
    this.http = new HttpClient(handler);
  }

  // Get segments
  getSegmentsData() {
    const payload = { page: 1, per_page: 500 };
    const API_URL = this.newSegmentV2APIURL + '/segment';
    const queryParams = { page: payload.page, per_page: payload.per_page };
    return this.api.get(API_URL, { params: queryParams });
  }

  // Save segments
  public saveSegment(segmentData) {
    return this.handleSegmentRequest('post', '/segment', segmentData);
  }

  // Edit segments
  public editSegment(segmentData) {
    return this.handleSegmentRequest('put', `/segment/${segmentData.id}`, segmentData);
  }

  // Delete segments
  public deleteSegment(segmentId) {
    return this.handleSegmentRequest('delete', `/segment/${segmentId}`);
  }

  // Common method
  private handleSegmentRequest(method, endpoint, data = null) {
    const accountSlug = this.auth.getAccountSlug();
    const base = `https://${accountSlug}${environment.BASE_DOMAIN}/`;
    const URL = `${base}${this.newSegmentV2APIURL}${endpoint}`;

    const requestOptions = {};

    requestOptions['headers'] = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.auth.getAuthToken()}`);

    switch (method) {
      case 'post':
        return this.http.post(URL, data, requestOptions);
      case 'put':
        return this.http.put(URL, data, requestOptions);
      case 'delete':
        return this.http.delete(URL, requestOptions);
      default:
        throw new Error('Invalid method');
    }
  }

  // Get all segments data
  async getAllSegments(): Promise<any> {
    return new Promise(resolve => {
      this.store.pipe(select(getSegmentsData)).subscribe(response => {
        if (response?.segment && response.loaded) {
          resolve(response);
        } else {
          if (!response.segment && !response.loading && !response.loaded) {
            this.store.dispatch(new GetNewSegmentsData());
          }
        }
      });
    });
  }

  fetchAllSegment() {
    this.store.dispatch(new GetNewSegmentsData());
  }

  /**@description - Method To Get Order Segments Query Params*/
  public getSegmentQuery(segment) {
    let last30Days, last180Days, toDate;
    let filterQuery: any = {
      selectedView: `${segment.id || ''}${segment.id ? '_' : ''}${
        segment.name.includes(' ') ? segment.name.replaceAll(' ', '$') : segment.name
      }`,
      page: 0,
      perPage: 50,
      index: 'prod'
    };

    last30Days = new Date(
      moment()
        .subtract(29, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();

    last180Days = new Date(
      moment()
        .subtract(179, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();

    toDate = new Date().setHours(23, 59, 59).valueOf();

    for (const key in segment.filter) {
      if (typeof segment.filter[key] === 'string') {
        if (segment.filter[key].includes('last30days')) {
          segment.filter[key] = segment.filter[key].replace('fromDate', last30Days).replace('toDate', toDate);
        } else if (segment.filter[key].includes('last180days')) {
          segment.filter[key] = segment.filter[key].replace('fromDate', last180Days).replace('toDate', toDate);
        }
      }
    }

    return (filterQuery = { ...filterQuery, ...segment.filter });
  }

  public getReturnSegmentQuery(segmentName, segmentFilterQuery) {
    let last30Days, last180Days, toDate;
    let filterQuery: any = {
      selectedView: segmentName,
      page: 0,
      perPage: 10
    };

    last30Days = new Date(
      moment()
        .subtract(29, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();

    last180Days = new Date(
      moment()
        .subtract(179, 'days')
        .startOf('day')
        .valueOf()
    ).valueOf();

    toDate = new Date().setHours(23, 59, 59).valueOf();
    let params = segmentFilterQuery.split('&');
    params.forEach(param => {
      const [name, value] = param.split('=');
      if (name == 'selectedView') {
        filterQuery.selectedView = value;
      } else if (name == 'location') {
        filterQuery[name] = this.auth.getFlexSlug();
      } else {
        if (value.includes('last30days')) {
          filterQuery[name] = value + '-' + last30Days + 'TO' + toDate;
        } else if (value.includes('last180days')) {
          filterQuery[name] = value + '-' + last180Days + 'TO' + toDate;
        } else {
          filterQuery[name] = value;
        }
      }
    });
    return filterQuery;
  }

  // Emit current segment/ smart segment data
  emitCurrentSegement(data: Segment) {
    this.segmentDataSource.next(data);
  }

  segmentEvent(data: { created: boolean; segment: any; action: string }) {
    this.newSegmentEvent.next(data);
  }

  // Emit order data
  updateOrderDataToSegment(data: any) {
    this.listingDataSource.next(data);
  }

  getDetailsOfActiveSegment(data: any) {
    this.getActiveSegmentDetails.next(data);
  }

  // Sync count on order updation
  syncCount() {
    this.updateOrderDataToSegment('syncCount');
  }

  // Function to add Sales Channel filter
  addSalesChannelFilter(key) {
    let channels = this.parseAndSort('salesChannel', 'channelLabel');
    channels = channels.map(filter => ({ key: filter.externalChannelId, label: filter.channelLabel }));
    const saleChannel = this.createFilter('Sales channel', key, channels);
    return saleChannel;
  }

  // Function to add Location filter
  addLocationFilter(key) {
    let flLocations = this.parseAndSort('fulfillmentCenter', 'warehouseName');
    flLocations = flLocations.map(filter => ({ key: filter.externalWarehouseId, label: filter.warehouseName }));
    const location = this.createFilter('Location', key, flLocations);
    return location;
  }

  // Get location label from externalWarehouseId
  getLocationLabel(externalWarehouseId: string) {
    const flLocations = this.parseAndSort('fulfillmentCenter', 'warehouseName');
    const location = flLocations.find(loc => loc.externalWarehouseId == externalWarehouseId);
    return location ? location.warehouseName : externalWarehouseId;
  }

  // Helper function to parse and sort localStorage data
  parseAndSort(key, sortKey) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data).sort((a, b) => a[sortKey].localeCompare(b[sortKey])) : [];
  }

  // Helper function to create a filter object
  createFilter(displayKey, key, options) {
    return {
      condition: ['is', 'is not'],
      displayKey,
      fieldType: 'Dropdown',
      key,
      options
    };
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { AuthService } from 'src/app/shared/services';
import { Subscription } from 'rxjs';
import { isArray } from 'src/app/records/utils/common-util';

@Component({
  selector: 'app-export-date-range-filters',
  templateUrl: './export-date-range-filters.component.html',
  styleUrls: ['./export-date-range-filters.component.scss', '../order-schedule/order-schedule.component.scss']
})
export class ExportDateRangeFiltersComponent implements OnInit {
  @Input() jobType: any;
  @Output() selDateFilterEvt = new EventEmitter();
  interval = {
    dateRange: [
      { key: 'None', value: 'none' },
      { key: 'Today', value: 'today' },
      { key: 'Yesterday', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      { key: 'Last 90 Days', value: 'last_90_days' },
      { key: 'This week', value: 'this_week' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ],
    paymentRecReportDateRange: [
      { key: 'Today', value: 'today' },
      { key: 'Yesterday', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      { key: 'Last 90 Days', value: 'last_90_days' },
      { key: 'This week', value: 'this_week' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ],
    updatedatDateRange: [
      { key: 'None', value: 'none' },
      { key: 'Today', value: 'today' },
      { key: 'Yesterday', value: 'yesterday' },
      { key: 'Last 7 Days', value: 'last_7_days' },
      { key: 'Last 30 Days', value: 'last_30_days' },
      { key: 'This week', value: 'this_week' },
      { key: 'This month', value: 'this_month' },
      { key: 'Last month', value: 'last_month' }
    ]
  };
  requestBody = {};
  @ViewChild('placedOnChannelId') placedOnChannelId;
  @ViewChild('createdInEshopboxId') createdInEshopboxId;
  @ViewChild('invoicedOnId') invoicedOnId;
  @ViewChild('fullFilledOnId') fullFilledOnId;
  @ViewChild('deliveredOnId') deliveredOnId;
  @ViewChild('cancelledOnId') cancelledOnId;
  @ViewChild('returnReceivedOnId') returnReceivedOnId;
  @ViewChild('returnCompletedOnId') returnCompletedOnId;
  @ViewChild('readyToShipOnId') readyToShipOnId;
  @ViewChild('returnCreatedOnId') returnCreatedOnId;
  @ViewChild('updatedOrderDate') updatedOrderDate;
  @ViewChild('returnDeliveredOnId') returnDeliveredOnId;
  @ViewChild('returnPickedOnId') returnPickedOnId;
  enrolledFCData: any = [];
  filteredEnrolledFCData: any = [];
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('paymentOrderDate') paymentOrderDate;
  @ViewChild('allStatusSelected') private allStatusSelected: MatOption;
  @ViewChild('allRetrnTypeSelected') private allRetrnTypeSelected: MatOption;
  accountLocationSubscription$: Subscription;
  dateRangeForm: any;
  portalData: any;
  accountLists: any;
  searchWorkspace: string;
  accountslug: string;
  buttondisabledFlag = true;
  isStatus: boolean = false;
  isDate: boolean = false;
  returnStatus: any;
  returnType: any = ['Courier initated return', 'Customer initated return', 'Unknown return'];
  constructor(public datePipe: DatePipe, private fb: FormBuilder, public authService: AuthService) {
    this.accountslug = this.authService.getAccountSlug();
  }

  ngOnInit(): void {
    this.dateRangeForm = this.fb.group({
      placedOnChannelCtrl: [''],
      createdInEshopboxCtrl: [''],
      invoicedOnCtrl: [''],
      fullFilledOnCtrl: [''],
      deliveredOnCtrl: [''],
      cancelledOnCtrl: [''],
      returnReceivedOnCtrl: [''],
      returnCompletedOnCtrl: [''],
      readyToShipOnCtrl: [''],
      returnCreatedOnCtrl: [''],
      paymentOrderDateCtrl: [''],
      qcStatusCtrl: [''],
      locationFilterFormData: [''],
      sellingParty: [''],
      updatedOrderDateCtrl: [''],
      returnDeliveredOnCtrl: [''],
      returnPickedOnCtrl: [''],
      returnStatusCtrl: [''],
      returnTypeCtrl: ['']
    });

    this.enrolledFCData = localStorage.fulfillmentCenter
      ? this.sortData(JSON.parse(localStorage.fulfillmentCenter), 'warehouseName')
      : [];
    this.filteredEnrolledFCData = [...this.enrolledFCData];
    this.portalData = localStorage.getItem('portalDetails') ? JSON.parse(localStorage.getItem('portalDetails')) : [];
    this.getAccountAndWarehouseData();
  }

  ngOnChanges() {
    this.returnStatus =
      this.jobType === 'all_returns_virtual_kit'
        ? ['Awaiting pickup', 'Return on the way', 'Lost', ' Return Delivered', 'Restocked', 'Quarantined', 'Damaged']
        : this.jobType === 'all_draft_return'
        ? ['Restocked', 'Quarantined', 'Damaged']
        : [];
  }

  getSalesChannelFilterData(data) {
    this.requestBody['salesChannel'] = {
      condition: 'is',
      displayKey: data.salesChannel.length ? data.displayKey : '',
      value: data.salesChannel
    };
  }

  getPortalFilterData(data) {
    console.log('data', data, this.portalData);
    if (this.portalData.length == data.portal.length) {
      this.requestBody['portal'] = {
        condition: 'is',
        displayKey: 'Portal: all',
        value: ['all']
      };
    } else {
      this.requestBody['portal'] = {
        condition: 'is',
        displayKey: data.portal.length ? data.displayKey : '',
        value: data.portal
      };
    }
  }

  getCustomDates(type, e, displayKey) {
    let dateObj = {
      condition: 'is-between',
      value: [moment(e.fromDate).format('YYYY-MM-DD'), moment(e.toDate).format('YYYY-MM-DD')],
      displayKey:
        displayKey +
        this.datePipe.transform(e.fromDate, 'd MMM, yyyy') +
        ' - ' +
        this.datePipe.transform(e.toDate, 'd MMM, yyyy')
    };
    switch (type) {
      case 'orderDetails.orderDate':
        this.dateRangeForm.get('placedOnChannelCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderDetails.orderDate'] = dateObj;
        break;
      case 'orderReceivedOn':
        this.dateRangeForm.get('createdInEshopboxCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderReceivedOn'] = dateObj;
        break;
      case 'shipmentDetails.invoiceDate':
        this.dateRangeForm.get('invoicedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['shipmentDetails.invoiceDate'] = dateObj;
        break;
      case 'orderHandoverDoneTimestamp':
        this.dateRangeForm.get('fullFilledOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderHandoverDoneTimestamp'] = dateObj;
        break;
      case 'orderDeliveredTimestamp':
        this.dateRangeForm.get('deliveredOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderDeliveredTimestamp'] = dateObj;
        break;
      case 'orderCancelledTimestamp':
        this.dateRangeForm.get('cancelledOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderCancelledTimestamp'] = dateObj;
        break;
      case 'returns.returnDetails.receivedOnTimestamp':
        this.dateRangeForm.get('returnReceivedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returns.returnDetails.receivedOnTimestamp'] = dateObj;
        break;
      case 'returns.returnDetails.completedOnTimestamp':
        this.dateRangeForm.get('returnCompletedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returns.returnDetails.completedOnTimestamp'] = dateObj;
        break;
      case 'orderReadyToShipTimestamp':
        this.dateRangeForm.get('readyToShipOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderReadyToShipTimestamp'] = dateObj;
        break;
      case 'returns.returnDetails.createdOnTimestamp':
        this.dateRangeForm.get('returnCreatedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returns.returnDetails.createdOnTimestamp'] = dateObj;
        break;
      case 'returnReceivedOn':
        this.dateRangeForm.get('returnReceivedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returnReceivedOn'] = dateObj;
        break;
      case 'orderDate':
        this.dateRangeForm.get('placedOnChannelCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderDate'] = dateObj;
        break;
      case 'returnCompletedOn':
        this.dateRangeForm.get('returnCompletedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returnCompletedOn'] = dateObj;
        break;
      case 'paymentOrderDate':
        this.dateRangeForm.get('paymentOrderDateCtrl').setValue(dateObj.displayKey);
        this.requestBody['paymentOrderDate'] = dateObj;
        break;
      case 'orderLastUpdatedAtTimestamp':
        this.dateRangeForm.get('updatedOrderDateCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderLastUpdatedAtTimestamp'] = dateObj;
        break;
      case 'updatedOrderDateOn':
        this.dateRangeForm.get('updatedOrderDateCtrl').setValue(dateObj.displayKey);
        this.requestBody['orderLastUpdatedAtTimestamp'] = dateObj;
        break;
      case 'returns.returnDetails.deliveredOnTimestamp':
        this.dateRangeForm.get('returnDeliveredOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returns.returnDetails.deliveredOnTimestamp'] = dateObj;
        break;
      case 'returns.returnDetails.pickedOnTimestamp':
        this.dateRangeForm.get('returnPickedOnCtrl').setValue(dateObj.displayKey);
        this.requestBody['returns.returnDetails.pickedOnTimestamp'] = dateObj;
        break;
    }
    this.buttondisabledFlag = !Object.values(this.requestBody).length;
    this.isDate = Object.values(this.requestBody).length ? true : false;
    this.placedOnChannelId.close();
    this.createdInEshopboxId.close();
    this.invoicedOnId.close();
    this.fullFilledOnId.close();
    this.deliveredOnId.close();
    this.cancelledOnId.close();
    this.returnReceivedOnId.close();
    this.returnCompletedOnId.close();
    this.readyToShipOnId.close();
    this.returnCreatedOnId.close();
    this.paymentOrderDate.close();
    this.updatedOrderDate.close();
  }

  /** @description Get Date Request Body For All Orders*/
  public getDate(type, value, displayKey) {
    if (value['value'] == 'none') {
      switch (type) {
        case 'orderDetails.orderDate':
          this.dateRangeForm.get('placedOnChannelCtrl').setValue('');
          break;
        case 'orderReceivedOn':
          this.dateRangeForm.get('createdInEshopboxCtrl').setValue('');
          break;
        case 'shipmentDetails.invoiceDate':
          this.dateRangeForm.get('invoicedOnCtrl').setValue('');
          break;
        case 'orderHandoverDoneTimestamp':
          this.dateRangeForm.get('fullFilledOnCtrl').setValue('');
          break;
        case 'orderDeliveredTimestamp':
          this.dateRangeForm.get('deliveredOnCtrl').setValue('');
          break;
        case 'orderCancelledTimestamp':
          this.dateRangeForm.get('cancelledOnCtrl').setValue('');
          break;
        case 'returns.returnDetails.receivedOnTimestamp':
          this.dateRangeForm.get('returnReceivedOnCtrl').setValue('');
          break;
        case 'returns.returnDetails.completedOnTimestamp':
          this.dateRangeForm.get('returnCompletedOnCtrl').setValue('');
          break;
        case 'firstRTSTimestamp':
          this.dateRangeForm.get('readyToShipOnCtrl').setValue('');
          break;
        case 'returns.returnDetails.createdOnTimestamp':
          this.dateRangeForm.get('returnCreatedOnCtrl').setValue('');
          break;
        case 'returnReceivedOn':
          this.dateRangeForm.get('returnReceivedOnCtrl').setValue();
          break;
        case 'orderDate':
          this.dateRangeForm.get('placedOnChannelCtrl').setValue();
          break;
        case 'returnCompletedOn':
          this.dateRangeForm.get('returnCompletedOnCtrl').setValue();
          break;
        case 'paymentOrderDate':
          this.dateRangeForm.get('paymentOrderDateCtrl').setValue();
          break;
        case 'orderLastUpdatedAtTimestamp':
          this.dateRangeForm.get('updatedOrderDateCtrl').setValue();
          break;
        case 'updatedOrderDateOn':
          this.dateRangeForm.get('updatedOrderDateCtrl').setValue();
          break;
        case 'returns.returnDetails.deliveredOnTimestamp':
          this.dateRangeForm.get('returnDeliveredOnCtrl').setValue('');
          break;
        case 'returns.returnDetails.pickedOnTimestamp':
          this.dateRangeForm.get('returnPickedOnCtrl').setValue('');
          break;
      }
      delete this.requestBody[type];
    } else if (value['value'] != 'none') {
      switch (type) {
        case 'orderDetails.orderDate':
          this.dateRangeForm.get('placedOnChannelCtrl').setValue(displayKey);
          this.requestBody['orderDetails.orderDate'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderReceivedOn':
          this.dateRangeForm.get('createdInEshopboxCtrl').setValue(displayKey);
          this.requestBody['orderReceivedOn'] = this.convertDate(value['value'], displayKey);
          break;
        case 'shipmentDetails.invoiceDate':
          this.dateRangeForm.get('invoicedOnCtrl').setValue(displayKey);
          this.requestBody['shipmentDetails.invoiceDate'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderHandoverDoneTimestamp':
          this.dateRangeForm.get('fullFilledOnCtrl').setValue(displayKey);
          this.requestBody['orderHandoverDoneTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderDeliveredTimestamp':
          this.dateRangeForm.get('deliveredOnCtrl').setValue(displayKey);
          this.requestBody['orderDeliveredTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderCancelledTimestamp':
          this.dateRangeForm.get('cancelledOnCtrl').setValue(displayKey);
          this.requestBody['orderCancelledTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returns.returnDetails.receivedOnTimestamp':
          this.dateRangeForm.get('returnReceivedOnCtrl').setValue(displayKey);
          this.requestBody['returns.returnDetails.receivedOnTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returns.returnDetails.completedOnTimestamp':
          this.dateRangeForm.get('returnCompletedOnCtrl').setValue(displayKey);
          this.requestBody['returns.returnDetails.completedOnTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'firstRTSTimestamp':
          this.dateRangeForm.get('readyToShipOnCtrl').setValue(displayKey);
          this.requestBody['firstRTSTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returns.returnDetails.createdOnTimestamp':
          this.dateRangeForm.get('returnCreatedOnCtrl').setValue(displayKey);
          this.requestBody['returns.returnDetails.createdOnTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returnReceivedOn':
          this.dateRangeForm.get('returnReceivedOnCtrl').setValue(displayKey);
          this.requestBody['returnReceivedOn'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderDate':
          this.dateRangeForm.get('placedOnChannelCtrl').setValue(displayKey);
          this.requestBody['orderDate'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returnCompletedOn':
          this.dateRangeForm.get('returnCompletedOnCtrl').setValue(displayKey);
          this.requestBody['returnCompletedOn'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderReadyToShipTimestamp':
          this.dateRangeForm.get('readyToShipOnCtrl').setValue(displayKey);
          this.requestBody['orderReadyToShipTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'paymentOrderDate':
          this.dateRangeForm.get('paymentOrderDateCtrl').setValue(displayKey);
          this.requestBody['paymentOrderDate'] = this.convertDate(value['value'], displayKey);
          break;
        case 'orderLastUpdatedAtTimestamp':
          this.dateRangeForm.get('updatedOrderDateCtrl').setValue(displayKey);
          this.requestBody['orderLastUpdatedAtTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'updatedOrderDateOn':
          this.dateRangeForm.get('updatedOrderDateCtrl').setValue(displayKey);
          this.requestBody['orderLastUpdatedAtTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returns.returnDetails.deliveredOnTimestamp':
          this.dateRangeForm.get('returnDeliveredOnCtrl').setValue(displayKey);
          this.requestBody['returns.returnDetails.deliveredOnTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
        case 'returns.returnDetails.pickedOnTimestamp':
          this.dateRangeForm.get('returnPickedOnCtrl').setValue(displayKey);
          this.requestBody['returns.returnDetails.pickedOnTimestamp'] = this.convertDate(value['value'], displayKey);
          break;
      }
    }
    this.buttondisabledFlag = !Object.values(this.requestBody).length;
    this.isDate = Object.values(this.requestBody).length ? true : false;
  }

  getStatus(value) {
    if (this.dateRangeForm.value.returnStatusCtrl.length > 0) {
      this.isStatus = true;
    } else {
      this.isStatus = false;
    }
  }

  public convertDate(conditionData, displayKey) {
    let fromDate: any;
    let toDate: any;
    switch (conditionData) {
      case 'today':
        fromDate = moment(new Date()).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'yesterday':
        fromDate = moment(
          new Date(
            moment()
              .subtract(1, 'days')
              .startOf('day')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(
          new Date(
            moment()
              .subtract(1, 'days')
              .startOf('day')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        break;
      case 'last_7_days':
        fromDate = moment(
          new Date(
            moment()
              .subtract(6, 'days')
              .startOf('day')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'last_30_days':
        fromDate = moment(
          new Date(
            moment()
              .subtract(29, 'days')
              .startOf('day')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'last_90_days':
        fromDate = moment(
          new Date(
            moment()
              .subtract(89, 'days')
              .startOf('day')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'this_week':
        fromDate = moment(
          new Date(
            moment()
              .startOf('week')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'this_month':
        fromDate = moment(
          new Date(
            moment()
              .startOf('month')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(new Date()).format('YYYY-MM-DD');
        break;
      case 'last_month':
        fromDate = moment(
          new Date(
            moment()
              .subtract(1, 'months')
              .startOf('month')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        toDate = moment(
          new Date(
            moment()
              .subtract(1, 'months')
              .endOf('month')
              .valueOf()
          )
        ).format('YYYY-MM-DD');
        break;
    }
    return { condition: 'is-between', value: [fromDate, toDate], displayKey };
  }
  public emitEnt() {
    // console.log(this.dateRangeForm.value.locationFilterFormData)
    if (this.dateRangeForm.value.qcStatusCtrl) {
      this.requestBody['qcStatus'] = {
        condition: 'is',
        displayKey: 'QC Status: ' + this.dateRangeForm.value.qcStatusCtrl.join(', '),
        value: this.dateRangeForm.value.qcStatusCtrl
      };

      // this.requestBody['qcStatus'] = this.dateRangeForm.value.qcStatusCtrl.join(', ')
    }
    if (this.dateRangeForm.value.locationFilterFormData) {
      this.requestBody['warehouses'] = {
        condition: 'is',
        displayKey: 'Warehouses: ' + this.dateRangeForm.value.locationFilterFormData.join(', '),
        value: this.dateRangeForm.value.locationFilterFormData.map(String)
      };

      // this.requestBody['warehouses'] = this.dateRangeForm.value.locationFilterFormData.join(', ')
    }
    if (this.dateRangeForm.value.returnStatusCtrl) {
      this.requestBody['status'] = {
        condition: 'is',
        displayKey: 'Status: ' + this.dateRangeForm.value.returnStatusCtrl.join(', '),
        value: this.dateRangeForm.value.returnStatusCtrl.map(String)
      };
    }
    if (this.dateRangeForm.value.returnTypeCtrl) {
      this.requestBody['returnType'] = {
        condition: 'is',
        displayKey: 'ReturnType: ' + this.dateRangeForm.value.returnTypeCtrl.join(', '),
        value: this.dateRangeForm.value.returnTypeCtrl.map(String)
      };
    }
    if (this.accountslug == 'eshop' && this.dateRangeForm.value.sellingParty) {
      this.requestBody['sellingParty'] = {
        condition: 'is',
        displayKey: 'Selling party: ' + this.dateRangeForm.value.sellingParty,
        value: this.dateRangeForm.value.sellingParty
      };
      // this.dateRangeForm.value.sellingParty;
    } else if (this.accountslug == 'eshop' && this.dateRangeForm.value.sellingParty == '') {
      this.requestBody['sellingParty'] = {
        condition: 'is',
        displayKey: 'Selling party: ' + 'All',
        value: 'all'
      };
    } else {
      // this.requestBody['sellingParty'] = this.accountslug;
      if (['all_draft_return', 'all_returns_virtual_kit'].includes(this.jobType)) {
        let wareHouseDetails = this.filteredEnrolledFCData.find(
          x => x.warehouseWorkspaceSlug == this.authService.getFlexSlug()
        );
        this.requestBody['warehouses'] = {
          condition: 'is',
          displayKey: 'warehouses: ' + wareHouseDetails?.warehouseId,
          value: [(wareHouseDetails?.warehouseId).toString()]
        };
      }
      if (this.jobType != 'all_returns_virtual_kit') {
        this.requestBody['sellingParty'] = {
          condition: 'is',
          displayKey: 'Selling party: ' + this.accountslug,
          value: this.accountslug
        };
      }
    }
    this.selDateFilterEvt.emit(this.requestBody);
  }

  /**@description Method to Sort array Data Alphabetically Based on provided key */
  public sortData(data, sortingBasedOnKey: string) {
    return data.sort((a, b) => {
      return a[sortingBasedOnKey] > b[sortingBasedOnKey] ? 1 : -1;
    });
  }

  toggleAllLoaction() {
    if (this.allSelected.selected) {
      this.dateRangeForm.controls.locationFilterFormData.patchValue([
        ...this.filteredEnrolledFCData.map(item => item.warehouseId),
        0
      ]);
    } else {
      this.dateRangeForm.controls.locationFilterFormData.patchValue([]);
    }
    // this.onSubmitDataForm();

    console.log(this.dateRangeForm.value.locationFilterFormData);
  }
  displayLocation(check, data) {
    if (check && data.length > 0) {
      let obj = this.filteredEnrolledFCData.find(val => val.warehouseId === data[0]);
      return obj.facilityLabel;
    }
  }

  toggleAllStatus() {
    if (this.allStatusSelected.selected) {
      this.dateRangeForm.controls.returnStatusCtrl.patchValue([...this.returnStatus.map(item => item)]);
      this.isStatus = true;
    } else {
      this.dateRangeForm.controls.returnStatusCtrl.patchValue([]);
      this.isStatus = false;
    }
  }

  toggleAllReturnType() {
    if (this.allRetrnTypeSelected.selected) {
      this.dateRangeForm.controls.returnTypeCtrl.patchValue([...this.returnType.map(item => item)]);
    } else {
      this.dateRangeForm.controls.returnTypeCtrl.patchValue([]);
    }
  }

  displayReturnStatus(check, data) {
    if (check && data.length > 0) {
      let obj = this.returnStatus.find(val => val === data[0]);
      return obj;
    }
  }

  displayReturnType(check, data) {
    if (check && data.length > 0) {
      let obj = this.returnType.find(val => val === data[0]);
      return obj;
    }
  }

  getAccountAndWarehouseData() {
    this.accountLocationSubscription$ = this.authService
      .getAccountAndWarehouseList()
      // .subscribe(accountListData => {
      .subscribe(([accountListData, fulfillmentCenterData]) => {
        if (
          (isArray(accountListData) || accountListData?.accountListLoaded) &&
          (isArray(fulfillmentCenterData) || fulfillmentCenterData?.fulfillmentCenterLoaded)
        ) {
          const accountList = isArray(accountListData) ? accountListData : accountListData?.accountListData?.data;
          // this.accountLists = accountList;
          this.accountLists = accountList.filter(acc => acc['status'] == '1' && acc['warehouseId'] == null);
          if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
        }
      });
  }

  ngOnDestroy() {
    if (this.accountLocationSubscription$) this.accountLocationSubscription$.unsubscribe();
  }
}

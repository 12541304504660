import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Pusher from 'pusher-js';
import { BehaviorSubject, Subject } from 'rxjs';
import { GetPlanMappings } from 'src/app/auth/actions/auth.actions';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastNotificationsService } from 'src/app/shared/services/toast-notifications.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  closeTicketSnackbar$ = new Subject();
  CHANNEL = environment?.pusher_subscription_status?.pusher_subscription_status_channel;
  EVENT = environment?.pusher_subscription_status?.pusher_subscription_status_event;
  pusher: any;
  channel: any;
  // Keys For Registring Pusher Connection
  PUSHER_KEY = environment?.mapping_pusher?.PUSHER_KEY;
  PUSHER_CLUSTER = environment?.mapping_pusher?.PUSHER_CLUSTER;

  constructor(
    private authService: AuthService,
    private store: Store<{ auth; fc }>,
    private toastNotificationServ: ToastNotificationsService
  ) {
    // pusher for plan mapping data - when plan is modified in zoho, call mappings api.
    // Registering Pusher Connection For the Workspace
    this.pusher = new Pusher(this.PUSHER_KEY, { cluster: this.PUSHER_CLUSTER });
    this.pusher.connection.bind('state_change', function(states) {
      console.log('pusher state MAPPING', states);
    });
    this.pusher.connection.bind('error', function(error) {
      console.error('pusher connection error log MAPPING', error);
    });
    // this.pusher = this.authService.pusher;
    this.channel = this.pusher.subscribe(this.CHANNEL);

    this.channel.bind(this.EVENT, response => {
      if (response) {
        for (let status in response) {
          if (!response[status].account_slug || response[status].account_slug !== this.authService.getAccountSlug()) {
            continue;
          }
          if (status == 'SUBSCRIPTION CREATED') {
            // For any payment related action i.e. new subscription was activated, refresh the page.
            window.location.reload();
          } else if (status == 'SUBSCRIPTION REMOVED') {
            let timer = 5;

            // if subscription status is Live, Cancelled, Expired or Deleted, show banner and reload after 5 sec
            this.toastNotificationServ.showErrorToast(
              `There have been changes in your subscription. 
              Please contact your success manager for more information. 
              Your workspace will reload in ${timer} seconds`,
              5000
            );

            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else if (status == 'SUBSCRIPTION CHANGED') {
            // if Plan upgrade (plan changed), Plan downgrade
            // Addon upgrade (Addon qty added or new addon) , Addon downgrade, refresh mapping data by calling api.
            this.store.dispatch(new GetPlanMappings());
          }
        }
      }
    });
  }

  private showBannerSubject = new BehaviorSubject<boolean>(false);
  showBanner$ = this.showBannerSubject.asObservable();

  showBanner() {
    this.showBannerSubject.next(true);
  }

  hideBanner() {
    this.showBannerSubject.next(false);
  }

  closeSnackbar() {
    this.closeTicketSnackbar$.next(true);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
  constructor() {}

  transform(items: any[], searchText: string, moduleType: string = 'search'): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return this.filterData(items, searchText, moduleType);
  }

  private filterData(items: any[], searchText: string, moduleType: string): any[] {
    if (moduleType === 'searchFC') {
      return items.filter(fc => fc.facilityLabel.toLowerCase().includes(searchText));
    } else if (moduleType === 'workspace') {
      return items.filter(fc => fc.workspace.toLowerCase().includes(searchText));
    } else if (moduleType === 'searchChannel') {
      return items.filter(fc => fc.channelLabel.toLowerCase().includes(searchText));
    } else if (moduleType === 'toParty') {
      return items.filter(loc => loc.partyName.toLowerCase().includes(searchText));
    } else if (moduleType === 'fromWarehouse') {
      return items.filter(loc => loc.facility.toLowerCase().includes(searchText));
    } else if (moduleType === 'status') {
      return items.filter(st => st.value.toLowerCase().includes(searchText));
    } else {
      return items.filter(it => it.name.toLowerCase().includes(searchText));
    }
  }
}

import * as auth from '../actions/auth.actions';
import { AuthState } from '../auth.state';

export const initialState: AuthState = {
  signinloaded: false,
  signinloading: false,
  userSignInInfo: [],
  userAccountInfo: null,
  signuploaded: false,
  signuploading: false,
  profileloading: false,
  profileloaded: false,
  accountloading: false,
  accountloaded: false,
  alreadyExists: false,
  userType: '',
  user: null,
  jobloading: false,
  jobloaded: false,
  jobslist: [],
  otp: null,
  otpValidating: false,
  otpValidated: false,
  createWorkspace: null,
  workspaceCreated: false,
  workspaceCreating: false,
  accountSlugCreated: false,
  accountSlugCreating: false,
  createAccountSlug: null,
  activityToken: null,
  salesChannel: [],
  fulfillmentCenter: {
    fulfillmentCenterData: null,
    fulfillmentCenterLoading: false,
    fulfillmentCenterLoaded: false
  },
  segments: [],
  scheduleJobloading: false,
  scheduleJobloaded: false,
  scheduleJoblist: [],
  editScheduleloading: false,
  editScheduleloaded: false,
  editSchedulelist: [],
  deleteScheduleloading: false,
  deleteScheduleloaded: false,
  deleteScheduleslist: {},
  subscriptionPlan: {
    isSubscriptionPlanLoading: false,
    isSubscriptionPlanLoaded: false,
    subscriptionPlanData: null
  },
  isPlansLoading: false,
  isPlansLoaded: false,
  plansData: null,
  subscriptionLoading: false,
  subscriptionLoaded: false,
  subscriptionData: null,
  updateCustomer: {
    updateCustomerLoading: false,
    updateCustomerLoaded: false,
    updateCustomerData: null
  },
  getCustomer: {
    getCustomerLoading: false,
    getCustomerLoaded: false,
    getCustomerData: null
  },
  makePayment: {
    isMakePaymentLoading: false,
    isMakePaymentLoaded: false,
    makePaymentData: null
  },
  authorizePayment: {
    isAuthorizePaymentLoading: false,
    isAuthorizePaymentLoaded: false,
    authorizePaymentData: null
  },
  filterData: {
    filtersData: null
  },
  updateProfile: {
    userProfileData: null,
    isUserProfileLoading: false,
    isUserProfileLoaded: false
  },
  currentUserData: {
    currentUser: null,
    loading: false,
    loaded: false
  },
  accountList: {
    accountListData: null,
    accountListLoading: false,
    accountListLoaded: false
  },
  isPlanMappingsLoading: false,
  isPlanMappingsLoaded: false,
  planMappingsData: null
};

export function AuthReducer(state = initialState, action: auth.AuthUnion): AuthState {
  switch (action.type) {
    case auth.AuthActionTypes.SIGNIN: {
      return {
        ...state,
        signinloaded: false,
        signinloading: true,
        userSignInInfo: action.payload
      };
    }
    case auth.AuthActionTypes.SIGNIN_SUCCESS: {
      return {
        ...state,
        signinloaded: true,
        signinloading: false,
        userSignInInfo: action.payload,
        userType: ''
      };
    }
    case auth.AuthActionTypes.SIGNIN_FAILURE: {
      return {
        ...state,
        signinloaded: false,
        signinloading: false,
        userSignInInfo: action.payload,
        userType: ''
      };
    }

    case auth.AuthActionTypes.SIGNUP_USER: {
      return {
        ...state,
        signuploaded: false,
        signuploading: true,
        alreadyExists: false,
        user: null
      };
    }
    case auth.AuthActionTypes.SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        signuploaded: true,
        signuploading: false,
        alreadyExists: false,
        user: action.payload,
        userType: 'newUser'
      };
    }
    case auth.AuthActionTypes.SIGNUP_FAILURE: {
      return {
        ...state,
        signuploaded: false,
        signuploading: false,
        alreadyExists: false,
        user: action.payload.error
      };
    }
    case auth.AuthActionTypes.SIGNUP_ACCOUNT: {
      return {
        ...state,
        signuploaded: false,
        signuploading: true,
        alreadyExists: false
      };
    }
    case auth.AuthActionTypes.SIGNUP_ACCOUNT_SUCCESS: {
      return {
        ...state,
        signuploaded: true,
        signuploading: false,
        alreadyExists: false
      };
    }
    case auth.AuthActionTypes.SIGNUP_ACCOUNT_FAILURE: {
      return {
        ...state,
        signuploaded: false,
        signuploading: false,
        alreadyExists: false,
        user: null
      };
    }

    case auth.AuthActionTypes.GET_USER_PROFILE: {
      return {
        ...state,
        profileloaded: false,
        profileloading: true
      };
    }

    case auth.AuthActionTypes.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profileloaded: true,
        profileloading: false,
        user: action.payload
      };
    }

    case auth.AuthActionTypes.GET_PLAN_MAPPINGS: {
      return {
        ...state,
        isPlanMappingsLoading: true,
        isPlanMappingsLoaded: false,
        planMappingsData: null
      };
    }

    case auth.AuthActionTypes.GET_PLAN_MAPPINGS_SUCCESS: {
      return {
        ...state,
        isPlanMappingsLoading: false,
        isPlanMappingsLoaded: true,
        planMappingsData: action.payload
      };
    }

    case auth.AuthActionTypes.GET_PLAN_MAPPINGS_FAILURE: {
      return {
        ...state,
        isPlanMappingsLoading: false,
        isPlanMappingsLoaded: true,
        planMappingsData: action.payload
      };
    }

    case auth.AuthActionTypes.GET_USER_PROFILE_FAILURE: {
      return {
        ...state,
        profileloaded: false,
        profileloading: false
      };
    }

    case auth.AuthActionTypes.GET_USER_ACCOUNT_DETAILS: {
      return {
        ...state,
        accountloaded: false,
        accountloading: true
      };
    }

    case auth.AuthActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS:
    case auth.AuthActionTypes.UPDATE_USER_ACCOUNT_DETAILS: {
      return {
        ...state,
        accountloaded: true,
        accountloading: false,
        userAccountInfo: action.payload
      };
    }

    case auth.AuthActionTypes.GET_USER_ACCOUNT_DETAILS_FAILURE: {
      return {
        ...state,
        accountloaded: false,
        accountloading: false
      };
    }

    case auth.AuthActionTypes.CHECK_ALREADY_EXISTS: {
      return {
        ...state,
        alreadyExists: false
      };
    }
    case auth.AuthActionTypes.ALREADY_EXISTS: {
      return {
        ...state,
        alreadyExists: true
      };
    }
    case auth.AuthActionTypes.MOVE_ALREADY_EXISTS: {
      return {
        ...state,
        alreadyExists: false
      };
    }
    case auth.AuthActionTypes.LOGOUT: {
      return {
        ...state,
        user: null,
        userType: ''
      };
    }

    case auth.AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS: {
      return {
        ...state,
        jobloading: true,
        jobloaded: false
      };
    }

    case auth.AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS_SUCCESS: {
      return {
        ...state,
        jobloading: false,
        jobloaded: true,
        jobslist: action.payload
      };
    }

    case auth.AuthActionTypes.GET_ALL_EXPORT_IMPORT_JOBS_FAILURE: {
      return {
        ...state,
        jobloading: false,
        jobloaded: false
      };
    }
    case auth.AuthActionTypes.GET_ACTIVITY_TOKEN: {
      return {
        ...state,
        activityToken: null
      };
    }
    case auth.AuthActionTypes.GET_ACTIVITY_TOKEN_SUCCESS: {
      return {
        ...state,
        activityToken: action.payload
      };
    }
    case auth.AuthActionTypes.GET_ACTIVITY_TOKEN_FAILURE: {
      return {
        ...state,
        activityToken: action.payload
      };
    }

    // OTP
    case auth.AuthActionTypes.OTP_VALIDATION: {
      return {
        ...state,
        otpValidating: true,
        otpValidated: false
      };
    }

    case auth.AuthActionTypes.OTP_VALIDATION_SUCCESS: {
      return {
        ...state,
        otpValidating: false,
        otpValidated: true,
        otp: action.payload
      };
    }

    case auth.AuthActionTypes.OTP_VALIDATION_FAILURE: {
      return {
        ...state,
        otpValidating: false,
        otpValidated: false,
        otp: action.payload
      };
    }
    // CREATE WORKSPACE
    case auth.AuthActionTypes.CREATE_WORKSPACE: {
      return {
        ...state,
        workspaceCreating: true,
        workspaceCreated: false
      };
    }

    case auth.AuthActionTypes.CREATE_WORKSPACE_SUCCESS: {
      return {
        ...state,
        workspaceCreating: false,
        workspaceCreated: true,
        createWorkspace: action.payload
      };
    }

    case auth.AuthActionTypes.CREATE_WORKSPACE_FAILURE: {
      return {
        ...state,
        workspaceCreating: false,
        workspaceCreated: false,
        createWorkspace: action.payload
      };
    }

    // CREATE ACCOUNT SLUG
    case auth.AuthActionTypes.CREATE_ACCOUNT_SLUG: {
      return {
        ...state,
        accountSlugCreating: true,
        accountSlugCreated: false
      };
    }

    case auth.AuthActionTypes.CREATE_ACCOUNT_SLUG_SUCCESS: {
      return {
        ...state,
        accountSlugCreating: false,
        accountSlugCreated: true,
        createAccountSlug: action.payload
      };
    }

    case auth.AuthActionTypes.CREATE_ACCOUNT_SLUG_FAILURE: {
      return {
        ...state,
        accountSlugCreating: false,
        accountSlugCreated: false
      };
    }
    case auth.AuthActionTypes.GET_SALES_CHANNEL_DATA: {
      return {
        ...state,
        salesChannel: []
      };
    }
    case auth.AuthActionTypes.GET_SALES_CHANNEL_DATA_SUCCESS: {
      return {
        ...state,
        salesChannel: action.payload
      };
    }
    case auth.AuthActionTypes.GET_SALES_CHANNEL_DATA_FAILURE: {
      return {
        ...state
      };
    }

    case auth.AuthActionTypes.GET_FULFILLMENT_CENTER_DATA: {
      return {
        ...state,
        fulfillmentCenter: {
          fulfillmentCenterData: null,
          fulfillmentCenterLoading: true,
          fulfillmentCenterLoaded: false
        }
      };
    }
    case auth.AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_SUCCESS: {
      return {
        ...state,
        fulfillmentCenter: {
          fulfillmentCenterData: action.payload,
          fulfillmentCenterLoading: false,
          fulfillmentCenterLoaded: true
        }
      };
    }
    case auth.AuthActionTypes.GET_FULFILLMENT_CENTER_DATA_FAILURE: {
      return {
        ...state,
        fulfillmentCenter: {
          fulfillmentCenterData: action.payload,
          fulfillmentCenterLoading: false,
          fulfillmentCenterLoaded: true
        }
      };
    }

    case auth.AuthActionTypes.GET_SEGMENTS_DATA: {
      // if (action.payload.page == 1) {
      //   return { ...state, segments: [] };
      // } else {
      return {
        ...state
      };
      // }
    }
    case auth.AuthActionTypes.GET_SEGMENTS_DATA_SUCCESS: {
      // let segmentsData = {};

      // if (action.payload.page == 1) {
      //   segmentsData = action.payload;
      // } else {
      //   segmentsData = { ...action.payload, data: [...state.segments.data, ...action.payload.data] };
      // }

      return {
        ...state,
        segments: action.payload
      };
    }
    case auth.AuthActionTypes.GET_SEGMENTS_DATA_FAILURE: {
      return {
        ...state
      };
    }

    case auth.AuthActionTypes.GET_SCHEDULE_JOB: {
      return {
        ...state,
        scheduleJoblist: [],
        scheduleJobloading: true,
        scheduleJobloaded: false
      };
    }
    case auth.AuthActionTypes.GET_SCHEDULE_JOB_SUCCESS: {
      return {
        ...state,
        scheduleJoblist: action.payload,
        scheduleJobloading: false,
        scheduleJobloaded: true
      };
    }
    case auth.AuthActionTypes.GET_SCHEDULE_JOB_FAILURE: {
      return {
        ...state,
        scheduleJobloading: false,
        scheduleJobloaded: true
      };
    }

    case auth.AuthActionTypes.EDIT_SCHEDULE: {
      return {
        ...state,
        editScheduleloading: true,
        editScheduleloaded: false
      };
    }
    case auth.AuthActionTypes.EDIT_SCHEDULE_SUCCESS: {
      return {
        ...state,
        editSchedulelist: action.payload,
        editScheduleloading: false,
        editScheduleloaded: true
      };
    }
    case auth.AuthActionTypes.EDIT_SCHEDULE_FAILURE: {
      return {
        ...state,
        editScheduleloading: false,
        editScheduleloaded: true
      };
    }

    case auth.AuthActionTypes.DELETE_SCHEDULE: {
      return {
        ...state,
        deleteScheduleloading: true,
        deleteScheduleloaded: false
      };
    }
    case auth.AuthActionTypes.DELETE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        deleteScheduleslist: action.payload,
        deleteScheduleloading: false,
        deleteScheduleloaded: true
      };
    }
    case auth.AuthActionTypes.DELETE_SCHEDULE_FAILURE: {
      return {
        ...state,
        deleteScheduleloading: false,
        deleteScheduleloaded: true
      };
    }

    case auth.AuthActionTypes.GET_PLAN_DETAILS: {
      return {
        ...state,
        subscriptionPlan: {
          isSubscriptionPlanLoading: true,
          isSubscriptionPlanLoaded: false,
          subscriptionPlanData: null
        }
      };
    }

    case auth.AuthActionTypes.GET_PLAN_DETAILS_SUCCESS: {
      return {
        ...state,
        subscriptionPlan: {
          isSubscriptionPlanLoading: false,
          isSubscriptionPlanLoaded: true,
          subscriptionPlanData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.GET_PLAN_DETAILS_FAILURE: {
      return {
        ...state,
        subscriptionPlan: {
          isSubscriptionPlanLoading: false,
          isSubscriptionPlanLoaded: true,
          subscriptionPlanData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.GET_PLANS: {
      return {
        ...state,
        isPlansLoading: true,
        isPlansLoaded: false,
        plansData: null
      };
    }

    case auth.AuthActionTypes.GET_PLANS_SUCCESS: {
      return {
        ...state,
        isPlansLoading: false,
        isPlansLoaded: true,
        plansData: action.payload
      };
    }

    case auth.AuthActionTypes.GET_PLANS_FAILURE: {
      return {
        ...state,
        isPlansLoading: false,
        isPlansLoaded: true,
        plansData: action.payload
      };
    }

    case auth.AuthActionTypes.GET_SUBSCRIPTION: {
      return {
        ...state,
        subscriptionLoading: true,
        subscriptionLoaded: false,
        subscriptionData: null
      };
    }

    case auth.AuthActionTypes.GET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        subscriptionData: action.payload
      };
    }

    case auth.AuthActionTypes.GET_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        subscriptionLoading: false,
        subscriptionLoaded: true,
        subscriptionData: action.payload
      };
    }

    case auth.AuthActionTypes.UPDATE_CUSTOMER: {
      return {
        ...state,
        updateCustomer: {
          updateCustomerLoading: true,
          updateCustomerLoaded: false,
          updateCustomerData: null
        }
      };
    }

    case auth.AuthActionTypes.UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        updateCustomer: {
          updateCustomerLoading: false,
          updateCustomerLoaded: true,
          updateCustomerData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.UPDATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        updateCustomer: {
          updateCustomerLoading: false,
          updateCustomerLoaded: true,
          updateCustomerData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.GET_CUSTOMER: {
      return {
        ...state,
        getCustomer: {
          getCustomerLoading: true,
          getCustomerLoaded: false,
          getCustomerData: null
        }
      };
    }

    case auth.AuthActionTypes.GET_CUSTOMER_SUCCESS: {
      return {
        ...state,
        getCustomer: {
          getCustomerLoading: false,
          getCustomerLoaded: true,
          getCustomerData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.GET_CUSTOMER_FAILURE: {
      return {
        ...state,
        getCustomer: {
          getCustomerLoading: false,
          getCustomerLoaded: true,
          getCustomerData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.MAKE_PAYMENT: {
      return {
        ...state,
        makePayment: {
          isMakePaymentLoading: true,
          isMakePaymentLoaded: false,
          makePaymentData: null
        }
      };
    }

    case auth.AuthActionTypes.MAKE_PAYMENT_SUCCESS: {
      return {
        ...state,
        makePayment: {
          isMakePaymentLoading: false,
          isMakePaymentLoaded: true,
          makePaymentData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.MAKE_PAYMENT_FAILURE: {
      return {
        ...state,
        makePayment: {
          isMakePaymentLoading: false,
          isMakePaymentLoaded: true,
          makePaymentData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.AUTHORIZE_PAYMENT: {
      return {
        ...state,
        authorizePayment: {
          isAuthorizePaymentLoading: true,
          isAuthorizePaymentLoaded: false,
          authorizePaymentData: null
        }
      };
    }

    case auth.AuthActionTypes.AUTHORIZE_PAYMENT_SUCCESS: {
      return {
        ...state,
        authorizePayment: {
          isAuthorizePaymentLoading: false,
          isAuthorizePaymentLoaded: true,
          authorizePaymentData: action.payload
        }
      };
    }

    case auth.AuthActionTypes.AUTHORIZE_PAYMENT_FAILURE: {
      return {
        ...state,
        authorizePayment: {
          isAuthorizePaymentLoading: false,
          isAuthorizePaymentLoaded: true,
          authorizePaymentData: action.payload
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS: {
      return {
        ...state,
        filterData: {
          filtersData: null
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS: {
      return {
        ...state,
        filterData: {
          filtersData: action.payload
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS_FAILURE: {
      return {
        ...state,
        filterData: {
          filtersData: action.payload
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS_V2: {
      return {
        ...state,
        filterData: {
          filtersData: null
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS_SUCCESS_V2: {
      return {
        ...state,
        filterData: {
          filtersData: action.payload
        }
      };
    }
    case auth.AuthActionTypes.GET_ORDERS_FILTERS_FAILURE_V2: {
      return {
        ...state,
        filterData: {
          filtersData: action.payload
        }
      };
    }
    case auth.AuthActionTypes.GET_LOGGEDIN_USER_DATA: {
      return {
        ...state,
        currentUserData: {
          currentUser: null,
          loading: true,
          loaded: false
        }
      };
    }
    case auth.AuthActionTypes.GET_LOGGEDIN_USER_DATA_SUCCESS: {
      return {
        ...state,
        currentUserData: {
          currentUser: action.payload,
          loading: false,
          loaded: true
        }
      };
    }
    case auth.AuthActionTypes.GET_LOGGEDIN_USER_DATA_FAILURE: {
      return {
        ...state,
        currentUserData: {
          currentUser: +action.payload['error']['code'],
          loading: false,
          loaded: true
        }
      };
    }

    case auth.AuthActionTypes.GET_ACCOUNT_LIST: {
      return {
        ...state,
        accountList: {
          accountListData: null,
          accountListLoading: true,
          accountListLoaded: false
        }
      };
    }
    case auth.AuthActionTypes.GET_ACCOUNT_LIST_SUCCESS: {
      return {
        ...state,
        accountList: {
          accountListData: action.payload,
          accountListLoading: false,
          accountListLoaded: true
        }
      };
    }
    case auth.AuthActionTypes.GET_ACCOUNT_LIST_FAILURE: {
      return {
        ...state,
        accountList: {
          accountListData: null,
          accountListLoading: false,
          accountListLoaded: true
        }
      };
    }

    case auth.AuthActionTypes.UPDATE_USER_PROFILE_DETAILS: {
      return {
        ...state,
        updateProfile: {
          userProfileData: null,
          isUserProfileLoading: true,
          isUserProfileLoaded: false
        }
      };
    }
    case auth.AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        updateProfile: {
          userProfileData: action.payload,
          isUserProfileLoading: false,
          isUserProfileLoaded: true
        }
      };
    }
    case auth.AuthActionTypes.UPDATE_USER_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        updateProfile: {
          userProfileData: action.payload,
          isUserProfileLoading: false,
          isUserProfileLoaded: true
        }
      };
    }

    default: {
      return state;
    }
  }
}

export const getSignInLoaded = (state: AuthState) => state.signinloaded;
export const getSignInLoading = (state: AuthState) => state.signinloading;
export const getSignInUserAlreadyExists = (state: AuthState) => state.alreadyExists;
// export const getSignInUserInfo = (state: AuthState) => state.user;
export const getSignUpLoaded = (state: AuthState) => state.signuploaded;
export const getSignUpLoading = (state: AuthState) => state.signuploading;
export const getUserAlreadyExists = (state: AuthState) => state.alreadyExists;
export const getUserInfo = (state: AuthState) => state.user;
export const getUserAccountInfo = (state: AuthState) => state.userAccountInfo;
export const getAccountLoading = (state: AuthState) => state.accountloading;
export const getProfileLoading = (state: AuthState) => state.profileloading;
export const getUserType = (state: AuthState) => state.userType;

export const getjobloading = (state: AuthState) => state.jobloading;
export const getjobloaded = (state: AuthState) => state.jobloaded;
export const getAlljobs = (state: AuthState) => state.jobslist;

export const otp = (state: AuthState) => state.otp;
export const otpValidating = (state: AuthState) => state.otpValidating;
export const otpValidated = (state: AuthState) => state.otpValidated;

export const createWorkspace = (state: AuthState) => state.createWorkspace;
export const workspaceCreated = (state: AuthState) => state.workspaceCreated;
export const workspaceCreating = (state: AuthState) => state.workspaceCreating;

export const createAccountSlug = (state: AuthState) => state.createAccountSlug;
export const accountSlugCreated = (state: AuthState) => state.accountSlugCreated;
export const accountSlugCreating = (state: AuthState) => state.accountSlugCreating;
export const getActivityToken = (state: AuthState) => state.activityToken;

export const getSalesChannelData = (state: AuthState) => state.salesChannel;
export const getFulfillmentCenterData = (state: AuthState) => state.fulfillmentCenter;
export const getSegmentsData = (state: AuthState) => state.segments;

export const getScheduleJobs = (state: AuthState) => state.scheduleJoblist;
export const editSchedule = (state: AuthState) => state.editSchedulelist;
export const deleteSchedule = (state: AuthState) => state.deleteScheduleslist;

export const subscriptionPlanDetails = (state: AuthState) => state.subscriptionPlan;
export const makePaymentData = (state: AuthState) => state.makePayment;
export const authorizePayment = (state: AuthState) => state.authorizePayment;
export const getOrdersFiltersData = (state: AuthState) => state.filterData.filtersData;
export const getUpdatedUserProfileDetails = (state: AuthState) => state.updateProfile;
export const getOrdersFiltersDataV2 = (state: AuthState) => state.filterData.filtersData;

export const GetCurrentLoggedInUserData = (state: AuthState) => state.currentUserData;
export const GetAccountList = (state: AuthState) => state.accountList;
export const planMappingsData = (state: AuthState) => state.planMappingsData;
export const getIsPlanMappingsLoading = (state: AuthState) => state.isPlanMappingsLoading;
export const getIsPlanMappingsLoaded = (state: AuthState) => state.isPlanMappingsLoaded;

export const plansData = (state: AuthState) => state.plansData;
export const updateCustomerData = (state: AuthState) => state.updateCustomer.updateCustomerData;
export const getCustomerData = (state: AuthState) => state.getCustomer.getCustomerData;
export const subsriptionData = (state: AuthState) => state.subscriptionData;

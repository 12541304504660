import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GetAllEximJobs, GetScheduleJobs } from 'src/app/auth/actions/auth.actions';
import { getJobList, getScheduleJobs } from 'src/app/auth/reducers/auth.selectors';
import { PusherService } from 'src/app/download-widget/service/pusher.service';
import { AuthService } from 'src/app/shared/services';
import { ConsignmentService } from '../../shared/services/consignment.service';
import { SocketService } from '../../shared/services/socket.service';
import { DeleteScheduledComponent } from '../delete-scheduled/delete-scheduled.component';
import { EditScheduledComponent } from '../edit-scheduled/edit-scheduled.component';

@Component({
  selector: 'app-file-manager',
  templateUrl: 'file-manager.component.html',
  styleUrls: ['./file-manager.component.scss', '../sidebar/sidebar.component.scss']
})
export class FileManagerComponent implements OnInit, OnChanges {
  @Input() filesMenuClicked: any;
  @Output() filesMenuUnclicked = new EventEmitter();
  filePathSlug = 'https://storage.googleapis.com';
  showimportmenu: boolean;
  tab: any = 'import';
  fullName: any;

  exportJobs: any;
  paginationObjExport = { page: 1, perPage: 25, total: 0 };
  exportJobSubscription$: Subscription;
  isLoading: boolean = true;
  isLoadingExport: boolean = true;
  isLoadingSchedule: boolean = true;

  scheduleJobs: any;
  paginationObjSchedule = { page: 1, perPage: 25, total: 0 };
  scheduleJobSubscription$: Subscription;

  importJobsList: any;
  paginationObjImport = { page: 1, perPage: 25, total: 0 };

  flagForBB: boolean = false;
  // buildingBlockWorkspaces = [];
  workspace: string = '';
  timeFormatObj = {
    second: 'sec',
    seconds: 'secs',
    minutes: 'mins',
    minute: 'min',
    hours: 'hrs',
    hour: 'hr'
  };
  daysAgo: any;

  constructor(
    private store: Store<{ auth }>,
    private conSrv: ConsignmentService,
    public authService: AuthService,
    private sockSrv: SocketService,
    private pusherSrv: PusherService,
    public dialog: MatDialog
  ) {
    if (window.location.pathname.includes('building-blocks')) {
      this.flagForBB = true;
      // this.tab = 'export'; // to display export tab active for BB
    } else this.flagForBB = false;
  }

  ngOnInit() {
    this.fullName = localStorage.getItem('fullName');
    this.getExportJobs();
    // if (!this.flagForBB) {
    //   this.getImportJobs();
    //   this.getScheduleJobs();
    // }
    // this.orderStore.dispatch(new GetMemberList({}));
    this.sockSrv.getImportJobFeed().subscribe(res => {
      for (let status in res) {
        if (
          (status == 'IMPORT JOB COMPLETED' &&
            res['IMPORT JOB COMPLETED']['accountSlug'] === localStorage.getItem('workspace')) ||
          (status == 'IMPORT JOB FAILED' &&
            res['IMPORT JOB FAILED']['accountSlug'] === localStorage.getItem('workspace'))
        ) {
          this.getImportJobs();
        }
      }
    });
    this.pusherSrv.getExportJobCompletedFeed().subscribe(res => {
      for (let status in res) {
        if (
          status === 'EXPORT JOB COMPLETED' &&
          res['EXPORT JOB COMPLETED']['accountSlug'] === localStorage.getItem('workspace') &&
          res['EXPORT JOB COMPLETED']['responseData']['userName'] === localStorage.getItem('email')
        ) {
          this.getExportJobsList();
        }
      }
    });
  }

  ngOnChanges(e) {
    if (this.filesMenuClicked) {
      this.getExportJobsList();
      this.getImportJobs();
      if (!this.flagForBB) {
        this.getScheduleJobs();
      }
    }
  }

  getExportJobs() {
    this.exportJobSubscription$ = this.store.pipe(select(getJobList)).subscribe(job => {
      this.isLoadingExport = false;
      if (job && job['data']) {
        this.exportJobs = job['data'];
        this.paginationObjExport['total'] = job['result'];
      }
    });
  }

  getImportJobs() {
    const body = {
      perPage: this.paginationObjImport['perPage'] === undefined ? 10 : this.paginationObjImport['perPage'],
      page: this.paginationObjImport['page'] === undefined ? 1 : this.paginationObjImport['page']
    };
    this.isLoading = true;
    this.conSrv.getAllImportJobs(body).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.importJobsList = res['data'];
        this.paginationObjImport['total'] = res['result'];
      }
    });
  }

  createFilePath(filePath) {
    let finalFilePath = '';
    if (filePath.includes('https')) finalFilePath = filePath;
    else finalFilePath = `${this.filePathSlug}${filePath}`;
    return finalFilePath;
  }

  paginateExport(event) {
    let requestBody = {};
    requestBody['perPage'] = this.paginationObjExport['perPage'];
    if (event.pageIndex || typeof event.pageIndex == 'number') {
      requestBody['page'] = event.pageIndex + 1;
      this.paginationObjExport['page'] = event.pageIndex + 1;
    }
    this.store.dispatch(new GetAllEximJobs(requestBody));
  }

  paginateImport(event) {
    let requestBody = {};
    requestBody['perPage'] = this.paginationObjImport['perPage'];
    if (event.pageIndex || typeof event.pageIndex == 'number') {
      requestBody['page'] = event.pageIndex + 1;
      this.paginationObjImport['page'] = event.pageIndex + 1;
    }
    this.getImportJobs();
  }

  getExportJobsList() {
    this.showimportmenu = true;
    this.isLoadingExport = true;
    this.store.dispatch(
      new GetAllEximJobs({
        perPage: this.paginationObjExport['perPage'] === undefined ? 10 : this.paginationObjExport['perPage'],
        page: this.paginationObjExport['page'] === undefined ? 1 : this.paginationObjExport['page']
      })
    );
  }

  getScheduleJobs() {
    this.isLoadingSchedule = true;
    this.scheduleJobs = [];
    this.store.dispatch(
      new GetScheduleJobs({
        per_page: this.paginationObjSchedule['perPage'] === undefined ? 10 : this.paginationObjSchedule['perPage'],
        page: this.paginationObjSchedule['page'] === undefined ? 1 : this.paginationObjSchedule['page']
      })
    );
    this.scheduleJobSubscription$ = this.store.pipe(select(getScheduleJobs)).subscribe(job => {
      if (job && job['data']) {
        this.isLoadingSchedule = false;
        this.scheduleJobs = job['data'];
        this.paginationObjSchedule['total'] = job['result'];
      }
    });
  }

  paginateSchedule(event) {
    let requestBody = {};
    requestBody['per_page'] = this.paginationObjSchedule['perPage'];
    if (event.pageIndex || typeof event.pageIndex == 'number') {
      requestBody['page'] = event.pageIndex + 1;
      this.paginationObjSchedule['page'] = event.pageIndex + 1;
    }
    this.getScheduleJobs();
  }

  formatDate(date) {
    const changeFormat: any = new Date(date).getTime();
    let formatTime = moment(changeFormat).fromNow();
    formatTime = formatTime.replace(
      /\b(?:hours|hour|minutes|minute|seconds|second)\b/gi,
      matched => this.timeFormatObj[matched]
    );
    return formatTime;
  }
  downloadDisableFun(i) {
    let formatDate = this.formatDate(i);
    this.daysAgo = formatDate.split(' ');
    if (this.daysAgo[0] > 7 && this.daysAgo[1] == 'days') {
      return true;
    } else {
      false;
    }
  }

  displayJobType(exportJob) {
    let updatedJobTye = '';
    switch (exportJob.jobType) {
      case 'return':
      case 'CIR':
        updatedJobTye = 'Returns';
        break;
      case 'd_on_time_handover_report':
        updatedJobTye = 'On time handover Report';
        break;
      case 'expense_event':
        updatedJobTye = 'Payables';
        break;
      case 'invoice_event':
        updatedJobTye = 'Receivables';
        break;
      case 'order_return':
        updatedJobTye = 'Order return';
        break;
      case 'product':
        updatedJobTye = 'Products';
        break;
      case 'sales_channel_inventory_update_logs':
        updatedJobTye = 'Sales channel inventory report';
        break;
      case 'product_batch_inventory':
        updatedJobTye = 'Batch-wise inventory report';
        break;
      case 'fee_rule':
        updatedJobTye = 'Fee rule';
        break;
      case 'grn_items':
        updatedJobTye = 'Inward report';
        break;
      case 'grn_items_pending':
        updatedJobTye = 'Pending inward items';
        break;
      case 'rejected_grn_items':
        updatedJobTye = 'Rejected items';
        break;
      case 'gatepass_items':
        updatedJobTye = 'Recall report';
        break;
      case 'activity_payment_report':
        updatedJobTye = 'Payout report';
        break;
      case 'reconciled_payment_export':
        updatedJobTye = 'Payout report';
        break;
      case 'reconciled_bulk_payment_export':
        updatedJobTye = 'Bulk Payout report';
        break;
      case 'base_product':
        updatedJobTye = 'Product';
        break;
      case 'merge_product':
        updatedJobTye = 'Merge product';
        break;
      case 'virtual_combo':
        updatedJobTye = 'Virtual kit';
        break;
      case 'kit':
        updatedJobTye = 'Kit';
        break;
      case 'product_availability':
        updatedJobTye = 'Product availability';
        break;
      case 'product_availability_v2':
        updatedJobTye = 'Product availability v2';
        break;
      case 'sale_order_report':
        updatedJobTye = 'Sale order report';
        break;
      case 'sale_order_report_v2':
        updatedJobTye = 'Sale order report';
        break;
      case 'shipment_report':
        updatedJobTye = 'Shipment report';
        break;
      case 'payments':
        updatedJobTye = 'Payments';
        break;
      case 'mark_product_available':
        updatedJobTye = 'Mark product available';
        break;
      case 'mark_product_unavailable':
        updatedJobTye = 'Mark product unavailable';
        break;
      case 'inward_consignment':
        updatedJobTye = 'Inward consignments';
        break;
      case 'recall_consignment':
        updatedJobTye = 'Recall consignments';
        break;
      case 'rto':
        updatedJobTye = 'Initiate return to origin (RTO)';
        break;
      case 'delivery_reattempt':
        updatedJobTye = 'Re-attempt delivery';
        break;
      case 'building_blocks':
        updatedJobTye =
          localStorage.getItem('workspace') === 'eshop'
            ? this.getbbNameByHandleBar(exportJob.exportFilters.buildingBlock)
            : 'Putaway Items';
        break;
      case 'oda_update':
        updatedJobTye = 'ODA_address_update';
        break;
      case 'flex_inventory_update':
        updatedJobTye = 'Inventory update';
        break;
      case 'cod_order_report':
        updatedJobTye = 'COD order level report';
        break;
      case 'cod_payout_report':
        updatedJobTye = 'COD payment level report';
        break;
      case 'finance_payout_report':
        updatedJobTye = 'Finance payment level report';
        break;
      case 'cod_payout_update_status':
        updatedJobTye = 'Finance Status Report';
        break;
      case 'd_exceptionrate_forward_report':
        updatedJobTye = 'Exception Rate Report';
        break;
      case 'd_exceptionrate_return_report':
        updatedJobTye = 'Exception Rate Report';
        break;
      case 'd_timetodeliver_report':
        updatedJobTye = 'Time to Deliver Report';
        break;
      case 'd_notification_dashboard_report':
        updatedJobTye = 'Notifications Report';
        break;
      case 'd_cancellation_rate_report':
        updatedJobTye = 'Cancellation Rate Report';
        break;
      case 'd_ontimedeliver_report':
        updatedJobTye = 'On Time Delivery Report';
        break;
      case 'd_faileddeliver_report':
        updatedJobTye = 'Failed Delivery Report';
        break;
      case 'cod_order_report':
        updatedJobTye = 'COD order level report';
        break;
      case 'cod_payout_report':
        updatedJobTye = 'COD payment level report';
        break;
      case 'finance_payout_report':
        updatedJobTye = 'Finance payment level report';
        break;
      case 'cod_payout_update_status':
        updatedJobTye = 'Finance Status Report';
        break;
      case 'd_regional_utilization_report':
        updatedJobTye = 'Regional Utilization Report';
        break;
      case 'd_regional_utilization_sku_report':
        updatedJobTye = 'Regional Utilization Report';
        break;
      case 'sale_order_report_virtual_kit':
        updatedJobTye = 'Sale order report (Component level)';
        break;
      case 'd_on_time_rts_report':
        updatedJobTye = 'On time RTS Dashboard Report';
        break;
      case 'pincode_serviceability':
        updatedJobTye = 'Pincode Serviceability';
        break;
      case 'd_regional_utilization_report':
        updatedJobTye = 'Regional Utilization Dashboard Report';
        break;
      case 'bq_sale_order_payments_report':
        updatedJobTye = 'Payment reconciliation report';
        break;
      case 'draft_return_report':
        updatedJobTye = 'Draft return report';
        break;
      case 'inventory_status_breakup':
        updatedJobTye = 'Inventory status breakup report';
        break;
      case 'weight_discrepancy':
        updatedJobTye = 'Weight Discrepancy';
        break;
      case 'cancelReturn':
        updatedJobTye = 'Cancel return';
        break;
      case 'inventory_ledger_summary_report':
        updatedJobTye = 'Inventory ledger summary report';
        break;
      case 'product_verification':
        updatedJobTye = 'Product Verification';
        break;
      case 'all_draft_return':
        updatedJobTye = 'All draft return';
        break;
      case 'all_returns_virtual_kit':
        updatedJobTye = 'All expected returns';
        break;
      case 'product_verification':
        updatedJobTye = 'Product Verification';
      case 'claims_export_job':
        updatedJobTye = 'Claims report';
        break;
      case 'bulk_claims_add_proofs':
        updatedJobTye = 'Add proof details';
        break;
      case 'bulk_claims_status_update':
        updatedJobTye = 'Update Claim Status';
        break;
      case 'payments_new':
        updatedJobTye = 'Payout';
        break;
      case 'marketplace_order_report':
        updatedJobTye = 'Marketplace order report';
        break;
      case 'marketplace_payment_report':
        updatedJobTye = 'Marketplace payment report';
        break;
      case 'product_report':
        updatedJobTye = 'Product report';
        break;
      default:
        updatedJobTye = exportJob.jobType;
    }
    return updatedJobTye;
  }

  closeimportmenu() {
    this.showimportmenu = false;
    this.filesMenuUnclicked.emit(false);
    this.paginationObjExport = { page: 1, perPage: 25, total: 0 };
    this.paginationObjImport = { page: 1, perPage: 25, total: 0 };
    this.paginationObjSchedule = { page: 1, perPage: 25, total: 0 };
  }

  activeTab(e) {
    this.tab = e;
  }

  isOverflowing(el) {
    return el.offsetWidth < el.scrollWidth;
  }

  public editSchedule(data) {
    const dialogRef = this.dialog.open(EditScheduledComponent, {
      panelClass: 'custom-modalbox',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['event'] == 'close') {
        this.scheduleJobSubscription$.unsubscribe();
        this.getScheduleJobs();
      }
    });
  }

  public deleteSchedule(id) {
    const dialogRef = this.dialog.open(DeleteScheduledComponent, {
      panelClass: 'custom-modalbox',
      data: id
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['event'] == 'close') {
        this.scheduleJobSubscription$.unsubscribe();
        this.getScheduleJobs();
      }
    });
  }

  getbbNameByHandleBar(handleBar) {
    let buildingBlockDetailsList = localStorage.getItem('BuildingBlockDetails');
    const currentBBDetails =
      buildingBlockDetailsList && JSON.parse(buildingBlockDetailsList).length > 0
        ? JSON.parse(buildingBlockDetailsList).find(m => m.handleBar == handleBar)
        : null;
    return currentBBDetails.name;
  }

  ngOnDestroy() {
    if (this.scheduleJobSubscription$) this.scheduleJobSubscription$.unsubscribe();
    this.filesMenuClicked = false;
  }
}

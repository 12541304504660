export class FilterModel {
  filterKey: any;
  filterCondition: any;
  filterValue: any;
  from: any;
  to: any;
  fromDate: any;
  toDate: any;
  fromTime: any;
  toTime: any;
  time: any;
  allSelected?: any;
}

export const SkipPicking = {
  primaryFilters: [
    'salesChannelName',
    'displayStatus',
    'paymentMethod',
    'shippingProvider',
    'Shipmentcreated at',
    'expectedFulfillmentBy',
    'expectedHandOverDate',
    'shipmentDispatchedAt',
    'cancelledAt'
  ],
  filterFields: [
    'labelStatus',
    'manifestStatus',
    'orderItemIds.scannableIdentifier',
    'bulkLabelsId',
    'trackingID',
    'manifestID',
    'riskScore'
  ]
};

export const ItemExportFilters = {
  itemFilters: [
    {
      condition: ['is'],
      displayKey: 'Return tracking ID',
      fieldType: 'SHORT_TEXT',
      key: 'returnTrackingIds'
    },
    {
      condition: ['is', 'is_between'],
      displayKey: 'Image captured at',
      fieldType: 'DATE_PICKER',
      hasTime: true,
      key: 'imageCapturedAt'
    },
    {
      condition: ['is', 'is_between'],
      displayKey: 'Image last updated at',
      fieldType: 'DATE_PICKER',
      hasTime: true,
      key: 'imageLastUpdatedAt'
    }
  ],
  primaryFilters: ['returnTrackingIds', 'imageCapturedAt', 'imageLastUpdatedAt']
};

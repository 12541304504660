<div class="segment-space-filter">
  <!-- Fitlers loading state | START -->
  <ng-container *ngIf="!currentSegmentData">
    <div class="filter-form-inner loader-skelton-filter">
      <div class="element-filed" *ngFor="let item of [].constructor(7); let i = index">
        <button mat-stroked-button class="form-button-filter-field">
          <ngx-skeleton-loader
            class=""
            [theme]="{ height: '17px', width: '110px', margin: '0px' }"
          ></ngx-skeleton-loader>
        </button>
      </div>
    </div>
  </ng-container>
  <!-- Fitlers loading state | END -->

  <div class="default-filters filter-mb-0" *ngIf="currentSegmentData && !searchQuery">
    <!-- FILTERS -->
    <ng-container
      *ngIf="dateRangeFilters.length > 0 || (primaryFilters && primaryFilters.length > 0) || moreFilters.length > 0"
    >
      <form autocomplete="off" class="filter-form">
        <div class="filter-form-inner">
          <div class="element-filed" *ngIf="dateRangeFilters.length > 0">
            <button
              id="flex-date-filter"
              disableRipple
              mat-stroked-button
              class="form-button-filter-field"
              [matMenuTriggerFor]="daterange"
              (menuOpened)="toggleDatePickerOpend()"
              (menuClosed)="toggleDatePickerClosed()"
              [ngClass]="toggleDate ? 'open' : ''"
            >
              <img
                src="https://ik.imagekit.io/2gwij97w0o/filter_icons/calendar-outline.svg?updatedAt=1694069453466"
                class="icon"
              />
              <span class="button-label">
                <span class="button-label-text">Date range</span>
              </span>
              <span class="material-icons material-symbols-outlined arrow-icon">
                arrow_drop_down
              </span>
            </button>
            <mat-menu
              #daterange="matMenu"
              class="filter-dropdown-backdrop-panelclass filter-dropdown-position full-calendar"
            >
              <span class="click-wrapper" (click)="stopPropagationFunction($event)">
                <div class="field-dropdown-container" *ngIf="toggleDate">
                  <!-- [filterArray]="filterArray" -->
                  <app-reusable-filter-options
                    [currentFilterData]="dateRangeFilters"
                    [isDateFilter]="true"
                    [filterType]="'DATE_FILTER'"
                    (applyFilterData)="applyFilterQuery($event, true)"
                    (clearFilterData)="clearDateFilterQuery($event)"
                    [buildingBlock]="buildingBlock"
                  ></app-reusable-filter-options>
                </div>
              </span>
            </mat-menu>
          </div>

          <ng-container *ngIf="primaryFilters && primaryFilters.length > 0">
            <ng-container *ngFor="let filterData of primaryFilters; let i = index">
              <!-- *ngIf="filterData?.fieldType != 'DATE_PICKER' && filterData?.fieldType != 'DateRange'" -->
              <div class="element-filed">
                <button
                  [id]="'flex-primary-filter' + filterData?.key"
                  disableRipple
                  mat-stroked-button
                  class="form-button-filter-field"
                  [matMenuTriggerFor]="menuPanel"
                  [ngClass]="{ open: filterDataVeriable == filterData?.key }"
                  (menuOpened)="toggleOpend(filterData?.key)"
                  (menuClosed)="toggleClosed()"
                >
                  <span class="button-click-wrapper" (click)="openFilter(filterData?.key, j, filterData?.fieldType)">
                    <span class="button-label">
                      <span class="button-label-text"> {{ filterData?.displayKey }}</span>
                    </span>
                    <span
                      class="material-icons material-symbols-outlined arrow-icon"
                      [ngClass]="{ 'arrow-icon-180': selectedField == filterData?.key }"
                    >
                      arrow_drop_down
                    </span>
                  </span>
                </button>

                <mat-menu #menuPanel="matMenu" class="filter-dropdown-backdrop-panelclass filter-dropdown-position">
                  <span class="click-wrapper" (click)="stopPropagationFunction($event)">
                    <div
                      class="field-dropdown-container"
                      [id]="filterData?.key + i"
                      *ngIf="openFilterToggle && selectedField == filterData?.key"
                    >
                      <app-reusable-filter-options
                        [currentFilterData]="filterData"
                        [isDateFilter]="filterData?.fieldType == 'DateRange'"
                        [isMoreFilters]="toggleMore"
                        [filterType]="'PRIMARY_FILTER'"
                        (applyFilterData)="applyFilterQuery($event)"
                        (clearFilterData)="clearFilterQuery($event, j)"
                        [buildingBlock]="buildingBlock"
                      ></app-reusable-filter-options>
                    </div>
                  </span>
                </mat-menu>
              </div>
            </ng-container>
          </ng-container>

          <div class="element-filed" *ngIf="moreFilters.length > 0">
            <button
              disableRipple
              mat-stroked-button
              class="form-button-filter-field more-filter-button common-filter-buttons"
              [disabled]="moreFilters.length < 1"
              (click)="toggleMore = !toggleMore"
              [ngClass]="toggleMore ? 'open' : ''"
            >
              <img
                src="https://ik.imagekit.io/2gwij97w0o/filter_icons/funnel-outline.svg?updatedAt=1694682070283"
                class="icon"
              />
              <span class="button-label" (click)="openMoreFiltersPanel()">
                <span class="button-label-text">More filters</span>
              </span>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>

  <!-- CHIPS -->
  <div
    class="filter-chips-container"
    *ngIf="newActualFilter.length > 0 && !searchQuery"
    [ngClass]="{ 'mt-15': newActualFilter?.length > 0 }"
  >
    <div class="filter-form-inner">
      <div class="element-filed chip-field" *ngFor="let filter of newActualFilter; let i = index">
        <!-- Show date chip as text chip -->
        <div class="chip-onsearch" *ngIf="filter.fieldType === 'DateRange'; else OtherThnDate">
          <img
            *ngIf="showRemoveChip(filter)"
            src="https://ik.imagekit.io/2gwij97w0o/filter_icons/chip-close.svg?updatedAt=1695622763618"
            class="clear-chip"
            (click)="clearChip(filter)"
          />
          <span>
            <!-- Key -->
            {{ filter?.displayKey ? filter?.displayKey : filter?.key }}
            <!-- Condition -->
            {{ filter?.conditionType != 'custom' ? 'is' : filter?.filterCondition }}
            <!-- Value -->
            <ng-container *ngIf="filter?.conditionType && filter?.conditionType != 'custom'; else showActualValue">
              {{
                filter?.conditionType == 'today'
                  ? 'Today'
                  : filter?.conditionType == 'yesterday'
                  ? 'Yesterday'
                  : filter?.conditionType == 'last7days'
                  ? 'Last 7 days'
                  : filter?.conditionType == 'last30days'
                  ? 'Last 30 days'
                  : filter?.conditionType == 'last90days'
                  ? 'Last 90 days'
                  : filter?.conditionType == 'last180days'
                  ? 'Last 180 days'
                  : filter?.conditionType == 'thisweek'
                  ? 'This week'
                  : filter?.conditionType == 'thismonth'
                  ? 'This month'
                  : filter?.conditionType == 'lastmonth'
                  ? 'Last month'
                  : filter?.conditionType
              }}
            </ng-container>
            <ng-template #showActualValue>
              <!-- Display filter value based on conditions -->
              {{ filter?.fromDate | date: 'd MMM y' }}
              <!-- Display a dash if both 'from' and 'to' values are present -->
              {{ (filter?.fromDate && filter?.toDate) || (filter?.fromDate && filter?.toDate) ? 'to' : '' }}
              <!-- Display 'to' value or formatted 'toDate' -->
              {{ filter?.toDate ? (filter.toDate | date: 'd MMM y') : '' }}
            </ng-template>
          </span>
        </div>

        <!-- Show preset value as text chip -->
        <ng-template #OtherThnDate>
          <div
            class="chip-onsearch"
            *ngIf="
              currentSegmentData?.filterQuery.includes(filter?.key) &&
                filter.fieldType === 'Dropdown' &&
                currentSegmentData.filter[filter.key] &&
                !currentSegmentData.filter[filter.key].includes(',');
              else defaultChip
            "
          >
            <span>
              {{ filter?.displayKey ? filter?.displayKey : filter?.key }}
              {{
                filter?.filterCondition.includes('is ')
                  ? filter?.filterCondition.replace('is ', '')
                  : filter?.filterCondition
              }}
              {{ filter?.filterValue | changeLabel: filter?.key | truncateFilter: 1 }}
            </span>
          </div>
          <ng-template #defaultChip>
            <button
              disableRipple
              mat-stroked-button
              class="form-button-filter-field chip_field_new"
              [matMenuTriggerFor]="chipOptionPanel"
              [ngClass]="{
                open: filterDataVeriableChip == filter?.key
              }"
              (menuOpened)="toggleOpendChip(filter?.key)"
              (menuClosed)="toggleClosedChip()"
            >
              <img
                *ngIf="showRemoveChip(filter)"
                src="https://ik.imagekit.io/2gwij97w0o/filter_icons/chip-close.svg?updatedAt=1695622763618"
                class="clear-chip"
                (click)="clearChip(filter)"
              />
              <span class="span-wrapper" (click)="openFilter(filter.key, i, filter.fieldType, true)">
                <span class="button-label">
                  <span class="button-label-text"> {{ filter?.displayKey ? filter?.displayKey : filter?.key }}</span>
                  <span class="condition-text"
                    >{{
                      filter?.filterCondition.includes('is ')
                        ? filter?.filterCondition.replace('is ', '')
                        : filter?.filterCondition
                    }}
                  </span>
                  <!-- <span class="selection-filter"> -->
                  <span class="selection-filter text-ellipsis">
                    <!-- Handling for IS_EMPTY/IS_NOT_EMPTY -->
                    <ng-container
                      *ngIf="
                        (filter?.filterCondition === 'is' || filter?.filterCondition === 'is not') &&
                          (!filter?.filterValue || !(filter?.filterValue)[0]);
                        else fValue
                      "
                    >
                      Empty
                    </ng-container>
                    <ng-template #fValue>
                      <!-- Display filter value based on conditions -->
                      {{
                        filter?.filterValue.length > 0 && filter.fieldType == 'Dropdown'
                          ? (filter?.filterValue | changeLabel: filter?.key | truncateFilter: 1)
                          : filter.filterValue
                      }}
                    </ng-template>
                  </span>
                </span>
                <span class="material-icons material-symbols-outlined arrow-icon">
                  arrow_drop_down
                </span>
              </span>
            </button>
            <mat-menu
              #chipOptionPanel="matMenu"
              class="filter-dropdown-backdrop-panelclass filter-dropdown-position chip-panelclass"
            >
              <span class="click-wrapper" (click)="stopPropagationFunction($event)">
                <div class="field-dropdown-container" *ngIf="filterDataVeriableChip == filter?.key">
                  <app-reusable-filter-options
                    [currentFilterData]="filter"
                    [isFilterFromChip]="filterDataVeriableChip"
                    [isDateFilter]="isDateFilter"
                    [filterType]="'CHIPS'"
                    (applyFilterData)="applyFilterQuery($event)"
                    (clearFilterData)="clearFilterQuery($event)"
                  ></app-reusable-filter-options>
                </div>
              </span>
            </mat-menu>
          </ng-template>
        </ng-template>
      </div>

      <ng-container *ngIf="newActualFilter.length > 0">
        <span
          *ngIf="
            !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key) &&
            newActualFilter?.length > (currentSegmentData?.filter | convertObjectToIterator).length
          "
          class="clear-all"
          (click)="clearAllFilters(true)"
        >
          <span>
            <img
              height="10"
              width="15"
              src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
            />
          </span>
          Clear all
        </span>

        <div
          class="save-segment"
          *ngIf="
            !filtersData?.selectedSegment?.isCore ||
            !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
          "
        >
          <span class="clear-all" (click)="openSaveEditPopup($event, filtersData.selectedSegment?.name, true)">
            <img src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/segment-icon.svg" />
            {{
              !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
                ? 'Save as view'
                : 'Edit view'
            }}
          </span>
          <div class="searchoverlay" [ngClass]="showForm ? 'visible' : ''" (click)="showForm = !showForm"></div>
          <ng-container *ngIf="showForm">
            <div class="save-form save-form-view">
              <h4>
                {{
                  !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
                    ? 'Save as view'
                    : 'Edit view'
                }}
              </h4>
              <div class="form-group mb-0">
                <input
                  type="text"
                  [(ngModel)]="segmentName"
                  autofocus
                  placeholder="Enter view name"
                  (ngModelChange)="checkDuplicateName()"
                />
                <p *ngIf="!duplicateName">
                  This view will be
                  {{
                    !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
                      ? 'saved'
                      : 'updated'
                  }}
                  in my views.
                </p>
                <mat-error *ngIf="duplicateName" class="error">
                  A view with the same name already exists. Please enter a different name.
                </mat-error>
                <div class="action-btn">
                  <button class="btn no-icon" (click)="onCancelSave()">Cancel</button>
                  <button
                    [disabled]="!segmentName || isLoading"
                    class="btn primary-btn no-icon"
                    [ngClass]="{ disabled: !segmentName || duplicateName, saving_segment: isLoading }"
                    (click)="
                      !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
                        ? onSaveSegment()
                        : onUpdateSegment(segmentName)
                    "
                  >
                    <div class="loader_inside_button" *ngIf="isLoading"></div>
                    {{
                      !currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)
                        ? 'Save'
                        : 'Update'
                    }}
                  </button>
                </div>
              </div>
              <button
                class="delete-segment"
                *ngIf="currentSegmentData?.filterQuery.includes(newActualFilter[newActualFilter?.length - 1]?.key)"
                (click)="onDeleteSegment()"
              >
                <img
                  src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/trash.svg"
                  alt="Delete view"
                />
                Delete view
              </button>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="searchQuery" class="search-by-input">
    <img
      src="https://ik.imagekit.io/2gwij97w0o/search-cross.svg.svg?updatedAt=1682415371094"
      class="clear-chip"
      (click)="clearSearch('')"
    />

    <span *ngIf="buildingBlock === RETURN_NO_SCAN; else otherBuidlingBlock">{{ searchQuery }}</span>
    <ng-template #otherBuidlingBlock>
      <span
        >Search by: <b>{{ searchQuery }}</b>
      </span>
    </ng-template>
  </div>
</div>

<!-- MORE FILTER START -->
<div class="slide-page more-filter-container slide-page-filter" *ngIf="toggleMore">
  <div class="slide-page-header">
    <div class="slide-page-header-title">More filters</div>
    <div class="slide-page-header-close" (click)="closeMoreFilterPanel()"></div>
  </div>
  <div class="slide-page-body slide_pg_new">
    <ng-container *ngFor="let filter of moreFilters; let i = index">
      <div class="element-filed more-form-field">
        <button
          [id]="'flex-moreFilter-filter' + filter?.key"
          disableRipple
          mat-stroked-button
          class="form-button-filter-field more-form-button-field"
          [matMenuTriggerFor]="moreFiltersMenu"
          [ngClass]="{ open: filterDataVeriable == filter?.displayKey }"
          (menuOpened)="toggleOpendMoreButtons(filter?.displayKey)"
          (menuClosed)="toggleClosedMoreButtons()"
        >
          <span class="button-click-wrapper" (click)="openFilter(filter, i, filter.fieldType, false, true)">
            <span class="buttocloseMoreFilterPaneln-label button-label">
              <span class="button-label-text button-label-font-large">{{ filter?.displayKey }}</span>
              <span class="condition-text" *ngIf="filterDataToShow[i]?.filterValue || filterDataToShow[i]?.fromDate">
                {{
                  filterDataToShow[i]?.filterCondition && filterDataToShow[i]?.filterCondition.includes('_')
                    ? filterDataToShow[i]?.filterCondition == 'not_like'
                      ? 'does not contain'
                      : filterDataToShow[i]?.filterCondition.replaceAll('_', ' ')
                    : filterDataToShow[i]?.filterCondition == 'like'
                    ? 'contains'
                    : filterDataToShow[i]?.filterCondition
                }}
              </span>
              <span class="selection-filter" *ngIf="filterDataToShow[i]?.filterCondition != 'is between'">{{
                filterDataToShow[i]?.filterValue
              }}</span>
              <span class="selection-filter" *ngIf="filterDataToShow[i]?.filterCondition == 'is between'">
                {{ filterDataToShow[i]?.fromDate | date: 'd MMM y' }}
                <ng-container *ngIf="filterDataToShow[i]?.toDate">
                  - {{ filterDataToShow[i]?.toDate | date: 'd MMM y' }}
                </ng-container>
              </span>
            </span>
            <span class="material-icons material-symbols-outlined arrow-icon">
              arrow_drop_down
            </span>
          </span>
        </button>
        <mat-menu
          #moreFiltersMenu="matMenu"
          class="filter-dropdown-backdrop-more-filter-panelclass filter-dropdown-more-filter-newpanel"
        >
          <span class="click-wrapper" (click)="stopPropagationFunction($event)">
            <div class="field-dropdown-container" *ngIf="filterDataVeriable == filter?.displayKey">
              <app-reusable-filter-options
                [currentFilterData]="filter"
                [isMoreFilters]="true"
                [isDateFilter]="filter?.fieldType == 'DateRange'"
                [filterType]="'MORE_FILTER'"
                (applyMoreFilterData)="applyMoreFitlerQuery($event, i)"
                (clearFilterData)="clearMoreFilterValue($event, i)"
                [currentSegmentsearchQuery]="filtersData?.selectedSegment?.filterQuery"
              ></app-reusable-filter-options>
            </div>
          </span>
        </mat-menu>
      </div>
    </ng-container>
  </div>

  <!-- Clear all and apply filters -->
  <div class="slide-page-footer">
    <button
      mat-button
      color="primary"
      class="theme-secondary-button no-border clear-all-filter-btn"
      [disableRipple]="true"
      (click)="clearAllFilters(false)"
    >
      Clear all
    </button>
    <button
      mat-button
      color="primary"
      class="theme-primary-button apply-filter-btn"
      [disableRipple]="true"
      (click)="applyAllMoreFilters()"
    >
      Apply filters
    </button>
  </div>
</div>
<div class="slide-page-overlay" (click)="closeMoreFilterPanel()" *ngIf="toggleMore"></div>
<!-- MORE FILTER END -->

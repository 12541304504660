<div class="notification-container">
  <span>
    <div>
      <img src="https://ik.imagekit.io/2gwij97w0o/Upgrade-Filled.svg?updatedAt=1724841951801" alt="" />
    </div>
    <div [innerHTML]="content | safe: 'html'" class="content"></div>
    <div>
      <app-get-feature [showWhiteBtn]="true"></app-get-feature>
    </div>
  </span>
</div>

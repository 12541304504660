<div *ngIf="!isMobile" class="sub-menu">
  <div class="sub-menu-inner">
    <div class="sub-menu-location-section">
      <button
        matTooltip="{{ currentFacilityLabel }}"
        matTooltipPosition="below"
        matTooltipClass="theme-tooltip top-position below"
        (click)="$event.stopPropagation($event)"
        disableRipple
        mat-button
        [matMenuTriggerFor]="locationDropdownMenu"
        class="location-selector"
      >
        <img class="location-icon" src="https://ik.imagekit.io/2gwij97w0o/Location-icon.svg" alt="" />
        <span class="location-fc-name">{{ currentFacilityLabel }}</span>
        <span class="arrow-icon"></span>
      </button>
      <mat-menu
        class="location-dropdown-menu"
        #locationDropdownMenu="matMenu"
        backdropClass="sub-menu-location-backdrop"
      >
        <mat-form-field
          *ngIf="accountList.length > 1"
          class="location-dropdown-search input-full-width-icon placeholder-text"
          (click)="$event.stopPropagation($event)"
          [ngClass]="subMenuSearchValue ? 'search-valid' : ''"
        >
          <mat-icon class="search-icon"> </mat-icon>
          <input
            #searchField
            matInput
            type="text"
            [(ngModel)]="searchFc"
            name="search"
            class="input-icon"
            placeholder="Search location"
            (ngModelChange)="detectChange(searchFc)"
          />
        </mat-form-field>
        <div class="location-dropdown-menu-inner">
          <ng-container *ngFor="let warehouse of accountList | searchDropDownFilter: searchFc:'facilityLabel'">
            <button
              matTooltipClass="theme-tooltip top-position-menu-items below"
              matTooltip="{{ warehouse.facilityLabel }}"
              matTooltipPosition="below"
              disableRipple
              mat-menu-item
              [ngClass]="
                flexAccountSlug == warehouse.accountSlug ? 'account-fc-button btn-active' : 'account-fc-button'
              "
              (click)="selectedFC(warehouse?.accountSlug)"
            >
              {{ warehouse?.facilityLabel }}
            </button>
          </ng-container>
        </div>
      </mat-menu>
    </div>
    <div class="sub-menu-items">
      <span class="sub-menu-item-label" *ngIf="subNavItems.length > 0">{{ subNavigationParent }}:</span>
      <ng-container
        *ngIf="
          pickingMethod &&
            pickingMethod === 'skipPicking' &&
            (handleBar === 'shipment' || handleBar === 'picklist' || handleBar === 'manifests');
          else deviceBasedPicking
        "
      >
        <button
          (click)="openbuildingBlock('shipment', true)"
          mat-button
          class="sub-menu-button"
          [ngClass]="{ 'subnav-highlight': handleBar === 'shipment' && currentUrl == '' }"
        >
          <span class="location-fc-name">Pack</span>
        </button>
        <button
          (click)="openbuildingBlock('manifests', true)"
          mat-button
          class="sub-menu-button"
          [ngClass]="{ 'subnav-highlight': handleBar === 'manifests' && currentUrl == '' }"
        >
          <span class="location-fc-name">Ship</span>
        </button>
      </ng-container>
      <ng-template #deviceBasedPicking>
        <ng-container *ngIf="subNavItems.length > 0">
          <ng-container *ngFor="let item of subNavItems">
            <ng-container *ngIf="!isItemInMappings(item) || item.isVisible">
              <ng-container *ngIf="item.fullPath.includes('handleBar'); else otherBtn">
                <button
                  *ngIf="
                    item.visibileFor.includes('all') ||
                    item.visibileFor.includes(accountSlug) ||
                    (flexAccountSlug == 'tcnsggn' &&
                      (item.URL == 'ajioReturnLR' || item.URL == 'refusedShipmentsManifest'))
                  "
                  (click)="openbuildingBlock(item.URL, true)"
                  mat-button
                  class="sub-menu-button"
                  [ngClass]="{ 'subnav-highlight': item.URL === handleBar && currentUrl == '' }"
                >
                  <span class="location-fc-name">{{ item.name }}</span>
                </button>
                <button
                  *ngIf="!item.visibileFor.includes(accountSlug) && item.showInLocation.includes(flexAccountSlug)"
                  (click)="openbuildingBlock(item.URL, true)"
                  mat-button
                  class="sub-menu-button"
                  [ngClass]="{ 'subnav-highlight': item.URL === handleBar && currentUrl == '' }"
                >
                  <span class="location-fc-name">{{ item.name }}</span>
                </button>
              </ng-container>
              <ng-template #otherBtn>
                <button
                  mat-button
                  class="sub-menu-button"
                  [ngClass]="{ 'subnav-highlight': currentUrl.includes(item.URL) }"
                >
                  <span class="location-fc-name" (click)="openbuildingBlock(item.URL, true, item.URL)">{{
                    item.name
                  }}</span>
                </button>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="subNavItems.length == 0 && shortCutBB">
          <button mat-button class="sub-menu-button subnav-highlight">
            <span class="location-fc-name">{{ shortCutBB.name }}</span>
          </button>
        </ng-container>
      </ng-template>

      <span
        *ngIf="
          subNavItems.length > 0 &&
          subNavItems[0].parent == 'Process return' &&
          accountSlug === 'eshop' &&
          (flexAccountSlug === 'fggn' || flexAccountSlug === 'testfc')
        "
      >
        <button
          (click)="$event.stopPropagation($event)"
          (menuOpened)="rotateArrowClick($event)"
          disableRipple
          mat-button
          [matMenuTriggerFor]="subMenuButton"
          #subMenuButtonTrigger="matMenuTrigger"
          class="sub-menu-button sub-menu-button-dropdown auto-width-button"
          [ngClass]="{ 'subnav-highlight': tcnsNavItem }"
        >
          <span
            >TCNS store returns <ng-container *ngIf="tcnsNavItem">: {{ tcnsNavItem }}</ng-container>
          </span>
          <img
            class="mrl-6"
            src="https://ik.imagekit.io/2gwij97w0o/top_nav/chevron-down.svg?updatedAt=1683262382504"
            [ngClass]="rotateArrow ? 'rotate270' : 'rotate90'"
          />
        </button>
        <mat-menu
          (closed)="rotateArrowClick($event)"
          class="sub-menu-dropdown"
          #subMenuButton="matMenu"
          xPosition="before"
        >
          <ng-container *ngFor="let item of ROUTESDATA['orders']">
            <ng-container *ngIf="item.parent === 'TCNS store returns'">
              <button
                [disableRipple]="true"
                (click)="openbuildingBlock(item.URL, true)"
                mat-menu-item
                class="sub-navigation-menu-button"
              >
                <span>{{ item.name }}</span>
              </button>
            </ng-container>
          </ng-container>
        </mat-menu>
      </span>
    </div>
  </div>
</div>

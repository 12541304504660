import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgrade-product-plan',
  templateUrl: './upgrade-product-plan.component.html',
  styleUrls: ['./upgrade-product-plan.component.scss']
})
export class UpgradeProductPlanComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

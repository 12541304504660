<ng-container *ngIf="currentFilterData?.fieldType === 'Dropdown'">
  <ng-container *ngIf="filterOptions && filterOptions.length > 0">
    <ng-container *ngTemplateOutlet="condition; context: { $implicit: currentFilterData }"></ng-container>
  </ng-container>

  <ng-container *ngIf="filterOptions && filterOptions.length > 4">
    <ng-container *ngTemplateOutlet="search"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="multiselect"></ng-container>

  <ng-container *ngIf="filterOptions && filterOptions.length > 0">
    <ng-container *ngTemplateOutlet="commonButtons"></ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="currentFilterData?.fieldType === 'String'">
  <ng-container *ngTemplateOutlet="condition; context: { $implicit: currentFilterData }"></ng-container>
  <!-- <ng-container *ngIf="currentFilterData.fieldType != 'BOOLEAN'"> -->
  <ng-container *ngTemplateOutlet="singleInput"></ng-container>
  <!-- </ng-container> -->
  <ng-container *ngTemplateOutlet="commonButtons"></ng-container>
</ng-container>

<ng-container *ngIf="currentFilterData?.fieldType === 'Number'">
  <ng-container *ngTemplateOutlet="condition; context: { $implicit: currentFilterData }"></ng-container>

  <ng-container *ngIf="selectedCondition != 'is between'; else multipleInput">
    <ng-container *ngTemplateOutlet="singleInput"></ng-container>
  </ng-container>

  <ng-container *ngTemplateOutlet="commonButtons"></ng-container>
</ng-container>

<ng-container *ngIf="isDateFilter">
  <ng-container *ngTemplateOutlet="daterange"></ng-container>
</ng-container>

<!-- Condition -->
<ng-template #condition let-currentField>
  <div class="single-condition" *ngIf="currentField.condition.length == 1; else conditionList">
    Condition: <span>{{ selectedCondition }}</span>
  </div>
  <ng-template #conditionList>
    <mat-form-field class="input-condition-form-field" floatLabel="never">
      <mat-label>Condition:</mat-label>
      <mat-select
        #condition="ngModel"
        [(ngModel)]="selectedCondition"
        name="selectedCondition"
        (selectionChange)="conditionChanged(currentFilterData, selectedCondition)"
        panelClass="filter-condition-dropdown filter-new-dropdown"
        class="panel-filter-condition-dropdown"
      >
        <mat-option class="mat-option-drodown" *ngFor="let opsn of currentField.condition" [value]="opsn">
          {{ opsn }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-template>
</ng-template>
<!--// Condition  -->

<!--Date Range -->
<ng-template #daterange>
  <ul class="filter-data-list overflow-visible date-list-ul">
    <ng-container *ngFor="let option of filterOptionsList; let in = index">
      <li
        *ngIf="!isFilterFromChip || isFilterFromChip === option?.key"
        [ngClass]="
          !option.showMultiselectWithFilterOption ? 'theme-checkbox filter-checkbox border' : 'date-created-container'
        "
      >
        <mat-checkbox
          class="theme-checkbox filter-checkbox border"
          [checked]="option.conditionType && option.conditionType != 'Select'"
          [(ngModel)]="option.showMultiselectWithFilterOption"
          (click)="selectDateField($event, option, in)"
          >{{ option.displayKey }}</mat-checkbox
        >
        <ng-container *ngIf="option.showMultiselectWithFilterOption">
          <button
            disableRipple
            mat-stroked-button
            class="date-created-button"
            (click)="toggleRangeOptionPanel(option)"
            [matMenuTriggerFor]="daterangecreated"
          >
            <span class="date-created-button-selected-DateRange">
              <ng-container *ngIf="option?.conditionType != 'custom'; else customDate">
                {{
                  option?.conditionType == 'today'
                    ? 'Today'
                    : option?.conditionType == 'yesterday'
                    ? 'Yesterday'
                    : option?.conditionType == 'last7days'
                    ? 'Last 7 days'
                    : option?.conditionType == 'last30days'
                    ? 'Last 30 days'
                    : option?.conditionType == 'last90days'
                    ? 'Last 90 days'
                    : option?.conditionType == 'last180days'
                    ? 'Last 180 days'
                    : option?.conditionType == 'thisweek'
                    ? 'This week'
                    : option?.conditionType == 'thismonth'
                    ? 'This month'
                    : option?.conditionType == 'lastmonth'
                    ? 'Last month'
                    : option?.conditionType
                }}
              </ng-container>
              <ng-template #customDate>
                {{ option?.fromDate | date: 'dd/MM/yyyy' }}
                <ng-container *ngIf="option?.toDate"> - {{ option?.toDate | date: 'dd/MM/yyyy' }} </ng-container>
              </ng-template>
            </span>
            <span class="material-icons material-symbols-outlined arrow-icon">
              arrow_drop_down
            </span>
          </button>
          <mat-menu
            #daterangecreated="matMenu"
            class="data-dropdown-container-backdrop-panelclass parent-full-calendar"
          >
            <span
              class="click-wrapper"
              [ngClass]="{
                'full-calendar': customDateRange && selectedCondition == 'is between',
                'single-calendar': customDateRange && selectedCondition != 'is between'
              }"
              (click)="stopPropagationFunction($event)"
            >
              <div class="field-dropdown-container">
                <div class="data-dropdown-container">
                  <div class="dropdown-dates">
                    <ul class="date-list" *ngIf="option.isDateRangeOptionPanelOpen">
                      <li *ngFor="let dateOption of dateOptionsList; let i = index">
                        <button
                          class="select-date-item"
                          [ngClass]="{
                            active: option.conditionType && option.conditionType === dateOption.value
                          }"
                          disableRipple
                          mat-stroked-button
                          (mouseout)="customDateRangeOut()"
                          (click)="onChangeCondition($event, dateOption, option, in)"
                        >
                          {{ dateOption.viewValue }}
                        </button>
                      </li>
                      <li>
                        <button
                          #childElement
                          class="select-date-item custom-date-range"
                          [ngClass]="{ active: customDateRange || option.conditionType.includes('-') }"
                          disableRipple
                          mat-stroked-button
                          (mouseover)="customDateRangeIn(option?.key, $event)"
                        >
                          Custom
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="dropdown-calendar" *ngIf="customDateRange">
                    <small class="dropdown-calendar-condition">
                      <ng-container *ngTemplateOutlet="condition; context: { $implicit: option }"></ng-container>
                    </small>
                    <div class="dropdown-calendar-body">
                      <div class="dropdown-calendar-body-col">
                        <!-- Date: FROM -->
                        <label *ngIf="selectedCondition === 'is between'">From</label>
                        <div class="date-input-range" (click)="stopPropagationFunction($event)">
                          <div class="date-input-range-col">
                            <input
                              matInput
                              readonly
                              class="dropdown-calendar-input"
                              placeholder="{{ fromDatePlaceholder }}"
                              class="dropdown-calendar-input"
                            />
                          </div>
                        </div>
                        <mat-calendar
                          class="cal-month-left"
                          [selected]="selectedStartDate"
                          [minDate]="
                            buildingBlock == ALL_ORDERS || buildingBlock == RETURN_NO_SCAN ? minStartDate : null
                          "
                          (selectedChange)="onSelectFromDate($event)"
                        ></mat-calendar>
                      </div>
                      <!-- Date: TO -->
                      <div *ngIf="selectedCondition === 'is between'" class="dropdown-calendar-body-col">
                        <label>To</label>
                        <div
                          class="date-input-range"
                          (click)="stopPropagationFunction($event)"
                          [matTooltipDisabled]="!dateToDisabled"
                          matTooltipClass="theme-tooltip set-todate-tooltip-position below"
                          matTooltipPosition="below"
                          matTooltip="To date must be after from date"
                        >
                          <div class="date-input-range-col no-padding">
                            <input
                              matInput
                              readonly
                              class="dropdown-calendar-input"
                              placeholder="{{ toDatePlaceholder }}"
                              class="dropdown-calendar-input"
                              [disabled]="dateToDisabled"
                              [ngModelOptions]="{ standalone: true }"
                            />
                          </div>
                        </div>
                        <mat-calendar
                          class="cal-month-left"
                          [selected]="selectedEndDate"
                          (selectedChange)="onSelectToDate($event)"
                          [maxDate]="buildingBlock == RETURN_NO_SCAN ? maxEndDate : null"
                        ></mat-calendar>
                      </div>
                    </div>
                    <!-- Return no scan 30 days validation -->
                    <mat-error
                      class="error-message date-range-error"
                      *ngIf="
                        buildingBlock == RETURN_NO_SCAN &&
                        selectedCondition === 'is between' &&
                        timeDifference > returnMaxDateRange
                      "
                    >
                      You can only view data for the last {{ returnMaxDateRange }} days. To access older data, please
                      use the export option.
                    </mat-error>

                    <!-- 180 days range error for "All orders" & "Return no scan" -->
                    <mat-error
                      class="error-message date-range-error"
                      *ngIf="
                        buildingBlock == ALL_ORDERS &&
                        selectedCondition === 'is between' &&
                        timeDifference > allowedDateRange
                      "
                    >
                      You can only view data for the last {{ allowedDateRange }} days. To access older data, please use
                      the <a class="underline" (click)="openExport()">export</a> option.
                    </mat-error>
                    <!-- 180 days range error for "All orders" & "Return no scan" -->
                    <div
                      class="dropdown-calendar-footer"
                      [ngClass]="{ 'single-calendar': selectedCondition != 'is between' }"
                    >
                      <div
                        class="range-text"
                        *ngIf="selectedCondition === 'is between' && timeDifference != '' && timeDifference >= 0"
                      >
                        Range:
                        <span class="dark"> {{ timeDifference }} {{ timeDifference < 2 ? 'Day' : 'Days' }}</span>
                      </div>
                      <mat-error class="error-message" *ngIf="selectedCondition === 'is between' && timeDifference < 0">
                        Please select valid date.
                      </mat-error>

                      <div class="button-block">
                        <div class="clear">
                          <button
                            mat-button
                            (click)="resetCalendar(in)"
                            color="primary"
                            class="theme-secondary-button"
                            [disableRipple]="true"
                          >
                            Clear
                          </button>
                        </div>
                        <div class="action">
                          <button
                            mat-button
                            [disabled]="
                              ((buildingBlock == ALL_ORDERS || buildingBlock == RETURN_NO_SCAN) &&
                                timeDifference > allowedDateRange) ||
                              (buildingBlock == RETURN_NO_SCAN && timeDifference > returnMaxDateRange) ||
                              (selectedCondition === 'is between' && timeDifference < 0) ||
                              (selectedCondition != 'is between' && !selectedStartDate)
                            "
                            color="primary"
                            class="theme-primary-button"
                            (click)="mapFilterObjectCustom(option)"
                            [disableRipple]="true"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </mat-menu>
        </ng-container>
      </li>
    </ng-container>
  </ul>
  <ng-container *ngTemplateOutlet="commonButtons"></ng-container>
</ng-template>
<!--// Date Range -->

<!-- Search input -->
<ng-template #search>
  <mat-form-field class="input-full-width-icon input-full-width-search-input input-search-icon" floatLabel="never">
    <mat-icon class="search-icon"> </mat-icon>
    <input
      matInput
      type="search"
      name="search"
      [(ngModel)]="searchTextSelect"
      class="input-icon"
      placeholder="Search"
      translate="placeholder"
    />

    <!-- (ngModelChange)="onSearchTextSelectChange(filterData)" -->
    <span *ngIf="searchTextSelect"
      ><img
        (click)="searchTextSelect = ''"
        src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross-btn.svg"
        alt="close"
    /></span>
  </mat-form-field>
</ng-template>
<!--// Search input  -->

<!-- Multiselect -->
<ng-template #multiselect>
  <!-- Options available -->
  <ul
    class="filter-data-list select-all-checkbox"
    *ngIf="
      filterOptions &&
      filterOptions.length > 1 &&
      (
        filterOptions
        | searchDropDownFilter
          : searchTextSelect
          : (currentFilterData.key === 'orderDetails.saleChannel' ||
            currentFilterData.key === 'shipmentDetails.facilityCode'
              ? 'label'
              : 'key')
      ).length > 0
    "
  >
    <li>
      <mat-checkbox
        class="theme-checkbox filter-checkbox select-all border"
        [checked]="currentFilterData.allSelected"
        [(ngModel)]="currentFilterData.allSelected"
        (click)="$event.stopPropagation()"
        (change)="$event ? masterToggle(currentFilterData.allSelected) : null"
        >Select all</mat-checkbox
      >
    </li>
  </ul>
  <ul class="filter-data-list" *ngIf="filterOptions && filterOptions.length > 0">
    <ng-container>
      <li
        *ngFor="
          let option of filterOptions
            | searchDropDownFilter
              : searchTextSelect
              : (currentFilterData.key === 'orderDetails.saleChannel' ||
                currentFilterData.key === 'shipmentDetails.facilityCode'
                  ? 'label'
                  : 'key');
          let i = index
        "
      >
        <mat-checkbox
          class="theme-checkbox filter-checkbox border"
          [checked]="option.checked"
          [(ngModel)]="option.checked"
          name="selectValIMS.{{ i }}"
          (ngModelChange)="selectedColumns(option.checked, option.key)"
          [value]="option.key"
          (click)="$event.stopPropagation()"
          matTooltip="{{ option.key.length > 19 ? option.key : '' }}"
          matTooltipPosition="above"
          [matTooltipClass]="'theme-tooltip above'"
        >
          <ng-container
            *ngIf="
              currentFilterData.key === 'orderDetails.saleChannel' ||
                currentFilterData.key === 'salesChannel' ||
                currentFilterData.key === 'shipmentDetails.facilityCode';
              else dynamicOptions
            "
          >
            {{ option.label | strFormatter | sentenceCase }}
          </ng-container>
          <ng-template #dynamicOptions>
            {{ option.key | changeLabel: currentFilterData.key | strFormatter | sentenceCase }}
          </ng-template>
        </mat-checkbox>
      </li>
    </ng-container>
    <ng-container
      *ngIf="
        searchTextSelect &&
        (
          filterOptions
          | searchDropDownFilter
            : searchTextSelect
            : (currentFilterData.key === 'orderDetails.saleChannel' ||
              currentFilterData.key === 'shipmentDetails.facilityCode'
                ? 'label'
                : 'key')
        ).length == 0
      "
    >
      <ng-container *ngTemplateOutlet="noOptionFound; context: { message: 'No search result found.' }"></ng-container>
    </ng-container>
  </ul>

  <!-- No options available -->
  <ng-container *ngIf="!filterOptions || filterOptions.length == 0">
    <ng-container
      *ngTemplateOutlet="noOptionFound; context: { message: 'No available options for this filter.' }"
    ></ng-container>
  </ng-container>
</ng-template>
<!--// Multiselect -->

<!-- Single Input Field -->
<ng-template #singleInput>
  <mat-form-field class="input-full-width-icon input-full-width-single-input" floatLabel="never">
    <input
      matInput
      [type]="currentFilterData.fieldType === 'NUMBER' ? 'number' : 'text'"
      name="singleInput"
      [(ngModel)]="currentFilterData.filterValue"
      class="input-icon"
      placeholder="Enter {{ currentFilterData.displayKey }}"
      translate="placeholder"
    />
    <img
      *ngIf="currentFilterData.filterValue"
      (click)="currentFilterData.filterValue = ''"
      src="https://ik.imagekit.io/2gwij97w0o/filter_icons/close_icon_filter.svg?updatedAt=1681108446693"
      class="clear-field"
    />
  </mat-form-field>
</ng-template>
<!--// Single Input Field -->

<ng-template #multipleInput>
  <!-- From: -->
  <span class="filter-label-field">From</span>
  <mat-form-field class="input-full-width-icon input-full-width-single-input" floatLabel="never">
    <input
      matInput
      [type]="currentFilterData.fieldType === 'NUMBER' ? 'number' : 'text'"
      name="search"
      [(ngModel)]="currentFilterData.fromDate"
      class="input-icon"
      placeholder="Enter {{ currentFilterData.displayKey }}"
      translate="placeholder"
    />
    <img
      *ngIf="currentFilterData.fromDate"
      (click)="currentFilterData.fromDate = ''"
      src="https://ik.imagekit.io/2gwij97w0o/filter_icons/close_icon_filter.svg?updatedAt=1681108446693"
      class="clear-field"
    />
  </mat-form-field>
  <span class="filter-label-field">To</span>
  <!-- To: -->
  <mat-form-field class="input-full-width-icon input-full-width-single-input" floatLabel="never">
    <input
      matInput
      [type]="currentFilterData.fieldType === 'NUMBER' ? 'number' : 'text'"
      name="search"
      [(ngModel)]="currentFilterData.toDate"
      class="input-icon"
      placeholder="Enter {{ currentFilterData.displayKey }}"
      translate="placeholder"
    />
    <img
      *ngIf="currentFilterData.toDate"
      (click)="currentFilterData.toDate = ''"
      src="https://ik.imagekit.io/2gwij97w0o/filter_icons/close_icon_filter.svg?updatedAt=1681108446693"
      class="clear-field"
    />
  </mat-form-field>
</ng-template>

<!-- Common Buttons -->
<ng-template #commonButtons>
  <div class="button-block filter-button-block">
    <div class="clear" (click)="onClearFilter(currentFilterData)">
      <span>
        <button
          [disabled]="
            (currentFilterData?.fieldType != 'DateRange' &&
              currentFilterData[0]?.fieldType != 'DateRange' &&
              (!currentFilterData?.filterValue || currentFilterData?.filterValue?.length == 0)) ||
            (currentFilterData?.fieldType === 'DateRange' && !currentFilterData?.conditionType) ||
            (currentFilterData[0]?.fieldType === 'DateRange' && !currentFilterData[0]?.conditionType)
          "
          class="done-btn"
        >
          Clear all
        </button>
      </span>
    </div>
    <div class="action">
      <span>
        <button
          [disabled]="
            (currentFilterData?.fieldType != 'DateRange' &&
              currentFilterData[0]?.fieldType != 'DateRange' &&
              (!currentFilterData?.filterValue || currentFilterData?.filterValue?.length == 0)) ||
            (currentFilterData?.fieldType === 'DateRange' && !currentFilterData?.conditionType) ||
            (currentFilterData[0]?.fieldType === 'DateRange' && !currentFilterData[0]?.conditionType)
          "
          class="done-btn"
          (click)="onApplyFilter(currentFilterData)"
        >
          Apply filter
        </button>
      </span>
    </div>
  </div>
</ng-template>
<!--// Common Buttons -->

<ng-template #noOptionFound let-message="message">
  <div class="no-search-result-found">
    <span class="no-search-icon"
      ><img src="https://ik.imagekit.io/2gwij97w0o/funnel-close.png?updatedAt=1722320143765" alt="no-search"
    /></span>
    <p>{{ message }}</p>
  </div>
</ng-template>

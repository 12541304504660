import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTESDATA } from 'src/app/constants/routes';
import { FilterHelperService } from 'src/app/filter-widget/service/filters-helper.service';
import { environment } from 'src/environments/environment';
import { WorkflowSettings } from '../../../building-blocks/containers/self-fulfilment-settings/workflowSettings.model';
import { AuthService } from '../../services';
import { DataStoreService } from '../../services/data-store.service';

@Component({
  selector: 'app-sub-navigation-bb',
  templateUrl: './sub-navigation-bb.component.html',
  styleUrls: ['./sub-navigation-bb.component.scss']
})
export class SubNavigationBbComponent implements OnInit {
  public isMobile: boolean = false;
  public currentFacilityLabel: string = '';
  public accountList = [];
  public flexAccountSlug: string = '';
  public subMenuSearchValue: string = '';
  public shouldFocus: any = false;
  public searchFc: string;
  public selectedOptionFC: string = '';
  public isBBLoading: boolean = false;
  public subNavigationParent: string = '';
  public subNavItems = [];
  public pickingMethod: string = '';
  public workflowSettings: WorkflowSettings;
  public handleBar: any = '';
  public shipmentSegmentArray: string[] = ['To pack', 'To manifest', 'On hold', 'All'];
  public manifestSegmentArray: string[] = ['To handover', 'To acknowledge', 'Handover done', 'Drafts', 'All'];
  public shortCutBB: any;
  public rotateArrow: boolean = false;
  public handleBarAlt: any = 'inventory';
  // public productVerificationNavigationButton: boolean = false;
  @ViewChild('searchField') searchField: ElementRef;
  public ROUTESDATA = ROUTESDATA;
  public tcnsNavItem: any = '';
  public currentBBDetails: any;
  public handlebarArray = {
    isInStock: 'inventory'
  };
  public queryparamsForStatic = [
    {
      url: '/product/product-verification',
      value: {
        isInStock: '',
        isStatic: true
      }
    }
  ];
  externalWarehouseId: any = '';
  public currentUrl: string = '';
  assetsMappings: any;
  manageInventoryMappings: any;
  subNavsToMap = {
    Shelfs: 'shelfs',
    Totes: 'totes',
    'Pigeon hole': 'pigeon_hole',
    'Staging area': 'staging_area',
    'Inventory snapshot': 'inventory_snapshot',
    'Weight and dimension verification': 'weight_and_dimension_verification',
    'Putaway inventory': 'putaway_inventory',
    'Shelf transfer': 'shelf_transfer',
    'Cycle count': 'cycle_count',
    'Mark found': 'mark_found'
  };
  selectedMappingType: any;

  constructor(
    private authService: AuthService,
    private _route: ActivatedRoute,
    private router: Router,
    private filterHelperService: FilterHelperService,
    public dataStoreService: DataStoreService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getCurrentWorkspaceFCs();
    this.isMobile = this.authService.isMobileDevice();
    this.workflowSettings = await this.dataStoreService.getSettings();
    this.pickingMethod = this.workflowSettings.pickingInfo.pickingMethod;
    if (this.pickingMethod && this.pickingMethod === 'skipPicking' && this.authService.isMobileDevice()) {
      this.router.navigate(['/login-using-desktop', 'skipPicking']);
      return;
    }
    this.handleBar = this._route.snapshot.queryParams.handleBar;
    if (this.handleBar == undefined) {
      let array = {};
      this._route.queryParams.subscribe(params => {
        array = params;
      });
      this.handleBar = this.handlebarArray[Object.keys(array)[0]];
      this.currentUrl = this.router.url;
    } else {
      this.currentUrl = '';
    }
    let buildingBlockDetailsList = localStorage.getItem('BuildingBlockDetails');
    this.currentBBDetails =
      buildingBlockDetailsList && JSON.parse(buildingBlockDetailsList).length > 0
        ? JSON.parse(buildingBlockDetailsList).filter(m => m.handleBar == this.handleBar)
        : [];
    this.getSubNavigationItems(this.currentBBDetails);
    // if (JSON.parse(localStorage.getItem('BuildingBlockDetails'))) this.setBbDetails();
    // else {
    //   this.updateBBDetails = this.buildingBlockService.getUpdateBBDetails.subscribe(data => {
    //     this.setBbDetails();
    //   });
    // }
  }

  ngAfterViewChecked() {
    if (this.shouldFocus && this.searchField) {
      this.searchField.nativeElement.focus();
    }
  }

  async getCurrentWorkspaceFCs() {
    const accountList: any = await this.authService.getAccountLists();
    this.setAccountDetails(accountList);
    this.externalWarehouseId = this.accountList?.find(
      user => user.accountSlug === this.flexAccountSlug
    ).externalWarehouseId;
  }

  async setAccountDetails(accountList) {
    const accountSlug = this.authService.getAccountSlug();
    this.accountList = accountList?.filter(
      x => x.parentClientWorkspaceSlug && x.parentClientWorkspaceSlug == accountSlug
    );
    this.flexAccountSlug = this.authService.getFlexSlug();
    this.currentFacilityLabel =
      this.accountList?.find(user => user.accountSlug === this.flexAccountSlug)?.facilityLabel ?? null;
  }

  async selectedFC(flexAccountSlug) {
    if (this.selectedOptionFC == flexAccountSlug) return;
    if (!window.location.host.includes('localhost')) this.isBBLoading = true;
    await this.authService.setFlexSlug(flexAccountSlug, true);
    localStorage.removeItem('BuildingBlockDetails');
    this.authService.loadInitialModule(false, true, flexAccountSlug);
  }

  detectChange(value) {
    this.shouldFocus = true;
    this.subMenuSearchValue = value;
  }

  openbuildingBlock(bbHandleBar, isSamePage?, navurl = '/building-blocks/view-record-list') {
    if (!navurl.includes('building-blocks')) {
      let param = this.queryparamsForStatic.filter(e => e.url == navurl)[0]['value'];
      param.isInStock = this.flexAccountSlug;
      this.router.navigate([navurl], {
        queryParams: param
      });
      return;
    }
    this.handleBar = bbHandleBar;
    this.filterHelperService.isCalledFromSidebar = true;
    let selectSegment;
    let segmentsData = JSON.parse(localStorage.getItem('segmentsData'));
    const allBBSegments = segmentsData?.filter(segment => {
      return segment[0] === 'BuildingBlocks';
    })[0];
    let allSegmentsforSelectedBuildingBlock: any = [];
    if (allBBSegments) {
      allSegmentsforSelectedBuildingBlock = allBBSegments[1].filter(segment => {
        return segment['buildingBlock'] === bbHandleBar;
      });
    }
    if (allBBSegments && allSegmentsforSelectedBuildingBlock.length > 0) {
      let filteredSegments;
      filteredSegments = allSegmentsforSelectedBuildingBlock.filter(seg => {
        if (this.pickingMethod === 'skipPicking' && bbHandleBar === 'shipment') {
          return this.shipmentSegmentArray.includes(seg.name) && seg.filterQuery !== '';
        }
        if (this.pickingMethod === 'deviceBased' && bbHandleBar === 'shipment') {
          return (!this.shipmentSegmentArray.includes(seg.name) || seg.name === 'All') && seg.filterQuery !== '';
        }
        if (bbHandleBar === 'manifests') {
          if (this.workflowSettings.fcType === 'client') {
            return this.workflowSettings?.manifestInfo?.manifestMethod?.includes('ScanAndManifest')
              ? this.manifestSegmentArray.includes(seg.name) && seg.filterQuery !== ''
              : this.manifestSegmentArray.includes(seg.name) && seg.name !== 'Drafts' && seg.filterQuery !== '';
          }
          if (this.workflowSettings.fcType === 'eshop') {
            return (!this.manifestSegmentArray.includes(seg.name) || seg.name === 'All') && seg.filterQuery !== '';
          }
        }
        return seg.buildingBlock === bbHandleBar && seg.filterQuery !== '';
      });
      selectSegment = filteredSegments.length > 0 ? filteredSegments[0].filterQuery : '';
      const queryParams = { handleBar: bbHandleBar, location: this.authService.getFlexSlug(), filter: selectSegment };
      const params = new URLSearchParams(queryParams);
      if (isSamePage) {
        this.router.navigate([navurl], { queryParams: queryParams });
      } else {
        const url =
          'https://' + this.authService.getAccountSlug() + environment.BASE_DOMAIN + navurl + '?' + params.toString();
        window.open(url, '_blank');
      }
    } else {
      const queryParams = { handleBar: bbHandleBar, location: this.authService.getFlexSlug() };
      const params = new URLSearchParams(queryParams);
      if (isSamePage) {
        this.router.navigate([navurl], { queryParams: queryParams });
      } else {
        const url =
          'https://' + this.authService.getAccountSlug() + environment.BASE_DOMAIN + navurl + '?' + params.toString();
        window.open(url, '_blank');
      }
    }
  }

  openbuildingBlockStatic(item) {}

  rotateArrowClick(e) {
    //e.stopPropagation();
    if (this.rotateArrow) {
      this.rotateArrow = false;
    } else {
      this.rotateArrow = true;
    }
  }

  isItemInMappings(item) {
    return Object.keys(this.subNavsToMap).includes(item.name);
  }

  setSubItemsMappings() {
    this.authService.getPlanMappingsData('settings.assets').subscribe(data => {
      this.assetsMappings = data;
      if (this.subNavigationParent === 'Assets') this.handleSubItemsMappings('assets');
    });
    this.authService.getPlanMappingsData('settings.inventory.manage_inventory').subscribe(data => {
      this.manageInventoryMappings = data;
      if (this.subNavigationParent === 'Manage inventory') this.handleSubItemsMappings('inventoryManagement');
    });
  }

  handleSubItemsMappings(mappingType) {
    this.selectedMappingType = mappingType === 'assets' ? this.assetsMappings : this.manageInventoryMappings;
    if (this.selectedMappingType) {
      this.subNavItems = this.subNavItems.map(item => {
        if (Object.keys(this.subNavsToMap).includes(item.name)) {
          const mappingKey = this.subNavsToMap[item.name];
          return {
            ...item,
            isVisible:
              item.name === 'Inventory snapshot'
                ? this.selectedMappingType?.inventory_snapshot?.page == 'show'
                : this.selectedMappingType?.[mappingKey] == 'show',
            hasAccess:
              item.name === 'Inventory snapshot'
                ? this.selectedMappingType?.inventory_snapshot?.page == 'show'
                : this.selectedMappingType?.[mappingKey] == 'show'
          };
        }
        return item;
      });
    }
  }

  getSubNavigationItems(currentBBDetails) {
    this.subNavItems = [];
    for (const key in this.ROUTESDATA) {
      const getParentName = this.ROUTESDATA[key].find((navItem: any) => navItem.URL === this.handleBar);
      if (getParentName && getParentName.parent != 'TCNS store returns') {
        this.subNavigationParent = getParentName.parent;
        if (Array.isArray(this.ROUTESDATA[key])) {
          if (
            this.subNavItems.length == 0 &&
            (this.subNavigationParent == 'Inventory report' ||
              this.subNavigationParent == 'Order report' ||
              this.subNavigationParent == 'Stock transfer' ||
              this.subNavigationParent == 'Return report')
          ) {
            this.subNavItems = this.ROUTESDATA[key].filter(
              (navItem: any) => navItem.urlType === 'FLEX' && navItem.URL === this.handleBar
            );
          } else if (this.subNavItems.length == 0)
            this.subNavItems = this.ROUTESDATA[key].filter(
              (navItem: any) => navItem.urlType === 'FLEX' && navItem.parent === this.subNavigationParent
            );
        }
      }
      const current = this.ROUTESDATA['orders'].find((navItem: any) => navItem.URL === this.handleBar);
      if (current && current.parent == 'TCNS store returns') {
        this.tcnsNavItem = current.name;
        this.subNavItems = this.ROUTESDATA['orders'].filter((navItem: any) => navItem.parent === 'Process return');
        break;
      } else if (this.tcnsNavItem) this.tcnsNavItem = '';
    }
    this.setSubItemsMappings();
  }
}

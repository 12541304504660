export const AUTH_DOMAINS = {
  AUTH0_DOMAIN: 'eshopbox-payment-reco.auth0.com', // Auth0 DOMAIN
  AUTH0_CUSTOM_DOMAIN: 'login.auperator.co' // LIVE || Auth0 CUSTOM DOMAIN
};

export const environment = {
  production: true,
  API_URL: 'auperator.co/api/v1',
  API_DOMAIN: '/api/',
  PLATFORM_API_URL: 'platform/api/v1',
  ACTIVITY_TOKEN: 'api/v1/account/token',
  ACTIVITY_API_URL: 'api/v1/activity',
  BUILDINGBLOCK_API_URL: 'buildingblock/api/v1/',
  APP_INSTALL: 'v1/scopes',
  APP_DETAILS_URI: 'v1/appdata/',
  STREAM_APP_KEY: 'rwtm3vk3ugke',
  STREAM_APP_ID: '74208',
  STREAM_FEED_GROUP: 'workspace_feed',
  PAYMENTS_API_URL: '.auperator.co/payments/api/v1',
  BASE_DOMAIN: '.auperator.co',
  COOKIE_DOMAIN: '.auperator.co',
  CLAIMS_API_URL: 'claims-service/api/v1/claims',
  DOMAIN: 'auth.auperator.co',
  CUSTOMER_PORTAL_DOMAIN: '.auperator.net/',
  INSTALLED_APP_LIST: 'v1/appinstall',
  CHARGEBEE: { RAZORPAY_API_KEY: 'rzp_test_5sgObMOJbTAX96' },
  pusher: {
    PUSHER_KEY: '19fbf37fe26e5c2b25c2',
    PUSHER_CLUSTER: 'ap2',
    CHANNEL: 'my-channel',
    APP_ID: '1216870',
    PUSHER_SECRET_KEY: '8220e9e873a5ab516585'
  },
  mapping_pusher: {
    APP_ID: '1885754',
    PUSHER_KEY: '77540464b595145e83a3',
    PUSHER_SECRET_KEY: '7abcd2622add31a186dd',
    PUSHER_CLUSTER: 'ap2'
  },
  pusher_payout_import: {
    pusher_import_event: 'payout-event',
    pusher_import_channel: 'payout-channel',
    PUSHER_KEY: '7da6456c719fd40b759e',
    PUSHER_CLUSTER: 'ap2'
  },
  pusher_import: {
    pusher_import_event: 'staging_import_job_event',
    pusher_import_channel: 'staging_import_job_channel'
  },
  pusher_export: {
    pusher_export_event: 'staging_export_job_event',
    pusher_export_channel: 'staging_export_job_channel'
  },
  pusher_schedule: {
    pusher_schedule_event: 'staging_export_job_event',
    pusher_schedule_channel: 'staging_export_job_channel'
  },
  pusher_segment: {
    pusher_segment_event: 'staging_segement_event',
    pusher_segment_channel: 'staging_segement_channel'
  },
  pusher_workflow_settings: {
    pusher_workflow_settings_event: 'staging_workflow_settings_event',
    pusher_workflow_settings_channel: 'staging_workflow_settings_channel'
  },
  pusher_bulk_labels: {
    pusher_bulk_labels_event: 'staging_bulk_labels_event',
    pusher_bulk_labels_channel: 'staging_bulk_labels_channel'
  },
  pusher_subscription_status: {
    pusher_subscription_status_event: 'staging_subscription_event',
    pusher_subscription_status_channel: 'staging_subscription_channel'
  },
  AUTH: {
    AUDIENCE: 'https://eshopbox-portal-dev.appspot.com',
    CLIENT_ID: 'TSH2TXx7Wvfx6hppIFfzl5cb1MGqv9Vp',
    CLIENT_SECRET: '4PcbqFDTbb4-eckEmXe3KhBzEf8OX6NjvBWi1zctxDcpYl_cbRMus_SOW3_7i3pe',
    DOMAIN: AUTH_DOMAINS.AUTH0_CUSTOM_DOMAIN,
    // REDIRECT: 'http://auth.auperator.co/auth/callback',
    REDIRECT: `${window.location.protocol}//${window.location.host}/auth/callback`,
    SCOPE: 'openid profile email',
    LOGOUT_URL: 'https://login.auperator.co/v2/logout?returnTo=https://auth.auperator.co/auth/login'
  },
  batchsettingAPI: 'https://eshopbox-portal-dev.el.r.appspot.com/_ah/api/esb/v1/batch/account',
  FILESTACK_APIKEY: 'AQ2ZyOPLSRSmn5ETQx29dz',
  FILE_BUCKET_PATH: 'http://storage.googleapis.com',
  algolia: {
    search_key: '844163cf3151d22544949a3037a30cde',
    id: 'testingJDWXFA92YD',
    product_index: 'product_service',
    channel_index: 'es_staging_test',
    order_return_index: 'es_staging_test',
    order_return_search_key: '844163cf3151d22544949a3037a30cde'

    //usealgoliadirectly: true,
  },
  intercom: {
    app_id: 'rii6vypc'
  },
  canny: {
    comapnyId: '64381af97a42a70960a3876e',
    redirect: 'https://eshopbox.canny.io/feature-requests'
  },
  riskscore: true,
  riskScoreSpace: ['quickstarttestvarun', 'testing_brand_party', 'farmely'],
  zohoBaseURL: 'https://zoho-middleware-dot-esb-platform-staging.appspot.com/_ah/api/esb/v1/',
  zohoDepartmentID: '124283000000010772',
  zohoDomain: 'https://desk.zoho.in',
  showRefreshApplicationPop: false
};

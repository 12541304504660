import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetCustomer } from 'src/app/auth/actions/auth.actions';
import * as fromSelector from 'src/app/auth/reducers/auth.selectors';
import { AuthService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
import { FeeDetails, fields, HostedPageResponse, location, team } from './add-more.modal';
@Component({
  selector: 'app-add-team-member',
  templateUrl: './add-team-member.component.html',
  styleUrls: ['./add-team-member.component.scss']
})
export class AddTeamMemberComponent implements OnInit {
  allMappings$: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authservice: AuthService,
    private store: Store<{ auth }>,
    private router: Router,
    public dialogRef: MatDialogRef<AddTeamMemberComponent>
  ) {}
  coustomerDeatails: any;
  estimateData: any;
  mappingData: any;
  private unsubscribe$ = new Subject<void>();
  isProceedLoader: boolean = false;
  getCustomerSubscription: Subscription;
  estimationPayload: any;
  public referralCode = '';
  dialogContent: fields;
  addCount: number = 1;
  addonCode: string;
  priceForOneLoding: boolean = false;
  addonItemId: string;
  addonPrice: string;
  zohoPlanId: any;
  priceForOne: any;
  addOncharge = {
    addonPrice: 0,
    totalTax: 0,
    totalCharges: 0
  };
  priceIsLoading: boolean = false;
  public currentDate = new Date();
  ngOnInit(): void {
    if (this.data.field == 'team') {
      this.dialogContent = team;
    } else {
      this.dialogContent = location;
    }
    this.priceForOneLoding = true;
    this.allMappings$ = this.authservice.getPlanMappingsData('').subscribe(data => {
      this.mappingData = data;

      this.mappingData.addons.forEach(element => {
        if (element.addonName == this.dialogContent.addonName) {
          this.addonItemId = element.zohoAddonId;
          this.addonCode = element.addonCode;
          this.addonPrice = element.addonPrice;
        }
      });
    });
    this.getCustomerSubscription = this.store.pipe(select(fromSelector.getCustomer)).subscribe(data => {
      if (data) {
        this.coustomerDeatails = data;
        this.getcharges();
      } else {
        this.store.dispatch(new GetCustomer());
      }
    });
  }
  increaseCount() {
    if (!this.priceIsLoading) {
      this.addCount += 1;
      this.getcharges();
    }
  }

  decreaseCount() {
    if (!this.priceIsLoading) {
      if (this.addCount >= 1) {
        this.addCount -= 1;
        this.getcharges();
      }
    }
  }

  onInputFocusOut(event: any) {
    if (this.addCount == null || this.addCount < 0 || this.addCount == 0) {
      this.addCount = 0;
    } else {
      this.getcharges();
    }
  }

  getcharges() {
    this.priceIsLoading = true;
    this.authservice
      .getEstimate(this.createEstimationPayload())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: FeeDetails) => {
        this.estimateData = res;

        let addonCharges = this.estimateData.plans.addOns[0];
        this.addOncharge.addonPrice = addonCharges.addOnPrice;
        this.addOncharge.totalTax = Number(
          parseFloat((addonCharges.addOnPrice * (addonCharges.addOnTaxPercentage / 100)).toString()).toFixed(2)
        );

        this.addOncharge.totalCharges = this.addOncharge.addonPrice + this.addOncharge.totalTax;
        if (this.addCount == 1) {
          this.priceForOne = this.estimateData.plans.addOns[0].addOnPrice;
          this.priceForOneLoding = false;
        }
        this.priceIsLoading = false;
      });
  }

  createEstimationPayload() {
    let customerId = this.coustomerDeatails.customer.customer_id;
    let payload = {
      customerId: customerId,
      lineItems: [
        {
          item_order: 1,
          item_id: this.mappingData.zohoPlanId,
          quantity: '1.00',
          hsn_or_sac: '',
          unit: '1'
        },
        {
          item_id: this.addonItemId,
          quantity: this.addCount
        }
      ],
      is_inclusive_tax: null,
      is_tcs_amount_in_percent: true,
      estimateType: 'new_subscription',
      can_charge_setup_fee_immediately: false,
      billingCycles: -1,
      coupon: null
    };
    this.estimationPayload = payload;
    return this.estimationPayload;
  }

  processPayment() {
    this.isProceedLoader = true;
    let currentSlug = this.authservice.getAccountSlug();
    let redirectionUrl = 'https://' + currentSlug + environment.BASE_DOMAIN + this.dialogContent.redirectEndPoint;

    let paymentPayload = {
      subscription_id: this.mappingData.subscriptionId,
      addons: [
        {
          addon_code: this.addonCode,
          quantity: this.addCount
        }
      ],
      redirect_url: redirectionUrl
    };
    this.authservice
      .toUpdateHostedSubscription(paymentPayload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: HostedPageResponse) => {
        this.isProceedLoader = false;
        this.dialogRef.close();
        window.location.href = res.hostedpage.url;
      });
  }
  ngOnDestroy() {
    this.allMappings$?.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

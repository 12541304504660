import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeLabel'
})
export class ChangeLabelPipe implements PipeTransform {
  transform(filterValue: string | string[], filterKey: string) {
    switch (filterKey) {
      case 'orderDetails.saleChannel':
      case 'salesChannel':
        return this.transformSalesChannel(filterValue);
      case 'shipmentDetails.facilityCode':
        return this.transformFacilityCode(filterValue);
      case 'paymentMethodDTO.isCOD':
      case 'orderDetails.isCod':
        return this.transformIsCOD(filterValue);
      case 'orderItemDetails.combo':
        return this.transformProductType(filterValue);

      default:
        if (Array.isArray(filterValue)) {
          return filterValue.map(value => this.transformDefault(value));
        } else {
          return this.transformDefault(filterValue);
        }
    }
  }

  private transformIsCOD(filterValue: string | string[]): string | string[] {
    const transformSingleValue = (value: string): string => {
      switch (value) {
        case '1':
          return 'Cash on delivery (COD)';
        case '0':
          return 'Prepaid';
        default:
          return value;
      }
    };

    if (Array.isArray(filterValue)) {
      return filterValue.map(transformSingleValue);
    } else {
      return transformSingleValue(filterValue);
    }
  }

  private transformProductType(filterValue: string | string[]): string | string[] {
    const transformSingleValue = (value: string): string => {
      switch (value) {
        case 'Yes':
          return 'Virtual Kit';
        default:
          return 'Base Product';
      }
    };

    if (Array.isArray(filterValue)) {
      return filterValue.map(transformSingleValue);
    } else {
      return transformSingleValue(filterValue);
    }
  }

  private transformDefault(filterValue: string): string {
    switch (filterValue.toLowerCase()) {
      case 'virtual_combo':
        return 'VIRTUAL_KIT';
      case 'expected':
        return 'Upcoming Return';
      case 'complete':
        return 'Return Completed';
      case 'shipped':
        return 'Fulfilled';
      case 'sidelined':
        return 'Sidelined(Unfulfillable)';
      case 'courier return':
        return 'Failed delivery return';
      case 'customer return':
        return 'Customer initiated return';
      default:
        return filterValue;
    }
  }

  private transformSalesChannel(filterValue: string | string[]) {
    const salesChannel = this.getSalesChannel();

    const transformSingleValue = (value: string): string => {
      const channel = salesChannel.find(x => x.externalChannelId === value || x.externalChannelId === value['key']);
      return channel ? channel.channelLabel : value;
    };

    if (Array.isArray(filterValue)) {
      return filterValue.map(transformSingleValue);
    } else {
      return transformSingleValue(filterValue);
    }
  }

  private transformFacilityCode(filterValue: string | string[]) {
    const locations = this.getFulfillmentCenters();

    const transformSingleValue = (value: string): string => {
      const location = locations.find(x => x.externalWarehouseId === value || x.externalWarehouseId === value['key']);
      return location ? location.warehouseName : value;
    };

    if (Array.isArray(filterValue)) {
      return filterValue.map(transformSingleValue);
    } else {
      return transformSingleValue(filterValue);
    }
  }

  private getSalesChannel(): any[] {
    const salesChannel = localStorage.getItem('salesChannel');
    return salesChannel ? JSON.parse(salesChannel) : [];
  }

  private getFulfillmentCenters(): any[] {
    const fulfillmentCenter = localStorage.getItem('fulfillmentCenter');
    return fulfillmentCenter ? JSON.parse(fulfillmentCenter) : [];
  }
}

<div class="otr-container-home-page">
  <ng-container>
    <div class="header-top-popup-otr">
      <div class="header-top_popup_innr">
        <div class="header-left-side">
          <span>
            <img [src]="iconSrc" alt="" />
          </span>
          <div class="heading-popup">
            <h2>{{ header }}</h2>
            <p>
              {{ para }}
            </p>
          </div>
        </div>
        <div class="header-right-side">
          <a class="connect_btn" *ngIf="showBtn" (click)="goToTickets()">View Ticket</a>
          <a class="close" (click)="closeTicketSnackbar()"
            ><img
              src="https://ik.imagekit.io/2gwij97w0o/Client_portal_frontend_assets/img/cross_icon.svg?updatedAt=1626337817356"
          /></a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { NgAisModule } from 'angular-instantsearch';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SafePipeModule } from 'safe-pipe';

// COMPONENTS
import { SortComponent } from '../components/sort/sort.component';
import { AddTeamMemberComponent } from '../plan/components/add-team-member/add-team-member.component';
import { GetFeatureComponent } from '../plan/components/get-feature/get-feature.component';
import { PlanModifyDialogComponent } from '../plan/components/plan-modify-dialog/plan-modify-dialog.component';
import { PlanNotificationComponent } from '../plan/components/plan-notification/plan-notification.component';
import { TicketNotificationComponent } from '../plan/components/ticket-notification/ticket-notification.component';
import { UpgradeProductPlanComponent } from '../plan/components/upgrade-product-plan/upgrade-product-plan.component';
import { LoaderUiComponent } from '../records/components/loader-ui/loader-ui.component';
import { BrowserUnsupportedComponent } from './component/browser-unsupported/browser-unsupported.component';
import { CustomAlertWithLinkComponent } from './component/custom-alert-with-link/custom-alert-with-link.component';
import { CustomDateRangeFilterComponent } from './component/custom-date-range-filter/custom-date-range-filter.component';
import { DateFilterComponent } from './component/date-filter/date-filter.component';
import { DownloadTemplateComponent } from './component/download-template/download-template.component';
import { ConfirmationDeletePopupComponent } from './component/export/confirmation-delete-popup/confirmation-delete-popup.component';
import { ExportColumnsComponent } from './component/export/export-columns/export-columns.component';
import { ExportDateRangeFiltersComponent } from './component/export/export-date-range-filters/export-date-range-filters.component';
import { ExportDateRangeComponent } from './component/export/export-date-range/export-date-range.component';
import { ExportInfoIconComponent } from './component/export/export-info-icon/export-info-icon.component';
import { ExportPortalFilterComponent } from './component/export/export-portal-filter/export-portal-filter.component';
import { ExportProductColumnsComponent } from './component/export/export-product-columns/export-product-columns.component';
import { ExportSalesChannelFilterComponent } from './component/export/export-sales-channel-filter/export-sales-channel-filter.component';
import { OrderMatchingComponent } from './component/export/order-matching-hover/order-matching.component';
import { OrderScheduleComponent } from './component/export/order-schedule/order-schedule.component';
import { SaveTemplateExportComponent } from './component/export/save-template-export/save-template-export.component';
import { ScheduleInfoIconComponent } from './component/export/schedule-info-icon/schedule-info-icon.component';
import { TemplateEditExportComponent } from './component/export/template-edit-export/template-edit-export.component';
import { FieldDetailsFormComponent } from './component/field-details-form/field-details-form.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { FilteredChipsComponent } from './component/filtered-chips/filtered-chips.component';
import { FiltersComponent } from './component/filters/filters.component';
import { ImportFileDialogComponent } from './component/import-file-dialog/import-file-dialog.component';
import { NewHeaderComponent } from './component/new-header/new-header.component';
import { OtpInputComponent } from './component/otp-input/otp-input.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ReusableFilterOptionsComponent } from './component/reusable-filter/reusable-filter-options/reusable-filter-options.component';
import { ReusableFilterComponent } from './component/reusable-filter/reusable-filter.component';
import { SegmentWithCountComponent } from './component/segment-with-count/segment-with-count.component';
import { SegmentsComponent } from './component/segment-with-count/segments/segments.component';
import { ServeErrorPageComponent } from './component/serve-error-page/serve-error-page.component';
import { SortingComponent } from './component/sorting/sorting.component';
import { AttachFilesComponent } from './component/table-element/attach-files/attach-files.component';
import { DeleteDialogComponent } from './component/table-element/delete-dialog/delete-dialog.component';
import { EditDialogComponent } from './component/table-element/edit-dialog/edit-dialog.component';
import { MultiValueWidgetComponent } from './component/table-element/multi-value-widget/multi-value-widget.component';
import { TableDataComponent } from './component/table-element/table-data/table-data.component';
import { UploadSheetComponent } from './component/upload-sheet/upload-sheet.component';
import { UploadStatusComponent } from './component/upload-status/upload-status.component';

// PIPES
import { TransformDatePipe } from '../_pipes/date-transform.pipe';
import { ActiveSegmentPipe } from '../filter-new/pipes/active-segment.pipe';
import { TitleCasePipe } from '../payments/containers/marketplace/Pipes/title-case.pipe';
import { ToStringPipe } from '../payments/containers/marketplace/Pipes/to-string.pipe';
import { FormatDateHasTimePipe } from '../records/utils/format-date-has-time.pipe';
import { JSBarcodePipe } from '../shared/pipes/jsbarcode.pipe';
import { Uniquearray } from '../shared/pipes/uniquearray.pipe';
import { ChangeLabelPipe } from './pipes/change-label.pipe';
import { ContainSpecificationPipe } from './pipes/contain-specification.pipe';
import { ConvertObjectToIteratorPipe } from './pipes/convert-object-to-iterator.pipe';
import { GetNameFromList } from './pipes/get-portal-name.pipe';
import { getPutawayCompletedItems } from './pipes/get-putaway-completed-items.pipe';
import { GetSum } from './pipes/get-sum.pipe';
import { ImageKitPipe } from './pipes/image-kit.pipe';
import { FilterLocationAccessPipe } from './pipes/location-access.pipe';
import { NumberTransformOrdinalsPipe } from './pipes/number-transform-ordinals.pipe';
import { NumericPipe } from './pipes/numeric.pipe';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { SpaceAfterComma } from './pipes/space-after-comma.pipe';
import { statusColorPipe } from './pipes/statusColor.pipe';
import { GetTruncatedName } from './pipes/string-operations.pipe';
import { StringFormatterPipe } from './pipes/stringFormatter.pipe';
import { TimePipe } from './pipes/time.pipe';
import { TruncateFilterPipe } from './pipes/truncate-filter-pipe.pipe';
import { SearchFcFilterPipe } from './utils/search-fc-filter.pipe';
import { SearchInDashoboardFilterPipe } from './utils/search-in-dashboard-filter.pipe';
import { searchDropDownFilter } from './utils/search-location-filter.pipe';
import { SkipPickingDisplayFieldNamePipe } from './utils/skip-picking-display-field-name.pipe';
//DIRECTIVES
import { NoEmojisDirective } from './directives/NoEmojis.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { TooltipDirective } from './directives/tooltip.directive';

//OTHERS
import { SearchLocationAppPipe } from '../../../src/app/shared/utils/search-location-app.pipe';
import { PageNotFoundComponent } from '../auth/components/page-not-found/page-not-found.component';
import { HelpTooltipComponent } from '../components/help-tooltip/help-tooltip.component';
import { InputWithChipsComponent } from '../components/input-with-chips/input-with-chips.component';
import { RichTooltipComponent } from '../components/rich-tooltip/rich-tooltip.component';
import { Configuration } from '../constants/config';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { CustomToastComponent } from './component/custom-toast/custom-toast.component';
import { SubNavigationBbComponent } from './component/sub-navigation-bb/sub-navigation-bb.component';
import { ProductdetailMissingPipe } from './pipes/productdetail-missing.pipe';

// import { TooltipModule } from 'ng2-tooltip-directive';
import { UpdateMissingProductPopupComponent } from './component/update-missing-product-popup/update-missing-product-popup.component';

import { InrFormatPipe } from '../payments/containers/marketplace/Pipes/inr-format.pipe';
import { ReusableTooltipModule } from '../reusable-tooltip/reusable-tooltip.module';
import { NewSegmentEffects } from './component/segment-with-count/store/effects/segment.effects';
import { SanitizeInputDirective } from './directives/sanitize-input.directive';
import { IndianCurrencyFormatPipe } from './pipes/indian-currency-format.pipe';

// import { TooltipModule } from 'ng2-tooltip-directive';

import { AppLocationSearchPipe } from './utils/app-location-search.pipe';
const modules = [
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MatRadioModule,
  MatProgressBarModule,
  MatAutocompleteModule,
  MatSliderModule,
  MatListModule,
  MatFormFieldModule,
  MatExpansionModule,
  MatButtonModule,
  MatChipsModule,
  MatCheckboxModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatTableModule,
  MatDialogModule,
  MatTooltipModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatMenuModule,
  MatTableModule,
  MatIconModule,
  MatInputModule,
  MatCardModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  RouterModule,
  NgxMatSelectSearchModule,
  SafePipeModule,
  NgxSkeletonLoaderModule,
  ScrollingModule,
  // StoreModule.forFeature('segment', SegmentReducer),
  EffectsModule.forFeature([NewSegmentEffects]),
  ReusableTooltipModule
];
const cmps = [
  SortComponent,
  FileUploadComponent,
  FiltersComponent,
  PaginationComponent,
  UploadSheetComponent,
  DownloadTemplateComponent,
  UploadStatusComponent,
  DateFilterComponent,
  SortingComponent,
  FilteredChipsComponent,
  ServeErrorPageComponent,
  BrowserUnsupportedComponent,
  ImportFileDialogComponent,
  TableDataComponent,
  AttachFilesComponent,
  EditDialogComponent,
  DeleteDialogComponent,
  ConfirmationDeletePopupComponent,
  OtpInputComponent,
  FieldDetailsFormComponent,
  MultiValueWidgetComponent,
  SaveTemplateExportComponent,
  TemplateEditExportComponent,
  ExportDateRangeComponent,
  ExportColumnsComponent,
  OrderScheduleComponent,
  ExportInfoIconComponent,
  ExportProductColumnsComponent,
  ExportDateRangeFiltersComponent,
  CustomDateRangeFilterComponent,
  OrderMatchingComponent,
  ExportSalesChannelFilterComponent,
  ExportPortalFilterComponent,
  RichTooltipComponent,
  HelpTooltipComponent,
  LoaderUiComponent,
  PageNotFoundComponent,
  HelpTooltipComponent,
  BreadcrumbComponent,
  ContainSpecificationPipe,
  InputWithChipsComponent,
  NewHeaderComponent,
  SegmentWithCountComponent,
  SegmentsComponent,
  ReusableFilterComponent,
  ReusableFilterOptionsComponent,
  CustomToastComponent,
  GetFeatureComponent,
  PlanModifyDialogComponent,
  TicketNotificationComponent,
  PlanNotificationComponent,
  AddTeamMemberComponent,
  UpgradeProductPlanComponent,
  UpdateMissingProductPopupComponent,
  SubNavigationBbComponent
];
const pipes = [
  Uniquearray,
  TransformDatePipe,
  GetNameFromList,
  GetSum,
  NumericPipe,
  StringFormatterPipe,
  TimePipe,
  NumberTransformOrdinalsPipe,
  SentenceCasePipe,
  SpaceAfterComma,
  getPutawayCompletedItems,
  ScheduleInfoIconComponent,
  ConvertObjectToIteratorPipe,
  SearchLocationAppPipe,
  SearchInDashoboardFilterPipe,
  searchDropDownFilter,
  SearchFcFilterPipe,
  SearchInDashoboardFilterPipe,
  JSBarcodePipe,
  SkipPickingDisplayFieldNamePipe,
  FormatDateHasTimePipe,
  SearchLocationAppPipe,
  FilterLocationAccessPipe,
  statusColorPipe,
  FilterLocationAccessPipe,
  statusColorPipe,
  SearchLocationAppPipe,
  InrFormatPipe,
  ToStringPipe,
  TitleCasePipe,
  ActiveSegmentPipe,
  FilterLocationAccessPipe,
  ToStringPipe,
  ActiveSegmentPipe,
  statusColorPipe,
  ChangeLabelPipe,
  TruncateFilterPipe,
  ContainSpecificationPipe,
  ImageKitPipe,
  GetTruncatedName,
  TitleCasePipe,
  ProductdetailMissingPipe,
  AppLocationSearchPipe,
  IndianCurrencyFormatPipe
];
const drvs = [NumberOnlyDirective, TooltipDirective, NoEmojisDirective, SanitizeInputDirective];

@NgModule({
  declarations: [...cmps, ...pipes, ...drvs, CustomAlertWithLinkComponent],
  imports: [...modules, NgAisModule, ScrollingModule, MatSnackBarModule],
  providers: [
    Configuration,
    GetSum,
    getPutawayCompletedItems,
    searchDropDownFilter,
    SkipPickingDisplayFieldNamePipe,
    FormatDateHasTimePipe,
    FilterLocationAccessPipe,
    TimePipe
  ],
  entryComponents: [
    EditDialogComponent,
    DeleteDialogComponent,
    SaveTemplateExportComponent,
    TemplateEditExportComponent,
    ConfirmationDeletePopupComponent,
    InputWithChipsComponent,
    CustomToastComponent
  ],
  exports: [...cmps, ...pipes, ...drvs, NgxSkeletonLoaderModule, ReusableTooltipModule]
})
export class SharedModule {}

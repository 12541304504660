import { TooltipOptions } from 'src/app/reusable-tooltip/tooltip-options.service';

type FieldTypes = 'DATE_PICKER' | 'DROPDOWN' | 'SHORT_TEXT' | 'NUMBER' | 'BOOLEAN';

// Declare segment module (buildingBlock) name here
export enum SegmentModuleName {
  ALL_ORDERS = 'all-orders',
  RETURN_NO_SCAN = 'return-no-scan'
}

export interface Segment {
  id: string;
  name: string;
  buildingBlock: string;
  isCore: boolean;
  filterQuery: string;
  filters?: string[];
  group?: string;
  iconType?: string;
  description?: string;
  version: string;
  dateRangeFilters: string[];
  primaryFilters: string[];
  moreFilters: string[];
  showCount: boolean;
  smartSegmentIds?: string;
  displayColumns?: (string | any)[];
  createdAt: string;
  updatedAt: string;
  viewType: string;
}

export interface Filter {
  displayKey: string;
  condition: string[];
  fieldType: FieldTypes;
  key: string;
  buildingBlock: string;
  options?: (string | any)[];
  id?: string;
}

interface Loader {
  loading: boolean;
  loaded: boolean;
}

export interface Segments extends Loader {
  segment: Segment;
}

export interface Count extends Loader {
  count: any;
}

export interface Filters extends Loader {
  filters: Filter[];
}

export const DRAFT_RETURN_DESCRIPTION = `<div class='newsegment_tooltip_main'>
  <p class='descritpion_heading'>Retuns received that are not yet created as returns.</p>
  <p class='text_bold'>You can perform a quality check and restock inventory, but you need to create the return by mapping the order in your workspace. <a class='hyperlink' href='https://help.eshopbox.com/en/articles/6014473-returns-overview-faqs#h_9c215f91f9' target='_blank'>Learn more</a></p>
</div>
`;

export const TOOLTIP_CONFIG: TooltipOptions = {
  showDelay: 1400,
  hideDelay: 300,
  contentType: 'html'
};

export const GET_FEATURE_TOOLTIP: string = `<div class="get-feature-tooltip">
<p>
 This feature is not available in your current plan. Upgrade your plan to get this feature.<span>
 <a onclick=  "document.getElementById('get-feature').click(); return false;"> Get feature</a> </span>
</p>

</div>`;

export const TOOLTIP_CONFIG_FEATURE: TooltipOptions = {
  showDelay: 100,
  hideDelay: 100,
  contentType: 'html'
};

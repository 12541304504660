import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { OrdersServiceV2 } from 'src/app/orderV2/serviceV2/orders.service';
import {
  DRAFT_RETURN_DESCRIPTION,
  Segment,
  SegmentModuleName,
  TOOLTIP_CONFIG
} from 'src/app/shared/models/segment-with-count.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NewSegmentService } from 'src/app/shared/services/segment-with-count/new-segment.service';
import {
  GetAllOrderSegmentCount,
  GetNewSegmentsData,
  GetNoScanReturnSegmentCount
} from '../store/actions/segment.actions';
import {
  getAllOrdersCountData,
  getNoScanReturnSegmentCountData,
  getSegmentsData
} from '../store/selectors/segment.selectors';
import { NoScanReturnService } from 'src/app/no-scan-return/no-scan-return.service';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit {
  @Input() buildingBlock: string;
  @Output() emitSearchKey = new EventEmitter<any>();

  // Component properties

  mappingObj: any;
  segMapping = {
    Unfulfillable: 'show'
  };
  currentSegmentData = [];
  myView = { data: [], count: 0 };
  allSegments = [];
  activeSegmentId: string;
  activeSmartSegmentId: string;
  currentSmartSegments = [];
  isSegmentLoading = true;
  isSegmentCountLoading = true;
  isSmartSegmentCountLoading = true;
  segmentSubscription$: Subscription;
  countSubscription$: Subscription;
  listingCurrentData$: Subscription;
  segmentsCount: any;
  groupedData: { [key: string]: any[] } = {};
  loadSmartSegment = false;
  dataCount: number;
  defaultMyView: number = 13;
  page: number = 1;
  groupedSmartSegment: { [key: string]: any[] } = {};
  isSegmentCreated$: Subscription;
  newSegmentCreated: boolean;
  private _queryFilterData = new BehaviorSubject<any>(null);
  searchQuery: any;
  TOOLTIP_CONFIG = TOOLTIP_CONFIG;
  allOrdersMappings$: Subscription;

  @Input()
  set queryFilterData(value) {
    this._queryFilterData.next(value);
  }
  get queryFilterData() {
    return this._queryFilterData.getValue();
  }

  // Module/building block names
  ALL_ORDERS = SegmentModuleName.ALL_ORDERS;
  RETURN_NO_SCAN = SegmentModuleName.RETURN_NO_SCAN;
  DRAFT_RETURN_DESCRIPTION: string = DRAFT_RETURN_DESCRIPTION;

  constructor(
    private store: Store<{ segmentState }>,
    private orderServiceV2: OrdersServiceV2,
    private router: Router,
    private newSegmentService: NewSegmentService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private noScanReturnService: NoScanReturnService
  ) {}

  ngOnInit(): void {
    this.getSegmentData();
    this.getListingTotalCount();
    this.segmentCreatedEvent();
    this.getExistingFiltersOnReload();
    this.allOrdersMappings$ = this.auth.getPlanMappingsData('orders.all_orders').subscribe(data => {
      this.mappingObj = data;
      this.segMapping.Unfulfillable = this.mappingObj.unfulfillable_segment;
    });
  }

  public getExistingFiltersOnReload() {
    this._queryFilterData.subscribe(async data => {
      if (data) {
        const sQuery = this.searchQuery;
        this.searchQuery = data['query'] ? data['query'] : '';
        if (sQuery && !this.searchQuery) {
          this.showActiveSegment();
        }
      } else this.searchQuery = null;
    });
  }

  segmentCreatedEvent() {
    this.isSegmentCreated$ = this.newSegmentService.getSegmentEvent.subscribe(data => {
      if (data) {
        this.newSegmentCreated = data.created;
        if (this.newSegmentCreated) {
          setTimeout(() => {
            this.newSegmentCreated = false;
          }, 4000);
        }

        const index = this.currentSegmentData.findIndex(x => x.id === data.segment.id);
        switch (data.action) {
          case 'create':
            this.currentSegmentData.push(data.segment);
            break;
          case 'update':
            this.currentSegmentData[index] = data.segment;
            const indexMyView = this.groupedSmartSegment['defaultSubGroup']['defaultGroup'].findIndex(
              x => x.id === data.segment.id
            );
            this.groupedSmartSegment['defaultSubGroup']['defaultGroup'][indexMyView] = data.segment;
            break;
          default:
            this.currentSegmentData.splice(index, 1);
            let loadLastView = this.myView.data[this.myView.data.length - 1];
            if (this.myView.data.length <= 1) {
              switch (this.buildingBlock) {
                case this.ALL_ORDERS:
                  loadLastView = this.currentSegmentData.find(x => x.name == 'Pending to pack');
                  break;
                case this.RETURN_NO_SCAN:
                  loadLastView = this.currentSegmentData.find(x => x.name == 'Awaiting pickup');
                  break;
              }
            }
            this.emitSegmentsData(loadLastView, false);
            break;
        }
        this.getMyViewDetails();
      }
    });
  }

  // Fetches listing total data
  getListingTotalCount(): void {
    this.listingCurrentData$ = this.newSegmentService.listingCurrentData.subscribe(data => {
      if (data === 'syncCount') {
        this.segmentsCount = {};
        this.loadSmartSegment = false;
        this.fetchSegmentCount('segment', true);
      } else {
        const filterNotApplied = this.isFilterApplied();
        if (filterNotApplied) {
          this.updateDataCount(+data);
        }
      }
    });
  }

  isFilterApplied(): boolean {
    const id = this.activeSmartSegmentId ? this.activeSmartSegmentId : this.activeSegmentId;
    const segmentDetails = this.getDetailById(id);
    const queryParam = this.route?.snapshot?.queryParams;

    if (segmentDetails && queryParam) {
      // Keys to exclude from the check
      const excludeKeys = ['selectedView', 'page', 'perPage', 'index'];

      // Extract relevant keys from queryParam
      const relevantKeys = Object.keys(queryParam).filter(key => !excludeKeys.includes(key));

      // Check if each relevant key and its value exist in filterQuery
      for (let key of relevantKeys) {
        // Build the expected string pattern to find in filterQuery
        const expectedPattern = `${key}`;

        if (!segmentDetails?.filterQuery.toLowerCase().includes(expectedPattern?.toLowerCase())) {
          return false;
        }
      }
    }
    // If all keys and values match, return true
    return true;
  }

  // Updates segment data count
  updateDataCount(newCount: number): void {
    const id = this.activeSmartSegmentId ? this.activeSmartSegmentId : this.activeSegmentId;
    const isInvalidCondition =
      (!this.activeSmartSegmentId && !this.activeSegmentId) ||
      !this.segmentsCount ||
      Object.keys(this.segmentsCount).length === 0;

    if (isInvalidCondition) {
      return;
    }
    this.updateCountById(id, newCount);
  }

  // Fetches segment data from store
  getSegmentData(): void {
    this.segmentSubscription$ = this.store.pipe(select(getSegmentsData)).subscribe(response => {
      this.isSegmentLoading = response.loading;
      if (response?.segment && response.loaded) {
        this.currentSegmentData = response?.segment['data'].filter(
          segment => segment.buildingBlock === this.buildingBlock
        );
        this.allSegments = this.currentSegmentData.filter(segment => segment.group);
        this.getMyViewDetails();
        this.showActiveSegment();
        this.groupDataByGroup();
      } else {
        if (!response.segment && !response.loading && !response.loaded) {
          this.store.dispatch(new GetNewSegmentsData());
        }
      }
    });
  }

  // Retrieves current smart segment details
  getcurrentSmartSegment(updateSmartSegment: boolean): void {
    const currentSegment = this.getDetailById(this.activeSegmentId);
    let smartSegment = [];
    if (currentSegment && currentSegment?.smartSegmentIds) {
      smartSegment = currentSegment.smartSegmentIds.map(id => this.getDetailById(id));
    }
    this.currentSmartSegments = smartSegment;
    if (smartSegment.length > 0) {
      if (updateSmartSegment) {
        this.activeSmartSegmentId = this.currentSmartSegments[0]?.id;
      }
      this.dataCount = smartSegment.length;
    } else {
      this.activeSmartSegmentId = '';
    }
    this.groupSmartSegment();
    this.emitCurrentSegmentData();
  }

  // Groups smart segments
  groupSmartSegment(): void {
    const subGroupMap = new Map<
      string,
      { defaultGroup: Segment[]; needAttentionGroup: Segment[]; allGroup: Segment[] }
    >();
    const defaultSubGroupKey = 'defaultSubGroup';

    let distinctSubGroups = new Set<string>();

    // Collect segments into their respective subGroups
    this.currentSmartSegments.forEach(segment => {
      const subGroup = segment.subGroup || defaultSubGroupKey;

      if (segment.subGroup) {
        distinctSubGroups.add(segment.subGroup);
      }

      if (!subGroupMap.has(subGroup)) {
        subGroupMap.set(subGroup, {
          defaultGroup: [],
          needAttentionGroup: [],
          allGroup: []
        });
      }

      const groups = subGroupMap.get(subGroup);

      if (segment.name !== 'All' && (!segment.iconType || segment.iconType === '')) {
        groups.defaultGroup.push(segment);
      } else if (segment.iconType) {
        groups.needAttentionGroup.push(segment);
      } else {
        if (groups.defaultGroup.length === 0) {
          groups.defaultGroup.push(segment);
        } else {
          groups.allGroup.push(segment);
        }
      }
    });

    // If there's only one subGroup, move defaultSubGroup contents into it
    if (distinctSubGroups.size === 1) {
      const singleSubGroup = [...distinctSubGroups][0];
      const defaultGroups = subGroupMap.get(defaultSubGroupKey);
      const targetGroups = subGroupMap.get(singleSubGroup);

      if (defaultGroups && targetGroups) {
        for (const groupKey in defaultGroups) {
          targetGroups[groupKey].push(...defaultGroups[groupKey]);
        }
      }

      subGroupMap.delete(defaultSubGroupKey);
    }

    const filteredGroups = {};
    subGroupMap.forEach((value, key) => {
      const filteredSubGroups = {};
      for (const groupKey in value) {
        if (value[groupKey].length > 0) {
          filteredSubGroups[groupKey] = value[groupKey];
        }
      }
      if (Object.keys(filteredSubGroups).length > 0) {
        filteredGroups[key] = filteredSubGroups;
      }
    });

    this.groupedSmartSegment = filteredGroups;
  }

  // Emits current segment data
  emitCurrentSegmentData(): void {
    let currentSegmentData: Segment;
    if (!this.activeSmartSegmentId && !this.activeSegmentId) {
      switch (this.buildingBlock) {
        case this.ALL_ORDERS:
          currentSegmentData = this.getDetailById('6661829b56454838ecb9d931');
          break;
      }
    } else {
      currentSegmentData = this.activeSmartSegmentId
        ? this.getDetailById(this.activeSmartSegmentId)
        : this.getDetailById(this.activeSegmentId);
    }
    this.newSegmentService.emitCurrentSegement(currentSegmentData);
  }

  // Retrieves my view details
  getMyViewDetails(): void {
    let myView: any = this.currentSegmentData.filter(segment => !segment.isCore);
    if (myView.length > 0) {
      myView = myView.filter((value, index, self) => index === self.findIndex(obj => obj.id === value.id));
      this.myView.data = myView;
      this.myView.count = myView.length;
    }
  }

  // Emits segments data
  emitSegmentsData(segment, isSegment): void {
    this.emitSearchKey.emit('');
    if (isSegment) {
      this.loadSmartSegment = true;
      this.activeSegmentId = segment.id;
      this.getcurrentSmartSegment(true);
      this.fetchSegmentCount('segment', false);
    } else {
      this.activeSmartSegmentId = segment.id;
      if (!this.activeSegmentId) {
        if (segment?.isCore) {
          const segmentDetails = this.currentSegmentData.find(
            x => x.smartSegmentIds && x.smartSegmentIds.includes(this.activeSmartSegmentId)
          );
          this.activeSegmentId = segmentDetails.id;
        } else this.activeSegmentId = 'myView';
      }
      this.emitCurrentSegmentData();
    }

    this.redirectOnSegmentChange();
  }

  // Loads my view
  loadMyView(isAutoLoad, isViewMore = false): void {
    this.activeSegmentId = 'myView';
    if (this.myView.count === 0) {
      return;
    }
    this.currentSmartSegments = this.myView.data;

    // Calculate the total data count based on the current page
    this.dataCount = Math.min(this.myView.count, this.page * this.defaultMyView);

    // Check if the activeSmartSegmentId exists in currentSmartSegments
    if (!this.currentSmartSegments.some(seg => seg.id === this.activeSmartSegmentId)) {
      const currentView = this.myView.data[0];
      this.activeSmartSegmentId = currentView?.id;
    }

    this.emitCurrentSegmentData();
    this.groupSmartSegment();
    if (!isAutoLoad) {
      if (!isViewMore) {
        this.loadSmartSegment = true;
        this.redirectOnSegmentChange();
      }
      this.fetchSegmentCount(this.activeSegmentId, false);
    } else this.getSegmentCount(this.activeSegmentId, true);
  }

  // Fetches segment count
  getSegmentCount(type, isInital): void {
    let currentSelector: any;
    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        currentSelector = getAllOrdersCountData;
        break;
      case this.RETURN_NO_SCAN:
        currentSelector = getNoScanReturnSegmentCountData;
        break;
    }

    this.countSubscription$ = this.store.pipe(select(currentSelector)).subscribe(response => {
      if (isInital) {
        if (!this.loadSmartSegment) {
          this.isSegmentCountLoading = response.loading;
        }
        this.isSmartSegmentCountLoading = response.loading;
      } else {
        this.isSmartSegmentCountLoading = response.loading;
        this.isSegmentCountLoading = false;
      }
      if (response?.count && response.loaded) {
        if (Object.keys(response?.count).length !== 0) {
          this.segmentsCount = response?.count;
        }
      } else {
        if (!response.count && !response.loading && !response.loaded) {
          this.fetchSegmentCount(type, isInital);
        }
      }
    });
  }

  // Retrieves segment count query
  getSegmentCountQuery(type, isInitial): any {
    let countQuery;

    if (type === 'myView') {
      // Combining the segments based on whether it's the initial load or not
      let initialMyBiew = this.myView.data;
      // Apply pagination logic to get the segments for the current page
      const startIndex = (this.page - 1) * this.defaultMyView;
      const endIndex = startIndex + this.defaultMyView;
      initialMyBiew = initialMyBiew.slice(startIndex, endIndex);
      const segmentsToCheck = isInitial ? [...this.allSegments, ...initialMyBiew] : initialMyBiew;

      // Filter and reduce to create the countQuery
      countQuery = segmentsToCheck
        .filter(seg => seg?.showCount && seg.filterQuery)
        .reduce((acc, item) => {
          if (item.filterQuery) {
            acc[item.id] =
              item.filterQuery.includes('last30days') || item.filterQuery.includes('last180days')
                ? this.replaceWithDate(item.filterQuery)
                : item.filterQuery;
          }
          return acc;
        }, {} as { [key: string]: string });
    } else {
      const segmentsToCheck = isInitial
        ? [...this.allSegments, ...this.currentSmartSegments]
        : this.currentSmartSegments;
      countQuery = segmentsToCheck
        .filter(seg => seg?.showCount && seg.filterQuery)
        .reduce((acc, item) => {
          let modifiedQuery = item.filterQuery;
          if (modifiedQuery.includes('location=currentLocation')) {
            // Perform the replacement
            modifiedQuery = modifiedQuery.replace(/location=currentLocation/, `location=${this.auth.getFlexSlug()}`);
          }

          acc[item.id] =
            modifiedQuery.includes('last30days') || modifiedQuery.includes('last180days')
              ? this.replaceWithDate(modifiedQuery)
              : modifiedQuery;
          return acc;
        }, {} as { [key: string]: string });
    }

    if (this.segmentsCount && Object.keys(this.segmentsCount).length !== 0) {
      for (const key in this.segmentsCount) {
        if (this.segmentsCount.hasOwnProperty(key)) {
          if (countQuery.hasOwnProperty(key)) {
            delete countQuery[key];
          }
        }
      }
    }
    return countQuery;
  }

  replaceWithDate(filterQuery) {
    const getStartDate = daysAgo =>
      new Date(
        moment()
          .subtract(daysAgo, 'days')
          .startOf('day')
          .valueOf()
      ).valueOf();
    const toDate = new Date(
      moment()
        .endOf('day')
        .valueOf()
    ).valueOf();
    const today = new Date(
      moment()
        .startOf('day')
        .valueOf()
    ).valueOf();

    if (this.buildingBlock == 'return-no-scan') {
      const replacements = {
        last30days: `last30Days-${getStartDate(29)}TO${toDate}`,
        last180days: `last180days-${getStartDate(179)}TO${toDate}`,
        today: `${today}TO${toDate}`
      };
      Object.keys(replacements).forEach(key => {
        filterQuery = filterQuery.replace(key, replacements[key]);
      });

      return filterQuery;
    } else {
      const replacements = {
        last30days: getStartDate(29),
        last180days: getStartDate(179),
        today: today,
        toDate: toDate
      };

      return filterQuery.replace(/last30days|last180days|today|toDate/g, match => replacements[match]);
    }
  }

  /**@desciption Open Order Export Dialog */
  public openDraftReturnDialog() {
    this.orderServiceV2.openDraftReturnDialog(false);
  }

  // Group segment based on group key and sort using sortIndex key
  groupDataByGroup() {
    const sortedOrders = this.allSegments.sort((a, b) => a.sortIndex - b.sortIndex);

    const groupedOrders = {};

    sortedOrders.forEach(segment => {
      if (!groupedOrders[segment.group]) {
        groupedOrders[segment.group] = [];
      }
      groupedOrders[segment.group].push(segment);
    });

    this.groupedData = groupedOrders;
    // Handling to hide draft return flow(without order id) for all workspace accept Godavari.product
    let workspaceSlug = this.auth.getAccountSlug();
    if (
      this.buildingBlock == this.RETURN_NO_SCAN &&
      !this.noScanReturnService.workspaceAllowListForDraftReturn.includes(workspaceSlug)
    ) {
      let index = this.groupedData['Received'].findIndex(x => x.name == 'Draft returns');
      this.groupedData['Received'].splice(index, 1);
    }
  }

  // Fetch segment counts
  fetchSegmentCount(type, isInital) {
    // Set loading state based on isInital flag
    if (isInital) {
      this.isSegmentCountLoading = true;
    } else {
      this.isSmartSegmentCountLoading = true;
    }

    // Get filter query
    const filterQuery = this.getSegmentCountQuery(type, isInital);

    // Check if filterQuery is not an empty object
    if (Object.keys(filterQuery).length !== 0) {
      const payload = { filterQuery, operation: isInital ? 'replace' : 'add', countData: this.segmentsCount };
      this.dispatchAction(payload);
    } else {
      // Reset loading state if no dispatch
      if (isInital) {
        this.isSegmentCountLoading = false;
      } else {
        this.isSmartSegmentCountLoading = false;
      }
    }
  }

  // Dispatch action as per current building block/page
  dispatchAction(payload) {
    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        this.store.dispatch(new GetAllOrderSegmentCount(payload));
        break;
      case this.RETURN_NO_SCAN:
        this.store.dispatch(new GetNoScanReturnSegmentCount(payload));
        break;
    }
  }

  // Get segment/ smart segment details by Id
  getDetailById(segmentId) {
    return this.currentSegmentData.find(segment => segment.id === segmentId);
  }

  syncCount() {
    this.getSegmentCount('segment', true);
  }

  // Load data based on selected segment/smart segment
  redirectOnSegmentChange() {
    const segment = this.activeSmartSegmentId
      ? this.getDetailById(this.activeSmartSegmentId)
      : this.getDetailById(this.activeSegmentId);

    switch (this.buildingBlock) {
      case this.ALL_ORDERS:
        const query = this.newSegmentService.getSegmentQuery(segment);
        this.router.navigate(['/order-V2/list'], { queryParams: query });
        break;
      case this.RETURN_NO_SCAN:
        const returnQuery = this.newSegmentService.getReturnSegmentQuery(segment.name, segment.filterQuery);
        this.router.navigate(['/process-no-scan-return/receive-return-list'], { queryParams: returnQuery });
        //set the selected segment's group name to local stroage
        if (this.buildingBlock === 'return-no-scan') {
          this.newSegmentService.getDetailsOfActiveSegment(JSON.parse(JSON.stringify(segment || '')));
        }
        break;
    }
  }

  trackByIndex(index: number): any {
    return index;
  }

  // View more of my view
  viewMore(): void {
    if (this.page * this.defaultMyView < this.myView.count) {
      this.page += 1; // Increase the page number
      this.loadMyView(false, true); // Load more data if not all data is already loaded
    } else {
      this.page += 1; // Just increase the page number and dataCount without fetching
      this.dataCount = this.page * this.defaultMyView;
    }
  }

  // View less of my view
  viewLess(): void {
    if (this.page > 1) {
      this.page -= 1; // Decrease the page number
      this.dataCount = this.page * this.defaultMyView; // Adjust dataCount based on the new page
    }
  }

  toggleView(): void {
    this.dataCount = this.dataCount === this.defaultMyView ? this.myView.count : this.defaultMyView;
  }

  // Update sgement or smart segment count if mismatched with listing count
  updateCountById(key: string, newValue: string | number): void {
    if (this.segmentsCount.hasOwnProperty(key) && this.segmentsCount[key] !== newValue) {
      this.segmentsCount[key] = newValue;
    }
  }

  // Show active segment and smart segment based on selectedView from param
  showActiveSegment() {
    // Extract the selected view parameter from the route
    let selectedView = this.route.snapshot?.queryParams?.selectedView;

    // Check if selectedView contains '$' and replace if necessary, then convert to number
    selectedView = selectedView.includes('$')
      ? selectedView.replaceAll('$', ' ')
      : selectedView.includes('IS|')
      ? selectedView.split('IS|')?.[1]
      : selectedView;

    const [id, name] = selectedView.includes('_') ? selectedView.split('_') : [null, selectedView];
    // Find the segment that matches the selected view and get its ID
    const activeSegment = this.currentSegmentData.find(x => (id ? x.id == id && x.name === name : x.name === name));

    //on reload set active segment's group name to local stroage
    if (this.buildingBlock == 'return-no-scan') {
      this.newSegmentService.getDetailsOfActiveSegment(activeSegment || '');
    }
    if (activeSegment) {
      this.activeSmartSegmentId = activeSegment.id;
      if (!activeSegment?.isCore) {
        this.getMyViewDetails();
        this.loadMyView(true);
      } else {
        const segment = this.currentSegmentData.find(
          x => x.smartSegmentIds && x.smartSegmentIds.includes(this.activeSmartSegmentId)
        );
        this.activeSegmentId = segment ? segment.id : activeSegment.id;
        // Iterate over the segments to find the one that contains the activeSmartSegmentId in its smartSegmentIds
        // for (const seg of this.currentSegmentData) {
        //   if (seg.smartSegmentIds && seg.smartSegmentIds.includes(this.activeSmartSegmentId)) {
        //     this.activeSegmentId = seg.id;
        //     // Call a method to get the current smart segment details
        //     this.getcurrentSmartSegment(false);
        //     this.getSegmentCount('segment', true);
        //   }
        // }
        this.getcurrentSmartSegment(false);
        this.getSegmentCount('segment', true);
      }
    }

    const isSegment = this.isFilterApplied();
    if (!isSegment) {
      this.activeSegmentId = '';
      this.activeSmartSegmentId = '';
    }
  }

  ngOnDestroy() {
    if (this.segmentSubscription$) this.segmentSubscription$.unsubscribe();
    if (this.countSubscription$) this.countSubscription$.unsubscribe();
    if (this.listingCurrentData$) this.listingCurrentData$.unsubscribe();
    if (this.isSegmentCreated$) this.isSegmentCreated$.unsubscribe();
    this.allOrdersMappings$?.unsubscribe();
  }
}

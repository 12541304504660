<div class="toast-content">
  <div class="icon-container">
    <!-- Use your custom loading image here -->
    <img
      class="loading-icon"
      src="https://ik.imagekit.io/2gwij97w0o/import_in_progress.png?updatedAt=1727265421559"
      alt="Loading"
    />
  </div>
  <div class="message-container">
    <span class="bold">Your import is in progress...</span><br />
    <span class="progress-font"
      >Once completed, you can view the import status in <strong>Files</strong> in the side navigation here.</span
    >
  </div>
  <div class="icon-container">
    <!-- Use your custom close image here -->
    <img
      class="close-icon"
      src="https://ik.imagekit.io/2gwij97w0o/Vector%20(1).png?updatedAt=1727266587962"
      alt="Close"
      (click)="closeSnackbar()"
    />
  </div>
</div>

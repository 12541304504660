<div class="upgrade-product-plan-container">
  <div class="upgrade-plan">
    <div>
      <div class="content">
        <div class="header">
          <img src="https://ik.imagekit.io/2gwij97w0o/Upgrade-Filled.svg?updatedAt=1724841951801" alt="" />
          <span class="font">Upgrade to unlock this feature</span>
        </div>

        <div class="title">Track and manage your inventory across locations</div>
        <div class="ul-parent">
          <ul>
            <li>
              <span> A centralized view of your inventory across multiple locations</span>
            </li>
            <li>
              <span>
                Automatically sync inventory across multiple sales channels
              </span>
            </li>
            <li>
              <span>
                Track inventory at each stage: sellable, non-sellable, inward in progress, outward in progress
              </span>
            </li>
          </ul>
        </div>

        <app-get-feature [showRedBtn]="true"></app-get-feature>
      </div>
    </div>
    <div class="stock-img">
      <img src="https://ik.imagekit.io/2gwij97w0o/Group%20483133.png?updatedAt=1724841846334" alt="" />
    </div>
  </div>
</div>

export const defaultMappings = {
  settings: {
    bank_account_details: 'show',
    fees: 'hide',
    customer_notifications: {
      whatsapp_checkboxes_visibility: 'upgrade',
      view_whatsapp_pricing: 'upgrade'
    },
    customer_portal: {
      setup_tracking_page_domain: {
        add_domain_cta: 'upgrade'
      }
    },
    pincode_serviceability: 'show',
    payment_terms: 'hide',
    order_routing: {
      create_rule_cta: 'upgrade',
      default_rule: 'show'
    },
    weight_discrepancy: 'show',
    assets: {
      totes: 'hide',
      pigeon_hole: 'hide',
      staging_area: 'hide',
      shelfs: 'hide'
    },
    incidents: 'show',
    brand: 'show',
    e_invoicing: 'hide',
    serial_number_scan: {
      banner: {
        serial_number_scan_is_only_available_for_eshopbox_fulfilment_locations_in_your_present_plan_to_enable_serial_number_scan_for_your_self_fulfilment_locations_consider_upgrading_your_plan:
          'hide'
      }
    },
    inventory_locations: {
      count: 'unlimited'
    },
    risk_score: 'hide',
    custom_fields: 'hide',
    packaging: 'hide',
    team: {
      count: 'unlimited',
      add_member: {
        settings: {
          payment_settings: 'hide',
          risk_score: 'hide',
          custom_fields: 'hide',
          customer_portal: 'show',
          team: 'show',
          order_routing: 'show',
          inventory_settings: 'hide',
          weight_discrepancy: 'show',
          shipping_mode: 'show',
          account_and_brand: 'show',
          incidents: 'show',
          locations: 'show',
          packaging_settings: 'hide',
          e_invoicing: 'hide'
        },
        permissions: {
          reports: 'show',
          all_b2b_order: 'hide',
          all_orders: 'show',
          all_products: 'show',
          all_consignments: 'hide',
          payments: 'show',
          apps: 'show',
          all_removals: 'hide'
        },
        self_fulfilment: {
          allow_access_to_manage_assets_i_e_shelf_tote_pigeon_hole_staging_area: 'hide',
          allow_access_to_update_inventory: 'hide'
        }
      }
    },
    batch_tracking: {
      enable_batch_tracking: 'hide',
      banner: {
        batch_tracking_is_only_available_for_Eshopbox_fulfilment_locations_in_your_present_plan_To_enable_batch_tracking_subscribe_to_Eshopbox_fulfilment_location_or_consider_upgrading_your_plan:
          'hide'
      }
    },
    self_fulfilment_settings: {
      scan_and_manifest: 'upgrade',
      picklist_template: 'show',
      single_step_return_processing: 'show',
      bulk_pack: 'show',
      scan_and_return: 'upgrade',
      device_based_picking: 'upgrade',
      auto_recommend_packaging: 'upgrade',
      b2b_order_processing: 'upgrade',
      skip_picking: 'show',
      scan_and_pack: 'upgrade'
    },
    cod_payment_terms: 'show',
    team_notifications: {
      consignment_notifications: 'upgrade'
    },
    kyc: 'show',
    shipping_mode: 'show',
    rate_calculator: 'show',
    locations: {
      my_locations: {
        self_fulfilment: {
          sku_level: 'upgrade',
          no_invetonry: 'show',
          unit_level: 'upgrade'
        }
      },
      eshopbox_fulfilment_centers: {
        enable_cta: 'upgrade'
      }
    },
    account: 'show'
  },
  help: {
    full_help: 'show'
  },
  reports: {
    all_reports: 'show'
  },
  payments: {
    market_place: {
      overview: 'hide',
      order_settlements: 'hide',
      previous_payments: 'hide'
    },
    website_cod: {
      cod: 'show'
    }
  },
  orders: {
    all_orders: {
      draft_returns_segment: 'hide',
      export: {
        split_virtual_kit_orders_into_component: 'hide',
        draft_return: 'hide',
        sold_as_virtual_kit: 'hide'
      },
      unfulfillable_segment: 'hide'
    },
    process_orders: 'hide',
    process_b2b_orders: 'hide',
    process_returns: 'hide',
    all_b2b_orders: {
      create: {
        from_location_eshopbox_fc: 'hide',
        from_location_sf_fc: 'hide'
      }
    }
  },
  all_exports: {
    save_template_cta: 'upgrade',
    schedule_report_cta: 'upgrade',
    save_template_cta_count: '0',
    schedule_report_cta_count: '0'
  },
  inventory: {
    all_products: {
      more_filters: {
        available_on_channel: 'hide',
        component: 'hide',
        type: 'hide'
      },
      import: {
        merge_products: {
          custom_field_messaging: 'hide'
        },
        manage_product_availability_on_sales_channel: 'hide',
        create_new_or_update_exisiting_products: {
          product: {
            custom_field_messaging: 'hide',
            required_serial_number: 'hide'
          },
          virtual_kit: {
            custom_field_messaging: 'hide',
            page: 'upgrade'
          }
        },
        request_verification_of_weight_and_dimension: {
          custom_field_messaging: 'hide'
        }
      },
      create: {
        product: {
          required_serial_number: 'upgrade',
          banner: {
            serial_number_scan_is_only_available_for_eshopbox_fulfilment_locations_in_your_present_plan: 'hide'
          }
        },
        virtual_kit: 'upgrade',
        batch_tracking: {
          enable_batch_tracking_toggle: 'hide',
          banner: {
            batch_tracking_is_only_available_for_Eshopbox_fulfilment_locations_in_your_present_plan: 'hide'
          }
        }
      },
      availability: 'hide',
      filters: {
        sales_channel: 'hide',
        locations: 'hide'
      },
      stock: 'upgrade',
      product_information: {
        sellable_units: 'hide',
        sales_channel: 'hide',
        locations: 'hide',
        actions: {
          edit: {
            requires_serial_number: {
              required_serial_number: 'hide',
              banner: {
                serial_number_scan_is_only_available_for_eshopbox_fulfilment_locations_in_your_present_plan: 'hide'
              }
            },
            batch_tracking: {
              enable_batch_tracking_toggle: 'hide',
              banner: {
                batch_tracking_is_only_available_for_Eshopbox_fulfilment_locations_in_your_present_plan: 'hide'
              }
            }
          }
        }
      },
      export: {
        location_details: 'hide',
        inventory_details: 'hide',
        availability: 'hide',
        what_report_do_you_want_to_export: {
          product_and_inventory: {
            and_inventory: 'hide',
            with_consolidated_inventory: 'hide'
          }
        },
        product_details: {
          requires_serial_number: 'hide'
        }
      },
      segments: {
        all: 'hide',
        inward_in_progress: 'hide',
        not_in_stock: 'hide',
        non_sellable: 'hide',
        outward_in_progress: 'hide',
        sellable: 'hide'
      }
    },
    manage_inventory: {
      cycle_count: 'hide',
      mark_found: 'hide',
      inventory_snapshot: {
        import: 'hide'
      },
      weight_and_dimension_verification: 'hide',
      putaway_inventory: 'hide',
      shelf_transfer: 'hide'
    },
    remove_inactive_inventory: {
      ship: 'hide',
      pack: 'hide',
      all_removals: 'hide'
    },
    product_verification: 'hide'
  },
  consignments: {
    receive: 'hide',
    send_inventory_to_eshopbox_fc: 'hide',
    all_consignments: 'hide',
    remove_inactive_inventory: 'hide',
    create: {
      to_location_eshopbox_fc: 'hide',
      to_sf_fc: 'hide',
      page: 'hide'
    },
    close: 'hide'
  },
  home_screen: {
    setup_guide: {
      setup_your_workspace: 'show',
      start_shipping_order_from_eshopbox_fulfilment_centre: 'hide',
      start_shipping_orders_from_your_fulfilment_location: 'hide',
      tools_to_get_you_started: 'show',
      help_resources: 'hide'
    }
  },
  apps: {
    vinculum: {
      set_automations: {
        sync_inventory_level: {
          toggle: 'upgrade'
        }
      },
      more_automation_to_protect_your_bottom_line: {
        toggle: 'upgrade'
      },
      integrations: 'show'
    },
    whatsapp: {
      integrations: 'upgrade'
    },
    build_custom_app_in_eshopbox: {
      new_app: {
        workspace: {
          create_new_development_workspace: 'upgrade'
        }
      }
    },
    shopify: {
      set_automations: {
        sync_inventory_level: {
          toggle: 'upgrade'
        },
        would_you_like_to_import_all_orders: {
          import_orders_only_if_satisfies_the_following_condition: 'upgrade',
          import_all_orders: 'hide'
        }
      },
      more_automation_to_protect_your_bottom_line: {
        toggle: 'upgrade'
      }
    },
    unicommerce: {
      set_automations: {
        sync_inventory_level: {
          toggle: 'upgrade'
        }
      },
      more_automation_to_protect_your_bottom_line: {
        toggle: 'upgrade'
      },
      integrations: 'show'
    },
    nykaa: {
      integrations: 'upgrade'
    },
    clear: {
      integrations: 'upgrade'
    },
    woocommerce: {
      set_automations: {
        sync_inventory_level: {
          toggle: 'upgrade'
        },
        would_you_like_to_import_all_orders: {
          import_orders_only_if_satisfies_the_following_condition: 'upgrade',
          import_all_orders: 'hide'
        }
      },
      more_automation_to_protect_your_bottom_line: {
        toggle: 'upgrade'
      }
    },
    fynd: {
      set_automations: {
        sync_inventory_level: {
          toggle: 'upgrade'
        }
      },
      more_automation_to_protect_your_bottom_line: {
        toggle: 'upgrade'
      },
      integrations: 'show'
    },
    razorpay: {
      integrations: 'upgrade'
    },
    marketplaces: {
      amazon: 'upgrade',
      tata_cliq: 'upgrade',
      ajio: 'upgrade',
      nykaa: 'upgrade',
      flipkart: 'upgrade',
      myntra: 'upgrade',
      others: 'upgrade'
    },
    servitium_app: {
      integrations: 'upgrade'
    }
  },
  plan: null
};
